import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import LandingPage from '../../../../components/LandingPage';
import EnterEmail from './EnterEmail'
import InformationForgotPassword from './InformationForgotPassword';
import { forgotPassword, resetUserFlags } from '../../../../actions/user';

const propTypes = {
  forgotPassword: PropTypes.func,
  resetUserFlags: PropTypes.func,
  user: PropTypes.object,
}

const ForgotPasswordDialog = ({
  forgotPassword,
  resetUserFlags,
  user,
}) => {
  const [state, setState] = useState({
    page: 1,
    email: '',
  });

  const forgotUserPassword = () => {
    forgotPassword(state.email);
    setState({ ...state, page: 2 });
  }

  const renderPage = () => {
    switch (state.page) {
      case 1:
        return <EnterEmail
          email={state.email}
          forgotPassword={forgotUserPassword}
          onChange={event => setState({ ...state, email: event.target.value.trim() })} />
      case 2:
        return <InformationForgotPassword
          resetUserFlags={resetUserFlags}
          user={user.toJS()} />
      default:
        break;
    }
  }

  return <LandingPage
    headline='Passwort zurücksetzen'>
    {renderPage()}
  </LandingPage>
}

function dispatchToProps(dispatch) {
  return bindActionCreators({
    forgotPassword,
    resetUserFlags,
  }, dispatch)
}

function stateToProps(state) {
  const user = state.get('user');
  return {
    user
  }
}

ForgotPasswordDialog.propTypes = propTypes;
export default connect(stateToProps, dispatchToProps)(ForgotPasswordDialog);