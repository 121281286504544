import React from "react";
import PropTypes from "prop-types";

import "./styles.css";

const propTypes = {
  children: PropTypes.array,
  fullAppHeight: PropTypes.bool,
  padding: PropTypes.bool,
};

const defaultProps = {
  fullAppHeight: true,
  padding: true,
};

const Container = ({ children, fullAppHeight, padding }) => {
  return (
    <div
      className={`
    layout-conainer 
    ${fullAppHeight ? "layout-container-full-app-height" : ""}
    ${
      padding
        ? "layout-container-with-padding"
        : "layout-container-withoug-padding"
    }
    `}
    >
      {children}
    </div>
  );
};

Container.propTypes = propTypes;
Container.defaultProps = defaultProps;
export default Container;
