/* eslint-disable import/namespace */
/* eslint-disable no-unused-vars */
import { createAction } from "redux-actions";

import HTTP from "../lib/http";
import API from "../lib/api";
import {
  CONFIRM_USER_STATUS,
  RESET_REGISTER_FLAGS,
  REGISTER_USER_STATUS,
} from "../constants/actions/actions";
import { pending, success, fail } from "../constants/status";

export const resetRegisterFlags = createAction(RESET_REGISTER_FLAGS);
const registerUserStatus = createAction(REGISTER_USER_STATUS);
const confirmUserStatus = createAction(CONFIRM_USER_STATUS);

export function registerUser(user) {
  return async (dispatch, getState) => {
    dispatch(registerUserStatus(pending));
    await HTTP.post(API.registrationUser, { user })
      .then(function (response) {
        dispatch(registerUserStatus(success));
      })
      .catch(function (error) {
        dispatch(registerUserStatus(fail));
        console.log("ERROR REGISTER USER", error);
      });
  };
}

export function registerAdmin(user, organization) {
  return async (dispatch, getState) => {
    dispatch(registerUserStatus(pending));
    await HTTP.post(API.registrationAdmin, { demo: { user, organization } })
      .then(function (response) {
        dispatch(registerUserStatus(success));
      })
      .catch(function (error) {
        dispatch(registerUserStatus(fail));
        console.log("ERROR REGISTER USER/ORGANZATION", error);
      });
  };
}

export function confirmUser(token) {
  return async (dispatch, getState) => {
    dispatch(confirmUserStatus(pending));
    await HTTP.post(API.confirmation, { token })
      .then(function (response) {
        dispatch(confirmUserStatus(success));
      })
      .catch(function (error) {
        console.log("ERROR CONFIRM USER", error);
        dispatch(confirmUserStatus(fail));
      });
  };
}

export function registerFreeAccount(user, organization) {
  const licenceModel = {
    name: "Free",
  };
  return async (dispatch, getState) => {
    dispatch(registerUserStatus(pending));

    // H O N E Y P O T - C H E C K
    if (user.age > 0) {
      dispatch(registerUserStatus(success));
      return;
    }

    await HTTP.post(API.registrationFreeAccount, {
      user,
      organization,
      licenceModel,
    })
      .then(function (response) {
        dispatch(registerUserStatus(success));
      })
      .catch(function (error) {
        dispatch(registerUserStatus(fail));
        console.log("ERROR REGISTER USER/ORGANZATION", error);
      });
  };
}
