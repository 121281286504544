import axios from "axios";
import Storage from "./storage";

let instance = axios.create({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

instance.interceptors.request.use(function(config) {
  const newConfig = { ...config };
  if (Storage.get("jwt"))
    newConfig.headers = {
      ...newConfig.headers,
      Authorization: "bearer " + Storage.get("jwt"),
    };
  /*
if(config.params)
  newConfig.params = config.params.split(/(?=[A-Z])/).join('_');
console.log(config.data)
if(config.data)
  newConfig.data = config.data.split(/(?=[A-Z])/).join('_');
*/
  return newConfig;
});

instance.interceptors.response.use(function(config) {
  if (config.headers.jwt) Storage.setToken(config.headers.jwt);
  return config;
});

export default instance;
