/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Divider from "@mui/material/Divider";
import withStyles from "@mui/styles/withStyles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  dropdownScreenshotListItemElements,
  DELETE_KEY,
  IMAGE_KEY,
} from "../../../../../../constants/contextMenuEntries";

import { deleteMediaFile } from "../../../../../../actions/mediaFiles";

import CustomModal from "../../../../../../components/CustomModal";
import CustomImageView from "../../../../../../components/CustomImageView";
import Confirmation from "../../../../../../components/dialogs/Confirmation";
import ContextMenu from "../../../../../../containers/ContextMenu";

import * as styles from "../../../../../../styles/styles";
import "./styles.css";

const propTypes = {
  classes: PropTypes.object,
  screenshotItem: PropTypes.object,
  deleteMediaFile: PropTypes.func,
};

const overrideStyles = (theme) => ({
  root: {
    paddingTop: "0",
    paddingBottom: "0",
    paddingLeft: "0",
    margin: "0.5rem 1rem 0.5rem 1rem",
    width: "auto",
    backgroundColor: styles.white,
    boxShadow: styles.boxShadowListElement,
    "&:hover": {
      boxShadow: styles.boxShadowListElementHover,
    },
  },
  root_select: {
    paddingTop: "0",
    paddingBottom: "0",
    paddingLeft: "0",
    margin: "0.5rem 1rem 0.5rem 1rem",
    width: "auto",
    backgroundColor: "rgba(89, 83, 210, 0.08)",
    boxShadow: styles.boxShadowListElement,
    "&:hover": {
      boxShadow: styles.boxShadowListElementHover,
    },
  },
  vertical: {
    marginRight: "2rem",
  },
});

const ScreenshotListItem = ({ screenshotItem, deleteMediaFile, classes }) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [menuEntryKey, setMenuEntryKey] = React.useState(null);

  const closeModal = () => {
    setModalOpen(false);
    setMenuEntryKey(null);
  };

  const openModal = (menuEntryKey) => {
    const bool = typeof menuEntryKey === "string";
    setModalOpen(bool);
    setMenuEntryKey(bool ? menuEntryKey : false);
  };

  const onClickImage = (event) => {
    event.stopPropagation();
    openModal(IMAGE_KEY);
  };

  const onClickMediaFileDelete = () => {
    deleteMediaFile(screenshotItem.id);
    closeModal();
  };

  const inserted_at = moment(screenshotItem.inserted_at);
  const image = screenshotItem.image.original;
  const title = "Screenshot";

  return (
    <>
      {modalOpen &&
        renderModalContent(
          modalOpen,
          menuEntryKey,
          closeModal,
          onClickMediaFileDelete,
          screenshotItem
        )}
      <ListItem
        classes={{ root: classes.root }}
        className="screenshot-list-item-list-item"
      >
        <img
          onClick={onClickImage}
          src={image}
          className="screenshot-list-item-image-thumbnail"
          alt={`Bild`}
        />
        <Divider
          classes={{ vertical: classes.vertical }}
          orientation={"vertical"}
        />
        <ListItemText
          style={{ flex: 8 }}
          primary={title}
          secondary={inserted_at.format("dd. DD.MM.YYYY HH:mm")}
        />

        <ListItemSecondaryAction className="screenshot-list-item-secondary-action">
          <ContextMenu
            color={styles.darkBlueBase}
            contextMenuEntries={dropdownScreenshotListItemElements}
            onMenuEntryClicked={(menuEntryKey) =>
              handleOnMenuEntryClicked(menuEntryKey, openModal)
            }
          />
        </ListItemSecondaryAction>
      </ListItem>
    </>
  );
};

function handleOnMenuEntryClicked(menuEntryKey, openModal) {
  openModal(menuEntryKey);
}

function renderModalContent(
  modalOpen,
  menuEntryKey,
  closeModal,
  onClickMediaFileDelete,
  screenshotItem
) {
  switch (menuEntryKey) {
    case DELETE_KEY:
      return (
        <Confirmation
          highlightedText={"Screenshot"}
          headline="Screenshot löschen"
          leftSideText="Möchten Sie diesen"
          modalOpen={modalOpen}
          onClose={() => closeModal()}
          onClick={onClickMediaFileDelete}
          rightSideText={`erstellt am ${moment(
            screenshotItem.inserted_at
          ).format("DD.MM.YYYY")} wirklich löschen?`}
          textButton="Löschen"
        />
      );
    case IMAGE_KEY:
      return (
        <CustomModal
          showCloseButton={true}
          open={modalOpen}
          image={screenshotItem.image.original}
          onClose={() => closeModal()}
          render={() => (
            <CustomImageView>
              <img
                src={screenshotItem.image.original}
                className="image-modal-image"
                alt={`Bild`}
              />
            </CustomImageView>
          )}
        />
      );
    default:
      return <div></div>;
  }
}

function dispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteMediaFile,
    },
    dispatch
  );
}

ScreenshotListItem.propTypes = propTypes;
export default connect(
  null,
  dispatchToProps
)(withStyles(overrideStyles, { withTheme: true })(ScreenshotListItem));
