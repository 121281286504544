/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";

import { ListItem, Typography, Grid, Button } from "@mui/material";

import * as styles from "../../../../../styles/styles";

const propTypes = {};

const UpgradePlanListItem = () => {
  return (
    <ListItem
      sx={{
        backgroundColor: "#D1C4E9",
        marginTop: "5px",
        marginBottom: "5px",
        paddingTop: "20px",
        paddingBottom: "20px",
        paddingLeft: "15px",
        paddingRight: "15px",
        borderRadius: "10px",
      }}
    >
      <Grid container>
        <Grid item xs={10}>
          <Typography>
            Es können keine weiteren Projekte erstellt werden. Führen Sie bitte
            ein Upgrade durch, um beliebig viele Projekte erstellen, sowie
            weitere Funktionen nutzen zu können.
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              justifyContent: "end",
            }}
          >
            <Button
              href="https://www.netpipe.io/preise"
              target="_blank"
              rel="noreferrer"
              variant="contained"
            >
              Upgrade
            </Button>
          </div>
        </Grid>
      </Grid>
    </ListItem>
  );
};

UpgradePlanListItem.propTypes = propTypes;
export default UpgradePlanListItem;
