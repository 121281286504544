/* eslint-disable no-unused-vars */
import DialogContent from "@mui/material/DialogContent";
import List from "@mui/material/List";
import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React from "react";

import SingleActionDownloadItems from "./SingleActionDownloadItems";
import { subheadline } from "../styles";

const propTypes = {
  classes: PropTypes.object,
  firstCategoryFiles: PropTypes.array,
  secondCategoryFiles: PropTypes.array,
  thirdCategoryFiles: PropTypes.array,
  project: PropTypes.object,
};

const overrideStyles = (theme) => ({
  subheadline,
});

const SingleAction = ({
  classes,
  firstCategoryFiles,
  secondCategoryFiles,
  thirdCategoryFiles,
  project,
}) => {
  return (
    <>
      <DialogContent>
        <Typography color="primary" variant="body2">
          Dokumentation
        </Typography>
        <List>
          <SingleActionDownloadItems
            project={project}
            files={firstCategoryFiles}
          />
        </List>
        <Typography
          classes={{ root: classes.subheadline }}
          color="primary"
          variant="body2"
        >
          Geoformate
        </Typography>
        <List>
          <SingleActionDownloadItems
            project={project}
            files={secondCategoryFiles}
          />
        </List>
        <Typography
          classes={{ root: classes.subheadline }}
          color="primary"
          variant="body2"
        >
          Lokale Koordinaten
        </Typography>
        <List>
          <SingleActionDownloadItems
            project={project}
            files={thirdCategoryFiles}
          />
        </List>
      </DialogContent>
    </>
  );
};

SingleAction.propTypes = propTypes;
export default withStyles(overrideStyles, { withTheme: true })(SingleAction);
