import { fromJS } from 'immutable';
import {
  SEARCH_USER,
} from "../constants/actions/actions";

const initialState = fromJS({
  searchTerm: ''
});

export function userAdministration(state = initialState, action) {
  switch (action.type) {
    case SEARCH_USER:
      return state
        .set('searchTerm', action.payload)
    default:
      return state;
  }
}