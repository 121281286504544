export const LAYER_TYPE_LINE_IDX = 1;
export const LAYER_TYPE_REFERENCE_POINT_IDX = 2;
export const LAYER_TYPE_POINT_IDX = 3;
export const LAYER_TYPE_GNSS_POINT_IDX = 7;
export const LAYER_TYPE_GNSS_LINE_IDX = 8;
export const LAYER_TYPE_GNSS_POLYGON_IDX = 9;
export const LAYER_TYPE_GNSS_CONTINUOUS_LINE_IDX = 10;
export const LAYER_TYPE_GNSS_REF_POINT_IDX = 11;


export const LAYER_TYPE_NAME_MAP = {
  1: "Linie",
  2: "Referenzpunkte",
  3: "Punkt",
  7: "GNSS-Punkte",
  8: "GNSS-Linie",
  9: "GNSS-Polygon",
  10: "GNSS-Spur",
  11: "GNSS-Passpunkte"
};
