/* eslint-disable import/namespace */
/* eslint-disable no-unused-vars */
import { createAction } from "redux-actions";

import HTTP from "../lib/http";
import API from "../lib/api";

export function newsletterRegistration(email) {
  return async (dispatch, getState) => {
    await HTTP.post(API.newsletter, {
      email: email,
    });
  };
}
