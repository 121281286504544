/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import React, { useState } from "react";

import withStyles from "@mui/styles/withStyles";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

import ButtonGroup from "../../../components/ButtonGroup";
import CustomDialog from "../../../components/CustomDialog";
import HintView from "../../../components/HintView";
import { HINT_UPDATE_BEHAVIOUR } from "../../../constants/hinttypesmapping";
import { validateTextFields } from "../../../sharedFunctions/validation";

import * as styles from "../../../styles/styles";

const propTypes = {
  classes: PropTypes.object,
  measurement: PropTypes.object,
  layerConfig: PropTypes.object,
  componentList: PropTypes.array,
  modalOpen: PropTypes.bool,
  onPositiveButtonClicked: PropTypes.func,
  onNegativeButtonClicked: PropTypes.func,
};

const overrideStyles = (theme) => ({
  alignText: {
    margin: "auto 0 0 0",
  },
});

const EditMeasurementPointDialog = ({
  classes,
  measurement,
  layerConfig,
  componentList,
  modalOpen,
  onPositiveButtonClicked,
  onNegativeButtonClicked,
}) => {
  const { id_user, component, depth } = measurement;

  const activeComponentIndex = componentList.findIndex(
    (item) => item.label === component
  );

  const componentValue =
    activeComponentIndex > 0 ? componentList[activeComponentIndex] : null;

  const [stateIdUser, setIdUser] = useState(id_user ? id_user : measurement.id);
  const [stateComponentInputValue, setStateComponentInputValue] = useState(
    component ? component : ""
  );
  const [stateComponentValue, setStateComponentValue] =
    useState(componentValue);
  const [stateDepth, setDepth] = useState(depth ? depth : 0.0);

  const [stateDisablePositiveButton, setDisablePositiveButton] = useState(true);

  const validationValues = React.useMemo(() => {
    return {
      id_user: {
        value: id_user ? id_user : measurement.id,
        required: true,
      },
      component: {
        value: component ? component : "",
        required: false,
      },
      depth: {
        value: depth ? depth : 0.0,
        required: false,
      },
    };
  }, [id_user, component, depth, measurement.id]);

  const onChangeID = (event) => {
    const id_user = event.target.value;
    const newValues = {
      id_user: id_user,
      component: stateComponentInputValue,
      depth: stateDepth,
    };
    setIdUser(id_user);
    setDisablePositiveButton(validateTextFields(validationValues, newValues));
  };

  const onChangeComponent = (event) => {
    const component = event.target.value;
    const newValues = {
      id_user: stateIdUser,
      component: component,
      depth: stateDepth,
    };
    setStateComponentInputValue(component);
    setDisablePositiveButton(validateTextFields(validationValues, newValues));
  };

  const onChangeComponentAutocomplete = (event, newValue) => {
    let component = "";
    if (newValue) {
      component = newValue;
    }
    const newValues = {
      id_user: stateIdUser,
      component: component,
      depth: stateDepth,
    };
    setStateComponentInputValue(newValue);
    setDisablePositiveButton(validateTextFields(validationValues, newValues));
  };

  const onChangeDepth = (event) => {
    const depth = event.target.value;
    const newValues = {
      id_user: stateIdUser,
      component: stateComponentInputValue,
      depth: depth,
    };
    setDepth(depth);
    setDisablePositiveButton(validateTextFields(validationValues, newValues));
  };

  const handleOnPositiveButtonClicked = () => {
    const updateMeasurement = {
      ...measurement,
      id_user: stateIdUser,
      component: stateComponentInputValue,
      depth: stateDepth,
    };
    onPositiveButtonClicked(updateMeasurement);
  };

  const renderCustomIdTextField = () => {
    return (
      <TextField
        fullWidth={true}
        InputProps={{ classes: { input: classes.alignText } }}
        margin="none"
        label="ID"
        multiline={false}
        inputProps={{
          maxLength: 255,
        }}
        helperText={`${stateIdUser.length}/255`}
        onChange={onChangeID}
        placeholder="ID"
        required={true}
        style={{
          margin: `${styles.spacing24} 0 ${styles.spacing24} 0`,
        }}
        variant="outlined"
        value={stateIdUser}
      />
    );
  };

  const renderComponentTextField = () => {
    return (
      <TextField
        fullWidth={true}
        InputProps={{ classes: { input: classes.alignText } }}
        margin="none"
        label="Bauteilinformation"
        multiline={false}
        inputProps={{
          maxLength: 255,
        }}
        helperText={`${stateComponentInputValue.length}/255`}
        onChange={onChangeComponent}
        placeholder="Bauteilinformation"
        required={false}
        style={{ margin: `0 0 ${styles.spacing24} 0` }}
        variant="outlined"
        value={stateComponentInputValue}
      />
    );
  };

  const renderComponentAutocompleteInput = () => {
    return (
      <div style={{ margin: `0 0 ${styles.spacing32} 0` }}>
        <Autocomplete
          fullWidth={true}
          freeSolo
          id="combo-box-component"
          value={stateComponentValue}
          onChange={(event, newValue) => {
            setStateComponentValue(newValue);
          }}
          inputValue={stateComponentInputValue}
          onInputChange={onChangeComponentAutocomplete}
          options={componentList}
          renderInput={(params) => (
            <TextField {...params} label="Bezeichnung" />
          )}
          renderOption={(props, option, { inputValue }) => {
            const matches = match(option.label, inputValue, {
              insideWords: true,
            });
            const parts = parse(option.label, matches);

            return (
              <li {...props}>
                <div>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: part.highlight ? 700 : 400,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </div>
              </li>
            );
          }}
        />
      </div>
    );
  };

  const renderDepthTextField = () => {
    return (
      <TextField
        fullWidth={true}
        type="number"
        InputProps={{ classes: { input: classes.alignText } }}
        margin="none"
        label="Messpunkttiefe"
        multiline={false}
        onChange={onChangeDepth}
        placeholder="ID"
        required={false}
        style={{ margin: `0 0 ${styles.spacing24} 0` }}
        variant="outlined"
        value={stateDepth}
      />
    );
  };

  return (
    <>
      <CustomDialog
        open={modalOpen}
        onClick={handleOnPositiveButtonClicked}
        onClose={onNegativeButtonClicked}
        showHint={true}
        renderTitle={() => {
          return (
            <DialogTitle id="alert-dialog-title">
              {"Messpunkt editieren"}
            </DialogTitle>
          );
        }}
        renderHint={() => {
          return <HintView hintMessageType={HINT_UPDATE_BEHAVIOUR} />;
        }}
        renderContent={() => {
          return (
            <DialogContent>
              {renderCustomIdTextField()}
              {componentList.length > 0
                ? renderComponentAutocompleteInput()
                : renderComponentTextField()}
              {renderDepthTextField()}
            </DialogContent>
          );
        }}
        renderButtonGroup={(
          handleOnPositiveButtonClicked,
          onNegativeButtonClicked
        ) => {
          return (
            <DialogActions style={{ padding: "2.4rem 2.4rem 2rem" }}>
              <ButtonGroup
                align="right"
                leftButtonText="Abbrechen"
                leftButtonClick={onNegativeButtonClicked}
                rightButtonText={"Ändern"}
                rightButtonClick={handleOnPositiveButtonClicked}
                rightButtonDisabled={stateDisablePositiveButton}
                spacingTop={false}
              />
            </DialogActions>
          );
        }}
      />
    </>
  );
};

EditMeasurementPointDialog.propTypes = propTypes;
export default withStyles(overrideStyles, { withTheme: true })(
  EditMeasurementPointDialog
);
