/* eslint-disable import/namespace */
/* eslint-disable no-unused-vars */
import { createAction } from "redux-actions";
import {
  EDIT_MEDIA_FILE,
  REMOVE_MEDIA_FILE,
  REMOVE_MEDIA_FILES,
  ARCHIVED_REMOVE_MEDIA_FILES,
  SET_MEDIA_FILE,
  SET_MEDIA_FILES,
} from "../constants/actions/actions";
import {
  MEDIA_FILE_CREATE_EVENT,
  MEDIA_FILE_UPDATE_EVENT,
  MEDIA_FILE_DELETED_EVENT,
} from "../constants/channelevents";
import {
  deleteMediaFileFail,
  deleteMediaFileSuccess,
} from "../constants/snackbars";

import API from "../lib/api";
import HTTP from "../lib/http";
import { renderSnackbar } from "./snackbars";

export const setMediaFiles = createAction(SET_MEDIA_FILES);
export const setMediaFile = createAction(SET_MEDIA_FILE);
export const editMediaFile = createAction(EDIT_MEDIA_FILE);
export const removeMediaFile = createAction(REMOVE_MEDIA_FILE);
export const removeMediaFiles = createAction(REMOVE_MEDIA_FILES);
export const archivedRemoveMediaFiles = createAction(
  ARCHIVED_REMOVE_MEDIA_FILES
);

export const CableActions = (message, connection, store) => {
  switch (message.event) {
    case MEDIA_FILE_CREATE_EVENT:
      store.dispatch(
        setMediaFile({
          id: message.payload.media_file.id,
          item: message.payload.media_file,
        })
      );
      break;

    case MEDIA_FILE_UPDATE_EVENT:
      store.dispatch(
        editMediaFile({
          id: message.payload.media_file.id,
          item: message.payload.media_file,
        })
      );
      break;
    case MEDIA_FILE_DELETED_EVENT:
      store.dispatch(
        removeMediaFile({
          id: message.payload.media_file.id,
          item: message.payload.media_file,
        })
      );
      break;
    default:
      break;
  }
};

export function deleteMediaFile(id) {
  return async (dispatch, getState) => {
    await HTTP.delete(API.mediaFiles.deleteMediaFile(id))
      .then(function (response) {
        dispatch(renderSnackbar(deleteMediaFileSuccess));
      })
      .catch(function (error) {
        dispatch(renderSnackbar(deleteMediaFileFail));
        console.log("DELETE MEDIA FILE ERROR", error);
      });
  };
}
