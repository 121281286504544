/* eslint-disable import/namespace */
import { createAction } from "redux-actions";
import {
  SELECT_PROJECTS,
  UNSELECT_PROJECTS,
  SEARCH_PROJECTS,
  SET_PAGINATION_PAGE_COUNT,
  SET_ACTIVE_PAGINATION_PAGE,
  ACTIVE_PAGINATION_PAGE_UPDATED,
  TOTAL_SORTED_AND_FILTERED_PROJECT_COUNT,
} from "../constants/actions/actions";

export const searchProjects = createAction(SEARCH_PROJECTS);
export const selectProjects = createAction(SELECT_PROJECTS);
export const unselectProjects = createAction(UNSELECT_PROJECTS);
export const setPaginationPageCount = createAction(SET_PAGINATION_PAGE_COUNT);
export const setActivePaginationPage = createAction(SET_ACTIVE_PAGINATION_PAGE);
export const activePaginationPageUpdated = createAction(
  ACTIVE_PAGINATION_PAGE_UPDATED
);
export const setTotalSortedAndFilteredProjectCount = createAction(
  TOTAL_SORTED_AND_FILTERED_PROJECT_COUNT
);
