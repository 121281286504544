/* eslint-disable no-unused-vars */
import { fromJS } from "immutable";

import {
  SET_COMPONENT_CATALOG_CATEGORY_SEARCH_TERM,
  SET_ACTIVE_COMPONENT_CATALOG_UUID,
  SET_CATALOG_COMPONENTS,
  SET_SYNC_CATALOG_COMPONENTS_STATUS,
  // CATEGORY
  CREATE_CATALOG_CATEGORY,
  UPDATE_CATALOG_CATEGORY,
  DELETE_CATALOG_CATEGORY,
  // COMPONENT
  CREATE_CATALOG_COMPONENT,
  UPDATE_CATALOG_COMPONENT,
  DELETE_CATALOG_COMPONENT,
  // MATERIAL
  CREATE_CATALOG_MATERIAL,
  UPDATE_CATALOG_MATERIAL,
  DELETE_CATALOG_MATERIAL,
  // DIMENSION
  CREATE_CATALOG_DIMENSION,
  UPDATE_CATALOG_DIMENSION,
  DELETE_CATALOG_DIMENSION,
  // ORGANIZATION
  CREATE_CATALOG_ORGANIZATION_CATEGORY,
  // PIPE DEFINITION
  CREATE_CATALOG_PIPE_DEFINITION,
  DELETE_CATALOG_PIPE_DEFINITION,
} from "../constants/actions/actions";

import {
  MATERIAL,
  DIMENSION,
  PAYLOAD_CATALOG_COMPONENT,
  PAYLOAD_CATALOG_MATERIAL,
  PAYLOAD_CATALOG_DIMENSION,
} from "../constants/catalogCategoryMapping";

import { idle } from "../constants/status";

import {
  createComponentData,
  updateComponentData,
  deleteComponentData,
  setCatalogComponents,
  createCatalogOrganizationCategory,
  createCatalogCategory,
  updateCatalogCategory,
  deleteCatalogCategory,
  updateDataInPipeDefinitions,
  deleteDataInPipeDefinitions,
  createCatalogPipeDefinition,
  deleteCatalogPipeDefinition,
} from "../sharedFunctions/componentCatalogs/stateUtils";

// Split the incoming list of catalogs into seperate
// pieces. Everything can be conneceted again with the
// correct root uuid.
export const initialState = fromJS({
  componentCatalogSyncStatus: idle,
  activeComponentCatalogUuid: "",
  searchTerm: "",
  items: {
    catalogOrganizationCategories: [],
    catalogCategories: [],
    catalogComponentsCategories: [],
    catalogDimensionsCategories: [],
    catalogMaterialsCategories: [],
    catalogPipeDefinitionsCategories: [],
  },
});

export function componentCatalogs(state = initialState, action) {
  switch (action.type) {
    case SET_COMPONENT_CATALOG_CATEGORY_SEARCH_TERM: {
      return state.set("searchTerm", action.payload);
    }

    case SET_ACTIVE_COMPONENT_CATALOG_UUID: {
      return state.set("activeComponentCatalogUuid", action.payload);
    }

    case SET_CATALOG_COMPONENTS: {
      return setCatalogComponents(state, action);
    }

    case SET_SYNC_CATALOG_COMPONENTS_STATUS: {
      return state.set("componentCatalogSyncStatus", action.payload);
    }

    case CREATE_CATALOG_ORGANIZATION_CATEGORY: {
      return createCatalogOrganizationCategory(state, action);
    }
    // CATEGORY
    case CREATE_CATALOG_CATEGORY: {
      return createCatalogCategory(state, action);
    }
    case UPDATE_CATALOG_CATEGORY: {
      return updateCatalogCategory(state, action);
    }
    case DELETE_CATALOG_CATEGORY: {
      return deleteCatalogCategory(state, action);
    }

    // COMPONENT
    case CREATE_CATALOG_COMPONENT: {
      return createComponentData(
        state,
        action,
        "catalogComponentsCategories",
        PAYLOAD_CATALOG_COMPONENT
      );
    }
    case UPDATE_CATALOG_COMPONENT: {
      return updateComponentData(state, action, "catalogComponentsCategories");
    }
    case DELETE_CATALOG_COMPONENT: {
      return deleteComponentData(state, action, "catalogComponentsCategories");
    }

    // MATERIAL
    case CREATE_CATALOG_MATERIAL: {
      return createComponentData(
        state,
        action,
        "catalogMaterialsCategories",
        PAYLOAD_CATALOG_MATERIAL
      );
    }
    case UPDATE_CATALOG_MATERIAL: {
      const newState = updateDataInPipeDefinitions(state, action, MATERIAL);

      return updateComponentData(
        newState,
        action,
        "catalogMaterialsCategories"
      );
    }
    case DELETE_CATALOG_MATERIAL: {
      const newState = deleteDataInPipeDefinitions(state, action, MATERIAL);

      return deleteComponentData(
        newState,
        action,
        "catalogMaterialsCategories"
      );
    }

    // DIMENSION
    case CREATE_CATALOG_DIMENSION: {
      return createComponentData(
        state,
        action,
        "catalogDimensionsCategories",
        PAYLOAD_CATALOG_DIMENSION
      );
    }
    case UPDATE_CATALOG_DIMENSION: {
      const newState = updateDataInPipeDefinitions(state, action, DIMENSION);

      return updateComponentData(
        newState,
        action,
        "catalogDimensionsCategories"
      );
    }
    case DELETE_CATALOG_DIMENSION: {
      const newState = deleteDataInPipeDefinitions(state, action, DIMENSION);

      return deleteComponentData(
        newState,
        action,
        "catalogDimensionsCategories"
      );
    }

    // PIPE DEFINITION
    case CREATE_CATALOG_PIPE_DEFINITION: {
      return createCatalogPipeDefinition(state, action);
    }
    case DELETE_CATALOG_PIPE_DEFINITION: {
      return deleteCatalogPipeDefinition(state, action);
    }

    default:
      return state;
  }
}
