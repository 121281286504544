export const COMPONENTS = "components";
export const COMPONENT = "component";
export const MATERIALS = "materials";
export const MATERIAL = "material";
export const DIMENSIONS = "dimensions";
export const DIMENSION = "dimension";
export const PIPE_DEFINITION = "pipe_definition";
export const PIPE_DEFINITIONS = "pipe_definitions";

export const CATALOG_CATEGORY_MAPPING = {
  components: "Komponenten",
  component: "Komponente",
  materials: "Materialien",
  material: "Material",
  dimensions: "Dimensionen",
  dimension: "Dimension",
  pipe_definition: "Material/Dimension",
  pipe_definitions: "",
};

export const PAYLOAD_CATALOG_COMPONENT = "catalog_component";
export const PAYLOAD_CATALOG_MATERIAL = "catalog_material";
export const PAYLOAD_CATALOG_DIMENSION = "catalog_dimension";
