import React, { useState } from "react";
import PropTypes from "prop-types";

import PasswordField from "../../../../../components/registration/PasswordField";

import * as styles from "../../../../../styles/styles";
import "./styles.css";
import ButtonGroup from "../../../../../components/ButtonGroup";

const margin = { margin: `${styles.spacing16} 0` };

const propTypes = {
  resetPassword: PropTypes.any,
  history: PropTypes.object,
  password: PropTypes.string,
  onChangePassword: PropTypes.func,
};

const EnterPassword = ({
  resetPassword,
  history,
  password,
  onChangePassword,
}) => {
  const [valid, setValid] = useState(false);
  return (
    <>
      <PasswordField
        className=""
        placeholder="Neues Passwort eingeben"
        password={password}
        isValid={(valid) => setValid(valid)}
        onChange={onChangePassword}
        margin={margin}
        enterHit={resetPassword}
      />
      <ButtonGroup
        leftButtonText="Abbrechen"
        leftButtonClick={() => history.push("/login")}
        rightButtonClick={resetPassword}
        rightButtonText="Passwort zurücksetzen"
        rightButtonDisabled={!valid}
        align="evenly"
      />
    </>
  );
};

EnterPassword.propTypes = propTypes;

export default EnterPassword;
