/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";

import { nameMap } from "../../../../../constants/download";
import { arrayToStateObject } from "../../sharedFunctions";

import SingleActionDownloadItem from "./SingleActionDownloadItem";

import "./styles.css";

const propTypes = {
  files: PropTypes.array,
  project: PropTypes.object,
};

const SingleActionDownloadItems = ({ files, project, mediaFiles }) => {
  const [state, setState] = useState({
    ...arrayToStateObject(files),
  });

  const handleOnListItemClicked = (file) => {
    setState({ ...state, [file]: true });
  };

  const getFile = (type) => {
    let media = Object.values(mediaFiles).find(
      (mediaFile) => mediaFile && mediaFile.type === type
    );
    if (media && media.image && media.image.original) {
      return media.image.original;
    }
  };

  return files.map((file, index) => {
    let fileUrl = getFile(file);
    let fileName = getFileName(fileUrl);
    let fileExtension = getFileExtension(fileName);
    let fileNameWithoutExtension = getFileNameWithoutExtension(fileName);
    let secondaryText = setSecondaryText(fileUrl, state[file]);

    return (
      <SingleActionDownloadItem
        key={index}
        primaryText={nameMap[file]}
        secondaryText={secondaryText}
        listItemDisabled={!fileUrl}
        listItemDownloadFile={file}
        listItemDownloadLink={fileUrl}
        listItemDownloadFileName={fileNameWithoutExtension}
        listItemDownloadFileExtension={fileExtension}
        listItemIndex={index}
        onListItemClicked={handleOnListItemClicked}
      />
    );
  });
};

function getFileNameWithoutExtension(fileName) {
  if (fileName) {
    return fileName.split(".")[0];
  } else {
    return null;
  }
}

function getFileExtension(fileName) {
  if (fileName) {
    const fileNameArray = fileName.split(".");
    return fileNameArray[fileNameArray.length - 1];
  } else {
    return null;
  }
}

function getFileName(fileUrl) {
  if (fileUrl) {
    const pathArray = fileUrl.split("/");
    const lastPathItem = pathArray[pathArray.length - 1];
    const lastPathItemArray = lastPathItem.split("?");
    return lastPathItemArray[0];
  } else {
    return null;
  }
}

function setSecondaryText(fileObject, fileDownloadStatus) {
  if (!fileObject) {
    return "Nicht verfügbar";
  } else {
    return fileDownloadStatus ? "Datei heruntergeladen" : null;
  }
}

function stateToProps(state, props) {
  return {
    mediaFiles: state
      .getIn(["mediaFiles", "items"])
      .filter((mediaFile) => props.project.id === mediaFile.get("project_id"))
      .toJS(),
  };
}

SingleActionDownloadItems.propTypes = propTypes;
export default connect(stateToProps, null)(SingleActionDownloadItems);
