/* eslint-disable no-unused-vars */
import { Socket } from "phoenix";
import { CableActions as MeasureLayerConfigCableActions } from "../actions/measure_layer_configs";
import { CableActions as MeasurementCableActions } from "../actions/measurements";
import { CableActions as MeasurementLineCableActions } from "../actions/measurementlines";
import { CableActions as MeasurementSegmentCableActions } from "../actions/measurementsegments";
import { CableActions as MediaFileCableActions } from "../actions/mediaFiles";
import { CableActions as OrganizationCableActions } from "../actions/organizations";
import { CableActions as ProjectCableActions } from "../actions/projects";
import { CableActions as UserCableActions } from "../actions/user";
import { CableActions as VolumeCableActions } from "../actions/volumes";
import { CableActions as ProjectFileCableActions } from "../actions/projectFiles";
import { CableActions as TopographicPointCableActions } from "../actions/topographic_points";
import { CableActions as ComponentCatalogsCableActions } from "../actions/componentCatalogs";
import { CableActions as MeasureLayerConfigTemplatesCableActions } from "../actions/measureLayerConfigTemplates";
import { CableActions as MapLayerCableActions } from "../actions/map_layer";
import LocalStorage from "./storage";

import { SOCKET_URI } from "../constants/uri";

import {
  ORGANIZATION_UPDATE_EVENT,
  ORGANIZATION_USER_UPDATE_EVENT,
  PROJECT_CREATE_EVENT,
  PROJECT_ASSIGNED_EVENT,
  PROJECT_DEASSIGNED_EVENT,
  PROJECT_UPDATE_EVENT,
  PROJECT_DELETE_EVENT,
  PROJECT_RESET_EVENT,
  MEASURE_LAYER_CONFIGS_CREATE_EVENT,
  MEASURE_LAYER_CONFIG_UPDATE_EVENT,
  MEASURE_LAYER_CONFIG_DELETE_EVENT,
  MEASUREMENTS_CREATE_EVENT,
  MEASUREMENT_UPDATE_EVENT,
  MEASUREMENTS_DELETE_EVENT,
  MEASUREMENT_LINES_CREATE_EVENT,
  MEASUREMENT_LINE_UPDATE_EVENT,
  MEASUREMENT_SEGMENTS_CREATE_EVENT,
  MEASUREMENT_SEGMENT_UPDATE_EVENT,
  MEDIA_FILE_CREATE_EVENT,
  MEDIA_FILE_UPDATE_EVENT,
  MEDIA_FILE_DELETED_EVENT,
  VOLUMES_CREATE_EVENT,
  VOLUME_UPDATE_EVENT,
  VOLUME_DELETED_EVENT,
  USER_UPDATE_EVENT,
  USER_STATUS_EVENT,
  PROJECT_FILE_CREATE_EVENT,
  PROJECT_FILE_DELETED_EVENT,
  TOPOGRAPHIC_POINTS_CREATE_EVENT,
  TOPOGRAPHIC_POINT_UPDATE_EVENT,
  // COMPONENT CATALOG CATEGORY
  CATALOG_ORGANIZATION_CATEGORY_CREATED_EVENT,
  CATALOG_CATEGORY_UPDATED_EVENT,
  CATALOG_CATEGORY_DELETED_EVENT,
  // COMPONENT CATALOG COMPONENT
  CATALOG_COMPONENT_CATEGORY_CREATED_EVENT,
  CATALOG_COMPONENT_UPDATED_EVENT,
  CATALOG_COMPONENT_DELETED_EVENT,
  // COMPONENT CATALOG MATERIAL
  CATALOG_MATERIAL_CATEGORY_CREATED_EVENT,
  CATALOG_MATERIAL_UPDATED_EVENT,
  CATALOG_MATERIAL_DELETED_EVENT,
  // COMPONENT CATALOG DIMENSION
  CATALOG_DIMENSION_CATEGORY_CREATED_EVENT,
  CATALOG_DIMENSION_UPDATED_EVENT,
  CATALOG_DIMENSION_DELETED_EVENT,
  // COMPONENT CATALOG PIPE DEFINITION
  CATALOG_PIPE_DEFINITION_CATEGORY_CREATED_EVENT,
  CATALOG_PIPE_DEFINITION_CATEGORY_DELETED_EVENT,
  // MEASURE LAYER CONFIG TEMPLATE
  MEASURE_LAYER_CONFIG_TEMPLATE_CREATED_EVENT,
  MEASURE_LAYER_CONFIG_TEMPLATE_UPDATED_EVENT,
  MEASURE_LAYER_CONFIG_TEMPLATE_DELETED_EVENT,
  MEASURE_LAYER_CONFIG_ITEM_CREATED_EVENT,
  MEASURE_LAYER_CONFIG_ITEM_UPDATED_EVENT,
  MEASURE_LAYER_CONFIG_ITEM_DELETED_EVENT,
  // MAP_LAYER
  MAP_LAYER_CREATED_EVENT,
  MAP_LAYER_DELETED_EVENT,
  MAP_LAYER_UPDATED_EVENT,
  MAP_LAYER_TO_PROJECT_ASSIGNMENT_CREATED_EVENT,
  MAP_LAYER_TO_PROJECT_ASSIGNMENT_DELETED_EVENT
} from "../constants/channelevents";

let connection;

function dispatcher(response, conn, store) {
  switch (response.event) {
    case ORGANIZATION_UPDATE_EVENT:
    case ORGANIZATION_USER_UPDATE_EVENT:
      console.log("call OrganizationCableActions");
      OrganizationCableActions(response, conn, store);
      break;

    case PROJECT_CREATE_EVENT:
    case PROJECT_ASSIGNED_EVENT:
    case PROJECT_DEASSIGNED_EVENT:
    case PROJECT_UPDATE_EVENT:
    case PROJECT_DELETE_EVENT:
    case PROJECT_RESET_EVENT:
      ProjectCableActions(response, conn, store);
      break;

    case MEASURE_LAYER_CONFIGS_CREATE_EVENT:
    case MEASURE_LAYER_CONFIG_DELETE_EVENT:
    case MEASURE_LAYER_CONFIG_UPDATE_EVENT:
      MeasureLayerConfigCableActions(response, conn, store);
      break;

    case MEASUREMENTS_CREATE_EVENT:
    case MEASUREMENT_UPDATE_EVENT:
    case MEASUREMENTS_DELETE_EVENT:
      MeasurementCableActions(response, conn, store);
      break;

    case MEASUREMENT_LINES_CREATE_EVENT:
    case MEASUREMENT_LINE_UPDATE_EVENT:
      MeasurementLineCableActions(response, conn, store);
      break;

    case MEASUREMENT_SEGMENTS_CREATE_EVENT:
    case MEASUREMENT_SEGMENT_UPDATE_EVENT:
      MeasurementSegmentCableActions(response, conn, store);
      break;

    case MEDIA_FILE_CREATE_EVENT:
    case MEDIA_FILE_UPDATE_EVENT:
    case MEDIA_FILE_DELETED_EVENT:
      MediaFileCableActions(response, conn, store);
      break;

    case USER_UPDATE_EVENT:
    case USER_STATUS_EVENT:
      UserCableActions(response, conn, store);
      break;

    case VOLUMES_CREATE_EVENT:
    case VOLUME_UPDATE_EVENT:
    case VOLUME_DELETED_EVENT:
      VolumeCableActions(response, conn, store);
      break;

    case TOPOGRAPHIC_POINTS_CREATE_EVENT:
    case TOPOGRAPHIC_POINT_UPDATE_EVENT:
      TopographicPointCableActions(response, conn, store);
      break;

    case PROJECT_FILE_CREATE_EVENT:
    case PROJECT_FILE_DELETED_EVENT:
      ProjectFileCableActions(response, conn, store);
      break;

    case CATALOG_ORGANIZATION_CATEGORY_CREATED_EVENT:
    case CATALOG_CATEGORY_UPDATED_EVENT:
    case CATALOG_CATEGORY_DELETED_EVENT:
    case CATALOG_COMPONENT_CATEGORY_CREATED_EVENT:
    case CATALOG_COMPONENT_UPDATED_EVENT:
    case CATALOG_COMPONENT_DELETED_EVENT:
    case CATALOG_MATERIAL_CATEGORY_CREATED_EVENT:
    case CATALOG_MATERIAL_UPDATED_EVENT:
    case CATALOG_MATERIAL_DELETED_EVENT:
    case CATALOG_DIMENSION_CATEGORY_CREATED_EVENT:
    case CATALOG_DIMENSION_UPDATED_EVENT:
    case CATALOG_DIMENSION_DELETED_EVENT:
    case CATALOG_PIPE_DEFINITION_CATEGORY_CREATED_EVENT:
    case CATALOG_PIPE_DEFINITION_CATEGORY_DELETED_EVENT:
      ComponentCatalogsCableActions(response, conn, store);
      break;

    case MEASURE_LAYER_CONFIG_TEMPLATE_CREATED_EVENT:
    case MEASURE_LAYER_CONFIG_TEMPLATE_UPDATED_EVENT:
    case MEASURE_LAYER_CONFIG_TEMPLATE_DELETED_EVENT:
    case MEASURE_LAYER_CONFIG_ITEM_CREATED_EVENT:
    case MEASURE_LAYER_CONFIG_ITEM_UPDATED_EVENT:
    case MEASURE_LAYER_CONFIG_ITEM_DELETED_EVENT:
      MeasureLayerConfigTemplatesCableActions(response, conn, store);
      break;

    case MAP_LAYER_CREATED_EVENT:
    case MAP_LAYER_DELETED_EVENT:
    case MAP_LAYER_UPDATED_EVENT:
    case MAP_LAYER_TO_PROJECT_ASSIGNMENT_CREATED_EVENT:
    case MAP_LAYER_TO_PROJECT_ASSIGNMENT_DELETED_EVENT:
      MapLayerCableActions(response, conn, store)
      break;
    default:
      break;
  }
}

let openChannels = {
  organizationChannels: [],
  userChannel: null,
};

export default function init(store) {
  let token = LocalStorage.getToken();
  const user = store.getState().get("user");
  const userId = user.get("id");

  const organizationChannels = user
    .get("organization_role_groups")
    .map(
      (organization) =>
        `organization:${organization["resource_id"]}:${organization[
          "name"
        ].toLowerCase()}`
    );

  connection = new Socket(SOCKET_URI, { params: { token } });
  connection.connect();

  connection.onOpen(() => {
    let userChannel = connection.channel(`user:${userId}`, { client: "web" });
    userChannel.on("new_msg", (msg) => {
      console.log("Got message", msg);
      dispatcher(msg, connection, store);
    });
    userChannel
      .join()
      .receive("ok", ({ messages }) => {
        // console.log('Joined UserChannel' + userId, messages);
      })
      .receive("error", ({ reason }) =>
        console.log("Failed to join UserChannel" + userId, reason)
      )
      .receive("timeout", () =>
        console.log("Networking issue. Still waiting...")
      );

    openChannels.userChannel = userChannel;

    organizationChannels.forEach((organizationChannel) => {
      let orgaChannel = connection.channel(organizationChannel);
      orgaChannel.on("new_msg", (msg) => {
        console.log("Got message", msg);
        dispatcher(msg, connection, store);
      });
      orgaChannel
        .join()
        .receive("ok", ({ messages }) =>
          console.log("Joined " + organizationChannel, messages)
        )
        .receive("error", ({ reason }) =>
          console.log("Failed to join " + organizationChannel, reason)
        )
        .receive("timeout", () =>
          console.log("Networking issue. Still waiting...")
        );
      openChannels.organizationChannels.push(orgaChannel);
    });
  });

  connection.onClose(() => {
    console.log("ON CLOSE");
    //connection.send('Ping'); // Send the message 'Ping' to the server
  });

  connection.onError((error) => {
    console.log("WebSocket Error ", error);
  });

  connection.onMessage((e) => {
    if (e.topic !== "phoenix") {
      // console.log('Message:', e);
    }
    dispatcher(e, connection, store);

    switch (e.event) {
      case "phx_reply":
      case MEDIA_FILE_CREATE_EVENT:
      case MEDIA_FILE_DELETED_EVENT:
      case MEDIA_FILE_UPDATE_EVENT:
      case PROJECT_CREATE_EVENT:
      case PROJECT_ASSIGNED_EVENT:
      case PROJECT_DEASSIGNED_EVENT:
      case PROJECT_UPDATE_EVENT:
      case PROJECT_DELETE_EVENT:
      case PROJECT_RESET_EVENT:
      case ORGANIZATION_UPDATE_EVENT:
      case ORGANIZATION_USER_UPDATE_EVENT:
      case MEASUREMENTS_CREATE_EVENT:
      case MEASUREMENT_UPDATE_EVENT:
      case MEASUREMENTS_DELETE_EVENT:
      case USER_UPDATE_EVENT:
      case USER_STATUS_EVENT:
      case VOLUMES_CREATE_EVENT:
      case VOLUME_UPDATE_EVENT:
      case VOLUME_DELETED_EVENT:
      case MEASURE_LAYER_CONFIGS_CREATE_EVENT:
      case MEASURE_LAYER_CONFIG_DELETE_EVENT:
      case MEASURE_LAYER_CONFIG_UPDATE_EVENT:
      case PROJECT_FILE_CREATE_EVENT:
      case PROJECT_FILE_DELETED_EVENT:
      case TOPOGRAPHIC_POINTS_CREATE_EVENT:
      case TOPOGRAPHIC_POINT_UPDATE_EVENT:
      case CATALOG_ORGANIZATION_CATEGORY_CREATED_EVENT:
      case CATALOG_CATEGORY_UPDATED_EVENT:
      case CATALOG_CATEGORY_DELETED_EVENT:
      case CATALOG_COMPONENT_CATEGORY_CREATED_EVENT:
      case CATALOG_COMPONENT_UPDATED_EVENT:
      case CATALOG_COMPONENT_DELETED_EVENT:
      case CATALOG_MATERIAL_CATEGORY_CREATED_EVENT:
      case CATALOG_MATERIAL_UPDATED_EVENT:
      case CATALOG_MATERIAL_DELETED_EVENT:
      case CATALOG_DIMENSION_CATEGORY_CREATED_EVENT:
      case CATALOG_DIMENSION_UPDATED_EVENT:
      case CATALOG_DIMENSION_DELETED_EVENT:
      case CATALOG_PIPE_DEFINITION_CATEGORY_CREATED_EVENT:
      case CATALOG_PIPE_DEFINITION_CATEGORY_DELETED_EVENT:
      case MEASURE_LAYER_CONFIG_TEMPLATE_CREATED_EVENT:
      case MEASURE_LAYER_CONFIG_TEMPLATE_UPDATED_EVENT:
      case MEASURE_LAYER_CONFIG_TEMPLATE_DELETED_EVENT:
      case MEASURE_LAYER_CONFIG_ITEM_CREATED_EVENT:
      case MEASURE_LAYER_CONFIG_ITEM_UPDATED_EVENT:
      case MEASURE_LAYER_CONFIG_ITEM_DELETED_EVENT:
      case MAP_LAYER_CREATED_EVENT:
      case MAP_LAYER_DELETED_EVENT:
      case MAP_LAYER_UPDATED_EVENT:
      case MAP_LAYER_TO_PROJECT_ASSIGNMENT_CREATED_EVENT:
      case MAP_LAYER_TO_PROJECT_ASSIGNMENT_DELETED_EVENT:
        break;
      default:
        //console.log('unhandled socket event', e.event, e);
        break;
    }
  });
}
