/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import withStyles from "@mui/styles/withStyles";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import { InputBase, IconButton } from "@mui/material";

import classNames from "classnames";

import Header from "../../../../components/Header";

import * as styles from "../../../../styles/styles";
import "./styles.css";

const overrideStyles = (theme) => ({
  search: {
    marginLeft: "6rem",
    width: "auto",
    height: "3.8rem",
  },
  inputRoot: {
    color: styles.darkBlueBase,
    height: "3.7rem",
    lineHeight: "3.7rem",
  },
  inputInput: {
    margin: "0 0 0 2.5rem",
    padding: "1rem",
    width: "100%",
  },
  inputFocused: {
    color: styles.darkBlueDarker,
  },
});

const propTypes = {
  classes: PropTypes.object,
  searchTerm: PropTypes.string,
  onCreateMeasureLayerConfigTemplateClicked: PropTypes.func,
  setMeasureLayerConfigTemplateSearchTerm: PropTypes.func,
  isAdmin: PropTypes.bool,
};

const MeasureLayerConfigTemplatesHeader = ({
  classes,
  searchTerm,
  onCreateMeasureLayerConfigTemplateClicked,
  setMeasureLayerConfigTemplateSearchTerm,
  isAdmin,
}) => {
  const [searchTermState, setSearchTermState] = React.useState(searchTerm);
  const [timeoutState, setTimeoutState] = React.useState({});

  const goBackRoute = "/configurator";

  const onChange = (event) => {
    setSearchTermState(event.target.value);
    const searchTerm = event.target.value;

    clearTimeout(timeoutState);

    // Simple debounce effect to not search after every keystroke for a
    // matching project
    setTimeoutState(
      setTimeout(() => {
        setMeasureLayerConfigTemplateSearchTerm(searchTerm);
      }, 500)
    );
  };

  const renderSearchbar = () => {
    return (
      <div className={classNames(classes.search, "search-button")}>
        <div className="search-button-icon">
          <SearchIcon style={{ color: styles.darkBlueDark }} />
        </div>
        <InputBase
          placeholder={"Projekt-Vorlage suchen"}
          value={searchTermState}
          onChange={onChange}
          type="search"
          classes={{
            focused: classes.inputFocused,
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ "aria-label": "Search" }}
        />
      </div>
    );
  };

  const renderGoBack = () => {
    return (
      <Link
        to={goBackRoute}
        style={{
          position: "absolute",
          left: "1rem",
        }}
      >
        <IconButton
          style={{
            textDecoration: "none",
          }}
          size="large"
        >
          <ArrowBackIcon />
        </IconButton>
      </Link>
    );
  };

  return (
    <Header>
      <div className="header-left-side">
        {renderGoBack()}
        {renderSearchbar()}
      </div>
      <div className="header-right-side">
        {isAdmin && (
          <Button
            className="add-element-button"
            color="primary"
            variant="contained"
            size="medium"
            onClick={() => onCreateMeasureLayerConfigTemplateClicked()}
          >
            <AddCircleIcon className="add-element-button-icon" />
            Projekt-Vorlage anlegen
          </Button>
        )}
      </div>
    </Header>
  );
};

MeasureLayerConfigTemplatesHeader.propTypes = propTypes;
const styledComponentCatalogsHeader = withStyles(overrideStyles, {
  withTheme: true,
})(MeasureLayerConfigTemplatesHeader);
export default styledComponentCatalogsHeader;
