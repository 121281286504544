/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import React, { useState, useMemo } from "react";

import ButtonGroup from "../../../components/ButtonGroup";
import CustomDialog from "../../../components/CustomDialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import { validateTextFields } from "../../../sharedFunctions/validation";

import * as styles from "../../../styles/styles";

const propTypes = {
  edit: PropTypes.bool,
  data: PropTypes.object,
  modalOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
};

const defaultProps = {
  edit: false,
  data: {
    name: "",
    description: "",
  },
};

const MeasureLayerConfigTemplateDialog = ({
  edit,
  data,
  modalOpen,
  onClose,
  onClick,
}) => {
  const name = data.name;
  const description = data.description;

  const [stateName, setStateName] = useState(name);
  const [stateDescription, setStateDescription] = useState(description);
  const [stateDisablePositiveButton, setDisablePositiveButton] = useState(true);

  const validationValues = React.useMemo(() => {
    return {
      name: {
        value: name ? name : "",
        required: true,
      },
      description: {
        value: description ? description : "",
        required: true,
      },
    };
  }, [name, description]);

  const getOldValues = () => {
    return {
      name: stateName,
      description: stateDescription,
    };
  };

  const onChangeNameTextField = (event) => {
    const name = event.target.value;
    const newValues = { ...getOldValues(), name: name };
    setStateName(name);
    setDisablePositiveButton(validateTextFields(validationValues, newValues));
  };

  const onChangeDescriptionTextField = (event) => {
    const description = event.target.value;
    const newValues = { ...getOldValues(), description: description };
    setStateDescription(description);
    setDisablePositiveButton(validateTextFields(validationValues, newValues));
  };

  const executeOnClick = () => {
    if (edit) {
      onClick({
        uuid: data.uuid,
        data: {
          params: {
            name: stateName,
            description: stateDescription,
          },
        },
      });
    } else {
      onClick({
        data: {
          params: {
            name: stateName,
            description: stateDescription,
          },
        },
      });
    }
  };

  return (
    <>
      <CustomDialog
        maxWidth={"sm"}
        open={modalOpen}
        onClick={executeOnClick}
        onClose={onClose}
        showHint={false}
        renderTitle={() => {
          return (
            <DialogTitle
              id="alert-dialog-title"
              style={{ margin: `0 0 ${styles.spacing08} 0` }}
            >
              {edit ? "Template bearbeiten" : "Template anlegen"}
            </DialogTitle>
          );
        }}
        renderContent={() => {
          return (
            <DialogContent style={{ paddingTop: "20px" }}>
              <div style={{ margin: `0 0 ${styles.spacing16} 0` }}>
                <TextField
                  fullWidth={true}
                  id="outlined-full-width"
                  label="Name"
                  margin="none"
                  multiline={false}
                  inputProps={{
                    maxLength: 255,
                  }}
                  helperText={`${stateName.length}/255`}
                  onChange={onChangeNameTextField}
                  placeholder="Name"
                  required={true}
                  variant="outlined"
                  value={stateName}
                />
              </div>
              <div style={{ margin: `0 0 ${styles.spacing32} 0` }}>
                <TextField
                  fullWidth={true}
                  id="outlined-full-width"
                  label="Beschreibung"
                  margin="none"
                  multiline={false}
                  onChange={onChangeDescriptionTextField}
                  placeholder="Beschreibung"
                  required={true}
                  variant="outlined"
                  value={stateDescription}
                />
              </div>
            </DialogContent>
          );
        }}
        renderButtonGroup={(onClick, onClose) => {
          return (
            <DialogActions style={{ padding: "2.4rem 2.4rem 2rem" }}>
              <ButtonGroup
                align="right"
                leftButtonText="Abbrechen"
                leftButtonClick={onClose}
                rightButtonText={edit ? "Ändern" : "Anlegen"}
                rightButtonClick={onClick}
                rightButtonDisabled={stateDisablePositiveButton}
                spacingTop={false}
              />
            </DialogActions>
          );
        }}
      />
    </>
  );
};

MeasureLayerConfigTemplateDialog.propTypes = propTypes;
MeasureLayerConfigTemplateDialog.defaultProps = defaultProps;
export default MeasureLayerConfigTemplateDialog;
