import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

import Particles from "../Particles";
import computerImage from "../../assets/images/Devices@2x.png";

import Wordmark from "../../atoms/Wordmark";

import * as styles from "../../styles/styles";
import "./styles.css";

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  headline: PropTypes.string.isRequired,
};

const Registration = ({ children, headline }) => {
  const netpipeWordmarkWidth = 22.7;
  const netpipeWordmarkHeight = 6.1;

  return (
    <div className="landing-page-container">
      <div className="landing-page-left-side">
        <a
          rel="noreferrer"
          target="_blank"
          href="https://www.netpipe.io"
          className="landing-page-left-side-logo-wrapper"
        >
          <Wordmark
            width={netpipeWordmarkWidth}
            height={netpipeWordmarkHeight}
          />
        </a>
        <div className="landing-page-wrapper">
          <Typography
            style={{ margin: `0 0 ${styles.spacing24}` }}
            variant="h1"
            color="primary"
          >
            {headline}
          </Typography>
          {children}
        </div>
      </div>
      <div className="landing-page-right-side">
        <div className="intro-banner-img">
          <img
            className="landing-page-img"
            src={computerImage}
            alt="intro-banner-img"
          />
          <div className={`promo-video`}>
            <iframe
              className="video"
              src="https://www.youtube.com/embed/E8UqLB7IkfI?start=4&autohide=2&modestbranding=1&rel=0"
              frameBorder="0"
              allow="fullscreen;"
            />
          </div>
        </div>
        <div className="particles-wrapper">
          <Particles />
        </div>
      </div>
    </div>
  );
};

Registration.propTypes = propTypes;
export default Registration;
