/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import TrashIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Button from "@mui/material/Button";
import withStyles from "@mui/styles/withStyles";

import NoAvatar from "../../NoAvatar";
import ImageAvatar from "../../ImageAvatar";

import * as styles from "../../../styles/styles";
import "./styles.css";

const propTypes = {
  avatar: PropTypes.string,
  avatarTooLarge: PropTypes.bool,
  editAvatar: PropTypes.bool,
  firstName: PropTypes.string,
  getAvatarSize: PropTypes.func,
  lastName: PropTypes.string,
  onChangeAvatar: PropTypes.func,
};

const maxSize = 1.5; // in MB
const avatarStyles = { margin: "5rem 5rem 0 auto" };
const overrideStyles = (theme) => ({
  input: {
    display: "none",
  },
});

const UserAvatar = ({
  avatar,
  avatarTooLarge,
  classes,
  editAvatar,
  firstName,
  getAvatarSize,
  lastName,
  onChangeAvatar,
}) => {
  // cannot use <Typography /> component here
  // because text needs to break (see CSS styles)
  const avatarText = (
    <p
      style={{
        margin: `${styles.spacing16} 0 0 0`,
        color: `${avatarTooLarge ? styles.error : styles.darkBlueMediumDark}`,
      }}
      className="user-avatar-text"
    >
      {avatarTooLarge
        ? `Das Bild ist größer 
        als ${maxSize} MB. Bitte wählen 
        Sie ein Bild mit 
        maximal ${maxSize} MB.`
        : `Optimale Größe:
        200 px breit, 
        200 px hoch, 
        max. ${maxSize} MB.`}
    </p>
  );

  const uploadAvatar = (event) => {
    const newAvatar = event.target.files[0];
    if (newAvatar) {
      const fileSize = newAvatar.size / 1024 / 1024;
      if (fileSize <= maxSize) {
        onChangeAvatar(newAvatar);
        getAvatarSize(false);
      } else {
        onChangeAvatar(avatar);
        getAvatarSize(true);
      }
    } else {
      onChangeAvatar(avatar);
      getAvatarSize(false);
    }
  };

  const renderEditAvatar = () => {
    let avatarContainer = null;
    if (avatar) {
      avatarContainer = (
        <>
          <ImageAvatar avatar={avatar} />
          <div className="user-avatar-icon-button-wrapper">
            <Button
              size="medium"
              variant="contained"
              style={{ marginRight: styles.spacing08 }}
              onClick={() => onChangeAvatar(null)}
            >
              <TrashIcon />
            </Button>
            <input
              onChange={uploadAvatar}
              accept="image/*"
              className={classes.input}
              id="upload-avatar"
              type="file"
            />
            <label htmlFor="upload-avatar">
              <Button size="medium" variant="contained" component="span">
                <CloudUploadIcon />
              </Button>
            </label>
          </div>
          {avatarText}
        </>
      );
    } else {
      avatarContainer = (
        <>
          <label htmlFor="upload" className="user-avatar user-no-avatar">
            <input
              id="upload"
              onChange={uploadAvatar}
              style={{ display: "none" }}
              type="file"
            />
            <CloudUploadIcon />
          </label>
          {avatarText}
        </>
      );
    }
    return (
      <div className="user-avatar-edit-avatar-wrapper">
        <div className="user-avatar-edit-avatar-positioning">
          {avatarContainer}
        </div>
      </div>
    );
  };

  const renderAvatar = () => {
    if (avatar) {
      return <ImageAvatar avatar={avatar} avatarStyles={avatarStyles} />;
    }
    return (
      <NoAvatar
        variant="large"
        firstName={firstName}
        lastName={lastName}
        avatarStyles={avatarStyles}
      />
    );
  };

  return editAvatar ? renderEditAvatar() : renderAvatar();
};

UserAvatar.propTypes = propTypes;
export default withStyles(overrideStyles, { withTheme: true })(UserAvatar);
