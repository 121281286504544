import React from "react";
import PropTypes from "prop-types";

import UserMenu from "../../containers/UserMenu";

import "./styles.css";

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  divider: PropTypes.bool,
  subHeader: PropTypes.object,
  showUserSettings: PropTypes.bool,
};

const defaultProps = {
  showUserSettings: true,
  freeAccount: true,
};

const Header = ({ children, divider, subHeader, showUserSettings }) => {
  return (
    <div className="header-container">
      <div className="header-container-row">
        {children}
        <UserMenu showUserSettings={showUserSettings} />
      </div>
      {divider && <div className="header-container-divider" />}
      {subHeader}
    </div>
  );
};

Header.defaultProps = defaultProps;
Header.propTypes = propTypes;
export default Header;
