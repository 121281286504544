import { fromJS } from "immutable";

import {
  SELECT_PROJECTS,
  UNSELECT_PROJECTS,
  SEARCH_PROJECTS,
  SET_PAGINATION_PAGE_COUNT,
  SET_ACTIVE_PAGINATION_PAGE,
  ACTIVE_PAGINATION_PAGE_UPDATED,
  TOTAL_SORTED_AND_FILTERED_PROJECT_COUNT,
} from "../constants/actions/actions";

import { STANDARD_PROJECT_LIST_FLAG } from "../constants/projectList";

export const initialState = fromJS({
  selection: [],
  searchTerm: "",
  pagination: {
    standardProjectList: {
      pageCount: 1,
      activePage: 1,
    },
    archivedProjectList: {
      pageCount: 1,
      activePage: 1,
    },
  },
  totalSortedAndFilteredProjectCount: 0,
});

export function data(state = initialState, action) {
  switch (action.type) {
    case SELECT_PROJECTS:
      return state.updateIn(["selection"], (arr) =>
        arr.concat(action.payload).toSet().toList()
      );

    case UNSELECT_PROJECTS:
      return state.updateIn(["selection"], (arr) =>
        arr.filter((item) => !action.payload.includes(item))
      );

    case SEARCH_PROJECTS:
      return state.set("searchTerm", action.payload);

    case SET_PAGINATION_PAGE_COUNT:
      if (action.payload.key === STANDARD_PROJECT_LIST_FLAG) {
        return state.setIn(
          ["pagination", "standardProjectList", "pageCount"],
          action.payload.value
        );
      } else {
        return state.setIn(
          ["pagination", "archivedProjectList", "pageCount"],
          action.payload.value
        );
      }

    case ACTIVE_PAGINATION_PAGE_UPDATED:
    case SET_ACTIVE_PAGINATION_PAGE:
      if (action.payload.key === STANDARD_PROJECT_LIST_FLAG) {
        return state.setIn(
          ["pagination", "standardProjectList", "activePage"],
          action.payload.value
        );
      } else {
        return state.setIn(
          ["pagination", "archivedProjectList", "activePage"],
          action.payload.value
        );
      }
    case TOTAL_SORTED_AND_FILTERED_PROJECT_COUNT:
      return state.set("totalSortedAndFilteredProjectCount", action.payload);

    default:
      return state;
  }
}
