import React from "react";
import PropTypes from "prop-types";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import {
  appSettingsOrderingTitle,
  appSettingsOrderingDescription,
  stringSetMap,
} from "../../../../constants/userSettings";
import TooltipUiElement from "./TooltipUiElement";
import * as styles from "../../../../styles/styles";

const propTypes = {
  androidSettings: PropTypes.array,
  id: PropTypes.string,
  keyIdx: PropTypes.string,
  handleChangeStringSet: PropTypes.func,
};

const StringSetUiElement = ({
  androidSettings,
  id,
  keyIdx,
  handleChangeStringSet,
}) => {
  const uiElementList = [];
  const value = androidSettings[id].value;
  const stringSet = stringSetMap[keyIdx];

  uiElementList.push(
    <div 
      className="center-content string-set-title" 
      key={keyIdx}
    >
      <Typography
        variant="body2"
        style={{ margin: `0 ${styles.spacing16} 0 0` }}
      >
        {appSettingsOrderingTitle[keyIdx]}
      </Typography>
      {appSettingsOrderingDescription[keyIdx] && (
        <TooltipUiElement text={appSettingsOrderingDescription[keyIdx]} />
      )}
    </div>
  );

  const tempUiElementList = []
  for (const property in stringSet) {
    tempUiElementList.push(
      <FormControlLabel
        style={{flexBasis: '40%', boxSizing: 'border-box'}}
        key={`${keyIdx}_${property}`}
        control={
          <Checkbox
            key={`checkbox_${property}`}
            checked={value.includes(property)}
            onChange={(event) => {
              handleChangeStringSet({
                key: androidSettings[id].key,
                value: event.target.checked,
                property: property,
              });
            }}
            name={stringSet[property]}
          />
        }
        label={stringSet[property]}
      />
    );
  }

  uiElementList.push(
    <div 
      key={`content_${keyIdx}`} 
      style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row'}}
    >
      {tempUiElementList}
    </div>
  )

  return <div>{uiElementList}</div>;
};

StringSetUiElement.propTypes = propTypes;
export default StringSetUiElement;
