/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useLocation } from "react-router-dom";
import { activePaginationPageUpdated } from "../../actions/data";
import Pagination from "@mui/material/Pagination";
import { Typography } from "@mui/material";
import { hasOrganizationFeatureFlag } from "../../sharedFunctions/organization";
import { PROJECT_RESTRICTED_SINGLE } from "../../constants/featureFlags";
import {
  PROJECTS_ROUTE,
  PROJECTS_ARCHIVED_ROUTE,
  STANDARD_PROJECT_LIST_FLAG,
  ARCHIVED_PROJECT_LIST_FLAG,
} from "../../constants/projectList";
import "./styles.css";

const propTypes = {
  isProjectRestrictedSingle: PropTypes.bool,
  projectCount: PropTypes.number,
  activePaginationPageUpdated: PropTypes.func,
  paginationState: PropTypes.any,
  totalSortedAndFilteredProjectCount: PropTypes.number,
};

const CustomPagination = ({
  isProjectRestrictedSingle,
  projectCount,
  activePaginationPageUpdated,
  paginationState,
  totalSortedAndFilteredProjectCount,
}) => {
  let pageCount;
  let activePage;
  let currentPath = useLocation().pathname;

  switch (currentPath) {
    case PROJECTS_ARCHIVED_ROUTE:
      pageCount = paginationState.getIn(["archivedProjectList", "pageCount"]);
      activePage = paginationState.getIn(["archivedProjectList", "activePage"]);
      break;
    default:
    case PROJECTS_ROUTE:
      pageCount = paginationState.getIn(["standardProjectList", "pageCount"]);
      activePage = paginationState.getIn(["standardProjectList", "activePage"]);
      break;
  }

  const handleChange = (event, value) => {
    activePaginationPageUpdated({
      key:
        currentPath === PROJECTS_ROUTE
          ? STANDARD_PROJECT_LIST_FLAG
          : ARCHIVED_PROJECT_LIST_FLAG,
      value: value,
    });
  };

  // The pagination need the total amount of projects in the list
  // to calculate the amount of pages.
  // The currently shown page should be stored in the store.
  // With an Action the update of a page should be dispatched.
  // The project list can then update the shown projects in the project list. Captain Obvious....
  const nameProjectCounter =
    totalSortedAndFilteredProjectCount === 1 ? "Projekt" : "Projekte";
  return (
    <>
      <Pagination
        showFirstButton
        showLastButton
        className={"custom-pagination-main"}
        count={pageCount}
        page={activePage}
        color="primary"
        onChange={handleChange}
      />
      <div className={"project-counter"}>
        <Typography variant="body2">
          {isProjectRestrictedSingle
            ? `${totalSortedAndFilteredProjectCount}/1 ${nameProjectCounter}`
            : `${totalSortedAndFilteredProjectCount} ${nameProjectCounter}`}
        </Typography>
      </div>
    </>
  );
};

function dispatchToProps(dispatch) {
  return bindActionCreators(
    {
      activePaginationPageUpdated,
    },
    dispatch
  );
}

function stateToProps(state) {
  // See src/reducers/data.js for the pagination schema.
  const paginationState = state.getIn(["data", "pagination"]);
  const totalSortedAndFilteredProjectCount = state.getIn([
    "data",
    "totalSortedAndFilteredProjectCount",
  ]);

  const isProjectRestrictedSingle = hasOrganizationFeatureFlag(
    state,
    PROJECT_RESTRICTED_SINGLE
  );

  const projectCount = state.getIn(["projects", "items"]).count();

  return {
    isProjectRestrictedSingle,
    projectCount,
    paginationState: paginationState,
    totalSortedAndFilteredProjectCount: totalSortedAndFilteredProjectCount,
  };
}

CustomPagination.propTypes = propTypes;
export default connect(stateToProps, dispatchToProps)(CustomPagination);
