/* eslint-disable import/namespace */
/* eslint-disable no-unused-vars */
import { createAction } from "redux-actions";
import {
  EDIT_MEASUREMENT,
  REMOVE_MEASUREMENT,
  REMOVE_MEASUREMENTS,
  SET_MEASUREMENT,
  SET_MEASUREMENTS,
} from "../constants/actions/actions";
import {
  MEASUREMENTS_CREATE_EVENT,
  MEASUREMENT_UPDATE_EVENT,
  MEASUREMENTS_DELETE_EVENT,
} from "../constants/channelevents";
import {
  updateMeasurementFail,
  updateMeasurementSuccess,
} from "../constants/snackbars";

import API from "../lib/api";
import HTTP from "../lib/http";
import { renderSnackbar } from "./snackbars";

export const setMeasurements = createAction(SET_MEASUREMENTS);
export const setMeasurement = createAction(SET_MEASUREMENT);
export const editMeasurement = createAction(EDIT_MEASUREMENT);
export const removeMeasurement = createAction(REMOVE_MEASUREMENT);
export const removeMeasurements = createAction(REMOVE_MEASUREMENTS);

export const CableActions = (message, connection, store) => {
  switch (message.event) {
    case MEASUREMENTS_CREATE_EVENT:
      store.dispatch(setMeasurements(message.payload.measurements));
      break;

    case MEASUREMENT_UPDATE_EVENT:
      store.dispatch(
        editMeasurement({
          id: message.payload.measurement.id,
          item: message.payload.measurement,
        })
      );
      break;
    case MEASUREMENTS_DELETE_EVENT:
      store.dispatch(
        removeMeasurement({
          id: message.payload.measurement.id,
          item: message.payload.measurement,
        })
      );
      break;
    default:
      break;
  }
};

export function updateMeasurement(measurement) {
  return async (dispatch, getState) => {
    await HTTP.put(API.measurements.updateMeasurement(measurement.id), {
      measurement,
    })
      .then(function (response) {
        dispatch(renderSnackbar(updateMeasurementSuccess));
      })
      .catch(function (error) {
        dispatch(renderSnackbar(updateMeasurementFail));
        console.log("UPDATE MEASUREMENT ERROR", error);
      });
  };
}
