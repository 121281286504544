/* eslint-disable no-unused-vars */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

import { IMAGE } from "../../../constants/download";
import { 
  LAYER_TYPE_POINT_IDX,
  LAYER_TYPE_LINE_IDX,
  LAYER_TYPE_REFERENCE_POINT_IDX,
  LAYER_TYPE_GNSS_POINT_IDX,
  LAYER_TYPE_GNSS_LINE_IDX,
  LAYER_TYPE_GNSS_POLYGON_IDX,
  LAYER_TYPE_GNSS_CONTINUOUS_LINE_IDX,
  LAYER_TYPE_GNSS_REF_POINT_IDX
} from "../../../constants/measureLayerConfig";
import {
  MAP_VIEW_TYPE,
  AR_LAYER_VIEW_TYPE,
  PROJECT_FILES_VIEW_TYPE,
  SEARCH_KEY_OBJECT,
} from "../../../constants/project";
import { PROJECTS_ARCHIVED_ROUTE } from "../../../constants/projectList";
import { idle, fail } from "../../../constants/status";

import { syncRequestArchived } from "../../../actions/user";
import { getProjectFiles } from "../../../actions/projectFiles";
import { geocodeAddress } from "../../../actions/projects";
import { syncComponentCatalogs } from "../../../actions/componentCatalogs";

import { parseAddressForReverseGeocoding } from "../../../sharedFunctions/project/parser";

import Header from "../../../components/Header";
import SearchButtonResults from "../../../components/SearchButton/SearchButtonResults";
import ProjectSideBar from "../Project/Map/ProjectSideBar";

import Map from "./Map";
import MeasureLayer from "./MeasureLayer";
import ProjectFiles from "./ProjectFiles";

import "./styles.css";

const propTypes = {
  history: PropTypes.object,
  measurements: PropTypes.object,
  referencePoints: PropTypes.object,
  referencePointsPerLayer: PropTypes.array,
  measurementsPerLayer: PropTypes.array,
  measureLayerConfigs: PropTypes.object,
  topographicPoints: PropTypes.object,
  topographicPointsPerLayer: PropTypes.array,
  volumes: PropTypes.object,
  mediaFiles: PropTypes.object,
  projectFiles: PropTypes.object,
  project: PropTypes.object,
  projectPlace: PropTypes.object,
  doGeocodeOfAddress: PropTypes.bool,
  projects: PropTypes.object,
  users: PropTypes.array,
  syncRequestArchived: PropTypes.func,
  getProjectFiles: PropTypes.func,
  geocodeAddress: PropTypes.func,
  archivedProjectSyncStatus: PropTypes.any,
  componentCatalogSyncStatus: PropTypes.string,
  componentCatalogItems: PropTypes.object,
  syncComponentCatalogs: PropTypes.func,
  gnssPointsPerLayer: PropTypes.array,
  gnssRefPointsPerLayer: PropTypes.array,
  gnssLinesPerLayer: PropTypes.array,
  gnssPolygonPerLayer: PropTypes.array,
  gnssPoints: PropTypes.object,
  gnssImages: PropTypes.object
};

const Project = ({
  history,
  project,
  projectPlace,
  doGeocodeOfAddress,
  projects,
  users,
  measurements,
  referencePoints,
  referencePointsPerLayer,
  measurementsPerLayer,
  volumes,
  mediaFiles,
  projectFiles,
  measureLayerConfigs,
  topographicPoints,
  topographicPointsPerLayer,
  archivedProjectSyncStatus,
  syncRequestArchived,
  getProjectFiles,
  geocodeAddress,
  componentCatalogSyncStatus,
  componentCatalogItems,
  syncComponentCatalogs,
  gnssPointsPerLayer,
  gnssRefPointsPerLayer,
  gnssLinesPerLayer,
  gnssPolygonPerLayer,
  gnssPoints,
  gnssImages
}) => {
  // Prepare State-Handling
  const [searchTerm, setSearchTerm] = React.useState("");
  const [results, setResults] = React.useState({});
  const [viewType, setViewType] = React.useState(MAP_VIEW_TYPE);
  const [projectFilesRequested, setProjectFilesRequested] =
    React.useState(false);
  const [projectPlaceRequested, setProjectPlaceRequested] =
    React.useState(false);
  const [archiveView, setArchiveView] = React.useState(
    useLocation().pathname.includes(PROJECTS_ARCHIVED_ROUTE)
  );

  React.useEffect(() => {
    if (componentCatalogSyncStatus === idle) {
      syncComponentCatalogs();
    }
  }, [componentCatalogSyncStatus]);

  React.useEffect(() => {
    if (
      archiveView &&
      (archivedProjectSyncStatus === null || archivedProjectSyncStatus === fail)
    ) {
      syncRequestArchived();
    }
  }, [archivedProjectSyncStatus]);

  React.useEffect(() => {
    if (project && !projectFilesRequested && projectFiles.size === 0) {
      setProjectFilesRequested(true);
      getProjectFiles(project.get("id"));
    }
  }, [projectFilesRequested]);

  React.useEffect(() => {
    if (
      project &&
      projectPlace &&
      doGeocodeOfAddress &&
      !projectPlaceRequested
    ) {
      const encodedProjectPlace = parseAddressForReverseGeocoding(
        projectPlace.toJS()
      );
      geocodeAddress(
        project.toJS().id,
        encodedProjectPlace,
        "pk.eyJ1IjoiZ3JvYmVydCIsImEiOiJja3d2ejAwN2MweGdxMnFtaThpbGRxbzNmIn0.p83TeA_nVV8WiMEpNFYnoQ"
      );

      setProjectPlaceRequested(true);
    }
  }, [projectPlaceRequested]);

  const getShowProjectSidePanelActions = () => {
    // Conditions to show the MeasureLayerViewButton
    // - The Project-State is open
    // - If the Project-State is done, it contains only Volume-Measurements
    //   - If it is done at least one Line- or Point-Layer must be present.
    const projectStateOpen = project.get("state") === "open";
    const projectHasNoLineLayer = measurementsPerLayer.length === 0;
    const projectHasNoPointLayer = topographicPointsPerLayer.length === 0;

    const showMeasureLayerViewButton =
      projectStateOpen || (projectHasNoLineLayer && projectHasNoPointLayer);

    return {
      map_view_button: true,
      measure_layer_view_button: showMeasureLayerViewButton,
      project_files_view_button: true,
    };
  };

  const onProjectViewChanged = (newViewType) => {
    if (viewType !== newViewType) {
      setViewType(newViewType);
    }
  };

  const showView = () => {
    if (viewType === MAP_VIEW_TYPE) {
      return mapViewLayout(
        project,
        projects,
        users,
        searchTerm,
        setSearchTerm,
        setResults,
        results,
        measurements,
        referencePoints,
        referencePointsPerLayer,
        measurementsPerLayer,
        volumes,
        mediaFiles,
        topographicPoints,
        topographicPointsPerLayer,
        measureLayerConfigs,
        componentCatalogItems,
        gnssPointsPerLayer,
        gnssRefPointsPerLayer,
        gnssLinesPerLayer,
        gnssPolygonPerLayer,
        gnssPoints,
        gnssImages
      );
    } else if (viewType === AR_LAYER_VIEW_TYPE) {
      return arLayerViewLayout(measureLayerConfigs, project, users, projects);
    } else if (viewType === PROJECT_FILES_VIEW_TYPE) {
      return projectFilesViewLayout(project, projectFiles, users, projects);
    }
  };

  return (
    <div className="project-container">
      {project && (
        <ProjectSideBar
          changeProjectView={onProjectViewChanged}
          history={history}
          project={project.toJS()}
          showProjectSidePanelActions={getShowProjectSidePanelActions()}
        />
      )}

      {showView()}
    </div>
  );
};

function arLayerViewLayout(measureLayerConfigs, project, users, projects) {
  return (
    <MeasureLayer
      measureLayerConfigs={measureLayerConfigs.toJS()}
      project={project.toJS()}
      users={users}
      projects={projects}
    />
  );
}

function projectFilesViewLayout(project, projectFiles, users, projects) {
  return (
    <ProjectFiles
      project={project.toJS()}
      projectFiles={projectFiles.toJS()}
      users={users}
      projects={projects}
    />
  );
}

function mapViewLayout(
  project,
  projects,
  users,
  searchTerm,
  setSearchTerm,
  setResults,
  results,
  measurements,
  referencePoints,
  referencePointsPerLayer,
  measurementsPerLayer,
  volumes,
  mediaFiles,
  topographicPoints,
  topographicPointsPerLayer,
  measureLayerConfigs,
  componentCatalogItems,
  gnssPointsPerLayer,
  gnssRefPointsPerLayer,
  gnssLinesPerLayer,
  gnssPolygonPerLayer,
  gnssPoints,
  gnssImages
) {
  return (
    <div className="project-bottom-container">
      <Header>
        <SearchButtonResults
          onClickOutside={() => {
            setSearchTerm("");
            setResults({});
          }}
          projects={projects.toJS()}
          users={users}
          searchTerm={searchTerm}
          searchKeyList={SEARCH_KEY_OBJECT}
          search={(searchTerm) => setSearchTerm(searchTerm)}
          getResults={(results) => setResults(results)}
          onClick={() => setResults({})}
          results={results}
          placeholderText="Nach Projekt suchen..."
        />
      </Header>
      {project && (
        <Map
          project={project.toJS()}
          measurements={measurements.toJS()}
          referencePoints={referencePoints.toJS()}
          referencePointsPerLayer={referencePointsPerLayer}
          measurementsPerLayer={measurementsPerLayer}
          volumes={volumes.toJS()}
          mediaFiles={mediaFiles.toJS()}
          topographicPoints={topographicPoints.toJS()}
          topographicPointsPerLayer={topographicPointsPerLayer}
          measureLayerConfigsIm={measureLayerConfigs}
          componentCatalogItems={componentCatalogItems}
          gnssPointsPerLayer={gnssPointsPerLayer}
          gnssRefPointsPerLayer={gnssRefPointsPerLayer}
          gnssLinesPerLayer={gnssLinesPerLayer}
          gnssPolygonPerLayer={gnssPolygonPerLayer}
          gnssPoints={gnssPoints.toJS()}
          gnssImages={gnssImages.toJS()}
        />
      )}
    </div>
  );
}

function dispatchToProps(dispatch) {
  return bindActionCreators(
    {
      syncRequestArchived,
      getProjectFiles,
      geocodeAddress,
      syncComponentCatalogs,
    },
    dispatch
  );
}

/**
 *
 * @param {object} state The state holds the current redux-store
 * @param {number} projectIdInt The remote ID of the currently shown Project
 * @returns {object} Returns an immutable Object with all Measurements for the currently shown Project.
 * This function doesn't make a distinction between reference and measurement points.
 */
function parseMeasurements(state, projectIdInt) {
  return state
    .getIn(["measurements", "items"])
    .filter((item) => item.get("project_id") === projectIdInt);
}

/**
 *
 * @param {object} state The state holds the current redux-store
 * @param {number} projectIdInt The remote ID of the currently shown Project
 * @returns {object} Returns an immutable Object with all Reference Points for the currently shown Project.
 */
function parseReferencePoints(state, projectIdInt) {
  return state
    .getIn(["measurements", "items"])
    .filter(
      (item) =>
        item.get("project_id") === projectIdInt &&
        item.get("type") === "reference_point"
    );
}

/**
 *
 * @param {object} state The state holds the current redux-store
 * @param {number} projectIdInt The remote ID of the currently shown Project
 * @returns {object} Returns an immutable Object with all Topographic Points for the currently shown Project.
 */
function parseTopographicPoints(state, projectIdInt) {
  return state
    .getIn(["topographicPoints", "items"])
    .filter((item) => item.get("project_id") === projectIdInt);
}

/**
 *
 * @param {object} state The state holds the current redux-store
 * @param {number} projectIdInt The remote ID of the currently shown Project
 * @returns {array} Returns an Array of Objects. An Objects consist of two key:value pairs.
 * - layerConfig: The correct MeasureLayerConfig for a Layer
 * - referencePoints: Alle Reference Points for a Layer
 */
function parseReferencePointsPerLayer(state, projectIdInt) {
  const referencePointsPerLayer = [];
  const referencePointsLayers = state
    .getIn(["measureLayerConfigs", "items"])
    .filter(
      (item) =>
        (item.get("project_id") === projectIdInt) &
        (item.get("layer_type") === LAYER_TYPE_REFERENCE_POINT_IDX)
    );
  referencePointsLayers.valueSeq().forEach((referencePointLayer) => {
    const referencePoints = state
      .getIn(["measurements", "items"])
      .filter(
        (item) =>
          (item.get("project_id") === projectIdInt) &
          (item.get("type") === "reference_point")
      );
    referencePointsPerLayer.push({
      layerConfig: referencePointLayer.toJS(),
      referencePoints: referencePoints.toJS(),
    });
  });

  return referencePointsPerLayer;
}

/**
 *
 * @param {object} state The state holds the current redux-store
 * @param {number} projectIdInt The remote ID of the currently shown Project
 * @returns {array} Returns an Array of Object. An Object consist of four key:value pairs.
 * - layerConfig: The correct MeasureLayerConfig for a Layer
 * - line: The MeasurementLine for a Layer
 * - segments: All MeasurementSegments for a Layer
 * - measurements: All Measurement Points for a Layer
 */
function parseMeasurementsPerLayer(state, projectIdInt) {
  const measurementsPerLayer = [];
  const lineLayerConfigs = state
    .getIn(["measureLayerConfigs", "items"])
    .filter(
      (item) =>
        (item.get("project_id") === projectIdInt) &
        (item.get("layer_type") === LAYER_TYPE_LINE_IDX)
    );
  lineLayerConfigs.valueSeq().forEach((measureLayerConfig) => {
    const measurementLine = state
      .getIn(["measurementLines", "items"])
      .filter(
        (item) =>
          (item.get("project_id") === projectIdInt) &
          (item.get("uuid") === measureLayerConfig.get("uuid"))
      );

    measurementLine.valueSeq().forEach((line) => {
      const measurementSegments = state
        .getIn(["measurementSegments", "items"])
        .filter((item) => item.get("measurement_line_id") === line.get("id"));

      const measurementIdsPerLine = [];
      measurementSegments.valueSeq().forEach((segment) => {
        measurementIdsPerLine.push(segment.get("measurement_point_a_id"));
        measurementIdsPerLine.push(segment.get("measurement_point_b_id"));
      });

      const templist = new Set(measurementIdsPerLine);
      const measurements = state
        .getIn(["measurements", "items"])
        .filter((item) => templist.has(item.get("id")));

      measurementsPerLayer.push({
        layerConfig: measureLayerConfig.toJS(),
        line: line.toJS(),
        segments: measurementSegments.toJS(),
        measurements: measurements.toJS(),
      });
    });
  });

  return measurementsPerLayer;
}

/**
 *
 * @param {object} state The state holds the current redux-store
 * @param {number} projectIdInt The remote ID of the currently shown Project
 * @returns {array}  Returns an Array of Object. An Object consist of two key:value pairs.
 * - layerConfig: The correct MeasureLayerConfig for a Layer
 * - topographicPoints: All Topographic Points for a Layer
 */
function parseTopographicPointsPerLayer(state, projectIdInt) {
  const topographicPointsPerLayer = [];
  const topoLayers = state
    .getIn(["measureLayerConfigs", "items"])
    .filter(
      (item) =>
        (item.get("project_id") === projectIdInt) &
        (item.get("layer_type") === LAYER_TYPE_POINT_IDX)
    );
  topoLayers.valueSeq().forEach((topoLayer) => {
    const topographicPoints = state
      .getIn(["topographicPoints", "items"])
      .filter(
        (item) =>
          (item.get("project_id") === projectIdInt) &
          (item.get("measure_layer_config_uuid") === topoLayer.get("uuid"))
      );
    topographicPointsPerLayer.push({
      layerConfig: topoLayer.toJS(),
      topographicPoints: topographicPoints.toJS(),
    });
  });

  return topographicPointsPerLayer;
}

function parseGnssPoints(state, projectIdInt) {
  return state
    .getIn(["gnssData", "items", "points"])
    .filter((item) => item.get("project_id") === projectIdInt)
}

function parseGnssImages(state, projectIdInt) {
  return state
    .getIn(["gnssData", "items", "images"])
    .filter((item) => item.get("project_id") === projectIdInt)
}

function parseGnssPointsPerLayer(state, projectIdInt) {
  const gnssPointsPerLayer = [];
  const gnssPointLayers = state
    .getIn(["measureLayerConfigs", "items"])
    .filter(
      (item) => 
        (item.get("project_id") === projectIdInt) &
        (item.get("layer_type") === LAYER_TYPE_GNSS_POINT_IDX)
    );

  gnssPointLayers.valueSeq().forEach((gnssPointLayer) => {
    const gnssPoints = state
      .getIn(["gnssData", "items", "points"])
      .filter(
        (item) => 
          (item.get("project_id") === projectIdInt)&
          (item.get("layer_uuid") === gnssPointLayer.get("uuid"))
      )

    gnssPointsPerLayer.push({
      layerConfig: gnssPointLayer.toJS(),
      gnssPoints: gnssPoints.toJS()
    })
  })

  return gnssPointsPerLayer;
}

function parseGnssRefPointsPerLayer(state, projectIdInt) {
  const gnssRefPointsPerLayer = [];
  const gnssRefPointLayers = state
    .getIn(["measureLayerConfigs", "items"])
    .filter(
      (item) => 
        (item.get("project_id") === projectIdInt) &
        (item.get("layer_type") === LAYER_TYPE_GNSS_REF_POINT_IDX)
    );

  gnssRefPointLayers.valueSeq().forEach((gnssRefPointLayer) => {
    const gnssRefPoints = state
      .getIn(["gnssData", "items", "points"])
      .filter(
        (item) => 
          (item.get("project_id") === projectIdInt)&
          (item.get("layer_uuid") === gnssRefPointLayer.get("uuid"))
      )

    gnssRefPointsPerLayer.push({
      layerConfig: gnssRefPointLayer.toJS(),
      gnssRefPoints: gnssRefPoints.toJS()
    })
  })

  return gnssRefPointsPerLayer;
}

function parseGnssLinesPerLayer(state, projectIdInt) {
  const gnssLinesPerLayer = [];
  const gnssLineLayers = state
    .getIn(["measureLayerConfigs", "items"])
    .filter(
      (item) => 
        (item.get("project_id") === projectIdInt) &
        ((item.get("layer_type") === LAYER_TYPE_GNSS_LINE_IDX) || 
        (item.get("layer_type") === LAYER_TYPE_GNSS_CONTINUOUS_LINE_IDX))
    );

  gnssLineLayers.valueSeq().forEach((gnssLineLayer) => {
    const gnssLines = state
      .getIn(["gnssData", "items", "lines"])
      .filter(
        (item) =>
          (item.get("project_id") === projectIdInt) &
          (item.get("layer_uuid") === gnssLineLayer.get("uuid"))
      );

    gnssLines.valueSeq().forEach((gnssLine) => {
      const gnssLineSegments = state
        .getIn(["gnssData", "items", "segments"])
        .filter((item) => item.get("line_uuid") === gnssLine.get("uuid"));

      const gnssPointUuidsPerLine = [];
      gnssLineSegments.valueSeq().forEach((gnssSegment) => {
        gnssPointUuidsPerLine.push(gnssSegment.get("point_a_uuid"))
        gnssPointUuidsPerLine.push(gnssSegment.get("point_b_uuid"))
      })

      const templist = new Set(gnssPointUuidsPerLine);

      const gnssPoints = []
      templist.forEach(uuid => {
        const point = state
          .getIn(["gnssData", "items", "points"])
          .find(item => item.get("uuid") === uuid)

        if (point) {
          gnssPoints.push(point.toJS())
        }
      })
      
      gnssLinesPerLayer.push({
        layerConfig: gnssLineLayer.toJS(),
        gnssLine: gnssLine.toJS(),
        gnssSegments: gnssLineSegments.toJS(),
        gnssPoints: gnssPoints,
      });
    })
  })

  return gnssLinesPerLayer;
}

function parseGnssPolygonPerLayer(state, projectIdInt) {
  const gnssPolygonPerLayer = [];

  const measureLayerConfigs = state.getIn(["measureLayerConfigs", "items"]);
  const gnssData = state.getIn(["gnssData", "items"]);
  const gnssPolygonsData = gnssData.get("polygons");
  const gnssPolygonPointsData = gnssData.get("polygon_points");
  const gnssPointsData = gnssData.get("points");

  const gnssPolygonLayers = measureLayerConfigs
    .filter(
      (item) => 
        (item.get("project_id") === projectIdInt) &
        (item.get("layer_type") === LAYER_TYPE_GNSS_POLYGON_IDX)
    );
    
  gnssPolygonLayers.forEach((gnssPolygonlayer) => {
    const gnssPolygons = gnssPolygonsData
      .filter((item) => 
        (item.get("project_id") === projectIdInt) &
        (item.get("layer_uuid") === gnssPolygonlayer.get("uuid"))
      )

    gnssPolygons.forEach((gnssPolygon) => {
      const gnssPolygonPoints = gnssPolygonPointsData
        .filter((item) => item.get("gnss_polygon_uuid") === gnssPolygon.get("uuid"))

      const gnssPointUuidsPerLine = gnssPolygonPoints.map(
        (gnssPolygonPoint) => gnssPolygonPoint.get("gnss_point_uuid")
      ).toSet();

      const gnssPoints = gnssPointsData
        .filter(item => gnssPointUuidsPerLine.has(item.get("uuid")))
        .sortBy(item => item.get("index"))
        .toJS()

      gnssPolygonPerLayer.push({
        layerConfig: gnssPolygonlayer.toJS(),
        gnssPolygon: gnssPolygon.toJS(),
        gnssPolygonPoints: gnssPolygonPoints.toJS(),
        gnssPoints: gnssPoints
      })
    })
  })
  
  return gnssPolygonPerLayer;
}

/**
 *
 * @param {object} state The state holds the current redux-store
 * @param {number} projectIdInt The remote ID of the currently shown Project
 * @returns {object} Returns an immutable Object with all MeasureLayerConfigs for the currently shown Project.
 */
function parseMeasureLayerConfigs(state, projectIdInt) {
  return state
    .getIn(["measureLayerConfigs", "items"])
    .filter((item) => item.get("project_id") === projectIdInt);
}

/**
 *
 * @param {object} state The state holds the current redux-store
 * @param {number} projectIdInt The remote ID of the currently shown Project
 * @returns {object} Returns an immutable Object with all Volumes for the currently shown Project.
 */
function parseVolumes(state, projectIdInt) {
  return state
    .getIn(["volumes", "items"])
    .filter((item) => item.get("project_id") === projectIdInt);
}

/**
 *
 * @param {object} state The state holds the current redux-store
 * @param {number} projectIdInt The remote ID of the currently shown Project
 * @returns {object} Returns an immutable Object with all Media Files for the currently shown Project.
 */
function parseMediaFiles(state, projectIdInt) {
  return state
    .getIn(["mediaFiles", "items"])
    .filter(
      (item) =>
        item.get("project_id") === projectIdInt && item.get("type") === IMAGE
    );
}

/**
 *
 * @param {object} state The state holds the current redux-store
 * @param {number} projectIdInt The remote ID of the currently shown Project
 * @returns {object} Returns an immutable Object with all Project Files for the currently shown Project.
 */
function parseProjectFiles(state, projectIdInt) {
  return state
    .getIn(["projectFiles", "items"])
    .filter((item) => item.get("project_id") === projectIdInt);
}

function stateToProps(state, ownProps) {
  // Get Project
  const projects = state.getIn(["projects", "items"]);
  const projectId = ownProps.match.params.id;
  const projectIdInt = parseInt(projectId);
  const project = projects.get(projectId);

  // Get Measurement points
  const measurements = parseMeasurements(state, projectIdInt);

  // Get Reference points
  const referencePoints = parseReferencePoints(state, projectIdInt);

  // Get Topographic Points
  const topographicPoints = parseTopographicPoints(state, projectIdInt);

  // Get Reference points per Layer
  const referencePointsPerLayer = parseReferencePointsPerLayer(
    state,
    projectIdInt
  );

  // Get MeasurementsPerLine
  const measurementsPerLayer = parseMeasurementsPerLayer(state, projectIdInt);

  // Get topographic points separated by layer
  const topographicPointsPerLayer = parseTopographicPointsPerLayer(
    state,
    projectIdInt
  );

  // GNSS DATA
  const gnssPoints = parseGnssPoints(
    state, 
    projectIdInt
  );

  const gnssPointsPerLayer = parseGnssPointsPerLayer(
    state,
    projectIdInt
  )

  const gnssRefPointsPerLayer = parseGnssRefPointsPerLayer(
    state,
    projectIdInt
  )

  const gnssLinesPerLayer = parseGnssLinesPerLayer(
    state,
    projectIdInt
  )

  const gnssPolygonPerLayer = parseGnssPolygonPerLayer(
    state,
    projectIdInt
  )

  const gnssImages = parseGnssImages(
    state,
    projectIdInt
  )

  // Get MeasureLayerConfigs
  const measureLayerConfigs = parseMeasureLayerConfigs(state, projectIdInt);

  // Get Volumes
  const volumes = parseVolumes(state, projectIdInt);

  // Get MediaFiles
  const mediaFiles = parseMediaFiles(state, projectIdInt);

  // Get projectFiles
  const projectFiles = parseProjectFiles(state, projectIdInt);

  // Get Component Catalogs
  const componentCatalogSyncStatus = state.getIn([
    "componentCatalogs",
    "componentCatalogSyncStatus",
  ]);

  const componentCatalogItems = state.getIn(["componentCatalogs", "items"]);

  // Get the Organization and logged in User.
  const organization = state.getIn(["organizations", "items"]).first();
  const users = organization ? organization.get("users").toJS() : [];

  // If we looking at an archived Project, check
  // the sync state for archived Projects.
  const archivedProjectSyncStatus = state.getIn([
    "app",
    "archivedProjectSyncStatus",
  ]);

  // Special check if the project object exist.
  // Don't try to set doGeocodeOfAddress to true if actually not project and
  // therefor no place exist.
  const projectPlace =
    project && project.has("place") ? project.get("place") : {};

  // Geocode of an Address
  let doGeocodeOfAddress = false;

  if (projectPlace.size) {
    doGeocodeOfAddress =
      (measurements.size === 0 || topographicPoints.size === 0) &&
      projectPlace.size !== 0;
  }

  console.log(gnssLinesPerLayer)

  return {
    projects,
    users,
    project,
    projectPlace,
    doGeocodeOfAddress,
    measurements,
    referencePoints,
    referencePointsPerLayer,
    measurementsPerLayer,
    volumes,
    mediaFiles,
    projectFiles,
    measureLayerConfigs,
    topographicPoints,
    topographicPointsPerLayer,
    archivedProjectSyncStatus,
    componentCatalogSyncStatus,
    componentCatalogItems,
    gnssPointsPerLayer,
    gnssRefPointsPerLayer,
    gnssLinesPerLayer,
    gnssPolygonPerLayer,
    gnssPoints,
    gnssImages
  };
}

Project.propTypes = propTypes;
export default connect(stateToProps, dispatchToProps)(Project);
