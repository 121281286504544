import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import moment from 'moment';

import StateIndicator from '../../../../../../components/StateIndicator';

import { parseAddress } from '../../../../../../sharedFunctions/project/parser';

import * as styles from '../../../../../../styles/styles';
import './styles.css';

const propTypes = {
  project: PropTypes.object,
}

const ProjectInfo = ({
  project,
}) => {
  const inserted_at = moment(project.inserted_at);
  const updated_at = moment(project.updated_at);
  const due_date = moment(project.due_date);
  const place = parseAddress(project.place);
  const textStyles = { color: styles.white, paddingBottom: '2.4rem' };
  const textStylesLabel = { color: styles.white, opacity: 0.66 };

  const renderPlace = (place) => {
    return <>
      <Typography
        variant='body2'
        style={{ ...textStylesLabel }}>
        Standort
    </Typography>
      <Typography
        style={{ ...textStyles }}
        variant='body1'>
        {place}
      </Typography>
    </>
  }

  return <>
    <div
      className='project-info-headline-wrapper'>
      <Typography
        component='h1'
        variant='h2'
        style={{ color: styles.white }}>
        {project.title}
      </Typography>
    </div>
    <Typography
      variant='body2'
      style={{ ...textStylesLabel }}>
      Beschreibung
    </Typography>
    <Typography
      style={{ ...textStyles }}
      variant='body1'>
      {project.description}
    </Typography>

    {place !== "" && renderPlace(place)}

    <div
      className='project-info-wrapper'>
      <div
        style={{ flexDirection: 'column' }}>
        <Typography
          variant='body2'
          style={{ ...textStylesLabel }}>
          Auftragsnummer
        </Typography>
        <Typography
          style={{ color: styles.white }}
          variant='body1'>
          {project.job_order}
        </Typography>
      </div>
      <StateIndicator
        state={project.state} />
    </div>
    <Typography
      variant='body2'
      style={{ ...textStylesLabel }}>
      Erstellt am
    </Typography>
    <Typography
      variant='body2'
      style={{ ...textStyles }}>
      {`${inserted_at.format('dd. DD.MM.YYYY, HH:mm')} Uhr`}
    </Typography>
    <Typography
      variant='body2'
      style={{ ...textStylesLabel }}>
      Bearbeitet am
    </Typography>
    <Typography
      style={{ ...textStyles }}
      variant='body2'>
      {`${updated_at.format('dd. DD.MM.YYYY, HH:mm')} Uhr`}
    </Typography>

    {project.due_date != null &&
      <>
        <Typography
          variant='body2'
          style={{ ...textStylesLabel }}>
          Fälligkeitsdatum
    </Typography>
        <Typography
          style={{ ...textStyles }}
          variant='body2'>
          {`${due_date.format('dd. DD.MM.YYYY, HH:mm')} Uhr`}
        </Typography>
      </>
    }
  </>
}

ProjectInfo.propTypes = propTypes;
export default ProjectInfo;