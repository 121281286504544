/* eslint-disable import/namespace */
import { createAction } from "redux-actions";
import {
  SET_INIT_SYNCING,
  SET_LAST_SYNC,
  SET_STANDARD_PROJECT_SYNC_STATUS,
  SET_ARCHIVED_PROJECT_SYNC_STATUS,
} from "../constants/actions/actions";

export const setLastSync = createAction(SET_LAST_SYNC);
export const setInitSyncing = createAction(SET_INIT_SYNCING);
export const setStandardProjectSyncStatus = createAction(
  SET_STANDARD_PROJECT_SYNC_STATUS
);
export const setArchivedProjectSyncStatus = createAction(
  SET_ARCHIVED_PROJECT_SYNC_STATUS
);
