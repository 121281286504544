import React from "react";
import PropTypes from "prop-types";

import "./styles.css";

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  side: PropTypes.oneOf(["right", "left"]).isRequired,
  flexDirection: PropTypes.string,
  flexWrap: PropTypes.string,
};

const defaultProps = {
  flexDirection: "row",
  flexWrap: "nowrap",
};

const Column = ({ children, flexDirection, flexWrap, side }) => {
  return (
    <div
      className={`columns-container columns-${side}-side`}
      style={{ flexDirection, flexWrap }}
    >
      {children}
    </div>
  );
};
Column.defaultProps = defaultProps;
Column.propTypes = propTypes;
export default Column;
