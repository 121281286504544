import * as styles from "../../../../../../../styles/styles";
import { GNSS_POINT } from "../../../../../../../constants/pointLookupTable";
import { COMPONENT_TYPE_GNSS_POINT } from "../../../../../../../constants/ecs";

export default class ComponentGnssPoint {
  constructor(params) {
    this.componentType = COMPONENT_TYPE_GNSS_POINT;
    this.componentName = GNSS_POINT;
    this.currentHighlight = -1;
    this.hoveredStateId = -1;

    this.circleRadiusNormal = 5;
    this.circleRadiusHover = 10;

    this.name = params.name;

    this.idList = params.data.features.map((feature) => feature.id);

    this.source = {
      type: "geojson",
      data: params.data,
      promoteId: "uuid",
    };

    this.layer = {
      id: this.name,
      type: "circle",
      source: this.name,
      paint: {
        "circle-radius": [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          this.circleRadiusHover,
          this.circleRadiusNormal,
        ],
        "circle-color": [
          "case",
          ["boolean", ["feature-state", "click"], false],
          styles.primary,
          styles.white,
        ],
        "circle-stroke-color": styles.primary,
        "circle-stroke-width": 3,
      },
    };
  }

  updateColor(color) {
    this.layer.paint["circle-color"] = [
      "case",
      ["boolean", ["feature-state", "click"], false],
      color,
      styles.white,
    ];

    this.layer.paint["circle-stroke-color"] = color;
  }

  getComponentType() {
    return this.componentType;
  }

  getComponentName() {
    return this.componentName;
  }
}
