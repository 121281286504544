/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import React, { useState } from "react";

import withStyles from '@mui/styles/withStyles';
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

import ButtonGroup from "../../../components/ButtonGroup";
import CustomDialog from "../../../components/CustomDialog";

import * as styles from "../../../styles/styles";

const propTypes = {
    classes: PropTypes.object,
    mapLayer: PropTypes.object,
    projects: PropTypes.object,
    assignedProjectList: PropTypes.array,
    modalOpen: PropTypes.bool,
    onPositiveButtonClicked: PropTypes.func,
    onNegativeButtonClicked: PropTypes.func,
};
  
const overrideStyles = (theme) => ({
    alignText: {
        margin: "auto 0 0 0",
    },
});

const AssignMapLayerDialog = ({
    classes,
    mapLayer,
    projects,
    assignedProjectList,
    modalOpen,
    onPositiveButtonClicked,
    onNegativeButtonClicked,
}) => {

    const [stateProjectValue, setStateProjectValue] = React.useState("")
    const [stateOptions, setStateOptions] = React.useState([])
    const [stateProjectInputValue, setStateProjectInputValue] = React.useState("")
    const [stateDisablePositiveButton, setStateDisablePositiveButton] = React.useState(true)

    React.useEffect(() => {
        const options = []
        const projectsJS = projects.toJS()
        const alreadyAssignedProjecs = assignedProjectList.map((project) => project.id)

        for (const key in projectsJS) {
          if (!(alreadyAssignedProjecs.includes(projectsJS[key].id))) {
            options.push({
              label: `${projectsJS[key].title} - ${projectsJS[key].job_order}`,
              id: projectsJS[key]
            })
          } 
        }

        setStateOptions(options)
    }, [projects])

    const handleOnPositiveButtonClicked = () => {
        onPositiveButtonClicked(mapLayer.original, stateProjectValue.id);
    };

    const changeProjectAutocomplete = (event, newValue) => {
        setStateProjectInputValue(newValue)
        setStateDisablePositiveButton(newValue === "")
    }

    const onChange = (newValue) => {
        setStateProjectValue(newValue)
    }

    return (
        <>
          <CustomDialog
            open={modalOpen}
            onClick={handleOnPositiveButtonClicked}
            onClose={onNegativeButtonClicked}
            renderTitle={() => {
              return (
                <DialogTitle 
                    id="alert-dialog-title"
                    style={{ margin: `0 0 ${styles.spacing08} 0` }}>
                  {"Kartendatei zu einem Projekt zuweisen"}
                </DialogTitle>
              );
            }}
            renderContent={() => {
              return (
                <DialogContent style={{ paddingTop: "20px" }}>
                    <div style={{ margin: `0 0 ${styles.spacing32} 0` }}>
                        <Autocomplete
                            fullWidth={true}
                            freeSolo
                            id="combo-box-demo"
                            value={stateProjectValue}
                            onChange={(event, newValue) => {
                                onChange(newValue);
                            }}
                            inputValue={stateProjectInputValue}
                            onInputChange={changeProjectAutocomplete}
                            options={stateOptions}
                            renderInput={(params) => (
                                <TextField {...params} label="Projekt" />
                            )}
                            renderOption={(props, option, { inputValue }) => {
                                const matches = match(option.label, inputValue, {
                                insideWords: true,
                                });
                                const parts = parse(option.label, matches);

                                return (
                                <li {...props}>
                                    <div>
                                    {parts.map((part, index) => (
                                        <span
                                        key={index}
                                        style={{
                                            fontWeight: part.highlight ? 700 : 400,
                                        }}
                                        >
                                        {part.text}
                                        </span>
                                    ))}
                                    </div>
                                </li>
                                );
                            }}
                        />
                    </div>
                </DialogContent>
              );
            }}
            renderButtonGroup={(
              handleOnPositiveButtonClicked,
              onNegativeButtonClicked
            ) => {
              return (
                <DialogActions style={{ padding: "2.4rem 2.4rem 2rem" }}>
                  <ButtonGroup
                    align="right"
                    leftButtonText="Abbrechen"
                    leftButtonClick={onNegativeButtonClicked}
                    rightButtonText={"Zuweisen"}
                    rightButtonClick={handleOnPositiveButtonClicked}
                    rightButtonDisabled={stateDisablePositiveButton}
                    spacingTop={false}
                  />
                </DialogActions>
              );
            }}
          />
        </>
      );
}

AssignMapLayerDialog.propTypes = propTypes;
export default withStyles(overrideStyles, { withTheme: true })(
    AssignMapLayerDialog
);
