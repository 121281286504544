/* eslint-disable import/namespace */
/* eslint-disable no-unused-vars */
import { createAction } from "redux-actions";

import HTTP from "../lib/http";
import API from "../lib/api";
import { SEARCH_USER } from "../constants/actions/actions";
import { renderSnackbar } from "./snackbars";
import {
  inviteUserFail,
  inviteUserSuccess,
  resendInvitationFail,
  resendInvitationSuccess,
  deleteInvitationFail,
  deleteInvitationSuccess,
  removeUserFail,
  removeUserSuccess,
} from "../constants/snackbars";
import { getOrgaId } from "../sharedFunctions/organization";

export const searchUser = createAction(SEARCH_USER);

export function sendInvitation(user) {
  return async (dispatch, getState) => {
    const orgaId = getOrgaId(getState());
    if (orgaId) {
      await HTTP.post(API.organizations.sendInvitation(orgaId), user)
        .then(function (response) {
          dispatch(renderSnackbar(inviteUserSuccess));
        })
        .catch(function (error) {
          dispatch(renderSnackbar(inviteUserFail));
          console.log("ERROR INVITE USER", error);
        });
    } else {
      dispatch(renderSnackbar(inviteUserFail));
    }
  };
}

export function resendInvitation(user) {
  return async (dispatch, getState) => {
    const orgaId = getOrgaId(getState());
    if (orgaId) {
      await HTTP.post(API.organizations.resendInvitation(user), null)
        .then(function (response) {
          dispatch(renderSnackbar(resendInvitationSuccess));
        })
        .catch(function (error) {
          dispatch(renderSnackbar(resendInvitationFail));
          console.log("ERROR RESEND INVITATION", error);
        });
    } else {
      dispatch(renderSnackbar(resendInvitationFail));
    }
  };
}

export function deleteInvitation(user) {
  return async (dispatch, getState) => {
    const orgaId = getOrgaId(getState());
    if (orgaId) {
      await HTTP.delete(API.organizations.deleteInvitation(user.id), null)
        .then(function (response) {
          dispatch(renderSnackbar(deleteInvitationSuccess));
        })
        .catch(function (error) {
          dispatch(renderSnackbar(deleteInvitationFail));
          console.log("ERROR GET SUBSCRIPTIONS", error);
        });
    } else {
      dispatch(renderSnackbar(deleteInvitationFail));
    }
  };
}

export function removeUser(user) {
  return async (dispatch, getState) => {
    const orgaId = getOrgaId(getState());
    if (orgaId) {
      await HTTP.delete(API.organizations.removeUser(orgaId, user), null)
        .then(function (response) {
          dispatch(renderSnackbar(removeUserSuccess));
        })
        .catch(function (error) {
          dispatch(renderSnackbar(removeUserFail));
          console.log("ERROR DELETE USER", error);
        });
    } else {
      dispatch(renderSnackbar(removeUserFail));
    }
  };
}

export function updateUser(user) {
  return async (dispatch, getState) => {
    const orgaId = getOrgaId(getState());
    if (orgaId) {
      await HTTP.put(
        API.organizations.updateUser(orgaId, user.params.id),
        user
      ).catch(function (error) {
        console.log(error);
      });
    }
  };
}

export function uploadAvatar(userId, avatar) {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return async (dispatch, getState) => {
    const orgaId = getOrgaId(getState());
    if (orgaId) {
      await HTTP.put(
        API.organizations.updateUser(orgaId, userId),
        avatar,
        config
      ).catch(function (error) {
        console.log(error);
      });
    }
  };
}

export function deleteAvatar(userId) {
  return async (dispatch, getState) => {
    const orgaId = getOrgaId(getState());
    if (orgaId) {
      await HTTP.delete(
        API.organizations.deleteUserAvatar(orgaId, userId),
        null
      ).catch(function (error) {
        console.log(error);
      });
    }
  };
}
