import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { appSettingsOrderingCategoryDescription } from "../../../../constants/userSettings";

import * as styles from "../../../../styles/styles";

const propTypes = {
  property: PropTypes.string,
};

const DescriptionUiElement = ({ property }) => {
  return (
    <Typography
      key={`category-description-${property}`}
      variant="body1"
      style={{ margin: `0 0 ${styles.spacing12} 0` }}
    >
      {appSettingsOrderingCategoryDescription[property]}
    </Typography>
  );
};

DescriptionUiElement.propTypes = propTypes;
export default DescriptionUiElement;
