/* eslint-disable no-unused-vars */
export function getOrgaId(state) {
  return state.getIn(["user", "organization_role_groups"])[0]["resource_id"];
}

export function getLicenceModel(state) {
  const orgaId = getOrgaId(state);

  const organizations = state.getIn(["organizations", "items"]).toJS();

  const organization = Object.values(organizations).filter((item) => {
    return item.id === orgaId;
  });

  if (organization.length > 0) {
    return organization[0].licence_model.name;
  } else {
    return "unknown";
  }
}

export function hasOrganizationFeatureFlag(state, featureFlag) {
  const orgaId = getOrgaId(state);

  const organizations = state.getIn(["organizations", "items"]).toJS();

  const organization = Object.values(organizations).filter((item) => {
    return item.id === orgaId;
  });

  if (organization.length > 0) {
    const result = organization[0].licence_model.features.filter((feature) => {
      return feature.flag === featureFlag;
    });
    return result.length === 1;
  }
  return false;
}
