import Login from "./Login";
import Registration from "./Registration";
import ForgotPasswordDialog from "./ResetPassword/ForgotPasswordDialog";
import ResetPasswordDialog from "./ResetPassword/ResetPassword";
import ConfirmUser from "./ConfirmUser";

export default {
  Login,
  Registration,
  ConfirmUser,
  ForgotPasswordDialog,
  ResetPasswordDialog,
};
