// Status Survey
export const statusDone = "#8BC34A";
export const statusOpen = "#FF5722";
export const statusInProgress = "#FFC107";
export const statusInSync = "#03A9F4";
export const statusInUpload = "#03A9F4";
export const statusArchived = "#9E9E9E";

// COLORS
// Primary
export const primary = "#5953D2";
export const primaryLight = "#8F80FF";
export const primaryDark = "#443F9E";
export const primaryContrastText = "#FFFFFF";

// Secondary
export const secondary = "#853BD1";
export const secondaryLight = "#B96BFF";
export const secondaryDark = "#51009F";
export const secondaryContrastText = "#FFFFFF";

// Error
export const error = "#FF0400";
export const errorContrastText = "#FFFFFF";

// Greyish
export const darkBlueBase = "#1F2633";
export const darkBlueLighter = "#474E5D";
export const darkBlueDarker = "#000000";
export const darkBlueDark = "#3C424D";
export const darkBlueMediumDark = "#868A91";
export const darkBlueMedium = "#AAADB1";
export const darkBlueMediumLight = "#E3E4E5";
export const darkBlueLight = "#F0F1F1";
export const darkBlueSuperLight = "#F8F8F9";
export const darkBlueContrastText = "#FFFFFF";

// REST
export const white = "#FFFFFF";
export const red = "#FF0400";
export const redClick = "#B20400";
export const green = "#2DA50B";
export const greenClick = "#207C06";
export const black = "#000000";
export const red500 = "#F44336";
export const pink500 = "#E91E63";
export const purple500 = "#9C27B0";
export const deeppurple500 = "#673AB7";
export const indigo500 = "#3F51B5";
export const blue500 = "#2196F3";
export const lightblue500 = "#03A9F4";
export const cyan500 = "#00BCD4";
export const teal500 = "#009688";
export const green500 = "#4CAF50";
export const lightgreen500 = "#8BC34A";
export const lime500 = "#CDDC39";
export const yellow500 = "#FFEB3B";
export const amber500 = "#FFC107";
export const orange500 = "#FF9800";
export const deeporange500 = "#FF5722";

// FONT
// Dark
export const h1 = {
  fontSize: "3.4rem",
  fontWeight: 400,
  lineHeight: "4rem",
};
export const h2 = {
  fontSize: "2.4rem",
  fontWeight: 400,
  lineHeight: "3.2rem",
};
export const h3 = {
  fontSize: "2.0rem",
  fontWeight: 500,
  lineHeight: "2.8rem",
};
export const h4 = {
  fontSize: "2.0rem",
  fontWeight: 500,
  lineHeight: "2.8rem",
};
export const h5 = {
  fontSize: "2.0rem",
  fontWeight: 400,
  lineHeight: "2.8rem",
};
export const h6 = {
  fontSize: "2.0rem",
  fontWeight: 500,
  lineHeight: "2.8rem",
};
export const subtitle1 = {
  fontSize: "2.0rem",
  fontWeight: 400,
  lineHeight: "2.8rem",
};
export const subtitle2 = {
  fontSize: "1.6rem",
  fontWeight: 500,
  lineHeight: "2.4rem",
};
export const body1 = {
  fontSize: "1.6rem",
  fontWeight: 400,
  lineHeight: "2.4rem",
};
export const body2 = {
  fontSize: "1.4rem",
  fontWeight: 400,
  lineHeight: "1.6rem",
};
export const caption = {
  fontSize: "1.2rem",
  fontWeight: 400,
  lineHeight: "1.4rem",
};
export const button = {
  fontSize: "1.5rem",
  fontWeight: 400,
};

// AVATAR
export const avatarSize = 50;

// REST
export const boxShadowListElement =
  "0 0.3rem 0.6rem 0 rgba(0, 0, 0, 0.23), 0 0.3rem 0.6rem 0 rgba(0, 0, 0, 0.16)";
export const boxShadowListElementHover =
  "0 0.3rem 0.6rem 0 rgba(0, 0, 0, 0.33), 0 0.3rem 0.6rem 0 rgba(0, 0, 0, 0.30)";
export const spacing1 = "2rem";
export const spacing08 = "0.8rem";
export const spacing12 = "1.2rem";
export const spacing16 = "1.6rem";
export const spacing24 = "2.4rem";
export const spacing32 = "3.2rem";
export const spacing48 = "4.8rem";
export const drawerWidth = "26rem";
export const fontFamily = '"Roboto", "Helvetica", "Arial", sans-serif';
export const measurementPointColor = "#4D4D4D";
