import React from 'react';
import PropTypes from 'prop-types';
import Avatar from "@mui/material/Avatar";

import * as styles from '../../styles/styles';

const propTypes = {
  avatar: PropTypes.string.isRequired,
  avatarStyles: PropTypes.object,
};

const ImageAvatar = ({
  avatar,
  avatarStyles,
}) => {
  return <Avatar
    alt='avatar'
    src={avatar}
    style={{
        backgroundColor: styles.secondary,
        width: 200,
        height: 200,
        ...avatarStyles
      }} />
}

ImageAvatar.propTypes = propTypes;
export default ImageAvatar;