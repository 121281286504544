/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ButtonGroup from "../../../components/ButtonGroup";
import CustomDialog from "../../../components/CustomDialog";

import ComponentCatalogSelector from "./ComponentCatalogSelector";
import LayerTypeSelector from "./LayerTypeSelector";
import ColorSelection from "./ColorSelection";
import LayerNameInput from "./LayerNameInput";

import { validateTextFields } from "../../../sharedFunctions/validation";
import { hasOrganizationFeatureFlag } from "../../../sharedFunctions/organization";

import { 
  LAYER_TYPE_GNSS_CONTINUOUS_LINE_IDX, 
  LAYER_TYPE_GNSS_LINE_IDX, 
  LAYER_TYPE_GNSS_POINT_IDX, 
  LAYER_TYPE_GNSS_POLYGON_IDX, 
  LAYER_TYPE_LINE_IDX, 
  LAYER_TYPE_POINT_IDX
} from "../../../constants/measureLayerConfig";
import { FEATURE_GNSS_MEASUREMENT } from "../../../constants/featureFlags";

import * as styles from "../../../styles/styles";
import "./styles.css";

const propTypes = {
  project: PropTypes.object,
  measureLayerConfig: PropTypes.object,
  edit: PropTypes.bool,
  modalOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  measureLayerConfigs: PropTypes.object,
  onUpdateMeasureLayerConfig: PropTypes.func,
  onCreateMeasureLayerConfigs: PropTypes.func,
  defaultComponentCategories: PropTypes.array,
  organizationComponentCategories: PropTypes.array,
  componentPipeDefinitionItems: PropTypes.array,
  componentMaterialItems: PropTypes.array,
  catalogComponentItems: PropTypes.array,
  data: PropTypes.object,
  featureGnssMeasurementAllowed: PropTypes.bool
};

const defaultProps = {
  edit: false,
  project: {},
  measureLayerConfig: {
    color: "",
    layer_name: "",
    layer_type: 1,
    required: false,
  },
  featureGnssMeasurementAllowed: false
};

const MeasureLayerConfigDialog = ({
  measureLayerConfig,
  project,
  edit,
  modalOpen,
  onClick,
  onClose,
  measureLayerConfigs,
  onUpdateMeasureLayerConfig,
  onCreateMeasureLayerConfigs,
  defaultComponentCategories,
  organizationComponentCategories,
  componentPipeDefinitionItems,
  componentMaterialItems,
  catalogComponentItems,
  data,
  featureGnssMeasurementAllowed
}) => {
  const layer_name = measureLayerConfig.layer_name;
  const color = measureLayerConfig.color;
  const layer_type = measureLayerConfig.layer_type;
  const required = measureLayerConfig.required;
  const component_catalogs = measureLayerConfig.component_catalogs
    ? measureLayerConfig.component_catalogs
    : [];

  const [stateColor, setColor] = React.useState(color);
  const [stateLayerNameInputValue, setStateLayerNameInputValue] =
    React.useState(layer_name);
  const [stateLayerNameValue, setStateLayerNameValue] = React.useState(null);
  const [stateLayerType, setLayerType] = React.useState(layer_type);
  const [stateComponentCatalogs, setComponentCatalogs] =
    React.useState(component_catalogs);
  const [stateDisablePositiveButton, setDisablePositiveButton] = useState(true);
  const [stateOptions, setStateOptions] = React.useState([]);

  React.useEffect(() => {
    if (stateComponentCatalogs.length > 0) {

      const options = [];

      switch(stateLayerType) {
        case LAYER_TYPE_GNSS_CONTINUOUS_LINE_IDX:
        case LAYER_TYPE_GNSS_LINE_IDX:
        case LAYER_TYPE_LINE_IDX: {

          const pipeDefinitions = componentPipeDefinitionItems.filter((element) => {
            return element.rootUuid === stateComponentCatalogs[0];
          });
          const materials = componentMaterialItems.filter((element) => {
            return element.rootUuid === stateComponentCatalogs[0];
          });

          if (pipeDefinitions.length > 0) {
            const pipeDefinition = pipeDefinitions[0];

            pipeDefinition.items.forEach((item) => {
              options.push({
                label: `${item.material.name} - ${item.dimension.name}`,
              });
            });
          }

          if (materials.length > 0) {
            materials[0].items.forEach((item) => {
              options.push({
                label: `${item.name}`,
              });
            });
          }
          break;
        }
        case LAYER_TYPE_GNSS_POINT_IDX:
        case LAYER_TYPE_GNSS_POLYGON_IDX:
        case LAYER_TYPE_POINT_IDX: {
          const components = catalogComponentItems
            .filter((element) => {
              return element.rootUuid === stateComponentCatalogs[0]
            })
          
          if (components.length > 0) {
            components[0].items.forEach((item) => {
              options.push({
                label: `${item.name}`
              })
            })
          }
          
          break;
        }
      }

      const activeOptionIndex = options.findIndex((item) => {
        item.label === layer_name;
      });

      const optionValue =
        activeOptionIndex > 0 ? options[activeOptionIndex] : null;

      setStateLayerNameValue(optionValue);
      setStateOptions(options);
    }
  }, [stateComponentCatalogs, stateLayerType]);

  const validationValues = React.useMemo(() => {
    return {
      layer_name: {
        value: layer_name ? layer_name : "",
        required: true,
      },
      color: {
        value: color ? color : "",
        required: true,
      },
      layer_type: {
        value: layer_type ? layer_type : "",
        required: false,
      },
      component_catalog: {
        value: component_catalogs ? component_catalogs : [],
        required: false,
      },
    };
  }, [layer_name, color, layer_type, component_catalogs]);

  const getOldValues = () => {
    return {
      layer_name: stateLayerNameInputValue,
      color: stateColor,
      layer_type: stateLayerType,
      component_catalogs: stateComponentCatalogs,
    };
  };

  const onSelect = (colorKey) => {
    const newValues = { ...getOldValues(), color: colorKey };
    setColor(colorKey);
    setDisablePositiveButton(validateTextFields(validationValues, newValues));
  };

  const changeLayerNameTextField = (event) => {
    const layerName = event.target.value;
    const newValues = { ...getOldValues(), layer_name: layerName };
    setStateLayerNameInputValue(layerName);
    setDisablePositiveButton(validateTextFields(validationValues, newValues));
  };

  const changeLayerNameAutocomplete = (event, newValue) => {
    let newLayerName = "";
    if (newValue) {
      newLayerName = newValue;
    }
    const newValues = { ...getOldValues(), layer_name: newLayerName };
    setStateLayerNameInputValue(newValue);
    setDisablePositiveButton(validateTextFields(validationValues, newValues));
  };

  const changeLayerType = (event) => {
    const layerType = event.target.value;

    setStateLayerNameInputValue("");
    const layerName = "";
    const newValues = {
      ...getOldValues(),
      layer_type: layerType,
      layer_name: layerName,
    };

    setLayerType(layerType);
    setDisablePositiveButton(validateTextFields(validationValues, newValues));
  };

  const changeComponentCatalog = (event) => {
    const componentCatalog = event.target.value;
    const newValues = {
      ...getOldValues(),
      component_catalogs: [componentCatalog],
    };

    // If the user has selected a ComponentCatalog
    // or switched to a different one, the content
    // of the LayerName Input-Field is cleared. If the user
    // has removed a previously selected ComponentCatalog, the
    // content of the LayerName Input-Field stays the same.
    if (componentCatalog === "") {
      setComponentCatalogs([]);
    } else {
      setComponentCatalogs([componentCatalog]);
      setStateLayerNameInputValue("");
      setStateLayerNameValue(null);
    }

    setDisablePositiveButton(validateTextFields(validationValues, newValues));
  };

  const executeOnClick = () => {
    if (edit) {
      const updatedMeasureLayerConfig = {
        ...measureLayerConfig,
        id: measureLayerConfig.id,
        color: stateColor,
        layer_name: stateLayerNameInputValue.trim(),
        layer_type: stateLayerType,
        component_catalogs: stateComponentCatalogs,
      };
      onUpdateMeasureLayerConfig(updatedMeasureLayerConfig);
      onClick();
    } else {
      const newMeasureLayerConfig = {
        color: stateColor,
        layer_name: stateLayerNameInputValue.trim(),
        layer_type: stateLayerType,
        required: false,
        component_catalogs: stateComponentCatalogs,
      };
      const measureLayerConfigs = [newMeasureLayerConfig];
      onCreateMeasureLayerConfigs({ project, measureLayerConfigs });
      onClick();
    }
  };

  // Render the Layer Type Selector if the current Layer
  // is not required or if the Layer-Type is not 2 (ReferencePoint-Layer)
  const renderLayerTypeSelectorFlag = !required || stateLayerType !== 2;

  return (
    <>
      <CustomDialog
        maxWidth={"sm"}
        open={modalOpen}
        onClick={executeOnClick}
        onClose={onClose}
        showHint={false}
        renderTitle={() => {
          return (
            <DialogTitle
              id="alert-dialog-title"
              style={{ margin: `0 0 ${styles.spacing08} 0` }}
            >
              {edit ? "Layer bearbeiten" : "Layer anlegen"}
            </DialogTitle>
          );
        }}
        renderContent={() => {
          return (
            <DialogContent style={{ paddingTop: "20px" }}>
              <LayerNameInput
                stateComponentCatalogs={stateComponentCatalogs}
                stateOptions={stateOptions}
                stateLayerNameInputValue={stateLayerNameInputValue}
                stateLayerNameValue={stateLayerNameValue}
                stateLayerType={stateLayerType}
                changeLayerNameAutocomplete={changeLayerNameAutocomplete}
                setStateLayerNameValue={setStateLayerNameValue}
                changeLayerNameTextField={changeLayerNameTextField}
              />

              {renderLayerTypeSelectorFlag && (
                <LayerTypeSelector
                  stateLayerType={stateLayerType}
                  measureLayerConfigs={measureLayerConfigs}
                  edit={edit}
                  changeLayerType={changeLayerType}
                  featureGnssMeasurementAllowed={featureGnssMeasurementAllowed}
                />
              )}
              <ComponentCatalogSelector
                data={data}
                defaultComponentCategories={defaultComponentCategories}
                organizationComponentCategories={
                  organizationComponentCategories
                }
                changeComponentCatalog={changeComponentCatalog}
                stateComponentCatalogs={stateComponentCatalogs}
              />

              <ColorSelection stateColor={stateColor} onSelect={onSelect} />
            </DialogContent>
          );
        }}
        renderButtonGroup={(onClick, onClose) => {
          return (
            <DialogActions style={{ padding: "2.4rem 2.4rem 2rem" }}>
              <ButtonGroup
                align="right"
                leftButtonText="Abbrechen"
                leftButtonClick={onClose}
                rightButtonText={edit ? "Ändern" : "Anlegen"}
                rightButtonClick={onClick}
                rightButtonDisabled={stateDisablePositiveButton}
                spacingTop={false}
              />
            </DialogActions>
          );
        }}
      />
    </>
  );
};

function stateToProps(state, ownProps) {
  const defaultComponentCatalogUuids = state
    .getIn(["componentCatalogs", "items", "catalogOrganizationCategories"])
    .filter((item) => !item.item.organization_id)
    .map((item) => item.item.uuid);
  const organizationComponentCatalogUuids = state
    .getIn(["componentCatalogs", "items", "catalogOrganizationCategories"])
    .filter((item) => item.item.organization_id)
    .map((item) => item.item.uuid);

  const defaultComponentCategories = state
    .getIn(["componentCatalogs", "items", "catalogCategories"])
    .filter((item) => defaultComponentCatalogUuids.includes(item.rootUuid));

  const organizationComponentCategories = state
    .getIn(["componentCatalogs", "items", "catalogCategories"])
    .filter((item) =>
      organizationComponentCatalogUuids.includes(item.rootUuid)
    );

  const componentPipeDefinitionItems = state.getIn([
    "componentCatalogs",
    "items",
    "catalogPipeDefinitionsCategories",
  ]);

  const componentMaterialItems = state.getIn([
    "componentCatalogs",
    "items",
    "catalogMaterialsCategories",
  ]);

  const catalogComponentItems = state.getIn([
    "componentCatalogs",
    "items",
    "catalogComponentsCategories"
  ])

  const data = state.getIn(["componentCatalogs", "items"]);

  const featureGnssMeasurementAllowed = hasOrganizationFeatureFlag(state, FEATURE_GNSS_MEASUREMENT)

  return {
    data,
    defaultComponentCategories,
    organizationComponentCategories,
    componentPipeDefinitionItems,
    componentMaterialItems,
    catalogComponentItems,
    featureGnssMeasurementAllowed
  };
}

MeasureLayerConfigDialog.propTypes = propTypes;
MeasureLayerConfigDialog.defaultProps = defaultProps;
export default connect(stateToProps, null)(MeasureLayerConfigDialog);
