/* eslint-disable no-unused-vars */
import { COMPONENT_TYPE_CLICK } from "../../../../../../../constants/ecs";

export default class ComponentClick {
  constructor(params) {
    this.componentType = COMPONENT_TYPE_CLICK;
  }

  getComponentType() {
    return this.componentType;
  }
}
