export const MAP_VIEW_TYPE = "MAP_VIEW_TYPE";
export const AR_LAYER_VIEW_TYPE = "AR_LAYER_VIEW_TYPE";
export const PROJECT_FILES_VIEW_TYPE = "PROJECT_FILES_VIEW_TYPE";

export const SEARCH_KEY_OBJECT = {
  title: {
    main: "projects",
    key: "title",
  },
  description: {
    main: "projects",
    key: "description",
  },
  job_order: {
    main: "projects",
    key: "job_order",
  },
  place: {
    main: "projects",
    key: "place",
    children: {
      streetname: {
        main: "projects",
        key: "streetname",
      },
      zip_code: {
        main: "projects",
        key: "zip_code",
      },
      city: {
        main: "projects",
        key: "city",
      },
    },
  },
  state: {
    main: "projects",
    key: "state",
  },
  user_id: {
    main: "projects",
    key: "user_id",
    ref: {
      main: "users",
      key: "id",
      search_by: ["first_name", "last_name"],
    },
  },
  assigned_user_id: {
    main: "projects",
    key: "assigned_user_id",
    ref: {
      main: "users",
      key: "id",
      search_by: ["first_name", "last_name"],
    },
  },
};

export const STATE_OPEN = "open";
export const STATE_IN_PROGRESS = "in_progress";
export const STATE_DONE = "done";
export const STATE_SYNCED = "synced";
export const STATE_UPLOAD = "upload";
export const STATE_ARCHIVED = "archived";
