import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

import * as styles from '../../styles/styles';
import './styles.css';

const propTypes = {
  align: PropTypes.oneOf(['right', 'left', 'evenly', 'center']),
  containerStyles: PropTypes.object,
  leftButtonText: PropTypes.string.isRequired,
  leftButtonClick: PropTypes.func.isRequired,
  leftButtonDisabled: PropTypes.bool,
  leftButtonStyle: PropTypes.object,
  rightButtonText: PropTypes.string.isRequired,
  rightButtonClick: PropTypes.func.isRequired,
  rightButtonDisabled: PropTypes.bool,
  rightButtonStyle: PropTypes.object,
  spacingTop: PropTypes.bool,
};

const defaultProps = {
  align: 'right',
  containerStyles: {},
  leftButtonDisabled: false,
  leftButtonStyle: {},
  rightButtonDisabled: false,
  rightButtonStyle: {},
  spacingBottom: false,
  spacingTop: false,
};

const ButtonGroup = ({
  align,
  containerStyles,
  leftButtonText,
  leftButtonClick,
  leftButtonDisabled,
  leftButtonStyle,
  rightButtonDisabled,
  rightButtonText,
  rightButtonClick,
  rightButtonStyle,
  spacingBottom,
  spacingTop,
}) => {
  const renderButtons = () => (
    <>
      <Button
        color='primary'
        disabled={leftButtonDisabled}
        onClick={leftButtonClick}
        style={leftButtonStyle}
        size='medium'
        variant='text'>
        {leftButtonText}
      </Button>
      <Button
        color='primary'
        disabled={rightButtonDisabled}
        onClick={rightButtonClick}
        size='medium'
        style={{ ...rightButtonStyle, margin: `0 0 0 ${styles.spacing08}` }}
        variant='text'>
        {rightButtonText}
      </Button>
    </>
  )

  const addAlign = () => (
    `button-group-align-${align}`
  )

  const renderFirstDiv = addAlignToDiv => (
    <div
      className={`button-group-container 
        ${spacingTop ? 'button-group-spacing-top' : ''}
        ${spacingBottom ? 'button-group-spacing-bottom' : ''}
        ${!addAlignToDiv ? addAlign() : ''}`}
      style={containerStyles}>
      {addAlignToDiv ? renderSecondDiv(addAlignToDiv) : renderButtons()}
    </div>
  )

  const renderSecondDiv = addAlignToDiv => (
    <div
      className={addAlignToDiv ? addAlign() : ''}>
      {renderButtons()}
    </div>
  )

  const renderDivs = () => {
    if (align === 'left' || align === 'right') return renderFirstDiv(true);
    return renderFirstDiv(false);
  }

  return renderDivs();
}

ButtonGroup.defaultProps = defaultProps;
ButtonGroup.propTypes = propTypes;
export default ButtonGroup;