/* eslint-disable import/namespace */
/* eslint-disable no-unused-vars */
import { createAction } from "redux-actions";

import {
  SET_MEASUREMENT_LINE,
  SET_MEASUREMENT_LINES,
  EDIT_MEASUREMENT_LINE,
  REMOVE_MEASUREMENT_LINE,
  REMOVE_MEASUREMENT_LINES,
} from "../constants/actions/actions";

import {
  MEASUREMENT_LINES_CREATE_EVENT,
  MEASUREMENT_LINE_UPDATE_EVENT,
} from "../constants/channelevents";

import {
  updateMeasurementLineFail,
  updateMeasurementLineSuccess,
} from "../constants/snackbars";

import API from "../lib/api";
import HTTP from "../lib/http";
import { renderSnackbar } from "./snackbars";

export const setMeasurementLines = createAction(SET_MEASUREMENT_LINES);
export const setMeasurementLine = createAction(SET_MEASUREMENT_LINE);
export const editMeasurementLine = createAction(EDIT_MEASUREMENT_LINE);
export const removeMeasurementLine = createAction(REMOVE_MEASUREMENT_LINE);
export const removeMeasurementLines = createAction(REMOVE_MEASUREMENT_LINES);

export const CableActions = (message, connection, store) => {
  switch (message.event) {
    case MEASUREMENT_LINES_CREATE_EVENT:
      store.dispatch(setMeasurementLines(message.payload.measurement_lines));
      break;
    case MEASUREMENT_LINE_UPDATE_EVENT:
      store.dispatch(
        editMeasurementLine({
          id: message.payload.measurement_line.id,
          item: message.payload.measurement_line,
        })
      );
      break;
    default:
      break;
  }
};

export function updateMeasurementLine(measurement_line) {
  return async (dispatch, getState) => {
    await HTTP.put(
      API.measurementLines.updateMeasurementLine(measurement_line.id),
      { measurement_line }
    )
      .then(function (response) {
        dispatch(renderSnackbar(updateMeasurementLineSuccess));
      })
      .catch(function (error) {
        dispatch(renderSnackbar(updateMeasurementLineFail));
        console.log("UPDATE MEASUREMENT LINE ERROR", error);
      });
  };
}
