/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Button from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Header from "../../../../components/Header";
import MeasureLayerConfigDialog from "../../../../containers/dialogs/MeasureLayerConfigDialog";
import MeasureLayerTable from "./MeasureLayerTable";
import { SEARCH_KEY_OBJECT } from "../../../../constants/project";
import { 
  LAYER_TYPE_REFERENCE_POINT_IDX,
  LAYER_TYPE_LINE_IDX,
  LAYER_TYPE_POINT_IDX,
  LAYER_TYPE_GNSS_POINT_IDX,
  LAYER_TYPE_GNSS_LINE_IDX,
  LAYER_TYPE_GNSS_POLYGON_IDX,
  LAYER_TYPE_GNSS_CONTINUOUS_LINE_IDX,
  LAYER_TYPE_GNSS_REF_POINT_IDX
} from "../../../../constants/measureLayerConfig";
import SearchButtonResults from "../../../../components/SearchButton/SearchButtonResults";
import { createMeasureLayerConfigs } from "../../../../actions/measure_layer_configs";
import * as styles from "../../../../styles/styles";

const propTypes = {
  users: PropTypes.array,
  projects: PropTypes.object,
  project: PropTypes.object,
  measureLayerConfigs: PropTypes.object,
  createMeasureLayerConfigs: PropTypes.func,
};

const MeasureLayer = ({
  users,
  projects,
  project,
  measureLayerConfigs,
  createMeasureLayerConfigs,
}) => {
  const sortedMap = sortMeasureLayerConfigObject(measureLayerConfigs);

  const [createModalOpen, setCreateModalOpen] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [results, setResults] = React.useState({});

  const closeModal = () => {
    setCreateModalOpen(false);
  };

  const onCreateMeasureLayerConfigs = (payload) => {
    createMeasureLayerConfigs(payload.project, payload.measureLayerConfigs);
  };

  return (
    <div className="project-bottom-container">
      {createModalOpen &&
        showCreateMeasureLayerConfigDialog(
          project,
          createModalOpen,
          closeModal,
          measureLayerConfigs,
          onCreateMeasureLayerConfigs
        )}
      <Header>
        <SearchButtonResults
          onClickOutside={() => {
            setSearchTerm("");
            setResults({});
          }}
          projects={projects.toJS()}
          users={users}
          searchTerm={searchTerm}
          results={results}
          searchKeyList={SEARCH_KEY_OBJECT}
          search={(searchTerm) => setSearchTerm(searchTerm)}
          getResults={(results) => setResults(results)}
          onClick={() => setResults({})}
          placeholderText="Nach Projekt suchen..."
        />
        <div className="header-right-side">
          <Button
            style={{ margin: `0 ${styles.spacing16} 0 0` }}
            color="primary"
            variant="contained"
            size="medium"
            onClick={() => setCreateModalOpen(true)}
          >
            <AddCircleIcon style={{ margin: "0 1.2rem 0 0" }} />
            Layer anlegen
          </Button>
        </div>
      </Header>
      <MeasureLayerTable measureLayerConfigMap={sortedMap} />
    </div>
  );
};

function showCreateMeasureLayerConfigDialog(
  project,
  createModalOpen,
  closeModal,
  measureLayerConfigs,
  onCreateMeasureLayerConfigs
) {
  return (
    <MeasureLayerConfigDialog
      project={project}
      edit={false}
      modalOpen={createModalOpen}
      onClose={() => closeModal()}
      onClick={() => closeModal()}
      measureLayerConfigs={measureLayerConfigs}
      onCreateMeasureLayerConfigs={onCreateMeasureLayerConfigs}
    />
  );
}

function sortMeasureLayerConfigObject(unsortObject) {
  const sortedMap = new Map();

  Object.values(unsortObject).forEach((element) => {
    let priority = 0;
    if (element.required) {
      if (element.layer_type === LAYER_TYPE_REFERENCE_POINT_IDX) priority = 1;
      else if (element.layer_type === LAYER_TYPE_LINE_IDX) priority = 2;
      else if (element.layer_type === LAYER_TYPE_POINT_IDX) priority = 3;
    } else {
      if (element.layer_type === LAYER_TYPE_LINE_IDX) priority = 4;
      else if (element.layer_type === LAYER_TYPE_POINT_IDX) priority = 5;
      else if (element.layer_type === LAYER_TYPE_GNSS_POINT_IDX) priority = 6;
      else if (element.layer_type === LAYER_TYPE_GNSS_LINE_IDX) priority = 7;
      else if (element.layer_type === LAYER_TYPE_GNSS_POLYGON_IDX) priority = 8;
      else if (element.layer_type === LAYER_TYPE_GNSS_CONTINUOUS_LINE_IDX) priority = 9;
      else if (element.layer_type === LAYER_TYPE_GNSS_REF_POINT_IDX) priority = 10;
    }

    sortedMap.set(element.uuid || element.id, { priority, element });
  })

  // Create a new sorted Map by the priority of every entry of the sortedMap Map.
  const sortedMapByPriority = new Map([...sortedMap.entries()].sort((a, b) => a[1].priority - b[1].priority));
  
  // Create the final Map with the uuid or id of an element as the key
  // and the element as the value.
  const finalSortedMap = new Map();
  sortedMapByPriority.forEach((value, key) => {
    finalSortedMap.set(key, value.element);
  });

  return finalSortedMap;
}

function dispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createMeasureLayerConfigs,
    },
    dispatch
  );
}

MeasureLayer.propTypes = propTypes;
export default connect(null, dispatchToProps)(MeasureLayer);
