import { fromJS, Map } from "immutable";
import {
  EDIT_ORGANIZATION,
  EDIT_ORGANIZATION_USER,
  REMOVE_ORGANIZATION,
  SET_EXPIRED_SUBSCRIPTION,
  SET_ORGANIZATION,
  SET_ORGANIZATIONS,
  SET_REMOVED_FROM_ORGANIZATION,
} from "../constants/actions/actions";

const initialState = fromJS({
  subscriptionExpired: false,
  removedFromOrganization: null,
  loading: false,
  new: 0,
  items: fromJS({}),
  resendingInvitation: false,
});

export const prepareItems = (array) => {
  return Map(array.map((item) => [item.id.toString(), fromJS(item)]));
};

export function organizations(state = initialState, action) {
  switch (action.type) {
    case SET_ORGANIZATIONS:
      return state.mergeIn(["items"], prepareItems(action.payload));

    case SET_ORGANIZATION:
      return state.setIn(
        ["items", action.payload.id.toString()],
        fromJS(action.payload.item)
      );

    case EDIT_ORGANIZATION: {
      let { id, item } = action.payload;
      return state.getIn([`items`, id.toString()])
        ? state.mergeIn([`items`, id.toString()], fromJS(item))
        : state;
    }

    case EDIT_ORGANIZATION_USER: {
      let { organizationId, user } = action.payload;
      const organization = state.getIn(["items", organizationId.toString()]);
      if (organization) {
        let users = organization.get("users");
        users = users.set(
          users.findIndex((u) => user.id === u.get("id")),
          fromJS(user)
        );
        return state.setIn(
          ["items", organizationId.toString(), "users"],
          users
        );
      } else {
        return state;
      }
    }

    case REMOVE_ORGANIZATION:
      return state.deleteIn([`items`, action.payload.id.toString()]);

    case SET_EXPIRED_SUBSCRIPTION:
      return state.set("subscriptionExpired", action.payload);

    case SET_REMOVED_FROM_ORGANIZATION:
      return state.set("removedFromOrganization", action.payload);

    default:
      return state;
  }
}
