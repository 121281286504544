/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import queryString from "qs";

import Wordmark from "../../../atoms/Wordmark";
import { pending, success, fail } from "../../../constants/status";
import { confirmUser, resetRegisterFlags } from "../../../actions/register";
import Spinner from "../../../atoms/Spinner";

import qrCodeGooglePlayStore from "../../../assets/images/qr_code_google_play_store.png";

import "./styles.css";
import * as styles from "../../../styles/styles";

const propTypes = {
  confirmUser: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object,
  userStatus: PropTypes.string,
  resetRegisterFlags: PropTypes.func,
};

class ConfirmUser extends React.Component {
  componentDidMount() {
    const { location, confirmUser } = this.props;
    let urlParams = queryString.parse(location && location.search);
    const token = urlParams["?confirmation_token"];
    confirmUser(token);
  }

  componentWillUnmount() {
    this.props.resetRegisterFlags();
  }

  renderSuccessfullyConfirmed() {
    const { history } = this.props;
    return (
      <>
        <Typography
          variant="h6"
          style={{
            marginBottom: styles.spacing24,
            marginTop: styles.spacing24,
          }}
        >
          Ihr Account wurde erfolgreich freigeschaltet.
        </Typography>
        <Typography variant="body1" color="textPrimary">
          Falls noch nicht geschehen, laden Sie sich die Netpipe Android-App
          herunter. Sie finden die App im Google Play Store. Es gibt zwei
          Möglichkeiten, die App herunterzuladen. Entweder klicken Sie auf das
          Google Play Store Bild oder Sie scannen den QR-Code mit Ihrem
          Android-Smartphone ein.
        </Typography>
        <div className="app-download-div">
          <a href="https://play.google.com/store/apps/details?id=com.netpipe.measure&gl=DE&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
            <img
              className="google-play-store-badge"
              alt="Jetzt bei Google Play"
              src="https://play.google.com/intl/en_us/badges/static/images/badges/de_badge_web_generic.png"
            />
          </a>
          <img className="qr-code" src={qrCodeGooglePlayStore} alt={`Bild`} />
        </div>
        <Typography
          styles={{ marginTop: styles.spacing24 }}
          className="confirm-user-link"
          color="primary"
          onClick={() => history.push("/login")}
          variant="body2"
        >
          Zum Login
        </Typography>
      </>
    );
  }

  renderText() {
    const { userStatus } = this.props;

    if (userStatus === pending) {
      return (
        <Typography
          variant="h6"
          style={{
            color: styles.darkBlueMediumDark,
            marginBottom: styles.spacing24,
          }}
        >
          Ihr Account wird validiert. Einen Moment bitte.
        </Typography>
      );
    } else if (userStatus === success) {
      return this.renderSuccessfullyConfirmed();
    } else if (userStatus === fail) {
      return (
        <Typography variant="h6" style={{ color: styles.darkBlueMediumDark }}>
          Ein Fehler ist aufgetreten, bitte versuchen Sie es erneut. Falls das
          Problem weiterhin besteht, wenden Sie sich bitte an unseren netpipe
          Support: hello@netpipe.io.
        </Typography>
      );
      // Email neusenden?
    }
  }
  render() {
    const { userStatus } = this.props;
    const netpipeWordmarkWidth = 22.7;
    const netpipeWordmarkHeight = 6.1;

    return (
      <div className="root">
        <div className="confirm-user-container">
          <a href="https://www.netpipe.io">
            <Wordmark
              width={netpipeWordmarkWidth}
              height={netpipeWordmarkHeight}
            />
          </a>
          {this.renderText()}
          {userStatus === pending && <Spinner height={2.9} width={2.9} />}
        </div>
      </div>
    );
  }
}

ConfirmUser.propTypes = propTypes;

function dispatchToProps(dispatch) {
  return bindActionCreators(
    {
      confirmUser,
      resetRegisterFlags,
    },
    dispatch
  );
}

function stateToProps(state) {
  return {
    userStatus: state.getIn(["register", "confirmUserStatus"]),
  };
}

export default connect(stateToProps, dispatchToProps)(ConfirmUser);
