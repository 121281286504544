/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardActionArea } from "@mui/material";
import Typography from "@mui/material/Typography";

import classNames from "classnames";

import * as styles from "../../../../../styles/styles";

const propTypes = {
  history: PropTypes.object,
  measureLayerConfigTemplates: PropTypes.any,
  classes: PropTypes.object,
};

const overrideStyles = (theme) => ({
  root: {
    borderRadius: "10px",
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
    backgroundColor: styles.white,
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: styles.darkBlueMedium,
    //boxShadow: styles.boxShadowListElement,
    boxShadow: "none",
    "&:hover": {
      boxShadow: styles.boxShadowListElementHover,
    },
  },
});

const ConfiguratorTemplateCard = ({
  history,
  measureLayerConfigTemplates,
  classes,
}) => {
  const renderCardContent = () => {
    return (
      <div>
        <Typography
          variant="h5"
          component="div"
          sx={{ marginBottom: "1.0rem", fontSize: "2.5rem" }}
        >
          Projekt-Vorlagen
        </Typography>
        <Typography
          variant="body2"
          component="div"
          sx={{ marginBottom: "2rem", fontSize: "1.5rem" }}
        >
          Verwalten Sie hier Ihre Projekt-Vorlagen. Eine Projekt-Vorlage enthält
          vorkonfigurierte Mess-Ebenen (Punkte, Linien), die bei der Erstellung
          eines neuen Projekts automatisch angewendet werden können.
        </Typography>
        <Grid container direction="row">
          <Grid
            container
            direction="row"
            item
            xs={12}
            sx={{ marginBottom: "0.35rem" }}
          >
            <Grid item xs={1}>
              <Typography
                variant="body2"
                component="div"
                sx={{ fontSize: "1.5rem", fontWeight: "600" }}
              >
                {measureLayerConfigTemplates.length > 0
                  ? `${measureLayerConfigTemplates.length}`
                  : 0}
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Typography
                variant="body2"
                component="div"
                sx={{ fontSize: "1.5rem" }}
              >
                {measureLayerConfigTemplates.length === 1
                  ? "Projekt-Vorlage verfügbar"
                  : "Projekt-Vorlagen verfügbar"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <Grid item xs={4} sm={4} md={5}>
      <Card className={classNames(classes.root)}>
        <CardActionArea
          onClick={() => {
            history.push(`/configurator/layer-templates`);
          }}
        >
          <CardContent>{renderCardContent()}</CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

function stateToProps(state) {
  const data = state.getIn(["measureLayerConfigTemplates", "items"]).toJS();

  return {
    measureLayerConfigTemplates: data.measureLayerConfigTemplates,
  };
}

ConfiguratorTemplateCard.propTypes = propTypes;
export default connect(
  stateToProps,
  null
)(withStyles(overrideStyles, { withTheme: true })(ConfiguratorTemplateCard));
