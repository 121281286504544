import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";

import "./styles.css";
// import * as styles from '../../../../../styles/styles';

const propTypes = {
  forgotPassword: PropTypes.func,
  onChange: PropTypes.func,
  email: PropTypes.string,
};
const pattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const EnterEmail = ({ forgotPassword, onChange, email }) => {
  const onKeyDown = (event) => {
    if (event.key === "Enter") forgotPassword();
  };

  const validEmail = (email) => new RegExp(pattern).test(email);

  return (
    <>
      <TextField
        variant="standard"
        fullWidth={true}
        label="E-Mail"
        type="email"
        margin="normal"
        value={email}
        onKeyDown={onKeyDown}
        onChange={onChange}
      />
      <div className="enter-email-button-wrapper">
        <Link to="/login" style={{ textDecoration: "none" }}>
          <Button size="medium" variant="contained">
            Zurück
          </Button>
        </Link>
        <Button
          size="medium"
          variant="contained"
          color="primary"
          disabled={!validEmail(email)}
          onClick={() => forgotPassword()}
        >
          Zurücksetzen
        </Button>
      </div>
    </>
  );
};

EnterEmail.propTypes = propTypes;
export default EnterEmail;
