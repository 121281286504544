import React from "react";
import PropTypes from "prop-types";
import netpipeLogo from "../../../assets/images/Netpipe_icon_color.png";

const propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

const Logo = ({ width, height }) => {
  return (
    <img
      src={netpipeLogo}
      style={{
        width: `${width}em`,
        height: `${height}em`,
      }}
    />
  );
};

Logo.propTypes = propTypes;
export default Logo;
