import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

import { success, pending, fail } from "../../../../constants/status";
import { REGISTRATION_FROM_BROWSER_PATHNAME } from "../../../../constants/registrationConstants";
import Spinner from "../../../../atoms/Spinner";

import qrCodeGooglePlayStore from "../../../../assets/images/qr_code_google_play_store.png";

import "./styles.css";

const propTypes = {
  registerUserStatus: PropTypes.string,
  pathNameState: PropTypes.string,
};

const PageThree = ({ registerUserStatus, pathNameState }) => {
  const renderPageThreeBrowser = () => {
    if (registerUserStatus === pending) return <Spinner />;
    if (registerUserStatus === success)
      return (
        <div>
          <Typography variant="body1" color="textPrimary">
            Sie erhalten eine E-Mail, sobald Ihr Account freigeschaltet wurde.
            Dies erfolgt innerhalb von 24 Stunden. Wenden Sie sich bei Fragen
            gerne an <a href="mailto:hello@netpipe.io">hello@netpipe.io</a>.
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
            sx={{ marginTop: "20px" }}
          >
            In der Zwischenzeit können Sie die Netpipe Android-App
            herunterladen. Sie finden die App im Google Play Store. Es gibt zwei
            Möglichkeiten, die App herunterzuladen. Entweder klicken Sie auf das
            Google Play Store Bild oder Sie scannen den QR-Code mit Ihrem
            Android-Smartphone ein.
          </Typography>
          <div className="app-download-div">
            <a href="https://play.google.com/store/apps/details?id=com.netpipe.measure&gl=DE&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
              <img
                className="google-play-store-badge"
                alt="Jetzt bei Google Play"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/de_badge_web_generic.png"
              />
            </a>
            <img className="qr-code" src={qrCodeGooglePlayStore} alt={`Bild`} />
          </div>
        </div>
      );
    if (registerUserStatus === fail)
      return (
        <Typography color="error" variant="h6">
          Ein Fehler ist aufgetreten, bitte versuchen Sie es erneut. Falls das
          Problem weiterhin besteht, wenden Sie sich bitte an unseren netpipe
          Support: <a href="mailto:hello@netpipe.io">hello@netpipe.io</a>.
        </Typography>
      );
  };

  const renderPageThreeMobil = () => {
    if (registerUserStatus === pending) return <Spinner />;
    if (registerUserStatus === success)
      return (
        <Typography variant="body1" color="textPrimary">
          Sie erhalten eine E-Mail, sobald Ihr Account freigeschaltet wurde.
          Dies erfolgt innerhalb von 24 Stunden. Wenden Sie sich bei Fragen
          gerne an <a href="mailto:hello@netpipe.io">hello@netpipe.io</a>.
        </Typography>
      );
    if (registerUserStatus === fail)
      return (
        <Typography color="error" variant="h6">
          Ein Fehler ist aufgetreten, bitte versuchen Sie es erneut. Falls das
          Problem weiterhin besteht, wenden Sie sich bitte an unseren netpipe
          Support: <a href="mailto:hello@netpipe.io">hello@netpipe.io</a>.
        </Typography>
      );
  };

  return pathNameState === REGISTRATION_FROM_BROWSER_PATHNAME
    ? renderPageThreeBrowser()
    : renderPageThreeMobil();
};

PageThree.propTypes = propTypes;
export default PageThree;
