/* eslint-disable no-unused-vars */
import { fromJS, Map } from "immutable";
import {
    SET_MAP_LAYERS,
    SET_SYNC_STATUS_MAP_LAYER,
    CREATE_MAP_LAYER,
    UPDATE_MAP_LAYER,
    DELETE_MAP_LAYER,
    SET_MAP_LAYER_TO_PROJECT_ASSIGNMENTS,
    SET_SYNC_STATUS_MAP_LAYER_TO_PROJECT_ASSIGNMENTS,
    CREATE_MAP_LAYER_TO_PROJECT_ASSIGNMENT,
    DELETE_MAP_LAYER_TO_PROJECT_ASSIGNMENT,
    UPLOADING_MAP_LAYER,
    UPLOADING_MAP_LAYER_DONE
} from "../constants/actions/actions";

import { idle } from "../constants/status";
import { 
    setMapLayersToState,
    setMapLayerToProjectAssignmentsToState, 
    createMapLayerToState,
    updateMapLayerToState,
    deleteMapLayerToState,
    createMapLayerToProjectAssignmentsToState,
    deleteMapLayerToProjectAssignmentsToState
} from "../sharedFunctions/mapLayers/stateUtils";


const initialState = fromJS({
    mapLayersSyncStatus: idle,
    mapLayersToProjectAssignmentSyncStatus: idle,
    uploading: false,
    searchTerm: "",
    items: {
        mapLayers: [],
        mapLayersToProjectAssignment: []
    },
});

export function mapLayers(state = initialState, action) {
    switch (action.type) {
        case SET_MAP_LAYERS:
            return setMapLayersToState(state, action);

        case SET_SYNC_STATUS_MAP_LAYER:
            return state.set("mapLayersSyncStatus", action.payload);

        case SET_MAP_LAYER_TO_PROJECT_ASSIGNMENTS:
            return setMapLayerToProjectAssignmentsToState(state, action);    

        case SET_SYNC_STATUS_MAP_LAYER_TO_PROJECT_ASSIGNMENTS:
            return state.set("mapLayersToProjectAssignmentSyncStatus", action.payload);

        case CREATE_MAP_LAYER:
            return createMapLayerToState(state, action);

        case CREATE_MAP_LAYER_TO_PROJECT_ASSIGNMENT:
            return createMapLayerToProjectAssignmentsToState(state, action);   

        case UPDATE_MAP_LAYER:
            return updateMapLayerToState(state, action);

        case DELETE_MAP_LAYER:
            return deleteMapLayerToState(state, action);

        case DELETE_MAP_LAYER_TO_PROJECT_ASSIGNMENT:
            return deleteMapLayerToProjectAssignmentsToState(state, action);

        case UPLOADING_MAP_LAYER:
            return state.set("uploading", true);

        case UPLOADING_MAP_LAYER_DONE:
            return state.set("uploading", false);
            
        default:
            return state;
    }
}