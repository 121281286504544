const HOSTNAME = window.location.hostname.replace(/www\./i, "");

const DEFAULT_HTTP_URI = `https://api.${HOSTNAME}/api`;
export const HTTP_URI = process.env.REACT_APP_API_HTTP_PATH || DEFAULT_HTTP_URI;

const DEFAULT_SOCKET_URI = `wss://api.${HOSTNAME}/socket`;
export const SOCKET_URI =
  process.env.REACT_APP_API_SOCKET_PATH || DEFAULT_SOCKET_URI;

export const MAPBOX_PLACES_URI =
  "https://api.mapbox.com/geocoding/v5/mapbox.places";
