import React from "react";
import PropTypes from "prop-types";

import * as projectConst from "../../constants/project";
import * as styles from "../../styles/styles";
import "./styles.css";

const propTypes = {
  state: PropTypes.string.isRequired,
  style: PropTypes.object,
};

const defaultProps = {
  style: {},
};

const StateIndicator = ({ state, style }) => {
  let color = null;
  let text = null;
  switch (state) {
    case projectConst.STATE_OPEN:
      text = "Offen";
      color = styles.statusOpen;
      break;
    case projectConst.STATE_IN_PROGRESS:
      text = "In Bearbeitung";
      color = styles.statusInProgress;
      break;
    case projectConst.STATE_DONE:
      text = "Fertig";
      color = styles.statusDone;
      break;
    case projectConst.STATE_SYNCED:
      text = "Wird Synchronisiert";
      color = styles.statusInSync;
      break;
    case projectConst.STATE_UPLOAD:
      text = "Wird hochgeladen";
      color = styles.statusInUpload;
      break;
    case projectConst.STATE_ARCHIVED:
      text = "Archiviert";
      color = styles.statusArchived;
      break;
    default:
      break;
  }
  return (
    <span
      className="state-indicator-container"
      style={{ backgroundColor: color, ...style }}
    >
      <span style={{ color: styles.white }} className="state-indicator-text">
        {text}
      </span>
    </span>
  );
};

StateIndicator.propTypes = propTypes;
StateIndicator.defaultProps = defaultProps;
export default StateIndicator;
