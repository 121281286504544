export function isAdmin(user, immutable) {
  if (immutable) {
    return (
      user.filter((role) => {
        return role["name"] === "Admin";
      }).length > 0
    );
  } else {
    return (
      user.filter((role) => {
        return role.name === "Admin";
      }).length > 0
    );
  }
}
