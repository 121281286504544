/* eslint-disable no-unused-vars */
import React from "react";

import { Typography } from "@mui/material";

import Header from "../../../../components/Header";

const ConfiguratorHeader = () => {
  const renderTitle = () => {
    return (
      <Typography variant="h5" component="div" noWrap={true}>
        Konfigurator
      </Typography>
    );
  };

  return <Header>{renderTitle()}</Header>;
};

export default ConfiguratorHeader;
