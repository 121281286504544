import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

import ProjectListItem from "../ProjectListItem";

import Spinner from "../../../../../atoms/Spinner";

import {
  STANDARD_PROJECT_LIST_FLAG,
  ARCHIVED_PROJECT_LIST_FLAG,
  PROJECTS_PER_PAGE,
} from "../../../../../constants/projectList";
import { pending, success, fail } from "../../../../../constants/status";

const propTypes = {
  users: PropTypes.array,
  selectedProjects: PropTypes.array,
  projectStatus: PropTypes.string,
  sortedData: PropTypes.array,
  projectListTypeFlag: PropTypes.number,
  handleOnChangeCheckbox: PropTypes.func,
  history: PropTypes.object,
  projects: PropTypes.object,
  activePage: PropTypes.number,
};

const RenderProjects = ({
  users,
  selectedProjects,
  projectStatus,
  sortedData,
  projectListTypeFlag,
  handleOnChangeCheckbox,
  history,
  projects,
  activePage,
}) => {
  let elementToRender;

  // If something went wrong during the download preprocess
  // show this information to the user.
  if (projectStatus === fail)
    elementToRender = (
      <Typography variant="h6">
        Ein Fehler ist aufgetreten, bitte versuchen Sie es erneut. Falls das
        Problem weiterhin besteht, wenden Sie sich bitte an unseren netpipe
        Support: hello@netpipe.io.
      </Typography>
    );

  // Return early if data is still downloaded from the backend.
  if (projectStatus === pending) return <Spinner />;

  // If everything is downloaded from the backend, but
  // the data list is still empty we show a simple text element
  // instead of a project list.
  if (sortedData.length === 0) {
    if (projectListTypeFlag === STANDARD_PROJECT_LIST_FLAG)
      elementToRender = (
        <h2 className="MuiTypography-root MuiTypography-h2 MuiTypography-colorPrimary projectsEmpty">
          Sie haben noch keine Projekte angelegt.
        </h2>
      );

    if (projectListTypeFlag === ARCHIVED_PROJECT_LIST_FLAG)
      elementToRender = (
        <h2 className="MuiTypography-root MuiTypography-h2 MuiTypography-colorPrimary projectsEmpty">
          Sie haben noch keine Projekte archiviert.
        </h2>
      );
  }

  // The download was successful and we have projects in our data list,
  // lets create for every project object a list item.
  if (projectStatus === success && projects)
    elementToRender = sortedData
      .slice(
        (activePage - 1) * PROJECTS_PER_PAGE,
        PROJECTS_PER_PAGE + (activePage - 1) * PROJECTS_PER_PAGE
      )
      .map((project, index) => {
        const projectInSelection = selectedProjects.includes(
          project.id.toString()
        );
        return (
          <ProjectListItem
            checkboxVisible={selectedProjects.length > 0}
            history={history}
            key={index}
            users={users}
            onChange={(args) =>
              handleOnChangeCheckbox(args, projectInSelection)
            }
            project={project}
            projectInSelection={projectInSelection}
            projectListTypeFlag={projectListTypeFlag}
          />
        );
      });

  return elementToRender;
};

RenderProjects.propTypes = propTypes;
export default RenderProjects;
