export const DESCRIPTION = "description"
export const ORIGINAL_FILENAME = "original_filename"
export const MAP_LAYER_REASON_TYPE = "map_layer_reason_type"
export const MAP_LAYER_TYPE = "map_layer_type"
export const MAP_LAYER_ASSIGNMENT_TYPE = "map_layer_assignment_type"


export const OVERLAY_KEY = "overlay"
export const BASEMAP_KEY = "basemap"

export const OVERLAY_DE = "Overlay"
export const BASEMAP_DE = "Grundkarte"

export const MAP_LAYER_TYPE_SELECTION_LIST = [
    {
        label: OVERLAY_DE,
        value: OVERLAY_KEY
    },
    {
        label: BASEMAP_DE,
        value: BASEMAP_KEY
    }
]

export const MAP_LAYER_TYPE_MAPPING = {
    overlay: OVERLAY_DE,
    basemap: BASEMAP_DE
}


export const PROJECT_KEY = "project"
export const GLOBAL_KEY = "global"

export const PROJECT_DE = "Projektbezogen"
export const GLOBAL_DE = "Unternehmensweit"

export const MAP_LAYER_ASSIGNMENT_TYPE_SELECTION_LIST = [
    {
        label: PROJECT_DE,
        value: PROJECT_KEY
    },
    {
        label: GLOBAL_DE,
        value: GLOBAL_KEY
    }
]  

export const MAP_LAYER_ASSIGNMENT_TYPE_MAPPING = {
    project: PROJECT_DE,
    global: GLOBAL_DE
}


export const MAP_LAYER_KEY = "map_layer"
export const DEFAULT_MAP_LAYER_KEY = "default_map_layer"
export const FIND_POINT_LAYER_KEY = "find_point_layer"

export const MAP_LAYER_DE = "Kartenlayer"
export const DEFAULT_MAP_LAYER_DE = "Standard Kartenlayer"
export const FIND_POINT_LAYER_DE = "Absteckungslayer"

export const MAP_LAYER_REASON_TYPE_SELECTION_LIST = [
    {
        label: MAP_LAYER_DE,
        value: MAP_LAYER_KEY
    },
    {
        label: DEFAULT_MAP_LAYER_DE,
        value: DEFAULT_MAP_LAYER_KEY
    },
    {
        label: FIND_POINT_LAYER_DE,
        value: FIND_POINT_LAYER_KEY
    }
]
export const MAP_LAYER_REASON_TYPE_MAPPING = {
    map_layer: MAP_LAYER_DE,
    default_map_layer: DEFAULT_MAP_LAYER_DE,
    find_point_layer: FIND_POINT_LAYER_DE
}
