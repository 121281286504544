import * as styles from "../../../../../../../styles/styles";
import { COMPONENT_TYPE_GNSS_POLYGON } from "../../../../../../../constants/ecs";

export default class ComponentGnssPolygon {
  constructor(params) {
    this.componentType = COMPONENT_TYPE_GNSS_POLYGON;
    this.id = params.id;
    this.name = params.name;
    this.map = params.map;

    this.source = {
      type: "geojson",
      data: params.data,
    };

    this.layer = {
      id: this.name,
      type: "fill",
      source: this.name,
      paint: {
        "fill-color": styles.black,
        "fill-opacity": 0.5
      },
    };
  }

  getComponentType() {
    return this.componentType;
  }

  updateColor(color) {
    this.color = color;
    this.layer.paint["fill-color"] = color;
  }

  getLineId() { 
    return this.id;
  }

  getColor() {
    return this.color;
  }
}
