import * as styles from "../../../../../../../styles/styles";
import { COMPONENT_TYPE_GNSS_LINE } from "../../../../../../../constants/ecs";

export default class ComponentGnssLine {
  constructor(params) {
    this.componentType = COMPONENT_TYPE_GNSS_LINE;
    this.id = params.id;
    this.name = params.name;
    this.map = params.map;

    this.source = {
      type: "geojson",
      data: params.data,
    };

    this.layer = {
      id: this.name,
      type: "line",
      source: this.name,
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": styles.black,
        "line-width": 4,
      },
    };
  }

  getComponentType() {
    return this.componentType;
  }

  updateColor(color) {
    this.color = color;
    this.layer.paint["line-color"] = color;
  }

  getLineId() {
    return this.id;
  }

  getColor() {
    return this.color;
  }
}
