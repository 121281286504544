import PropTypes from "prop-types";
import React from "react";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

import { 
  LAYER_TYPE_LINE_IDX,
  LAYER_TYPE_GNSS_LINE_IDX,
  LAYER_TYPE_GNSS_CONTINUOUS_LINE_IDX,
  LAYER_TYPE_POINT_IDX,
  LAYER_TYPE_GNSS_POLYGON_IDX,
  LAYER_TYPE_GNSS_POINT_IDX
} from "../../../../constants/measureLayerConfig"

import * as styles from "../../../../styles/styles";

const propTypes = {
  stateComponentCatalogs: PropTypes.array,
  changeLayerNameAutocomplete: PropTypes.func,
  changeLayerNameTextField: PropTypes.func,
  stateOptions: PropTypes.array,
  stateLayerNameInputValue: PropTypes.string,
  stateLayerNameValue: PropTypes.object,
  stateLayerType: PropTypes.number,
  setStateLayerNameValue: PropTypes.func,
};

const LayerNameInput = ({
  stateComponentCatalogs,
  changeLayerNameAutocomplete,
  changeLayerNameTextField,
  stateOptions,
  stateLayerNameInputValue,
  stateLayerNameValue,
  stateLayerType,
  setStateLayerNameValue,
}) => {

  const renderLayerNameAutocompleteInput = () => {
    return (
      <div style={{ margin: `0 0 ${styles.spacing32} 0` }}>
        <Autocomplete
          fullWidth={true}
          freeSolo
          id="combo-box-demo"
          value={stateLayerNameValue}
          onChange={(event, newValue) => {
            setStateLayerNameValue(newValue);
          }}
          inputValue={stateLayerNameInputValue}
          onInputChange={changeLayerNameAutocomplete}
          options={stateOptions}
          renderInput={(params) => (
            <TextField {...params} label="Bezeichnung" />
          )}
          renderOption={(props, option, { inputValue }) => {
            const matches = match(option.label, inputValue, {
              insideWords: true,
            });
            const parts = parse(option.label, matches);

            return (
              <li {...props}>
                <div>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: part.highlight ? 700 : 400,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </div>
              </li>
            );
          }}
        />
      </div>
    );
  };

  const renderLayerNameInput = () => {
    return (
      <div style={{ margin: `0 0 ${styles.spacing32} 0` }}>
        <TextField
          fullWidth={true}
          id="outlined-full-width"
          label="Name"
          margin="none"
          multiline={false}
          inputProps={{
            maxLength: 255,
          }}
          helperText={`${stateLayerNameInputValue.length}/255`}
          onChange={changeLayerNameTextField}
          placeholder="Name"
          required={true}
          variant="outlined"
          value={stateLayerNameInputValue}
        />
      </div>
    );
  };

  return (
    <div>
      {stateComponentCatalogs.length > 0 &&
       (stateLayerType === LAYER_TYPE_LINE_IDX || 
        stateLayerType === LAYER_TYPE_GNSS_LINE_IDX ||
        stateLayerType === LAYER_TYPE_GNSS_CONTINUOUS_LINE_IDX || 
        stateLayerType === LAYER_TYPE_POINT_IDX || 
        stateLayerType === LAYER_TYPE_GNSS_POINT_IDX ||
        stateLayerType === LAYER_TYPE_GNSS_POLYGON_IDX)
        ? renderLayerNameAutocompleteInput()
        : renderLayerNameInput()}
    </div>
  );
};

LayerNameInput.propTypes = propTypes;
export default LayerNameInput;
