/* eslint-disable no-unused-vars */
import { fromJS } from "immutable";

import {
  SET_MEASURE_LAYER_CONFIG_TEMPLATE_SEARCH_TERM,
  SET_ACTIVE_MEASURE_LAYER_CONFIG_TEMPLATE_UUID,
  SET_MEASURE_LAYER_CONFIG_TEMPLATES,
  SET_SYNC_MEASURE_LAYER_CONFIG_TEMPLATES_STATUS,
  CREATE_MEASURE_LAYER_CONFIG_TEMPLATE,
  UPDATE_MEASURE_LAYER_CONFIG_TEMPLATE,
  DELETE_MEASURE_LAYER_CONFIG_TEMPLATE,
  CREATE_MEASURE_LAYER_CONFIG_ITEM,
  UPDATE_MEASURE_LAYER_CONFIG_ITEM,
  DELETE_MEASURE_LAYER_CONFIG_ITEM,
} from "../constants/actions/actions";

import {
  setMeasureLayerConfigTemplates,
  createMeasureLayerConfigTemplate,
  updateMeasureLayerConfigTemplate,
  deleteMeasureLayerConfigTemplate,
  createMeasureLayerConfigItem,
  updateMeasureLayerConfigItem,
  deleteMeasureLayerConfigItem,
} from "../sharedFunctions/measureLayerConfigTemplates/stateUtils";

import { idle } from "../constants/status";

export const initialState = fromJS({
  measureLayerConfigTemplateSyncStatus: idle,
  activeComponentCatalogUuid: "",
  searchTerm: "",
  items: {
    measureLayerConfigOrganizationTemplates: [],
    measureLayerConfigTemplates: [],
    measureLayerConfigItems: [],
  },
});

export function measureLayerConfigTemplates(state = initialState, action) {
  switch (action.type) {
    case SET_MEASURE_LAYER_CONFIG_TEMPLATE_SEARCH_TERM:
      return state.set("searchTerm", action.payload);
    case SET_ACTIVE_MEASURE_LAYER_CONFIG_TEMPLATE_UUID:
      return state.set("activeMeasureLayerConfigTemplateUuid", action.payload);
    case SET_MEASURE_LAYER_CONFIG_TEMPLATES:
      return setMeasureLayerConfigTemplates(state, action);
    case SET_SYNC_MEASURE_LAYER_CONFIG_TEMPLATES_STATUS:
      return state.set("measureLayerConfigTemplateSyncStatus", action.payload);
    case CREATE_MEASURE_LAYER_CONFIG_TEMPLATE:
      return createMeasureLayerConfigTemplate(state, action);
    case UPDATE_MEASURE_LAYER_CONFIG_TEMPLATE:
      return updateMeasureLayerConfigTemplate(state, action);
    case DELETE_MEASURE_LAYER_CONFIG_TEMPLATE:
      return deleteMeasureLayerConfigTemplate(state, action);
    case CREATE_MEASURE_LAYER_CONFIG_ITEM:
      return createMeasureLayerConfigItem(state, action);
    case UPDATE_MEASURE_LAYER_CONFIG_ITEM:
      return updateMeasureLayerConfigItem(state, action);
    case DELETE_MEASURE_LAYER_CONFIG_ITEM:
      return deleteMeasureLayerConfigItem(state, action);
    default:
      return state;
  }
}
