import { fromJS } from "immutable";
import { RENDER_SNACKBAR, RESET_SNACKBAR } from "../constants/actions/actions";
import {
  deleteProjectSuccess,
  deleteProjectFail,
  deleteProjectsFail,
  downloadProjectsFail,
  updateProjectFail,
  assignProjectFail,
  createProjectFail,
  inviteUserFail,
  inviteUserSuccess,
  resendInvitationFail,
  resendInvitationSuccess,
  deleteInvitationFail,
  removeUserFail,
  deleteAvatarFail,
  editUserDetailsFail,
  uploadAvatarFail,
  createMeasureLayerConfigFail,
  updateMeasureLayerConfigFail,
  deleteMeasureLayerConfigFail,
  updateMeasurementLineFail,
  updateMeasurementFail,
  deleteMediaFileFail,
  updateTopographicPointSuccess,
  updateTopographicPointFail,
  deleteVolumeFail,
  deleteVolumeSuccess,
  updateVolumeFail,
  updateVolumeSuccess,
  requestAccountDeletionSuccess,
  requestAccountDeletionFail,
  confirmAccountDeletionSuccess,
  confirmAccountDeletionFail,
} from "../constants/snackbars";

export const initialState = fromJS({
  [inviteUserFail]: false,
  [inviteUserSuccess]: false,
  [createProjectFail]: false,
  [updateProjectFail]: false,
  [assignProjectFail]: false,
  [deleteProjectSuccess]: false,
  [deleteProjectFail]: false,
  [deleteProjectsFail]: false,
  [downloadProjectsFail]: false,
  [resendInvitationFail]: false,
  [resendInvitationSuccess]: false,
  [deleteInvitationFail]: false,
  [removeUserFail]: false,
  [deleteAvatarFail]: false,
  [editUserDetailsFail]: false,
  [uploadAvatarFail]: false,
  [createMeasureLayerConfigFail]: false,
  [updateMeasureLayerConfigFail]: false,
  [deleteMeasureLayerConfigFail]: false,
  [updateMeasurementLineFail]: false,
  [updateMeasurementFail]: false,
  [deleteMediaFileFail]: false,
  [updateTopographicPointSuccess]: false,
  [updateTopographicPointFail]: false,
  [deleteVolumeFail]: false,
  [deleteVolumeSuccess]: false,
  [updateVolumeFail]: false,
  [updateVolumeSuccess]: false,
  [requestAccountDeletionSuccess]: false,
  [requestAccountDeletionFail]: false,
  [confirmAccountDeletionSuccess]: false,
  [confirmAccountDeletionFail]: false,
});

export function snackbars(state = initialState, action) {
  switch (action.type) {
    case RENDER_SNACKBAR:
      return state.set(action.payload, true);
    case RESET_SNACKBAR:
      return state.set(action.payload, false);
    default:
      return state;
  }
}
