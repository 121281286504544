/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";

import {
    Box,
    Button,
    MenuItem,
    Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';

import { MaterialReactTable } from "material-react-table";
import { MRT_Localization_DE } from "material-react-table/locales/de";

import {
    MAP_LAYER_TYPE_SELECTION_LIST,
    MAP_LAYER_REASON_TYPE_SELECTION_LIST,
    MAP_LAYER_ASSIGNMENT_TYPE_SELECTION_LIST,

    MAP_LAYER_TYPE_MAPPING,
    MAP_LAYER_REASON_TYPE_MAPPING,
    MAP_LAYER_ASSIGNMENT_TYPE_MAPPING,

    DESCRIPTION,
    ORIGINAL_FILENAME,
    MAP_LAYER_REASON_TYPE,
    MAP_LAYER_TYPE,
    MAP_LAYER_ASSIGNMENT_TYPE,
    PROJECT_KEY,
    GLOBAL_KEY
} from "../../../../constants/mapLayerMapping";

import AssignMapLayerDialog from "../../../../containers/dialogs/AssignMapLayerDialog";

import * as styles from "../../../../styles/styles";
import "./styles.css";

const propTypes = {
    isAdmin: PropTypes.bool,
    data: PropTypes.object,
    projects: PropTypes.object,
    mapLayerAssignments: PropTypes.object,
    updateMapLayer: PropTypes.func,
    deleteMapLayer: PropTypes.func,
    createMapLayerToProjectAssignment: PropTypes.func,
    deleteMapLayerToProjectAssignment: PropTypes.func,
    multiDeleteMapLayerToProjectAssignment: PropTypes.func
};

const MapLayersTable = ({
    isAdmin,
    data,
    projects,
    mapLayerAssignments,
    updateMapLayer,
    deleteMapLayer,
    createMapLayerToProjectAssignment,
    deleteMapLayerToProjectAssignment,
    multiDeleteMapLayerToProjectAssignment
}) => {

    const [assignmentModalOpen, setAssignmentModalOpen] = React.useState(false);
    const [assignmentMapLayer, setAssignmentMapLayer] = React.useState({})
    const [validationErrors, setValidationErrors] = React.useState({})
    const [editedMapLayers, setEditedMapLayers] = React.useState({});
    const [editedCellIds, setEditedCellIds] = React.useState([])

    const columns = React.useMemo(() => {
        return [
            {
                accessorKey: ORIGINAL_FILENAME,
                header: "Dateiname",
                enableEditing: false
            },
            {
                accessorKey: DESCRIPTION,
                header: "Beschreibung",
                muiEditTextFieldProps: ({ cell, row }) => ({
                    type: 'text',
                    required: true,
                    error: !!validationErrors?.[cell.id],
                    onBlur: (event) => {
                        if (cell.getValue() === event.target.value) {
                            return
                        }

                        const validationError = !validateRequired(event.target.value)
                            ? 'Required'
                            : undefined;

                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: validationError,
                        });
                        
                        if (!editedCellIds.includes(cell.id)) {
                            setEditedCellIds([
                                ... editedCellIds, cell.id
                            ])
                        }

                        var oldMapLayerData = {...row.original}

                        if (Object.keys(editedMapLayers).includes(row.id)) {
                            oldMapLayerData = {...editedMapLayers[row.id]}
                        }

                        setEditedMapLayers({ 
                            ...editedMapLayers, 
                            [row.id]:  { ...oldMapLayerData, [DESCRIPTION]: event.target.value } 
                        });
                    },
                }),
                muiTableBodyCellProps: ({ cell }) => {
                    var color = undefined

                    if (editedCellIds.includes(cell.id)) {
                        color = "rgba(22, 184, 44, 0.5)"
                    }

                    if (validationErrors[cell.id] !== undefined) {
                        color = "rgba(255, 47, 0, 0.5)"
                    }

                    return ({
                        sx: {
                            backgroundColor: color
                        }
                    })
                }
            },
            {
                accessorKey: MAP_LAYER_REASON_TYPE,
                header: "Kategorie",
                editVariant: "select",
                editSelectOptions: MAP_LAYER_REASON_TYPE_SELECTION_LIST,
                muiEditTextFieldProps: ({ cell, row }) => ({
                    select: true,
                    onChange: (event) => {
                        if (cell.getValue() === event.target.value) {
                            return
                        }

                        if (!editedCellIds.includes(cell.id)) {
                            setEditedCellIds([
                                ... editedCellIds, cell.id
                            ])
                        }

                        var oldMapLayerData = {...row.original}

                        if (Object.keys(editedMapLayers).includes(row.id)) {
                            oldMapLayerData = {...editedMapLayers[row.id]}
                        }

                        setEditedMapLayers({
                            ...editedMapLayers,
                            [row.id]: { ...oldMapLayerData, [MAP_LAYER_REASON_TYPE]: event.target.value}
                        })
                    }
                }),
                Cell: ({ renderedCellValue, row}) => {
                    return MAP_LAYER_REASON_TYPE_MAPPING[renderedCellValue]
                },
                muiTableBodyCellProps: ({ cell }) => {
                    var color = undefined

                    if (editedCellIds.includes(cell.id)) {
                        color = "rgba(22, 184, 44, 0.5)"
                    }

                    if (validationErrors[cell.id] !== undefined) {
                        color = "rgba(255, 47, 0, 0.5)"
                    }

                    return ({
                        sx: {
                            backgroundColor: color
                        }
                    })
                }
            },
            {
                accessorKey: MAP_LAYER_TYPE,
                header: "Typ",
                editVariant: "select",
                editSelectOptions: MAP_LAYER_TYPE_SELECTION_LIST,
                muiEditTextFieldProps: ({ cell, row }) => ({
                    select: true,
                    onChange: (event) => {
                        if (cell.getValue() === event.target.value) {
                            return
                        }

                        if (!editedCellIds.includes(cell.id)) {
                            setEditedCellIds([
                                ... editedCellIds, cell.id
                            ])
                        }

                        var oldMapLayerData = {...row.original}

                        if (Object.keys(editedMapLayers).includes(row.id)) {
                            oldMapLayerData = {...editedMapLayers[row.id]}
                        }

                        setEditedMapLayers({
                            ...editedMapLayers,
                            [row.id]: { ...oldMapLayerData, [MAP_LAYER_TYPE]: event.target.value}
                        })
                    }
                }),
                Cell: ({ renderedCellValue, row}) => {
                    return MAP_LAYER_TYPE_MAPPING[renderedCellValue]
                },
                muiTableBodyCellProps: ({ cell }) => {
                    var color = undefined

                    if (editedCellIds.includes(cell.id)) {
                        color = "rgba(22, 184, 44, 0.5)"
                    }

                    if (validationErrors[cell.id] !== undefined) {
                        color = "rgba(255, 47, 0, 0.5)"
                    }

                    return ({
                        sx: {
                            backgroundColor: color
                        }
                    })
                }
            },
            {
                accessorKey: MAP_LAYER_ASSIGNMENT_TYPE,
                header: "Zuweisung",
                editVariant: "select",
                editSelectOptions: MAP_LAYER_ASSIGNMENT_TYPE_SELECTION_LIST,
                muiEditTextFieldProps: ({ cell, row }) => ({
                    select: true,
                    onChange: (event) => {
                        if (cell.getValue() === event.target.value) {
                            return
                        }

                        if (!editedCellIds.includes(cell.id)) {
                            setEditedCellIds([
                                ... editedCellIds, cell.id
                            ])
                        }

                        var oldMapLayerData = {...row.original}

                        if (Object.keys(editedMapLayers).includes(row.id)) {
                            oldMapLayerData = {...editedMapLayers[row.id]}
                        }

                        setEditedMapLayers({
                            ...editedMapLayers,
                            [row.id]: { ...oldMapLayerData, [MAP_LAYER_ASSIGNMENT_TYPE]: event.target.value}
                        })
                    }
                }),
                Cell: ({ renderedCellValue, row}) => {
                    return MAP_LAYER_ASSIGNMENT_TYPE_MAPPING[renderedCellValue]
                },
                muiTableBodyCellProps: ({ cell }) => {
                    var color = undefined

                    if (editedCellIds.includes(cell.id)) {
                        color = "rgba(22, 184, 44, 0.5)"
                    }

                    if (validationErrors[cell.id] !== undefined) {
                        color = "rgba(255, 47, 0, 0.5)"
                    }

                    return ({
                        sx: {
                            backgroundColor: color
                        }
                    })
                }
            }
        ];
    }, [editedMapLayers,  validationErrors, mapLayerAssignments]);

    const onUpdateMapLayersClicked = (table) => {

        const removeProjectAssignmentList = []

        for (const key in editedMapLayers) {
            // If the assignment typ has changed from project to global, remove
            // all project assignments to this MapLayer.
            if (table.getRowModel().rows[key].original.map_layer_assignment_type === PROJECT_KEY && 
                editedMapLayers[key].map_layer_assignment_type === GLOBAL_KEY
            ) {
                if (editedMapLayers[key].uuid in mapLayerAssignments) {
                    removeProjectAssignmentList.push({
                        [editedMapLayers[key].uuid]: mapLayerAssignments[editedMapLayers[key].uuid]
                    })
                }
            }

            table.getRowModel().rows[key].original = editedMapLayers[key]
        }

        updateMapLayer(editedMapLayers)
        multiDeleteMapLayerToProjectAssignment(removeProjectAssignmentList)
        setEditedMapLayers({})
        setEditedCellIds([])
        setValidationErrors({})
    }

    const onResetClicked = (table) => {
        table.getRowModel().rows.forEach((row) => {
            row._valuesCache = {};
        });
        setEditedMapLayers({})
        setEditedCellIds([])
        setValidationErrors({})
    }

    const onDeleteClicked = (row) => {
        deleteMapLayer(row.original)
    }

    const closeModal = () => {
        setAssignmentMapLayer({})
        setAssignmentModalOpen(false)
    }

    const onAssignmentClicked = (row) => {
        setAssignmentMapLayer(row)
        setAssignmentModalOpen(true)
    }

    const handleAssignment = (mapLayer, selectedProject) => {
        closeModal()
        createMapLayerToProjectAssignment(selectedProject, mapLayer)
    }

    const onDeleteAssignmentClicked = (projectId, mapLayerUuid) => {
        deleteMapLayerToProjectAssignment(projectId, mapLayerUuid)
    }

    // Usable by a User with the Admin-Role
    const editableTable = (data, isAdmin) => {
        return (
            <>
                {assignmentModalOpen &&
                    showAssignmentModal(
                        assignmentMapLayer,
                        projects,
                        closeModal,
                        assignmentModalOpen,
                        handleAssignment,
                        mapLayerAssignments
                )}
                    <MaterialReactTable
                    initialState={{
                        density: "spacious",
                        pagination: { pageSize: 15, pageIndex: 0 },
                    }}
                    localization={MRT_Localization_DE}
                    muiTableContainerProps={{
                        sx: {
                            height: "calc(100vh - 25rem)",
                            maxHeight: "calc(100vh - 25rem)",
                        },
                    }}
                    muiTableHeadProps={{
                        sx: {
                            height: "52px",
                        },
                    }}
                    muiBottomToolbarProps={{
                        sx: {
                            height: "60px",
                        },
                    }}
                    muiTablePaginationProps={{
                        rowsPerPageOptions: [5, 10, 15, 20],
                    }}
                    muiTableBodyRowProps={({row}) => ({
                        hover: false
                    })}
                    renderTopToolbarCustomActions={() => {
                        return (
                            <div></div>
                        );
                    }}
                    enableStickyHeader
                    enableDensityToggle={false}
                    enableFullScreenToggle={false}
                    enableHiding={false}
                    positionActionsColumn="last"
                    editDisplayMode="cell"
                    enableEditing={true}
                    enableRowActions={true}
                    renderRowActionMenuItems={({ row, closeMenu }) => {
                        return (
                            createMenuItemEntries(
                                isAdmin,
                                row, 
                                closeMenu,
                                onDeleteClicked, 
                                onAssignmentClicked)
                        );
                    }}
                    enableExpandAll={false}
                    muiDetailPanelProps={() => ({
                        sx: (theme) => ({
                            backgroundColor:
                                theme.palette.mode === 'dark'
                                    ? 'rgba(255,210,244,0.1)'
                                    : styles.darkBlueLight,
                        }),
                    })}
                    muiExpandButtonProps={({ row, table}) => ({
                        onClick: () => {
                            table.setExpanded({ [row.id]: !row.getIsExpanded() })
                        },
                        sx: {
                            transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
                            transition: 'transform 0.2s',
                        }
                    })}
                    renderDetailPanel={({row}) => {
                        if (row.original.map_layer_assignment_type === PROJECT_KEY) {
                            return <React.Fragment key={`project-assignment-view-${row.original.uuid}`}>
                                {createDetailPanelView(row, mapLayerAssignments, onDeleteAssignmentClicked)}
                            </React.Fragment>
                            
                        } else {
                            return null
                        }
                    }}
                    renderBottomToolbarCustomActions={({table}) => {
                        return (
                        <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                            <Button
                                color="success"
                                variant="contained"
                                onClick={() => onUpdateMapLayersClicked(table)}
                                disabled={
                                Object.keys(editedMapLayers).length === 0 ||
                                Object.values(validationErrors).some((error) => !!error)
                                }
                            >
                                Speichern
                            </Button>
                            <Button
                                color="success"
                                variant="contained"
                                onClick={() => onResetClicked(table)}
                                disabled={
                                Object.keys(editedMapLayers).length === 0
                                }
                            >
                                Zurücksetzen
                            </Button>
                            {Object.values(validationErrors).some((error) => !!error) && (
                                <Typography color="error">Bitte erst alle Fehler beheben</Typography>
                            )}
                        </Box>
                        )
                    }}
                    columns={columns}
                    data={data.mapLayers}
                />
            </>
        );
    };
    
    // Usable by a User with the User-Role
    const nonEditableTable = (data, isAdmin) => {
        return (
            <>
                {assignmentModalOpen &&
                    showAssignmentModal(
                        assignmentMapLayer,
                        projects,
                        closeModal,
                        assignmentModalOpen,
                        handleAssignment,
                        mapLayerAssignments
                )}
            
                <MaterialReactTable
                    initialState={{
                        density: "spacious",
                        pagination: { pageSize: 15, pageIndex: 0 },
                    }}
                    localization={MRT_Localization_DE}
                    muiTableContainerProps={{
                        sx: {
                            height: "calc(100vh - 25rem)",
                            maxHeight: "calc(100vh - 25rem)",
                        },
                    }}
                    muiTableHeadProps={{
                        sx: {
                            height: "52px",
                        },
                    }}
                    muiBottomToolbarProps={{
                        sx: {
                            height: "52px",
                        },
                    }}
                    muiTablePaginationProps={{
                        rowsPerPageOptions: [5, 10, 15, 20],
                    }}
                    muiTableBodyRowProps={{
                        hover: false,
                    }}
                    renderTopToolbarCustomActions={() => {
                        return (
                            <div></div>
                        );
                    }}
                    enableStickyHeader
                    enableDensityToggle={false}
                    enableFullScreenToggle={false}
                    enableHiding={false}
                    enableExpandAll={false}
                    enableRowActions={true}
                        renderRowActionMenuItems={({ row, closeMenu }) => {
                            return (
                                createMenuItemEntries(
                                    isAdmin,
                                    row, 
                                    closeMenu,
                                    onDeleteClicked, 
                                    onAssignmentClicked)
                            );
                        }}
                    muiDetailPanelProps={() => ({
                        sx: (theme) => ({
                            backgroundColor:
                            theme.palette.mode === 'dark'
                                ? 'rgba(255,210,244,0.1)'
                                : styles.darkBlueLight,
                        }),
                    })}
                    muiExpandButtonProps={({ row, table}) => ({
                        onClick: () => {
                            table.setExpanded({ [row.id]: !row.getIsExpanded() })
                        },
                        sx: {
                            transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
                            transition: 'transform 0.2s',
                        }
                    })}
                    renderDetailPanel={({row}) => {
                        if (row.original.map_layer_assignment_type === "project") {
                            return <React.Fragment key={`project-assignment-view-${row.original.uuid}`}>
                                    {createDetailPanelView(row, mapLayerAssignments, onDeleteAssignmentClicked)}
                                </React.Fragment>
                            
                        } else {
                            return null
                        }
                    }}
                    columns={columns}
                    data={data.mapLayers}
                />
            </>
        );
    };

    return (
        <div>
            <div>
                <div style={{ margin: "2rem 2rem 2rem 2rem" }}>
                    {isAdmin
                    ? editableTable(data, isAdmin)
                    : nonEditableTable(data, isAdmin)}
                </div>
            </div>
        </div>
    )
}

const showAssignmentModal = (
    assignmentMapLayer,
    projects,
    closeModal,
    assignmentModalOpen,
    handleAssignment,
    mapLayerAssignments
) => {
    var assignedProjectList = []

    if (assignmentMapLayer.original.uuid in mapLayerAssignments) {
        assignedProjectList = mapLayerAssignments[assignmentMapLayer.original.uuid]
    }

    return <AssignMapLayerDialog 
        projects={projects}
        assignedProjectList={assignedProjectList}
        mapLayer={assignmentMapLayer}
        modalOpen={assignmentModalOpen}
        onNegativeButtonClicked={() => closeModal()}
        onPositiveButtonClicked={handleAssignment}
    />
}

const createMenuItemEntries = (
    isAdmin,
    row,
    closeMenu,
    onDeleteClicked,
    onAssignmentClicked
) => {
    const menuItemEntries = []

    if (isAdmin) {
        menuItemEntries.push(
            <MenuItem
                key={`row-item-1-${row.original.uuid}`}
                onClick={() => {
                    onDeleteClicked(row);
                    closeMenu();
                }}
            >
                <DeleteIcon /> Löschen
            </MenuItem>
        )
    }
    
    if (row.original.map_layer_assignment_type === PROJECT_KEY) {
        menuItemEntries.push(<MenuItem
            key={`row-item-2-${row.original.uuid}`}
            onClick={() => {
                onAssignmentClicked(row)
                closeMenu();
            }}
        >
            <AddIcon /> Zuweisen
        </MenuItem>)
    }

    return menuItemEntries
}

const createDetailPanelView = (
    row, 
    mapLayerAssignments,
    onDeleteAssignmentClicked
) => {
    const asignedProjectViews = []
    var projectList = []

    if (row.original.uuid in mapLayerAssignments) {
        projectList = [...projectList, ...mapLayerAssignments[row.original.uuid]]
    }

    if (projectList.length === 0) {
        asignedProjectViews.push(
            <div 
                key={`project-assignment-view-${row.original.uuid}-0`} 
                className="empty-detail-project-container"
            >
                <p>Bisher wurden noch keine Projekte zugewiesen</p>
            </div>
        )
    } else {
        projectList.forEach((project) => {
            asignedProjectViews.push(
                <div 
                    key={`project-assignment-view-${row.original.uuid}-${project.id}`} 
                    className="detail-project-container"
                >
                    <p>{project.title} - {project.job_order}</p>
                    <div className="button-container">
                        <IconButton 
                            aria-label="delete" 
                            color="#888"
                            onClick={() => onDeleteAssignmentClicked(project.id, row.original.uuid)}
                        >
                            <ClearIcon />
                        </IconButton>
                    </div>
                </div>
            )
        })
    }

    return (
        <div className="detail-view-container">
            <p className="title">
                Projektzuweisungen
            </p>
            {asignedProjectViews}
        </div>
    )
}

const validateRequired = (value) => !!value.length;

MapLayersTable.propTypes = propTypes;
export default MapLayersTable;