/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import Column from "../../layout/Column";

import * as styles from "../../../styles/styles";

const propTypes = {
  modalOpen: PropTypes.func,
};

const UserProfileDeleteUser = ({ modalOpen }) => {
  return (
    <>
      <Column side="left">
        <Typography color="primary" variant="h2">
          Mitarbeiter entfernen
        </Typography>
      </Column>

      <Column side="right">
        <div style={{ margin: `6rem 0 0 0` }}>
          <Button
            size="medium"
            variant="outlined"
            color="error"
            onClick={() => modalOpen()}
          >
            Mitarbeiter entfernen
          </Button>
        </div>
      </Column>
    </>
  );
};

UserProfileDeleteUser.propTypes = propTypes;
export default UserProfileDeleteUser;
