/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

import ButtonGroup from "../../../components/ButtonGroup";
import CustomDialog from "../../../components/CustomDialog";

import { validateTextFields } from "../../../sharedFunctions/validation";

import * as styles from "../../../styles/styles";

const propTypes = {
  data: PropTypes.object,
  modalOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
};

const ComponentCatalogPipeDefinitionDialog = ({
  data,
  modalOpen,
  onClose,
  onClick,
}) => {
  const materialList = React.useMemo(() => {
    const temp = [];

    data.data.materials[0].items.forEach((item) => {
      temp.push({ label: item.name, item: item });
    });

    return temp;
  }, [data.data.materials[0].items]);

  const dimensionList = React.useMemo(() => {
    const temp = [];

    data.data.dimensions[0].items.forEach((item) => {
      temp.push({ label: item.name, item: item });
    });

    return temp;
  }, [data.data.dimensions[0].items]);

  const [stateDisablePositiveButton, setDisablePositiveButton] =
    React.useState(true);
  const [stateMaterialNameValue, setStateMaterialNameValue] =
    React.useState(null);
  const [stateDimensionNameValue, setStateDimensionNameValue] =
    React.useState(null);
  const [stateMaterialNameInputValue, setStateMaterialNameInputValue] =
    React.useState("");
  const [stateDimensionNameInputValue, setStateDimensionNameInputValue] =
    React.useState("");

  const validationValues = React.useMemo(() => {
    return {
      materialName: {
        value: "",
        required: true,
      },
      dimensionName: {
        value: "",
        required: true,
      },
    };
  }, [data]);

  const getOldValues = () => {
    return {
      materialName: stateMaterialNameInputValue,
      dimensionName: stateDimensionNameInputValue,
    };
  };

  const onChangeMaterialNameAutocomplete = (event, newInputValue) => {
    const materialName = newInputValue;
    const newValues = { ...getOldValues(), materialName: materialName };
    setStateMaterialNameInputValue(newInputValue);
    setDisablePositiveButton(validateTextFields(validationValues, newValues));
  };

  const onChangeDimensionNameAutocomplete = (event, newValue) => {
    const dimensionName = newValue;
    const newValues = { ...getOldValues(), dimensionName: dimensionName };
    setStateDimensionNameInputValue(newValue);
    setDisablePositiveButton(validateTextFields(validationValues, newValues));
  };

  const executeOnClick = () => {
    const payload = {
      catalogCategoryUuid: data.catalogCategory[0].item.uuid,
      data: {
        params: {
          material: {
            name: stateMaterialNameInputValue,
          },
          dimension: {
            name: stateDimensionNameInputValue,
          },
        },
      },
    };

    onClick(payload);
  };

  const renderMaterialNameAutocompleteInput = () => {
    return (
      <div style={{ margin: `0 0 ${styles.spacing32} 0` }}>
        <Autocomplete
          fullWidth={true}
          id="combo-box-material"
          value={stateMaterialNameValue}
          onChange={(event, newValue) => {
            setStateMaterialNameValue(newValue);
          }}
          inputValue={stateMaterialNameInputValue}
          onInputChange={onChangeMaterialNameAutocomplete}
          options={materialList}
          renderInput={(params) => <TextField {...params} label="Material" />}
          renderOption={(props, option, { inputValue }) => {
            const matches = match(option.label, inputValue, {
              insideWords: true,
            });
            const parts = parse(option.label, matches);

            return (
              <li {...props}>
                <div>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: part.highlight ? 700 : 400,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </div>
              </li>
            );
          }}
        />
      </div>
    );
  };

  const renderDimensionNameAutocompleteInput = () => {
    return (
      <div style={{ margin: `0 0 ${styles.spacing32} 0` }}>
        <Autocomplete
          fullWidth={true}
          id="combo-box-dimension"
          value={stateDimensionNameValue}
          onChange={(event, newInputValue) => {
            setStateDimensionNameValue(newInputValue);
          }}
          inputValue={stateDimensionNameInputValue}
          onInputChange={onChangeDimensionNameAutocomplete}
          options={dimensionList}
          renderInput={(params) => <TextField {...params} label="Dimension" />}
          renderOption={(props, option, { inputValue }) => {
            const matches = match(option.label, inputValue, {
              insideWords: true,
            });
            const parts = parse(option.label, matches);

            return (
              <li {...props}>
                <div>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: part.highlight ? 700 : 400,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </div>
              </li>
            );
          }}
        />
      </div>
    );
  };

  return (
    <CustomDialog
      open={modalOpen}
      onClick={executeOnClick}
      onClose={onClose}
      showHint={false}
      renderTitle={() => {
        return (
          <DialogTitle
            id="alert-dialog-title"
            style={{ margin: `0 0 ${styles.spacing08} 0` }}
          >
            {"Material/Dimension anlegen"}
          </DialogTitle>
        );
      }}
      renderContent={() => {
        return (
          <DialogContent style={{ paddingTop: "20px" }}>
            {renderMaterialNameAutocompleteInput()}
            {renderDimensionNameAutocompleteInput()}
          </DialogContent>
        );
      }}
      renderButtonGroup={(onClick, onClose) => {
        return (
          <DialogActions style={{ padding: "2.4rem 2.4rem 2rem" }}>
            <ButtonGroup
              align="right"
              leftButtonText="Abbrechen"
              leftButtonClick={onClose}
              rightButtonText={"Anlegen"}
              rightButtonClick={onClick}
              rightButtonDisabled={stateDisablePositiveButton}
              spacingTop={false}
            />
          </DialogActions>
        );
      }}
    />
  );
};

ComponentCatalogPipeDefinitionDialog.propTypes = propTypes;
export default ComponentCatalogPipeDefinitionDialog;
