import React, { useState } from "react";
import PropTypes from "prop-types";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

const propTypes = {
  dropdownMenu: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
};

const SecondaryAction = ({ dropdownMenu, user }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (func, user) => {
    func(user);
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-owns="simple-menu"
        aria-haspopup="true"
        onClick={(event) => setAnchorEl(event.currentTarget)}
        size="large"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        id="simple-menu"
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={() => setAnchorEl(null)}
      >
        {dropdownMenu &&
          dropdownMenu.map((element, index) => {
            return (
              <MenuItem
                divider={index !== dropdownMenu.length - 1}
                key={element.key}
                onClick={() => handleClick(element.func, user)}
              >
                <Typography variant="body2" color="textPrimary">
                  {element.text}
                </Typography>
              </MenuItem>
            );
          })}
      </Menu>
    </>
  );
};

SecondaryAction.propTypes = propTypes;
export default SecondaryAction;
