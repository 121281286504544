export const extractFileInformation = (event) => {
  const name = event.target.files[0].name;
  const lastDot = name.lastIndexOf(".");

  const fileName = name.substring(0, lastDot);
  const ext = name.substring(lastDot + 1);

  const fileSize = event.target.files[0].size / 1024 / 1024;

  return [name, fileName, ext, fileSize];
};


export const extractFileInformationFromFile = (file) => {
  const lastDot = file.name.lastIndexOf(".");

  const fileName = file.name.substring(0, lastDot);
  const ext = file.name.substring(lastDot + 1);

  const fileSize = file.size / 1024 / 1024;

  return [file.name, fileName, ext, fileSize];
};
