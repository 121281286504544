/* eslint-disable import/namespace */
/* eslint-disable no-unused-vars */
import { fromJS, Map } from "immutable";

export const prepareItems = (array) => {
    return Map(array.map((item) => [item.uuid, fromJS(item)]));
  };

export function setGnssData(state, action) {
    const newItems = {
        images: fromJS(action.payload.images),
        lines: fromJS(action.payload.lines),
        points: fromJS(action.payload.points),
        polygons: fromJS(action.payload.polygons),
        polygon_points: fromJS(action.payload.polygons_points),
        segments: fromJS(action.payload.segments),
    };    
    return state.mergeIn(["items"], newItems);
}