/* eslint-disable import/namespace */
/* eslint-disable no-unused-vars */
import { purple500 } from "../../../../../../styles/styles";
import { COLOR_MAP } from "../../../../../../constants/colormapping";

import Entity from "./entity";
import ComponentLine from "./components/componentLine";
import ComponentMeasurementPoints from "./components/componentMeasurementPoints";
import ComponentReferencePoints from "./components/componentReferencePoints";
import ComponentTopographicPoints from "./components/componentTopographicPoints";
import ComponentGnssImage from "./components/componentGnssImage";
import ComponentGnssPoints from "./components/componentGnssPoint";
import ComponentGnssRefPoints from "./components/componentGnssRefPoint";
import ComponentGnssLine from "./components/componentGnssLine";
import ComponentLabels from "./components/componentLabels";
import ComponentClick from "./components/componendClick";
import ComponentHover from "./components/componentHover";

import { 
  createGeoJsonLine,
  createGeoJsonPoints, 
  createGeoJsonPolygon
} from "./utils/geojsonutils";

import {
  pointGroupIdLookupTable,
  MEASURE_POINT,
  REFERENCE_POINT,
  TOPOGRAPHIC_POINT,
  GNSS_POINT,
  GNSS_LINE,
  GNSS_POLYGON,
  GNSS_IMAGE,
  GNSS_REF_POINT
} from "../../../../../../constants/pointLookupTable";
import ComponentGnssPolygon from "./components/componentGnssPolygon";

export const generateMapEntities = (
  country,
  referencePoints,
  measurementsPerLine,
  topographicPointsPerLayer,
  gnssPointsPerLayer,
  gnssRefPointsPerLayer,
  gnssLinesPerLayer,
  gnssPolygonPerLayer,
  gnssImages
) => {
  const entities = [];

  measurementsPerLine.forEach((element) => {
    entities.push(createMeasurementLineEntity(element));
    entities.push(createMeasurementPointEntity(element));

    entities.push(
      createLabelEntity(
        country,
        element.measurements, 
        `measurements_${element.line.id}`, 
        MEASURE_POINT)
    );
  });

  referencePoints.forEach((element) => {
    entities.push(createReferencePointEntity(element));
    entities.push(
      createLabelEntity(
        country,
        element.referencePoints,
        `referencePoints_${element.layerConfig.id}`,
        REFERENCE_POINT
      )
    );
  });

  topographicPointsPerLayer.forEach((element) => {
    entities.push(createTopographicPointEntity(element));
    entities.push(
      createLabelEntity(
        country,
        element.topographicPoints,
        `topographicPoints_${element.layerConfig.id}`,
        TOPOGRAPHIC_POINT
      )
    );
  });

  gnssPointsPerLayer.forEach((element) => {
    entities.push(createGnssPointEntity(element.gnssPoints, element.layerConfig));
    entities.push(
      createLabelEntity(
        country,
        element.gnssPoints,
        `gnssPoints_${element.layerConfig.id}`,
        GNSS_POINT
      )
    );
  })

  gnssRefPointsPerLayer.forEach((element) => {
    entities.push(createGnssRefPointEntity(element.gnssRefPoints, element.layerConfig));
    entities.push(
      createLabelEntity(
        country,
        element.gnssRefPoints,
        `gnssRefPoints_${element.layerConfig.id}`,
        GNSS_REF_POINT
      )
    );
  })

  gnssLinesPerLayer.forEach((element) => {
    if (element.gnssPoints.length > 0) {
      entities.push(createGnssLineEntity(element))
      entities.push(createGnssPointEntity(element.gnssPoints, element.layerConfig));
      
      entities.push(
        createLabelEntity(
          country,
          element.gnssPoints,
          `gnssLinePoints_${element.layerConfig.id}`,
          GNSS_POINT
        )
      );
    }
  })

  gnssPolygonPerLayer.forEach((element) => {
    if (element.gnssPoints.length > 0) {
      entities.push(createGnssPolygonEntity(element))
      entities.push(createGnssPointEntity(element.gnssPoints, element.layerConfig));
      
      entities.push(
        createLabelEntity(
          country,
          element.gnssPoints,
          `gnssLinePoints_${element.layerConfig.id}`,
          GNSS_POINT
        )
      );
    }
  })

  if (gnssImages.length > 0) {
    entities.push(createGnssImageEntity(gnssImages))
  }
  

  return entities;
};

/**
 *
 * @param {Object} element
 * @returns
 */
function createMeasurementPointEntity(element) {
  const geoJsonMeasurePoints = createGeoJsonPoints(element.measurements, {
    pointGroupId: pointGroupIdLookupTable[MEASURE_POINT],
  });

  const measurementPointEntity = new Entity();

  const componentMeasurementPoints = new ComponentMeasurementPoints({
    name: `${MEASURE_POINT}_${element.line.id}`,
    data: geoJsonMeasurePoints,
  });

  const color = COLOR_MAP[element.line.color]
    ? COLOR_MAP[element.line.color]
    : purple500;

  componentMeasurementPoints.updateColor(color);

  const componentClick = new ComponentClick();
  const componentHover = new ComponentHover();

  measurementPointEntity.addComponent(componentMeasurementPoints);
  measurementPointEntity.addComponent(componentClick);
  measurementPointEntity.addComponent(componentHover);

  return measurementPointEntity;
}

/**
 *
 * @param {Object} element
 * @returns
 */
function createMeasurementLineEntity(element) {
  const geoJsonMeasurePoints = createGeoJsonPoints(element.measurements, {
    pointGroupId: pointGroupIdLookupTable[MEASURE_POINT],
  });
  const lineGeometry = createGeoJsonLine(geoJsonMeasurePoints, "measure_points");

  const measurementLineEntity = new Entity();

  const componentLine = new ComponentLine({
    id: element.line.id,
    name: `${MEASURE_POINT}_line_${element.line.id}`,
    data: lineGeometry,
  });

  const color = COLOR_MAP[element.line.color]
    ? COLOR_MAP[element.line.color]
    : purple500;

  componentLine.updateColor(color);

  measurementLineEntity.addComponent(componentLine);

  return measurementLineEntity;
}

/**
 *
 * @param {Array} referencePoints
 * @returns
 */
function createReferencePointEntity(element) {
  const referencePointEntity = new Entity();
  const geoJsonReferencePoints = createGeoJsonPoints(element.referencePoints, {
    pointGroupId: pointGroupIdLookupTable[REFERENCE_POINT],
  });

  const componentReferencePoints = new ComponentReferencePoints({
    name: `${REFERENCE_POINT}_${element.layerConfig.id}`,
    data: geoJsonReferencePoints,
  });

  const color = COLOR_MAP[element.layerConfig.color]
    ? COLOR_MAP[element.layerConfig.color]
    : purple500;

  componentReferencePoints.updateColor(color);

  const componentClick = new ComponentClick();
  const componentHover = new ComponentHover();

  referencePointEntity.addComponent(componentReferencePoints);
  referencePointEntity.addComponent(componentClick);
  referencePointEntity.addComponent(componentHover);

  return referencePointEntity;
}

/**
 *
 * @param {Object} element
 * @returns
 */
function createTopographicPointEntity(element) {
  const topographicPointsEntity = new Entity();
  const geoJsonTopographicPoints = createGeoJsonPoints(
    element.topographicPoints,
    {
      pointGroupId: pointGroupIdLookupTable[TOPOGRAPHIC_POINT],
    }
  );

  const componentTopographicPoints = new ComponentTopographicPoints({
    name: `${TOPOGRAPHIC_POINT}_${element.layerConfig.id}`,
    data: geoJsonTopographicPoints,
  });

  const color = COLOR_MAP[element.layerConfig.color]
    ? COLOR_MAP[element.layerConfig.color]
    : purple500;

  componentTopographicPoints.updateColor(color);

  const componentClick = new ComponentClick();
  const componentHover = new ComponentHover();

  topographicPointsEntity.addComponent(componentTopographicPoints);
  topographicPointsEntity.addComponent(componentClick);
  topographicPointsEntity.addComponent(componentHover);

  return topographicPointsEntity;
}


function createGnssPointEntity(gnssPoints, layerConfig) {
  const gnssPointEntity = new Entity();
  const geoJsonGnssPoints = createGeoJsonPoints(
    gnssPoints,
    {
      pointGroupId: pointGroupIdLookupTable[GNSS_POINT],
    }
  );

  const componentGnssPoints= new ComponentGnssPoints({
    name: `${GNSS_POINT}_${layerConfig.id}`,
    data: geoJsonGnssPoints,
  });

  const color = COLOR_MAP[layerConfig.color]
    ? COLOR_MAP[layerConfig.color]
    : purple500;

  componentGnssPoints.updateColor(color);

  const componentClick = new ComponentClick();
  const componentHover = new ComponentHover();

  gnssPointEntity.addComponent(componentGnssPoints);
  gnssPointEntity.addComponent(componentClick);
  gnssPointEntity.addComponent(componentHover);

  return gnssPointEntity;
}

function createGnssRefPointEntity(gnssRefPoints, layerConfig) {
  const gnssRefPointEntity = new Entity();
  const geoJsonGnssRefPoints = createGeoJsonPoints(
    gnssRefPoints,
    {
      pointGroupId: pointGroupIdLookupTable[GNSS_REF_POINT],
    }
  );

  const componentGnssRefPoints= new ComponentGnssRefPoints({
    name: `${GNSS_REF_POINT}_${layerConfig.id}`,
    data: geoJsonGnssRefPoints,
  });

  const color = COLOR_MAP[layerConfig.color]
    ? COLOR_MAP[layerConfig.color]
    : purple500;

  componentGnssRefPoints.updateColor(color);

  const componentClick = new ComponentClick();
  const componentHover = new ComponentHover();

  gnssRefPointEntity.addComponent(componentGnssRefPoints);
  gnssRefPointEntity.addComponent(componentClick);
  gnssRefPointEntity.addComponent(componentHover);

  return gnssRefPointEntity;
}

function createGnssLineEntity(element) {
  const geoJsonGnssPoints = createGeoJsonPoints(element.gnssPoints, {
    pointGroupId: pointGroupIdLookupTable[GNSS_LINE],
  });

  const lineGeometry = createGeoJsonLine(geoJsonGnssPoints, "")

  const gnssLineEntity = new Entity()

  const componentGnssLine = new ComponentGnssLine({
    id: element.gnssLine.uuid,
    name: `${GNSS_LINE}_${element.layerConfig.id}`,
    data: lineGeometry
  })

  const color = COLOR_MAP[element.layerConfig.color]
    ? COLOR_MAP[element.layerConfig.color]
    : purple500;

  componentGnssLine.updateColor(color)

  gnssLineEntity.addComponent(componentGnssLine)

  return gnssLineEntity
}

function createGnssPolygonEntity(element) {
  const geoJsonGnssPoints = createGeoJsonPoints(element.gnssPoints, {
    pointGroupId: pointGroupIdLookupTable[GNSS_POLYGON],
  });

  const polygonGeometry = createGeoJsonPolygon(geoJsonGnssPoints)

  const polygonFeature = polygonGeometry.features

  polygonGeometry.features = [polygonFeature]

  const gnssPolygonEntity = new Entity()

  const componentGnssPolyone = new ComponentGnssPolygon({
    id: element.gnssPolygon.uuid,
    name: `${GNSS_POLYGON}_${element.layerConfig.id}`,
    data: polygonGeometry
  })

  const color = COLOR_MAP[element.layerConfig.color]
    ? COLOR_MAP[element.layerConfig.color]
    : purple500;

  componentGnssPolyone.updateColor(color)

  gnssPolygonEntity.addComponent(componentGnssPolyone)

  return gnssPolygonEntity
}

function createGnssImageEntity(element) {
  const gnssImageEntity = new Entity();
  const geoJsonGnssPoints = createGeoJsonPoints(
    element,
    {
      pointGroupId: pointGroupIdLookupTable[GNSS_IMAGE],
    }
  );

  const componentGnssImage= new ComponentGnssImage({
    name: `${GNSS_IMAGE}_image`,
    data: geoJsonGnssPoints,
  });

  const componentClick = new ComponentClick();
  const componentHover = new ComponentHover();

  gnssImageEntity.addComponent(componentGnssImage)
  gnssImageEntity.addComponent(componentClick)
  gnssImageEntity.addComponent(componentHover)

  return gnssImageEntity
}


function createLabelEntity(
  country, 
  element, 
  name, 
  forEntityType
) {
  const labelEntity = new Entity();
  const geoJsonPoints = createGeoJsonPoints(element, {});
  const componentLabels = new ComponentLabels({
    country: country,
    data: geoJsonPoints,
    name: `labels_${name}`,
    forEntityType: forEntityType
  });
  labelEntity.addComponent(componentLabels);
  return labelEntity;
}
