/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Link } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import withStyles from "@mui/styles/withStyles";

import NoAvatar from "../../components/NoAvatar";
import { signOut } from "../../actions/user";
import * as styles from "../../styles/styles";

const propTypes = {
  classes: PropTypes.object,
  subscriptionExpired: PropTypes.bool,
  avatar: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  signOut: PropTypes.func,
  showUserSettings: PropTypes.bool,
  user: PropTypes.object,
};

const defaultProps = {
  showUserSettings: true,
};

const overrideStyles = (theme) => ({
  iconWidth: {
    minWidth: "3.5rem",
  },
});

const UserMenu = ({
  avatar,
  classes,
  firstName,
  lastName,
  signOut,
  showUserSettings,
  subscriptionExpired,
  user,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const renderAvatar = () => {
    if (avatar)
      return (
        <Avatar
          alt="avatar"
          src={avatar}
          style={{
            backgroundColor: styles.secondary,
            width: styles.avatarSize,
            height: styles.avatarSize,
          }}
        />
      );
    return (
      <NoAvatar variant="small" firstName={firstName} lastName={lastName} />
    );
  };

  return (
    <>
      <IconButton
        style={{ position: "absolute", right: "2.3rem", padding: "0.6rem" }}
        aria-owns={open ? "menu-appbar" : null}
        aria-haspopup="true"
        color="inherit"
        onClick={(event) => setAnchorEl(event.currentTarget)}
        size="large"
      >
        {renderAvatar()}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        id="menu-appbar"
        open={open}
        onClose={() => setAnchorEl(null)}
        style={{ zIndex: 9999 }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {!subscriptionExpired && showUserSettings && (
          <MenuItem
            component={Link}
            to="/settings"
            onClick={() => setAnchorEl(null)}
          >
            <ListItemIcon classes={{ root: classes.iconWidth }}>
              <AccountCircleIcon style={{ fontSize: "2.4rem" }} />
            </ListItemIcon>
            <ListItemText
              primary={`${user.get("first_name")} ${user.get("last_name")}`}
              primaryTypographyProps={{ variant: "body1" }}
            />
          </MenuItem>
        )}
        <MenuItem onClick={signOut}>
          <ListItemIcon classes={{ root: classes.iconWidth }}>
            <ExitToAppIcon style={{ fontSize: "2.4rem" }} />
          </ListItemIcon>
          <ListItemText
            primary="Logout"
            primaryTypographyProps={{ variant: "body1" }}
          />
        </MenuItem>
      </Menu>
    </>
  );
};

function dispatchToProps(dispatch) {
  return bindActionCreators(
    {
      signOut,
    },
    dispatch
  );
}

function stateToProps(state) {
  const user = state.get("user");
  return {
    avatar: user.getIn(["avatar", "original"]),
    firstName: user.get("first_name"),
    subscriptionExpired: state.getIn(["organizations", "subscriptionExpired"]),
    lastName: user.get("last_name"),
    user,
  };
}

UserMenu.propTypes = propTypes;
UserMenu.defaultProps = defaultProps;
const styledUserMenu = withStyles(overrideStyles, { withTheme: true })(
  UserMenu
);
export default connect(stateToProps, dispatchToProps)(styledUserMenu);
