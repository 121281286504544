import { fromJS, Map } from "immutable";
import {
  EDIT_MEASUREMENT_SEGMENT,
  REMOVE_MEASUREMENT_SEGMENT,
  REMOVE_MEASUREMENT_SEGMENTS,
  SET_MEASUREMENT_SEGMENT,
  SET_MEASUREMENT_SEGMENTS,
} from "../constants/actions/actions";

const initialState = fromJS({
  loading: false,
  new: 0,
  items: fromJS({}),
});

export const prepareItems = (array) => {
  return Map(array.map((item) => [item.id.toString(), fromJS(item)]));
};

export function measurementSegments(state = initialState, action) {
  switch (action.type) {
    case SET_MEASUREMENT_SEGMENTS:
      return state.mergeIn(["items"], prepareItems(action.payload));

    case SET_MEASUREMENT_SEGMENT:
      return state.setIn(
        ["items", action.payload.id.toString()],
        fromJS(action.payload.item)
      );

    case EDIT_MEASUREMENT_SEGMENT: {
      let { id, item } = action.payload;
      return state.getIn([`items`, id.toString()])
        ? state.mergeIn([`items`, id.toString()], fromJS(item))
        : state;
    }

    case REMOVE_MEASUREMENT_SEGMENT:
      return state.deleteIn([`items`, action.payload.id.toString()]);

    case REMOVE_MEASUREMENT_SEGMENTS:
      // I have no f*cking clue how to remove certain items. This
      // implementation feels bad!
      action.payload.measurementLines.valueSeq().forEach((line) => {
        state.set(
          "items",
          state
            .get("items")
            .filter(
              (item) => item.get("measurement_line_id") !== line.get("id")
            )
        );
      });

      return state;

    default:
      return state;
  }
}
