export const MEASURE_POINT = "measure_point";
export const REFERENCE_POINT = "reference_point";
export const TOPOGRAPHIC_POINT = "topographic_point";
export const LABEL = "label";
export const GNSS_POINT = "gnss_point";
export const GNSS_LINE = "gnss_line";
export const GNSS_POLYGON = "gnss_polygon";
export const GNSS_IMAGE = "gnss_image";
export const GNSS_REF_POINT = "gnss_ref_point";

export const pointNameLookupTable = {
  measure_point: "Messpunkt",
  reference_point: "Referenzpunkt",
  topographic_point: "Punkt",
  gnss_point: "GNSS-Punkt",
  gnss_image: "GNSS-Bild",
  gnss_ref_point: "GNSS-Passpunkt"
};

export const pointGroupIdLookupTable = {
  measure_point: 1,
  reference_point: 2,
  topographic_point: 3,
  gnss_point: 4,
  gnss_ref_point: 5
};
