import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { HINT_TYPE_MAPPING } from '../../constants/hinttypesmapping';
import './styles.css';

const propTypes = {
    hintMessageType: PropTypes.string
}

const HintView = ({
    hintMessageType
}) => {
    return <div className="hint-container">
        <Typography color="primary" variant="body1">
            {HINT_TYPE_MAPPING[hintMessageType] ? HINT_TYPE_MAPPING[hintMessageType] : ""}
        </Typography>
    </div>
}

HintView.propTypes = propTypes;
export default HintView;