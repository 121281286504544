import React from "react";
import PropTypes from "prop-types";

import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { AlertTitle } from "@mui/material";
import { green, amber, red, lightBlue } from "@mui/material/colors";

import { snackbarSeverityLookupTable } from "../../constants/snackbars";
import "./styles.css";

const propTypes = {
  message: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired,
};

const styles = {
  error: red[600],
  info: lightBlue[600],
  success: green[600],
  warning: amber[700],
};

const CustomSnackbar = ({ message, onClose, open, variant }) => {
  return (
    <Snackbar
      autoHideDuration={4000}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiSnackbarContent-root": { backgroundColor: styles[variant] },
      }}
    >
      <Alert variant="filled" severity={variant} sx={{ fontSize: 12 }}>
        <AlertTitle sx={{ fontSize: 14 }}>
          {snackbarSeverityLookupTable[variant]}
        </AlertTitle>
        {message}
      </Alert>
    </Snackbar>
  );
};

CustomSnackbar.propTypes = propTypes;
export default CustomSnackbar;
