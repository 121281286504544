import React from 'react';
import PropTypes from 'prop-types';
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';

import * as styles from '../../../styles/styles';

const propTypes = {
  resendInvitation: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
}

const InvitedUserActions = ({
  resendInvitation,
  user,
}) => {
  return <>
    <Typography
      color='textSecondary'
      style={{ margin: `0 ${styles.spacing24} 0 0` }}
      variant='body1'>
      Einladung ausstehend
    </Typography>
    <Button
      onClick={() => resendInvitation(user.id)}>
      Erneut senden
    </Button>
  </>
}

InvitedUserActions.propTypes = propTypes;
export default InvitedUserActions;