import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';

const propTypes = {
  classNameContainer: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  projects: PropTypes.object.isRequired,
}

const defaultProps = {
  classNameContainer: ''
}

const SearchResultsSmall = ({
  classNameContainer,
  onClick,
  projects,
}) => {
  const renderList = () => {
    return Object.keys(projects).map(key => {
      const project = projects[key];
      const id = project.id
      return <Link
        key={id}
        style={{ textDecoration: 'none', color: 'inherit' }}
        to={`/projects/${id}`}>
        <ListItem
          onClick={onClick}
          button={true}>
          <ListItemText
            primary={
              `Projekttitel: ${project.title}`
            }
            secondary={
              `Auftragsnummer: ${project.job_order}`
            } />
        </ListItem>
      </Link >
    })
  }

  return <div
    className={`search-result-small-container ${classNameContainer}`}>
    {renderList()}
  </div >
}

SearchResultsSmall.defaultProps = defaultProps;
SearchResultsSmall.propTypes = propTypes;
export default SearchResultsSmall;