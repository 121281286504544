import React from "react";
import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import {
  appSettingsOrderingTitle,
  appSettingsOrderingDescription,
} from "../../../../constants/userSettings";
import TooltipUiElement from "./TooltipUiElement";
import * as styles from "../../../../styles/styles";

const propTypes = {
  androidSettings: PropTypes.array,
  id: PropTypes.string,
  keyIdx: PropTypes.string,
  wordList: PropTypes.array,
  handleChange: PropTypes.func,
};

const DropdownUiElement = ({
  androidSettings,
  id,
  keyIdx,
  wordList,
  handleChange,
}) => {
  const optionsList = [];

  for (const idx in wordList) {
    optionsList.push(
      <option key={wordList[idx].value} value={wordList[idx].value}>
        {wordList[idx].text}
      </option>
    );
  }

  return (
    <div className="center-content" key={keyIdx}>
      <FormControl
        variant="outlined"
        style={{
          margin: `${styles.spacing12} 0 ${styles.spacing24} 0`,
          paddingRight: `${styles.spacing12}`,
        }}
      >
        <InputLabel htmlFor="outlined-native-simple">
          {appSettingsOrderingTitle[androidSettings[id].key]}
        </InputLabel>
        <Select
          native
          value={androidSettings[id].value}
          onChange={(event) =>
            handleChange({
              key: androidSettings[id].key,
              value: event.target.value,
            })
          }
          label={appSettingsOrderingTitle[androidSettings[id].key]}
          inputProps={{
            name: "dropdown",
            id: "outlined-native-simple",
          }}
        >
          {optionsList}
        </Select>
      </FormControl>
      {appSettingsOrderingDescription[androidSettings[id].key] && (
        <TooltipUiElement
          text={appSettingsOrderingDescription[androidSettings[id].key]}
        />
      )}
    </div>
  );
};

DropdownUiElement.propTypes = propTypes;
export default DropdownUiElement;
