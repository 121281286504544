import * as styles from '../../styles/styles';

export const styling = {
  root: {
    display: 'flex',
    margin: '1rem 0',
    backgroundColor: styles.white,
    boxShadow: styles.boxShadowListElement
  },
  listItem: {
    height: '7.4rem',
  },
}