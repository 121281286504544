import React from 'react';
import Avatar from "@mui/material/Avatar";
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from "@mui/material/Typography";
import PropTypes from 'prop-types';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import NoAvatar from '../NoAvatar';
import * as styles from '../../styles/styles';

const propTypes = {
  avatar: PropTypes.string,
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  registredUser: PropTypes.bool
};

const AvatarName = ({
  avatar,
  email,
  firstName,
  lastName,
  registredUser
}) => {
  const avatarMargin = `0 ${styles.spacing16} 0 0`;
  const renderRegistredUserAvatar = () => {
    if (avatar) return <>
      <ListItemAvatar>
        <Avatar
          alt='avatar'
          src={avatar}
          style={{
            backgroundColor: styles.secondary,
            width: styles.avatarSize,
            height: styles.avatarSize,
            margin: avatarMargin
          }} />
      </ListItemAvatar >
      <Typography
        color='textPrimary'
        variant='body1'>
        {`${firstName} ${lastName}`}
      </Typography>
    </>
    return <>
      <ListItemAvatar>
        <NoAvatar
          firstName={firstName}
          lastName={lastName}
          margin={avatarMargin}
          variant='small' />
      </ListItemAvatar >
      <Typography
        color='textPrimary'
        variant='body1'>
        {`${firstName} ${lastName}`}
      </Typography>
    </>
  }

  const renderAvatar = (registredUser) => {
    if (registredUser) return renderRegistredUserAvatar();
    return <>
      <ListItemAvatar>
        <Avatar
          alt='avatar'
          style={{
            backgroundColor: styles.darkBlueMediumLight,
            width: styles.avatarSize,
            height: styles.avatarSize,
            margin: avatarMargin
          }}>
          <AccessTimeIcon
            style={{ fontSize: '4rem' }} />
        </Avatar>
      </ListItemAvatar >
      <Typography
        color='textSecondary'
        variant='body1'>
        {email}
      </Typography>
    </>
  }

  return renderAvatar(registredUser)
}

AvatarName.propTypes = propTypes;
export default AvatarName;