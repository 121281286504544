import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ProjectList from "./ProjectList";

import CustomPagination from "../../../components/CustomPagination";
import Header from "../../../components/Header";

import BulkActionsMenu from "../../../containers/BulkActionsMenu";
import SingleActionsMenu from "../../../containers/SingleActionsMenu";
import ShowSnackbar from "../../../containers/ShowSnackbar";

import { hasOrganizationFeatureFlag } from "../../../sharedFunctions/organization";
import { PROJECT_RESTRICTED_SINGLE } from "../../../constants/featureFlags";

const propTypes = {
  disableCreateButton: PropTypes.bool,
  data: PropTypes.object,
  history: PropTypes.object,
};

const Projects = ({ disableCreateButton, data, history }) => {
  const selectedProjects = data.get("selection");

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Header
        divider={selectedProjects.size > 0}
        subHeader={<BulkActionsMenu />}
      >
        <SingleActionsMenu disableCreateButton={disableCreateButton} />
      </Header>
      <ShowSnackbar />
      <ProjectList
        history={history}
        showFileUploadProjectModal={true}
        selectedProjects={selectedProjects.toJS()}
      />
      <CustomPagination />
    </div>
  );
};

function stateToProps(state) {
  const isProjectRestrictedSingle = hasOrganizationFeatureFlag(
    state,
    PROJECT_RESTRICTED_SINGLE
  );

  const projectCount = state.getIn(["projects", "items"]).count();

  const disableCreateButton = isProjectRestrictedSingle && projectCount === 1;

  return {
    disableCreateButton,
    data: state.get("data"),
  };
}

Projects.propTypes = propTypes;
export default connect(stateToProps, null)(Projects);
