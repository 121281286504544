export const DOWNLOAD_KEY = "download";
export const EDIT_KEY = "edit";
export const ASSIGN_KEY = "assign";
export const DELETE_KEY = "delete";
export const IMAGE_KEY = "image";
export const RESET_KEY = "reset";
export const CREATE_KEY = "create";

export const dropdownFinishedProjectElements = [
  {
    text: "Herunterladen",
    key: DOWNLOAD_KEY,
  },
  {
    text: "Bearbeiten",
    key: EDIT_KEY,
  },
  {
    text: "Zuweisen",
    key: ASSIGN_KEY,
  },
  {
    text: "Zurücksetzen",
    key: RESET_KEY,
  },
  {
    text: "Löschen",
    key: DELETE_KEY,
  },
];

export const dropdownOpenProjectElements = [
  {
    text: "Bearbeiten",
    key: EDIT_KEY,
  },
  {
    text: "Zuweisen",
    key: ASSIGN_KEY,
  },
  {
    text: "Löschen",
    key: DELETE_KEY,
  },
];

export const dropdownArchivedProjectElements = [
  {
    text: "Bearbeiten",
    key: EDIT_KEY,
  },
  {
    text: "Zuweisen",
    key: ASSIGN_KEY,
  },
  {
    text: "Löschen",
    key: DELETE_KEY,
  },
];

export const dropdownScreenshotListItemElements = [
  {
    text: "Löschen",
    key: DELETE_KEY,
  },
];

export const dropdownVolumeListItemElements = [
  {
    text: "Bearbeiten",
    key: EDIT_KEY,
  },
  {
    text: "Löschen",
    key: DELETE_KEY,
  },
];

export const dropdownMeasurementListItemElements = [
  {
    text: "Bearbeiten",
    key: EDIT_KEY,
  },
];
