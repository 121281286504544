import { fromJS, Map } from "immutable";
import {
  SET_PROJECT_FILE,
  REMOVE_PROJECT_FILE,
  REMOVE_PROJECT_FILES,
  SET_PROJECT_FILES,
  SHOW_PROJECT_FILE_PREVIEW,
  HIDE_PROJECT_FILE_PREVIEW,
} from "../constants/actions/actions";

const initialState = fromJS({
  loading: false,
  new: 0,
  items: fromJS({}),
  ui: {
    creatingProjectFile: false,
  },
});

export const prepareItems = (array) => {
  return Map(array.map((item) => [item.id.toString(), fromJS(item)]));
};

export function projectFiles(state = initialState, action) {
  switch (action.type) {
    case SET_PROJECT_FILE:
      return state.setIn(
        ["items", action.payload.id.toString()],
        fromJS(action.payload.item)
      );
    case SET_PROJECT_FILES:
      return state.mergeIn(["items"], prepareItems(action.payload));
    case REMOVE_PROJECT_FILE:
      return state.deleteIn(["items", action.payload.id.toString()]);
    case REMOVE_PROJECT_FILES: {
      const projectIdInt = parseInt(action.payload.id);
      return state.set(
        "items",
        state
          .get("items")
          .filter((item) => item.get("project_id") !== projectIdInt)
      );
    }
    case SHOW_PROJECT_FILE_PREVIEW:
      return state.setIn(["ui", "creatingProjectFile"], true);
    case HIDE_PROJECT_FILE_PREVIEW:
      return state.setIn(["ui", "creatingProjectFile"], false);
    default:
      return state;
  }
}
