import { LAST_PATH } from "../constants/localStorageConstants";

import { useEffect } from "react";

const initBeforeUnLoad = (fromPath) => {
  // eslint-disable-next-line no-unused-vars
  window.onbeforeunload = (_event) => {
    localStorage.setItem(LAST_PATH, fromPath);
  };
};

/**
 * A React Hook that detects when the user is about to leave the page
 * and saves the provided 'fromPath' in the localStorage under the key 'LAST_PATH'.
 * This hook sets up an 'onbeforeunload' event listener on the window object,
 * which triggers when the user attempts to navigate away from the current page.
 * The 'fromPath' value is stored in the localStorage to track the previously visited path.
 *
 * @param {string} fromPath - The path to be saved in the localStorage as 'LAST_PATH'.
 * @returns {void} This hook does not return any value.
 */
export default function useExitDetector(fromPath) {
  window.onload = function () {
    initBeforeUnLoad(fromPath);
  };

  useEffect(() => {
    initBeforeUnLoad(fromPath);
  }, [fromPath]);
}
