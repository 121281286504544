/* eslint-disable import/namespace */
/* eslint-disable no-unused-vars */
import { fromJS, Map } from "immutable";
import {
  SET_GNSS_DATA
} from "../constants/actions/actions";

import { setGnssData } from "../sharedFunctions/gnssData/stateUtils";

const initialState = fromJS({
    items: fromJS({
        images: [],
        lines: [],
        points: [],
        polygons: [],
        polygon_points: [],
        segments: []
    }),
});

export function gnssData(state = initialState, action) {
    switch (action.type) {
        case SET_GNSS_DATA:
            return setGnssData(state, action);
        default:
            return state;
    }
}
