import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import { thunk } from "redux-thunk";
import { composeWithDevTools } from '@redux-devtools/extension';
import Immutable from 'immutable';

import rootReducer from '../reducers';
import { store as globalStore } from './globals';


const composeEnhancers = composeWithDevTools({
  serialize: {
    immutable: Immutable
  }
});

let middleware = [thunk];

if (process.env.NODE_ENV === "development") {
  const logger = createLogger({
    collapsed(getState, action, logEntry) {
      return !logEntry.error;
    }
  });

  middleware = middleware.concat(logger);
}

export const initStore = () => {
    const store = createStore(
      rootReducer,
      composeEnhancers(
        applyMiddleware(...middleware)
      ));
  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('../reducers', () => {
        const nextReducer = require('../reducers').default;
        store.replaceReducer(nextReducer);
      });
    }
  }
    globalStore.instance = store;
    return store;
  }
;


