import React from "react";
import PropTypes from "prop-types";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import {
  appSettingsOrderingDescription,
  appSettingsOrderingTitle,
} from "../../../../constants/userSettings";
import TooltipUiElement from "./TooltipUiElement";

const propTypes = {
  androidSettings: PropTypes.array,
  id: PropTypes.string,
  keyIdx: PropTypes.string,
  handleChange: PropTypes.func,
};

const BooleanUiElement = ({ androidSettings, id, keyIdx, handleChange }) => {
  return (
    <div className="center-content" key={keyIdx}>
      <FormControlLabel
        control={
          <Switch
            checked={androidSettings[id].value}
            onChange={(event) => {
              handleChange({
                key: androidSettings[id].key,
                value: event.target.checked,
              });
            }}
            name={androidSettings[id].key}
          />
        }
        label={appSettingsOrderingTitle[androidSettings[id].key]}
      />
      {appSettingsOrderingDescription[androidSettings[id].key] && (
        <TooltipUiElement
          text={appSettingsOrderingDescription[androidSettings[id].key]}
        />
      )}
    </div>
  );
};

BooleanUiElement.propTypes = propTypes;
export default BooleanUiElement;
