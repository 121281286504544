import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Button from "@mui/material/Button";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

import { searchUser } from "../../../actions/userAdministration";
import SearchButton from "../../../components/SearchButton";
import Spinner from "../../../atoms/Spinner";
import InviteNewUser from "../../../containers/dialogs/InviteNewUser";
import Header from "../../../components/Header";

import "./styles.css";

const propTypes = {
  organizations: PropTypes.object.isRequired,
  searchUser: PropTypes.func,
  searchTerm: PropTypes.string,
};

const HeaderUserAdministration = ({
  organizations,
  searchUser,
  searchTerm,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const organizationsJs = organizations.toJS();
  const organizationId = Object.keys(organizationsJs.items)[0];
  const organization = organizationsJs.items[organizationId] || {};
  const userCount = organization.user_count;
  const maxUserCount = organization.max_user_count;

  const showAvailableAccounts = () => {
    if (organizations)
      return (
        <Typography align="left" color="textPrimary" variant="subtitle1">
          {`${userCount}/${maxUserCount} aktiviert`}
        </Typography>
      );
    return <Spinner height={2.9} width={2.9} />;
  };

  return (
    <Header>
      <SearchButton
        searchTerm={searchTerm}
        search={searchUser}
        showResults={false}
        placeholderText={"Mitarbeiter suchen..."}
      />
      <div className="header-right-side">
        {modalOpen && (
          <InviteNewUser
            modalOpen={modalOpen}
            onClick={() => setModalOpen(false)}
            onClose={() => setModalOpen(false)}
          />
        )}
        <div className="header-user-administration-counter-wrapper">
          {showAvailableAccounts()}
          <Link
            to={{ pathname: "https://www.netpipe.io/kontakt-support" }}
            target="_blank"
            rel="noopener noreferer"
            style={{ textDecoration: "none" }}
          >
            <Typography
              className="header-user-administration-more-user"
              align="right"
              color="primary"
              variant="body1"
            >
              Mehr Nutzer?
            </Typography>
          </Link>
        </div>
        <Button
          color="primary"
          disabled={userCount === maxUserCount}
          size="medium"
          onClick={() => setModalOpen(true)}
          variant="contained"
        >
          <PersonAddIcon style={{ margin: "0 1rem 0 0" }} />
          Nutzer Hinzufügen
        </Button>
      </div>
    </Header>
  );
};

function dispatchToProps(dispatch) {
  return bindActionCreators(
    {
      searchUser,
    },
    dispatch
  );
}

function stateToProps(state) {
  return {
    organizations: state.get("organizations"),
    searchTerm: state.getIn(["userAdministration", "searchTerm"]),
  };
}

HeaderUserAdministration.propTypes = propTypes;
export default connect(stateToProps, dispatchToProps)(HeaderUserAdministration);
