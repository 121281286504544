import React from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import {
  appSettingsOrderingTitle,
  appSettingsOrderingDescription,
} from "../../../../constants/userSettings";
import TooltipUiElement from "./TooltipUiElement";
import * as styles from "../../../../styles/styles";

const propTypes = {
  androidSettings: PropTypes.array,
  id: PropTypes.string,
  keyIdx: PropTypes.string,
  handleChange: PropTypes.func,
};

const StringUiElement = ({ androidSettings, id, keyIdx, handleChange }) => {
  return (
    <div className="center-content" key={keyIdx}>
      <TextField
        fullWidth={true}
        margin="none"
        label={appSettingsOrderingTitle[androidSettings[id].key]}
        multiline={false}
        onChange={(event) =>
          handleChange({
            key: androidSettings[id].key,
            value: event.target.value,
          })
        }
        placeholder="ID"
        required={false}
        style={{
          margin: `${styles.spacing12} 0 ${styles.spacing24} 0`,
          paddingRight: `${styles.spacing12}`,
        }}
        variant="outlined"
        value={androidSettings[id].value}
      />
      {appSettingsOrderingDescription[androidSettings[id].key] && (
        <TooltipUiElement
          text={appSettingsOrderingDescription[androidSettings[id].key]}
        />
      )}
    </div>
  );
};

StringUiElement.propTypes = propTypes;
export default StringUiElement;
