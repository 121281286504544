import React from "react";
import PropTypes from "prop-types";

import List from "@mui/material/List";

import HeaderUserAdministration from "../../../../containers/users/HeaderUserAdministration";
import InvitedUsers from "../../../../containers/users/InvitedUsers";
import RegistredUsers from "../../../../containers/users/RegistredUsers";

import "./styles.css";

const propTypes = {
  history: PropTypes.object,
};

const UserAdministration = ({ history }) => {
  return (
    <>
      <HeaderUserAdministration />
      <List style={{ padding: "0 1rem" }} className="users-list-container">
        <RegistredUsers key={"registredUsers"} history={history} />
        <InvitedUsers key={"invitedUsers"} history={history} />
      </List>
    </>
  );
};

UserAdministration.propTypes = propTypes;
export default UserAdministration;
