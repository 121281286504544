import PropTypes from "prop-types";
import queryString from "qs";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  checkForExpiredSubscription,
  setExpiredSubscription,
} from "../../../actions/organizations";

import { setUser, validate } from "../../../actions/user";
import LandingPage from "../../../components/LandingPage";
import LogIn from "../../../containers/LogIn";
import API from "../../../lib/api";
import HTTP from "../../../lib/http";
import { initApp, initSockets } from "../../../lib/init";
import Storage from "../../../lib/storage";
import Validate from "../../../screens/public/Validate";
import { isAdmin } from "../../../sharedFunctions/user";

import "./styles.css";

const propTypes = {
  checkForExpiredSubscription: PropTypes.func,
  history: PropTypes.object,
  setExpiredSubscription: PropTypes.func,
  validate: PropTypes.func,
  removedFromOrganization: PropTypes.any,
  setUser: PropTypes.func,
  initApp: PropTypes.func,
  location: PropTypes.object,
};

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      validating: true,
      loading: false,
      passwordVisible: false,
      email: "",
      password: "",
      rememberLogin: false,
      errorText: "",
    };

    this.signIn = this.signIn.bind(this);
  }

  componentDidMount() {
    const {
      checkForExpiredSubscription,
      history,
      setExpiredSubscription,
      validate,
      removedFromOrganization,
    } = this.props;
    if (removedFromOrganization) {
      this.setState({
        validating: false,
        loading: false,
        errorText: "Sie wurden aus ihrer Organisation entfernt.",
      });
      return;
    }

    if (Storage.get("jwt")) {
      validate()
        .then((validateResponse) => {
          if (validateResponse.status === 200) {
            if (
              validateResponse.data.user.organization_role_groups.length === 0
            ) {
              this.setState({
                validating: false,
                loading: false,
                errorText: "Sie gehören keiner Organisation an.",
              });
              return;
            }
            initSockets();
            checkForExpiredSubscription().then((subscriptionResponse) => {
              if (subscriptionResponse.status === 423) {
                setExpiredSubscription(true);
                history.push("/subscription-expired");
              } else {
                history.push(this.getRedirectRoute());
              }
            });
          } else {
            if (
              validateResponse.response &&
              validateResponse.response.status === 401
            )
              Storage.clearStorage();
            this.setState({ validating: false });
          }
        })
        .catch((error) => {
          this.setState({ validating: false });
          console.log("ERROR", error);
        });
    } else {
      this.setState({ validating: false });
    }
  }

  async signIn() {
    const {
      checkForExpiredSubscription,
      history,
      setExpiredSubscription,
      setUser,
      initApp,
    } = this.props;
    try {
      this.setState({ loading: true });
      Storage.setRememberLogin(this.state.rememberLogin);
      const userResponse = await HTTP.post(
        API.user.signIn,
        {
          email: this.state.email,
          password: this.state.password,
          client: "web",
        },
        null
      );

      if (userResponse.data.user.organization_role_groups.length === 0) {
        console.log("NO ORGAS");
        this.setState({
          loading: false,
          errorText: "Sie gehören keiner Organisation an.",
        });
        return;
      }

      let user = {
        ...userResponse.data.user,
        login: true,
        loading: false,
      };

      setUser(user);
      initSockets();
      checkForExpiredSubscription().then((response) => {
        if (response.status === 423) {
          setExpiredSubscription(true);
          if (isAdmin(user.organization_role_groups, false)) {
            history.push("/billing");
          } else {
            history.push("/subscription-expired");
          }
          this.setState({ loading: false });
        } else {
          initApp().then(() => {
            this.setState({ loading: false });
            history.push(this.getRedirectRoute());
          });
        }
      });
    } catch (error) {
      console.log("ERROR IN SIGN IN", error);
      this.setState({
        loading: false,
        errorText:
          (error.response && error.response.data.error) || "Fehler beim Login",
      });
    }
  }

  getRedirectRoute() {
    let urlParams = queryString.parse(
      this.props.location && this.props.location.search
    );
    return urlParams["?redirect"] || "/projects";
  }

  onKeyDown(event) {
    if (event.key === "Enter") this.signIn();
  }

  renderLogin() {
    const {
      validating,
      loading,
      passwordVisible,
      email,
      password,
      rememberLogin,
      errorText,
    } = this.state;
    if (validating) return <Validate />;
    return (
      <LandingPage headline="Anmelden">
        <LogIn
          email={email}
          password={password}
          passwordVisible={passwordVisible}
          rememberLogin={rememberLogin}
          signIn={this.signIn}
          errorText={errorText}
          loading={loading}
          setEmail={(em) => this.setState({ email: em })}
          setPassword={(pw) => this.setState({ password: pw })}
          setPasswordVisible={(pwV) => this.setState({ passwordVisible: pwV })}
          setRememberLogin={(rL) => this.setState({ rememberLogin: rL })}
          history={this.props.history}
        />
      </LandingPage>
    );
  }

  render() {
    return this.renderLogin();
  }
}

Login.propTypes = propTypes;

function dispatchToProps(dispatch) {
  return bindActionCreators(
    {
      checkForExpiredSubscription,
      setExpiredSubscription,
      initApp,
      setUser,
      validate,
    },
    dispatch
  );
}

function stateToProps(state) {
  return {
    removedFromOrganization: state.getIn([
      "organizations",
      "removedFromOrganization",
    ]),
  };
}

export default connect(stateToProps, dispatchToProps)(Login);
