import { fromJS, Map } from 'immutable';
import {
  REGISTER_USER_STATUS,
  CONFIRM_USER_STATUS,
  RESET_REGISTER_FLAGS,
} from '../constants/actions/actions';

const initialState = fromJS({
  registerUserStatus: null,
  confirmUserStatus: null,
});

export const prepareItems = (array) => {
  return Map(array.map(item => [item.id.toString(), fromJS(item)]));
};

export function register(state = initialState, action) {
  switch (action.type) {
    case CONFIRM_USER_STATUS:
      return state
        .set('confirmUserStatus', action.payload);
    case REGISTER_USER_STATUS:
      return state
        .set('registerUserStatus', action.payload);
    case RESET_REGISTER_FLAGS:
      return state
        .set('registerUserStatus', null)
        .set('confirmUserStatus', null)
    default:
      return state;
  }
}