/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import * as styles from "../../styles/styles";

const propTypes = {
  children: PropTypes.object,
};

const options = {
  limitToWrapper: false,
  transformEnabled: true,
  limitToBounds: true,
  centerContent: true,
};

const pan = {
  velocity: true,
  velocityEqualToMove: true,
};

const CustomImageView = ({ children }) => {
  return (
    <>
      <div style={{ backgroundColor: styles.darkBlueMediumLight }}>
        <TransformWrapper options={options} pan={pan}>
          {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
            <React.Fragment>
              <div className="tools">
                <IconButton
                  variant="contained"
                  style={{
                    position: "absolute",
                    top: "1.5rem",
                    left: "1.5rem",
                    zIndex: 1,
                    backgroundColor: styles.primary,
                    color: styles.white,
                  }}
                  onClick={() => zoomIn()}
                  size="large"
                >
                  <AddIcon />
                </IconButton>
                <IconButton
                  variant="contained"
                  style={{
                    position: "absolute",
                    top: "1.5rem",
                    left: "7.5rem",
                    zIndex: 1,
                    backgroundColor: styles.primary,
                    color: styles.white,
                  }}
                  onClick={() => zoomOut()}
                  size="large"
                >
                  <RemoveIcon />
                </IconButton>
              </div>
              <TransformComponent>{children}</TransformComponent>
            </React.Fragment>
          )}
        </TransformWrapper>
      </div>
    </>
  );
};

CustomImageView.propTypes = propTypes;

export default CustomImageView;
