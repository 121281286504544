import { createTheme } from "@mui/material/styles";

import * as styles from "../styles/styles";

export default createTheme({
  palette: {
    primary: {
      light: styles.primaryLight,
      main: styles.primary,
      dark: styles.primaryDark,
      contrastText: styles.primaryContrastText,
    },
    secondary: {
      light: styles.secondaryLight,
      main: styles.secondary,
      dark: styles.secondaryDark,
      contrastText: styles.secondaryContrastText,
    },
    error: {
      light: styles.error,
      main: styles.error,
      dark: styles.error,
      contrastText: styles.errorContrastText,
    },
    text: {
      primary: styles.darkBlueDark,
      secondary: styles.darkBlueMediumDark,
      disabled: styles.darkBlueMedium,
      hint: styles.darkBlueContrastText,
    },
  },
  typography: {
    htmlFontSize: 10,
    useNextVariants: true,
    h1: styles.h1,
    h2: styles.h2,
    h3: styles.h3,
    h4: styles.h4,
    h5: styles.h5,
    h6: styles.h6,
    body1: styles.body1,
    body2: styles.body2,
    subtitle1: styles.subtitle1,
    subtitle2: styles.subtitle2,
    caption: styles.caption,
  },
  boxShadow: {
    boxShadow: "0 0 13px 4px rgba(0, 0, 0, 0.2)",
  },
  MuiTypography: {
    // variantMapping: {
    //   h1: 'h4',
    //   h2: 'h5',
    //   h3: 'h6',
    //   h4: 'h6',
    //   h5: 'h6',
    //   h6: 'h6',
    //   subtitle1: 'h2',
    //   subtitle2: 'h2',
    //   body1: 'span',
    //   body2: 'span',
    // },
  },
  components: {
    MuiInput: {
      styleOverrides: {
        underline: {
          "&:hover:not($disabled):not($focused):not($error):before": {
            borderBottom: `2px solid ${styles.darkBlueMediumDark}`,
          },
        },
        "&.Mui-error": {
          "&::after": {
            borderBottom: `2px solid ${styles.red}`,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          position: "relative",
          "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
            borderColor: styles.darkBlueMediumDark,
          },
        },
      },
    },
  },
});
