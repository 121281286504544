/* eslint-disable import/namespace */
/* eslint-disable no-unused-vars */
import { createAction } from "redux-actions";

import {
    SET_MAP_LAYERS,
    SET_SYNC_STATUS_MAP_LAYER,
    CREATE_MAP_LAYER,
    UPDATE_MAP_LAYER,
    DELETE_MAP_LAYER,
    SET_MAP_LAYER_TO_PROJECT_ASSIGNMENTS,
    SET_SYNC_STATUS_MAP_LAYER_TO_PROJECT_ASSIGNMENTS,
    CREATE_MAP_LAYER_TO_PROJECT_ASSIGNMENT,
    DELETE_MAP_LAYER_TO_PROJECT_ASSIGNMENT,
    UPLOADING_MAP_LAYER,
    UPLOADING_MAP_LAYER_DONE
  } from "../constants/actions/actions";
  
import {
    MAP_LAYER_CREATED_EVENT,
    MAP_LAYER_UPDATED_EVENT,
    MAP_LAYER_DELETED_EVENT,
    MAP_LAYER_TO_PROJECT_ASSIGNMENT_CREATED_EVENT,
    MAP_LAYER_TO_PROJECT_ASSIGNMENT_DELETED_EVENT,
} from "../constants/channelevents";

import {
    createMapLayerSuccess,
    createMapLayerFail,
    updateMapLayerSuccess,
    updateMapLayerFail,
    deleteMapLayerSuccess,
    deleteMapLayerFail
} from "../constants/snackbars";

import { renderSnackbar } from "./snackbars";

import { fail, pending, success } from "../constants/status";
import API from "../lib/api";
import HTTP from "../lib/http";
import { getOrgaId } from "../sharedFunctions/organization";

export const setMapLayersAction = createAction(SET_MAP_LAYERS)
export const setSyncStatusMapLayers = createAction(SET_SYNC_STATUS_MAP_LAYER)
export const createMapLayerAction = createAction(CREATE_MAP_LAYER)
export const updateMapLayerAction = createAction(UPDATE_MAP_LAYER)
export const deleteMapLayerAction = createAction(DELETE_MAP_LAYER)
export const setMapLayersToProjectAssignment = createAction(SET_MAP_LAYER_TO_PROJECT_ASSIGNMENTS)
export const setSyncStatusMapLayersToProjectAssignment = createAction(SET_SYNC_STATUS_MAP_LAYER_TO_PROJECT_ASSIGNMENTS)
export const createMapLayerToProjectAssignmentAction = createAction(CREATE_MAP_LAYER_TO_PROJECT_ASSIGNMENT)
export const deleteMapLayerToProjectAssignmentAction = createAction(DELETE_MAP_LAYER_TO_PROJECT_ASSIGNMENT)
export const uploadingMapLayerAction = createAction(UPLOADING_MAP_LAYER)
export const uploadingMapLayerDoneAction = createAction(UPLOADING_MAP_LAYER_DONE)

export const CableActions = (message, connection, store) => {
    switch (message.event) {
        case MAP_LAYER_CREATED_EVENT:
            store.dispatch(
                createMapLayerAction(
                    message.payload
                )
            );
            break;
        case MAP_LAYER_UPDATED_EVENT:
            store.dispatch(
                updateMapLayerAction(
                    message.payload
                )
            );
            break;
        case MAP_LAYER_DELETED_EVENT:
            store.dispatch(
                deleteMapLayerAction(
                    message.payload
                )
            );
            break;
        case MAP_LAYER_TO_PROJECT_ASSIGNMENT_CREATED_EVENT:
            store.dispatch(
                createMapLayerToProjectAssignmentAction(
                    message.payload
                )
            )
            break;
        case MAP_LAYER_TO_PROJECT_ASSIGNMENT_DELETED_EVENT:
            store.dispatch(
                deleteMapLayerToProjectAssignmentAction(
                    message.payload
                )
            )
            break;
        default:
            break;
    }
}

export function syncMapLayers() {
    return async(dispatch, getState) => {
        dispatch(setSyncStatusMapLayers(pending));
        const orgaId = getOrgaId(getState());

        await HTTP.get(API.organizations.mapLayers.syncMapLayers(orgaId))
        .then(function (response) {
            // success
            dispatch(setSyncStatusMapLayers(success));
            dispatch(setMapLayersAction(response.data))
        })
        .catch(function (error) {
            // error
            dispatch(setSyncStatusMapLayers(fail));
        })
    }
}

export function syncMapLayersToProjectAssignments() {
    return async(dispatch, getState) => {
        dispatch(setSyncStatusMapLayers(pending));
        const orgaId = getOrgaId(getState());

        await HTTP.get(API.organizations.mapLayers.syncMapLayerProjectAssignments(orgaId))
        .then(function (response) {
            // success
            dispatch(setSyncStatusMapLayersToProjectAssignment(success))
            dispatch(setMapLayersToProjectAssignment(response.data))
        })
        .catch(function (error) {
            // error 
            dispatch(setSyncStatusMapLayersToProjectAssignment(fail))
        })
    }
}

export function createMapLayer(map_layer) {
    const config = {
        headers: {
            "content-type": "multipart/form-data",
        },
    };

    return async(dispatch, getState) => {
        const orgaId = getOrgaId(getState());
        dispatch(uploadingMapLayerAction())

        await HTTP.post(
            API.organizations.mapLayers.createMapLayer(orgaId), 
            map_layer, 
            config)
        .then(function (response) {
            dispatch(renderSnackbar(createMapLayerSuccess));
            dispatch(uploadingMapLayerDoneAction())
        })
        .catch(function (error) {
            // handle 409(conflict) and 422(multiple reasons) error
            dispatch(renderSnackbar(createMapLayerFail));
            dispatch(uploadingMapLayerDoneAction())
        })
    }
}

export function createMapLayerToProjectAssignment(project, map_layer) {
    return async(dispatch, getState) => {
        await HTTP.post(API.projects.assignMapLayer(project.id, map_layer.uuid))
        .then(function (response) {
            // success
        })
        .catch(function (error) {
            // error
        })
    }
}

export function updateMapLayer(map_layer) {
    return async(dispatch, getState) => {
        const orgaId = getOrgaId(getState());

        await HTTP.put(API.organizations.mapLayers.updateMapLayer(orgaId, map_layer.uuid), {
            map_layer: map_layer
        })
        .then(function (response) {
            dispatch(renderSnackbar(updateMapLayerSuccess));
        })
        .catch(function (error) {
            dispatch(renderSnackbar(updateMapLayerFail));
        })
    }
}

export function deleteMapLayer(map_layer) {
    return async(dispatch, getState) => {
        const orgaId = getOrgaId(getState());

        await HTTP.delete(API.organizations.mapLayers.deleteMapLayer(orgaId, map_layer.uuid))
        .then(function (response) {
            dispatch(renderSnackbar(deleteMapLayerSuccess));
        })
        .catch(function (error) {
            dispatch(renderSnackbar(deleteMapLayerFail));
        })
    }
}

export function deleteMapLayerToProjectAssignment(projectId, mapLayerUuid) {
    return async(dispatch, getState) => {
        await HTTP.delete(API.projects.deleteMapLayerAssignment(projectId, mapLayerUuid))
        .then(function (response) {
            // success
        })
        .catch(function (error) {
            // error
        })
    }
}
