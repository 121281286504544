/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import React, { useState } from "react";

import withStyles from "@mui/styles/withStyles";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

import { 
    MAP_LAYER_TYPE_SELECTION_LIST,
    MAP_LAYER_ASSIGNMENT_TYPE_SELECTION_LIST,
    MAP_LAYER_REASON_TYPE_SELECTION_LIST
} from "../../../constants/mapLayerMapping";
import { validateTextFields } from "../../../sharedFunctions/validation";
import ButtonGroup from "../../../components/ButtonGroup";
import CustomDialog from "../../../components/CustomDialog";
import FileUploadComponent from "../../../components/FileUploadComponent";

import * as styles from "../../../styles/styles";


const propTypes = {
    classes: PropTypes.object,
    modalOpen: PropTypes.bool,
    onCloseModal: PropTypes.func,
    maxSize: PropTypes.number,
    acceptFileTypes: PropTypes.string,
    acceptedFileExtensions: PropTypes.array,
    onPositiveButtonClicked: PropTypes.func,
    onNegativeButtonClicked: PropTypes.func,
};

const overrideStyles = (theme) => ({
    alignText: {
        margin: "auto 0 0 0",
    },
});

const MapLayerUploadDialog = ({
    classes,
    modalOpen,
    onCloseModal,
    maxSize,
    acceptFileTypes,
    acceptedFileExtensions,
    onPositiveButtonClicked,
    onNegativeButtonClicked
}) => {
    const [stateMapLayerReasonType, setMapLayerReasonType] = useState("map_layer")
    const [stateMapLayerType, setMapLayerType] = useState("basemap")
    const [stateMapLayerAssignmentType, setMapLayerAssignmentType] = useState("global")
    const [stateDescription, setDescription] = useState("")
    const [stateFiles, setFiles] = useState([]);
    const [stateDisablePositiveButton, setDisablePositiveButton] = useState(true);

    const description = "";
    const mapLayerType = "";
    const mapLayerReasonType = "";
    const mapLayerAssignmentType = "";
    const fileList = "";

    const validationValues = React.useMemo(() => {
        return {
          description: {
            value: "",
            required: true,
          },
          mapLayerType: {
            value: "",
            required: true,
          },
          mapLayerReasonType: {
            value: "",
            required: true,
          },
          mapLayerAssignmentType: {
            value: "",
            required: true,
          },
          fileList: {
            value: "",
            required: true
          }
        };
      }, [
        description, 
        mapLayerType, 
        mapLayerReasonType, 
        mapLayerAssignmentType, 
        fileList]);

    const getOldValues = () => {
        return {
            description: stateDescription,
            mapLayerType: stateMapLayerType,
            mapLayerReasonType: stateMapLayerReasonType,
            mapLayerAssignmentType: stateMapLayerAssignmentType,
            fileList: stateFiles.length === 0 ? "" : `${stateFiles.length}`
        };
    };

    
    const changeMapLayerReasonType = (event) => {
        const name = event.target.value;
        const newValues = { ...getOldValues(), mapLayerReasonType: name };
        setMapLayerReasonType(name);
        setDisablePositiveButton(validateTextFields(validationValues, newValues));
    }

    const changeMapLayerType = (event) => {
        const name = event.target.value;
        const newValues = { ...getOldValues(), mapLayerType: name };
        setMapLayerType(name);
        setDisablePositiveButton(validateTextFields(validationValues, newValues));
    }

    const changeMapLayerAssignmentType = (event) => {
        const name = event.target.value;
        const newValues = { ...getOldValues(), mapLayerAssignmentType: name };
        setMapLayerAssignmentType(name);
        setDisablePositiveButton(validateTextFields(validationValues, newValues));
    }

    const changeDescription = (event) => {
        const name = event.target.value;
        const newValues = { ...getOldValues(), description: name };
        setDescription(name);
        setDisablePositiveButton(validateTextFields(validationValues, newValues));
    }

    const onFileSelected = (files) => {
        const name = files.length > 0 ? `${stateFiles.length + 1}` : "";
        const newValues = { ...getOldValues(), fileList: name };
        setFiles(files);
        setDisablePositiveButton(validateTextFields(validationValues, newValues));
    }

    const handleOnPositiveButtonClicked = () => {
        var myFormData = new FormData();
 
        myFormData.append(
            "map_layer[file]",
            stateFiles[0],
            stateFiles[0].name
        )

        myFormData.append(
            "map_layer[description]",
            stateDescription
        )
        
        // This is at the moment just a placeholder. 
        // Maybe we don't need this in the future.
        myFormData.append(
            "map_layer[map_file_type]",
            "tif"
        )

        myFormData.append(
            "map_layer[map_layer_type]",
            stateMapLayerType
        )

        myFormData.append(
            "map_layer[map_layer_reason_type]",
            stateMapLayerReasonType
        )

        myFormData.append(
            "map_layer[map_layer_assignment_type]",
            stateMapLayerAssignmentType
        )
        
        onPositiveButtonClicked(myFormData)
        onCloseModal();
    }

    return (
    <>
        <CustomDialog
            open={modalOpen}
            onClick={handleOnPositiveButtonClicked}
            onClose={onCloseModal}
            renderTitle={() => {
                return (
                    <DialogTitle id="alert-dialog-title">
                        {"Kartendatei hochladen"}
                    </DialogTitle>
                );
            }}
            renderContent={() => {
                return (
                    <DialogContent>
                        <div>
                        <div style={{ margin: `0 0 ${styles.spacing32} 0` }}>
                            <FileUploadComponent 
                                onFilesSelected={(files) => {
                                    onFileSelected(files)
                                }}
                                acceptFileTypes={acceptFileTypes}
                                acceptedFileExtensions={acceptedFileExtensions}
                                maxSize={maxSize}
                                titleText={"Mit Drag&Drop eine Datei hochladen"}
                                bodyText={`Maximal ${maxSize}MB pro Datei. Unterstützte Dateien sind *.ZIP, *.TIF, *.JSON und *.GEOJSON. Die ZIP-Datei muss eine DXF-Datei und eine JSON-Datei (Konfiguration der DXF-Datei) enthalten.`}/>
                            </div>
                            <div style={{ margin: `0 0 ${styles.spacing32} 0` }}>
                                <TextField
                                    fullWidth={true}
                                    InputProps={{ classes: { input: classes.alignText } }}
                                    margin="none"
                                    label="Beschreibung"
                                    multiline={true}
                                    onChange={changeDescription}
                                    placeholder="Beschreibung"
                                    required={true}
                                    minRows="5"
                                    variant="outlined"
                                    value={stateDescription}
                                />
                            </div>
                            <div style={{ margin: `0 0 ${styles.spacing32} 0` }}>
                                <FormControl fullWidth={true} size="medium">
                                    <InputLabel id="select_map_layer_reason_type_label">Kategorie *</InputLabel>
                                    <Select
                                        label="Kategorie"
                                        labelId="select_map_layer_reason_type_label"
                                        id="select_map_layer_reason_type"
                                        value={stateMapLayerReasonType}
                                        onChange={changeMapLayerReasonType}
                                        defaultValue={"map_layer"}
                                    >
                                         {
                                            MAP_LAYER_REASON_TYPE_SELECTION_LIST.map((item, index) => (
                                                <MenuItem key={index} value={item.value}>
                                                    {item.label}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </div>

                            <div style={{ margin: `0 0 ${styles.spacing32} 0` }}>
                                <FormControl fullWidth={true} size="medium">
                                    <InputLabel id="select_map_layer_type_label">Typ *</InputLabel>
                                    <Select
                                        label="Typ"
                                        labelId="select_map_layer_type_label"
                                        id="select_map_layer_type_type"
                                        value={stateMapLayerType}
                                        onChange={changeMapLayerType}
                                        defaultValue={"basemap"}
                                    >   
                                        {
                                            MAP_LAYER_TYPE_SELECTION_LIST.map((item, index) => (
                                                <MenuItem key={index} value={item.value}>
                                                    {item.label}
                                                </MenuItem>
                                            ))
                                        }
                                        
                                    </Select>
                                </FormControl>
                            </div>

                            <div style={{ margin: `0 0 ${styles.spacing32} 0` }}>
                                <FormControl fullWidth={true} size="medium">
                                    <InputLabel id="select_map_layer_assignment_type_label">Zuweisung *</InputLabel>
                                    <Select
                                        label="Zuweisung"
                                        labelId="select_map_layer_reason_type_label"
                                        id="select_map_layer_assignment_type"
                                        value={stateMapLayerAssignmentType}
                                        onChange={changeMapLayerAssignmentType}
                                        defaultValue={"global"}
                                    >
                                        {
                                            MAP_LAYER_ASSIGNMENT_TYPE_SELECTION_LIST.map((item, index) => (
                                                <MenuItem key={index} value={item.value}>
                                                    {item.label}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </DialogContent>
                );
            }}
            renderButtonGroup={(
                handleOnPositiveButtonClicked,
                onNegativeButtonClicked
              ) => {
                return (
                  <DialogActions style={{ padding: "2.4rem 2.4rem 2rem" }}>
                    <ButtonGroup
                      align="right"
                      leftButtonText="Abbrechen"
                      leftButtonClick={onNegativeButtonClicked}
                      rightButtonText={"Erstellen"}
                      rightButtonClick={handleOnPositiveButtonClicked}
                      rightButtonDisabled={stateDisablePositiveButton}
                      spacingTop={false}
                    />
                  </DialogActions>
                );
              }}
            
        />
    </>
)}


MapLayerUploadDialog.propTypes = propTypes;
export default withStyles(overrideStyles, { withTheme: true}) (
    MapLayerUploadDialog
)



