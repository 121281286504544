import * as turf from "@turf/turf";

import { german_boundary_low } from "./germany_boundary";

export const GERMANY = "germany";
export const UNDEFINED = "undefined";

export const checkCountry = (point) => {
    var germanBoundary = getGermanBoundary();

    if (turf.booleanPointInPolygon(point, germanBoundary)) {
        return GERMANY
    } else {
        UNDEFINED
    }
}

const getGermanBoundary = () => {
    return turf.multiPolygon(german_boundary_low.features[0].geometry.coordinates)
}

