/* eslint-disable no-unexpected-multiline */
import {
  SYSTEM_HOVER,
  COMPONENT_TYPE_HOVER,
  COMPONENT_TYPE_POINT,
  COMPONENT_TYPE_GNSS_POINT,
  COMPONENT_TYPE_GNSS_IMAGE,
  COMPONENT_TYPE_GNSS_REF_POINT,
  MOUSE_EVENT_ENTER,
  MOUSE_EVENT_LEAVE,
} from "../../../../../../../constants/ecs";

export default class SystemHover {
  constructor(params) {
    this.systemType = SYSTEM_HOVER;
    this.map = params.map;
  }

  /**
   * Registration process.
   * Register for every Entity which has the Component
   * ComponentHover enter and leave Mouse-Events.
   *
   * @param {Array} entities
   */
  run(entities) {
    for (var entity in entities) {
      this.runComponent(entities, entity, COMPONENT_TYPE_POINT)
      this.runComponent(entities, entity, COMPONENT_TYPE_GNSS_POINT)
      this.runComponent(entities, entity, COMPONENT_TYPE_GNSS_IMAGE)
      this.runComponent(entities, entity, COMPONENT_TYPE_GNSS_REF_POINT)
    }
  }

  runComponent(entities, entity, componentName) {
    if (
      entities[entity].getComponents()[COMPONENT_TYPE_HOVER] &&
      entities[entity].getComponents()[componentName]
    ) {
      this._setOnMouseOverEvent(
        entities[entity].getComponents()[componentName]
      );
    }
  }

  onGlobalHoverListEnterEvent(entities, elementId, componentType) {
    for (var entity in entities) {
      this.runOnGlobalHoverListEnterEvent(entities, entity, elementId, componentType, COMPONENT_TYPE_POINT)
      this.runOnGlobalHoverListEnterEvent(entities, entity, elementId, componentType, COMPONENT_TYPE_GNSS_POINT)
      this.runOnGlobalHoverListEnterEvent(entities, entity, elementId, componentType, COMPONENT_TYPE_GNSS_IMAGE)
      this.runOnGlobalHoverListEnterEvent(entities, entity, elementId, componentType, COMPONENT_TYPE_GNSS_REF_POINT)
    }
  }

  runOnGlobalHoverListEnterEvent(entities, entity, elementId, componentType, componentName) {
    if (
      entities[entity].getComponents()[COMPONENT_TYPE_HOVER] &&
      entities[entity].getComponents()[componentName] &&
      entities[entity]
        .getComponents()
        [componentName].getComponentName() === componentType &&
      entities[entity]
        .getComponents()
        [componentName].idList.includes(elementId)
    ) {
      const component =
        entities[entity].getComponents()[componentName];
      if (component.hoveredStateId !== -1) {
        this._deactivateHover(component.hoveredStateId, component);
      }
      this._activateHover(elementId, component);
      component.hoveredStateId = elementId;
    }
  }


  onGlobalHoverListLeaveEvent(entities, elementId, componentType) {
    for (var entity in entities) {
      this.runOnGlobalHoverListLeaveEvent(entities, entity, elementId, componentType, COMPONENT_TYPE_POINT)
      this.runOnGlobalHoverListLeaveEvent(entities, entity, elementId, componentType, COMPONENT_TYPE_GNSS_POINT)
      this.runOnGlobalHoverListLeaveEvent(entities, entity, elementId, componentType, COMPONENT_TYPE_GNSS_IMAGE)
      this.runOnGlobalHoverListLeaveEvent(entities, entity, elementId, componentType, COMPONENT_TYPE_GNSS_REF_POINT)
    }
  }

  runOnGlobalHoverListLeaveEvent(entities, entity, elementId, componentType, componentName) {
    if (
      entities[entity].getComponents()[COMPONENT_TYPE_HOVER] &&
      entities[entity].getComponents()[componentName] &&
      entities[entity]
        .getComponents()
        [componentName].getComponentName() === componentType &&
      entities[entity]
        .getComponents()
        [componentName].idList.includes(elementId)
    ) {
      const component =
        entities[entity].getComponents()[componentName];

      if (component.hoveredStateId !== -1) {
        this._deactivateHover(component.hoveredStateId, component);
      }
      component.hoveredStateId = -1;
    }
  }

  _setOnMouseOverEvent(component) {
    this.map.on(MOUSE_EVENT_ENTER, component.name, (event) => {
      this.map.getCanvas().style.cursor = "pointer";
      if (event.features.length > 0) {
        if (component.hoveredStateId !== -1) {
          this._deactivateHover(component.hoveredStateId, component);
        }
        
        //this._specialMouseEventEnterHandling(event, component)

        component.hoveredStateId = event.features[0].id;
        this._activateHover(component.hoveredStateId, component);
      }
    });

    this.map.on(MOUSE_EVENT_LEAVE, component.name, () => {
      this.map.getCanvas().style.cursor = "auto";
      if (component.hoveredStateId !== -1) {
        
        //this._specialMouseEventLeaveHandling(component)
        this._deactivateHover(component.hoveredStateId, component);
      }

      component.hoveredStateId = -1;
    });
  }

  _specialMouseEventEnterHandling(id, component) {
    if (component.componentName == COMPONENT_TYPE_GNSS_REF_POINT) {
      this.map.setLayoutProperty(component.name, 'icon-image', [
        'match',
        ['get', 'uuid'],
        id,
        'triangle-hover',
        'triangle'
      ])
    } else if (component.componentName == COMPONENT_TYPE_GNSS_IMAGE) {
      this.map.setLayoutProperty(component.name, 'icon-image', [
        'match',
        ['get', 'uuid'],
        id,
        'camera-hover',
        'camera'
      ])
    }
  }

  _specialMouseEventLeaveHandling(id, component) {
    if (component.componentName == COMPONENT_TYPE_GNSS_REF_POINT) {
      this.map.setLayoutProperty(component.name, 'icon-image', [
        'match',
        ['get', 'uuid'],
        id,
        'triangle',
        'triangle'
      ])
    } else if (component.componentName == COMPONENT_TYPE_GNSS_IMAGE) {
      this.map.setLayoutProperty(component.name, 'icon-image', [
        'match',
        ['get', 'uuid'],
        id,
        'camera',
        'camera'
      ])
    }
  }

  _activateHover(id, component) {
    this.map.setFeatureState(
      {
        source: component.name,
        id: id,
      },
      { hover: true }
    );

    this._specialMouseEventEnterHandling(id, component)
  }

  _deactivateHover(id, component) {
    this.map.setFeatureState(
      {
        source: component.name,
        id: id,
      },
      { hover: false }
    );

    this._specialMouseEventLeaveHandling(id, component)
  }
}
