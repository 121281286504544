import React from "react";
import PropTypes from "prop-types";

import SearchButton from "../";
import SearchResultsSmall from "./SearchResultsSmall";

import "./styles.css";

const propTypes = {
  getResults: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onClickOutside: PropTypes.func.isRequired,
  projects: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  results: PropTypes.object.isRequired,
  searchTerm: PropTypes.string.isRequired,
  search: PropTypes.func.isRequired,
  searchKeyList: PropTypes.object,
  placeholderText: PropTypes.string,
};

const defaultProps = {
  placeholderText: "Suche...",
};

class SearchButtonResults extends React.Component {
  constructor(props) {
    super(props);

    this.clickOutside = React.createRef();

    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClick, false);
  }

  handleClick(event) {
    this.clickOutside.current &&
      !this.clickOutside.current.contains(event.target) &&
      this.handleClickOutside();
  }

  handleClickOutside() {
    this.props.onClickOutside();
  }

  render() {
    const {
      projects,
      users,
      searchTerm,
      getResults,
      search,
      onClick,
      results,
      searchKeyList,
      placeholderText,
    } = this.props;
    return (
      <div ref={this.clickOutside}>
        <SearchButton
          showResults={true}
          projects={projects}
          users={users}
          results={getResults}
          searchTerm={searchTerm}
          searchKeyList={searchKeyList}
          search={search}
          placeholderText={placeholderText}
        >
          <SearchResultsSmall
            classNameContainer="project-search-position"
            onClick={onClick}
            projects={results}
          />
        </SearchButton>
      </div>
    );
  }
}

SearchButtonResults.propTypes = propTypes;
SearchButtonResults.defaultProps = defaultProps;
export default SearchButtonResults;
