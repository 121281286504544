/* eslint-disable import/namespace */
/* eslint-disable no-unused-vars */

import * as turfHelpers from "@turf/helpers";
import * as turf from "@turf/turf";
import proj4 from "proj4";

export const loadGridFile = async (gridName, url) => {
  const response = await fetch(url, {mode: 'no-cors'})
  console.log(response)
  if (!response.ok) {
    throw new Error(`Failed to load grid file: ${url}`);
  }

  const buffer = await response.arrayBuffer();

  proj4.nadgrid(gridName, buffer);
}

export const createGeoJsonLine = (points, filterValue) => {
  var filteredFeatures = []

  if (filterValue.length > 0) {
    filteredFeatures = points.features.filter(
      (feature) => feature.properties.type === "measure_point"
    );
  } else {
    filteredFeatures = points.features
  }

  const lineStrings = [];
  filteredFeatures.forEach((feature, index) => {
    if (index + 1 !== filteredFeatures.length)
      lineStrings.push(
        turf.lineString([
          feature.geometry.coordinates,
          filteredFeatures[index + 1].geometry.coordinates,
        ])
      );
  });
  return turf.featureCollection(lineStrings);
};

export const createGeoJsonPolygon = (points) => {
  const polygonPoints = []

  points.features.forEach((feature, index) => {
    polygonPoints.push(feature.geometry.coordinates)
  })

  if (points.features[0]) {
    polygonPoints.push(points.features[0].geometry.coordinates)
  }

  const polygon = turf.polygon([polygonPoints])

  return turf.featureCollection(polygon)
}

/**
 * This function takes a list of data objects. It is important that
 * a single object contains a valid utm_point and id key:value pair.
 * The opts parameter is used to filter out items from the dataList
 * and to create a valid id for every feature inside of the
 * resulting GeoJSON feature collection.
 * All coordinates are projected to the EPSG-Code 4326. That is the
 * coordinate system Mapbox can work with.
 *
 * @param {Array} dataList
 * @param {Object} opts
 * @returns a GeoJSON feature collection
 */
export const createGeoJsonPoints = (dataList, opts) => {
  var filtered = [];
  if (opts["filterBy"]) {
    filtered = Object.values(dataList).filter(
      (dataItem) => dataItem.type === opts.filterBy
    );
  } else {
    filtered = Object.values(dataList);
  }

  const toProjection = require("epsg-index/s/4326.json");

    return turf.featureCollection(
      filtered.map((dataItem) => {
        const point = dataItem;
        let latLng = getLatLngAlt(point);
        
        if (latLng === null) {
          try {
            const epsgCode = getCRS(point);
            const fromProjection = require(`epsg-index/s/${epsgCode}.json`);
            const proj4Coords = proj4(fromProjection.proj4, toProjection.proj4, [
              getX(point),
              getY(point),
            ]);

            return turfHelpers.point(proj4Coords, dataItem, {
                id: getId(dataItem)
              })
            
          } catch (error) {
            return turfHelpers.point([0, 0], dataItem, {
                id: getId(dataItem)
              })
          }
          
        } else {
          return turfHelpers.point(latLng, dataItem, {
            id: getId(dataItem)
          })
        }
      })
    );
};

const getLatLngAlt = (obj) => {
  if (obj.lat_lng_alt_coordinate) {
    return [
      obj.lat_lng_alt_coordinate[2],
      obj.lat_lng_alt_coordinate[1],
      obj.lat_lng_alt_coordinate[3],
    ];
  } else if (obj.lng_lat_alt_point) {
      return [
        obj.lng_lat_alt_point.coordinates[0],
        obj.lng_lat_alt_point.coordinates[1],
        obj.lng_lat_alt_point.coordinates[2],
      ];
  } else {
      return null;  // Wenn keines der Felder vorhanden ist
  }
}

const getId = (item) => {
  if ("id" in item) {
    return item.id
  } else if ("uuid" in item) {
    return item.uuid
  }
}

const getCRS = (item) => {
  if ("projected_coordinate" in item) {
    return item.projected_coordinate[0];
  } else if ("utm_point" in item) {
    const crsNameLong = item.utm_point.crs.properties.name;
    const epsgCode = crsNameLong.slice(
      crsNameLong.lastIndexOf(":") + 1,
      crsNameLong.length
    );
    return epsgCode
  }
}

const getX = (item) => {
  if ("projected_coordinate" in item) {
    return item.projected_coordinate[1];
  } else if ("utm_point" in item) {
    return item.utm_point.coordinates[0]
  }
}

const getY = (item) => {
  if ("projected_coordinate" in item) {
    return item.projected_coordinate[2];
  } else if ("utm_point" in item) {
    return item.utm_point.coordinates[1]
  }
}
