/* eslint-disable no-prototype-builtins */
export const validateTextFields = (validationValues, newValues) => {
  let keys = Object.keys(validationValues);
  let lengthCheck = false;
  let equalityCheckObject = {};

  for (let key of keys) {
    equalityCheckObject[key] = validationValues[key].value;
  }
  const equalityCheck =
    JSON.stringify(equalityCheckObject) === JSON.stringify(newValues);

  if (!equalityCheck) {
    for (let key of keys) {
      if (
        newValues.hasOwnProperty(key) &&
        newValues[key] != null &&
        newValues[key].length === 0 &&
        validationValues[key].required
      ) {
        lengthCheck = true;
        break;
      }
    }
  }
  return equalityCheck || lengthCheck;
};

export const isEmptyObject = (obj) => {
  var name;
  for (name in obj) {
    return false;
  }
  return true;
};
