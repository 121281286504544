/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useLocation } from "react-router-dom";
import List from "@mui/material/List";
import moment from "moment";

import { SEARCH_KEY_OBJECT } from "../../../../constants/project";
import { pending, success, fail, idle } from "../../../../constants/status";
import {
  PROJECTS_PER_PAGE,
  STANDARD_PROJECT_LIST_FLAG,
  ARCHIVED_PROJECT_LIST_FLAG,
  PROJECTS_ROUTE,
  PROJECTS_ARCHIVED_ROUTE,
} from "../../../../constants/projectList";

import { hasOrganizationFeatureFlag } from "../../../../sharedFunctions/organization";
import { PROJECT_RESTRICTED_SINGLE } from "../../../../constants/featureFlags";

import {
  selectProjects,
  unselectProjects,
  setPaginationPageCount,
  setTotalSortedAndFilteredProjectCount,
} from "../../../../actions/data";
import { syncRequestArchived } from "../../../../actions/user";
import { syncMeasureLayerConfigTemplates } from "../../../../actions/measureLayerConfigTemplates";

import {
  searchInImmutableList,
  filterImmutable,
} from "../../../../sharedFunctions/search";

import ProjectPreview from "../../../../components/ProjectPreview";

import RenderProjects from "./RenderProjects";
import UpgradePlanListItem from "./UpgradePlanListItem";

import "./styles.css";

const propTypes = {
  isProjectRestrictedSingle: PropTypes.bool,
  creatingProject: PropTypes.bool,
  dashboard: PropTypes.object,
  unfilteredProjects: PropTypes.object,
  users: PropTypes.array,
  projectStatus: PropTypes.string,
  selectProjects: PropTypes.func,
  unselectProjects: PropTypes.func,
  setPaginationPageCount: PropTypes.func,
  organization: PropTypes.object,
  searchTerm: PropTypes.string,
  syncRequestArchived: PropTypes.func,
  history: PropTypes.object,
  selectedProjects: PropTypes.array,
  archivedProjectSyncStatus: PropTypes.any,
  measureLayerConfigTemplatesSyncStatus: PropTypes.string,
  paginationState: PropTypes.object,
  setTotalSortedAndFilteredProjectCount: PropTypes.func,
  totalSortedAndFilteredProjectCount: PropTypes.number,
  syncStatusMeasureLayerTemplates: PropTypes.string,
  syncMeasureLayerConfigTemplates: PropTypes.func,
};

const ProjectList = ({
  isProjectRestrictedSingle,
  creatingProject,
  dashboard,
  history,
  users,
  unfilteredProjects,
  projectStatus,
  selectedProjects,
  selectProjects,
  unselectProjects,
  setPaginationPageCount,
  organization,
  searchTerm,
  archivedProjectSyncStatus,
  measureLayerConfigTemplatesSyncStatus,
  syncRequestArchived,
  paginationState,
  setTotalSortedAndFilteredProjectCount,
  totalSortedAndFilteredProjectCount,
  syncStatusMeasureLayerTemplates,
  syncMeasureLayerConfigTemplates,
}) => {
  let projectsByState;
  let projectListTypeFlag;
  let activePage;
  let oldPageCount = 0;
  let loadArchivedData = false;

  React.useEffect(() => {
    if (syncStatusMeasureLayerTemplates === idle) {
      syncMeasureLayerConfigTemplates();
    }
  }, [syncStatusMeasureLayerTemplates]);

  switch (useLocation().pathname) {
    case PROJECTS_ARCHIVED_ROUTE:
      projectListTypeFlag = ARCHIVED_PROJECT_LIST_FLAG;

      if (
        archivedProjectSyncStatus === null ||
        archivedProjectSyncStatus === fail
      ) {
        loadArchivedData = true;
      }

      projectsByState = filterImmutable(
        unfilteredProjects,
        "state",
        "archived",
        "projects"
      );

      activePage = paginationState.getIn(["archivedProjectList", "activePage"]);
      oldPageCount = paginationState.getIn([
        "archivedProjectList",
        "pageCount",
      ]);
      break;

    case PROJECTS_ROUTE:
    default:
      projectListTypeFlag = STANDARD_PROJECT_LIST_FLAG;

      projectsByState = filterImmutable(
        unfilteredProjects,
        "state",
        "open,done,in_progress,synced,upload",
        "projects"
      );

      activePage = paginationState.getIn(["standardProjectList", "activePage"]);
      oldPageCount = paginationState.getIn([
        "standardProjectList",
        "pageCount",
      ]);
      break;
  }

  const projects =
    searchTerm.length > 0
      ? searchInImmutableList(
          {
            projects: projectsByState,
            users: organization ? organization.get("users") : [],
          },
          SEARCH_KEY_OBJECT,
          searchTerm,
          "projects"
        )
      : projectsByState;

  const sortedData = React.useMemo(() => {
    if (dashboard === undefined || projects === undefined) return [];

    const descendingSorted = dashboard.getIn(["header", "descendingSorted"]);
    const sortValue = dashboard.getIn([
      "header",
      "currentDropdownSelection",
      "value",
    ]);
    const unknownUser = { first_name: "Unbekannter", last_name: "Nutzer" };
    let sortedData;

    sortedData = projects.sortBy((item) => {
      if (sortValue === "state") return item.get(sortValue);
      if (item.get(sortValue) == null) return 0;
      if (sortValue === "due_date") return moment(item.get(sortValue));
      if (sortValue === "inserted_at") return moment(item.get(sortValue));
      if (sortValue === "updated_at") return moment(item.get(sortValue));

      if (sortValue === "user_id") {
        const user =
          users.find((u) => u.id === item.get("user_id")) || unknownUser;
        const fullName = `${user.first_name} ${user.last_name}`;
        return fullName;
      }

      if (sortValue === "assigned_user_id") {
        const user =
          users.find((u) => u.id === item.get("assigned_user_id")) ||
          unknownUser;
        const fullName = `${user.first_name} ${user.last_name}`;
        return fullName;
      }
    });

    if (descendingSorted) sortedData = sortedData.reverse();

    return sortedData.valueSeq().toJS();
  }, [dashboard, users, projects]);

  useEffect(() => {
    if (loadArchivedData) {
      syncRequestArchived();
    }
  }, [loadArchivedData]);

  useEffect(() => {
    if (measureLayerConfigTemplatesSyncStatus === idle) {
      syncMeasureLayerConfigTemplates();
    }
  }, [measureLayerConfigTemplatesSyncStatus]);

  useEffect(() => {
    if (sortedData.length > 0 && projectStatus === success) {
      const pageCount = Math.ceil(sortedData.length / PROJECTS_PER_PAGE);

      const update = {
        key: projectListTypeFlag,
        value: pageCount,
      };

      if (oldPageCount !== pageCount) {
        setPaginationPageCount(update);
      }

      if (totalSortedAndFilteredProjectCount !== sortedData.length) {
        setTotalSortedAndFilteredProjectCount(sortedData.length);
      }
    }
  }, [sortedData]);

  const handleOnChangeCheckbox = React.useCallback(
    (args, projectInSelection) => {
      projectInSelection ? unselectProjects(args) : selectProjects(args);
    },
    [selectProjects, unselectProjects]
  );

  return (
    <>
      <List
        style={{ padding: "0.5rem 1rem" }}
        className={`project-list-list ${
          selectedProjects.length > 0 ? "header-open " : " "
        } 
        ${
          projectStatus === pending || projectStatus === fail
            ? "project-list-center-content"
            : "project-list-show-list"
        }`}
      >
        {creatingProject && <ProjectPreview />}

        {isProjectRestrictedSingle && unfilteredProjects.count() === 1 && (
          <UpgradePlanListItem />
        )}
        <RenderProjects
          users={users}
          selectedProjects={selectedProjects}
          projectStatus={projectStatus}
          sortedData={sortedData}
          projectListTypeFlag={projectListTypeFlag}
          handleOnChangeCheckbox={handleOnChangeCheckbox}
          history={history}
          projects={projects}
          activePage={activePage}
        />
      </List>
    </>
  );
};

function dispatchToProps(dispatch) {
  return bindActionCreators(
    {
      selectProjects,
      unselectProjects,
      setPaginationPageCount,
      syncRequestArchived,
      setTotalSortedAndFilteredProjectCount,
      syncMeasureLayerConfigTemplates,
    },
    dispatch
  );
}

function stateToProps(state) {
  const measureLayerConfigTemplatesSyncStatus = state.getIn([
    "measureLayerConfigTemplates",
    "measureLayerConfigTemplateSyncStatus",
  ]);
  const paginationState = state.getIn(["data", "pagination"]);
  const totalSortedAndFilteredProjectCount = state.getIn([
    "data",
    "totalSortedAndFilteredProjectCount",
  ]);
  const dashboard = state.get("dashboard");
  const organization = state.getIn(["organizations", "items"]).first();
  const users = organization ? organization.get("users").toJS() : [];
  const archivedProjectSyncStatus = state.getIn([
    "app",
    "archivedProjectSyncStatus",
  ]);
  const isProjectRestrictedSingle = hasOrganizationFeatureFlag(
    state,
    PROJECT_RESTRICTED_SINGLE
  );

  const syncStatusMeasureLayerTemplates = state.getIn([
    "measureLayerConfigTemplates",
    "measureLayerConfigTemplateSyncStatus",
  ]);

  return {
    isProjectRestrictedSingle,
    creatingProject: dashboard.getIn(["ui", "creatingProject"]),
    users: users,
    dashboard,
    unfilteredProjects: state.getIn(["projects", "items"]),
    projectStatus: state.getIn(["projects", "projectStatus"]),
    organization: organization,
    searchTerm: state.getIn(["data", "searchTerm"]),
    archivedProjectSyncStatus: archivedProjectSyncStatus,
    measureLayerConfigTemplatesSyncStatus,
    paginationState: paginationState,
    totalSortedAndFilteredProjectCount: totalSortedAndFilteredProjectCount,
    syncStatusMeasureLayerTemplates,
  };
}

ProjectList.propTypes = propTypes;
export default connect(stateToProps, dispatchToProps)(ProjectList);
