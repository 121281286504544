import {
  red500,
  pink500,
  purple500,
  deeppurple500,
  indigo500,
  blue500,
  lightblue500,
  cyan500,
  teal500,
  green500,
  lightgreen500,
  lime500,
  yellow500,
  amber500,
  orange500,
  deeporange500,
} from "../styles/styles";

export const COLOR_MAP = {
  red: red500,
  pink: pink500,
  purple: purple500,
  deep_purple: deeppurple500,
  indigo: indigo500,
  blue: blue500,
  light_blue: lightblue500,
  cyan: cyan500,
  teal: teal500,
  green: green500,
  light_green: lightgreen500,
  lime: lime500,
  yellow: yellow500,
  amber: amber500,
  orange: orange500,
  deep_orange: deeporange500,
};

export const COLOR_MAP_NAMES = {
  red: "Rot",
  pink: "Pink",
  purple: "Lila",
  deep_purple: "Dunkles Lila",
  indigo: "Indigo",
  blue: "Blau",
  light_blue: "Helles Blau",
  cyan: "Cyan",
  teal: "Türkis",
  green: "Grün",
  light_green: "Helles Grün",
  lime: "Limette",
  yellow: "Gelb",
  amber: "Amber",
  orange: "Orange",
  deep_orange: "Dunkles Orange",
};
