/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";

import ColoredCircle from "../../../../components/ColoredCircle";
import Confirmation from "../../../../components/dialogs/Confirmation";
import MeasureLayerConfigDialog from "../../../../containers/dialogs/MeasureLayerConfigDialog";

import { COLOR_MAP, COLOR_MAP_NAMES } from "../../../../constants/colormapping";
import { LAYER_TYPE_NAME_MAP } from "../../../../constants/measureLayerConfig";
import {
  createMeasureLayerConfigItem,
  updateMeasureLayerConfigItem,
  deleteMeasureLayerConfigItem,
} from "../../../../actions/measureLayerConfigTemplates";
import * as styles from "../../../../styles/styles";
import history from "../../../../lib/history";

const propTypes = {
  isAdmin: PropTypes.bool,
  catalogCategories: PropTypes.array,
  measureLayerConfigItemMap: PropTypes.object,
  measureLayerConfigTemplateUuid: PropTypes.string,
  createMeasureLayerConfigItem: PropTypes.func,
  updateMeasureLayerConfigItem: PropTypes.func,
  deleteMeasureLayerConfigItem: PropTypes.func,
};

const MeasureLayerConfigItemsTable = ({
  isAdmin,
  catalogCategories,
  measureLayerConfigItemMap,
  measureLayerConfigTemplateUuid,
  createMeasureLayerConfigItem,
  updateMeasureLayerConfigItem,
  deleteMeasureLayerConfigItem,
}) => {
  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [toModifyItemKey, setToModifyItemKey] = React.useState("");

  const closeModal = () => {
    setEditModalOpen(false);
    setDeleteModalOpen(false);
    setToModifyItemKey("");
  };

  const onClickMeasureLayerConfigDeleteItem = (measureLayerConfig) => {
    deleteMeasureLayerConfigItem(measureLayerConfig.uuid);
    closeModal();
  };

  const onUpdateMeasureLayerConfigItem = (payload) => {
    const data = {
      params: {
        ...payload,
      },
    };
    updateMeasureLayerConfigItem(payload.uuid, data);
  };

  const onEditClicked = (key) => {
    setEditModalOpen(true);
    setToModifyItemKey(key);
  };

  const onDeleteClicked = (key) => {
    setDeleteModalOpen(true);
    setToModifyItemKey(key);
  };

  const onGoToComponentCatalogClicked = (payload) => {
    history.push(`/configurator/component-catalogs/${payload}`);
  };

  return (
    <>
      {deleteModalOpen &&
        showDeleteModal(
          measureLayerConfigItemMap.get(toModifyItemKey),
          closeModal,
          deleteModalOpen,
          onClickMeasureLayerConfigDeleteItem
        )}
      {editModalOpen &&
        showEditModal(
          measureLayerConfigItemMap.get(toModifyItemKey),
          editModalOpen,
          closeModal,
          onUpdateMeasureLayerConfigItem
        )}
      <div>
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Typ</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Farbe</TableCell>
                <TableCell align="right">Bauteilliste</TableCell>
                {isAdmin && <TableCell align="right">Aktionen</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {measureLayerConfigItemMap &&
                createRows(
                  isAdmin,
                  catalogCategories,
                  measureLayerConfigItemMap,
                  onEditClicked,
                  onDeleteClicked,
                  onGoToComponentCatalogClicked
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

/**
 * This function takes all CatalogCategories as an Immutable Object and tries
 * to find the CatalogCategory Name from a given CatalogCategory UUID.
 *
 * @param {object} catalogCategories This is an Immutable Object holding all available CatalogCategories.
 * @param {string} catalogCategoryUuid The CatalogCategory UUID.
 * @returns The CatalogCategory Name as a String or an empty String if nothing was found.
 */
function extractComponentCategoryName(catalogCategories, catalogCategoryUuid) {
  const filteredData = catalogCategories.filter((item) => {
    return item.item.uuid === catalogCategoryUuid;
  });

  if (filteredData && filteredData.length === 1) {
    return filteredData[0].item.name;
  }

  return "";
}

function createRows(
  isAdmin,
  catalogCategories,
  measureLayerConfigItemMap,
  onEditClicked,
  onDeleteClicked,
  onGoToComponentCatalogClicked
) {
  const rowUiElements = [];
  let index = 1;

  for (const [key, value] of measureLayerConfigItemMap.entries()) {
    rowUiElements.push(
      <TableRow key={index}>
        <TableCell>
          <Typography variant="body2">{index}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">
            {LAYER_TYPE_NAME_MAP[value.layer_type]}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">{value.layer_name}</Typography>
        </TableCell>
        <TableCell>
          {COLOR_MAP[value.color] ? (
            <ColoredCircle
              color={COLOR_MAP[value.color]}
              name={COLOR_MAP_NAMES[value.color]}
              size={"xm"}
            />
          ) : (
            <ColoredCircle size={"xm"} />
          )}
        </TableCell>
        <TableCell align="right">
          {value.component_catalogs && value.component_catalogs.length > 0 && (
            <Tooltip
              title={extractComponentCategoryName(
                catalogCategories,
                value.component_catalogs[0]
              )}
              arrow
            >
              <IconButton
                aria-label="delete"
                onClick={() =>
                  onGoToComponentCatalogClicked(value.component_catalogs)
                }
                style={{
                  backgroundColor: styles.darkBlueSuperLight,
                  marginRight: styles.spacing16,
                }}
                size="large"
              >
                <CheckIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
        {isAdmin && (
          <TableCell align="right">
            <Tooltip title={"Editieren"} arrow>
              <IconButton
                aria-label="edit"
                onClick={() => onEditClicked(key)}
                style={{
                  backgroundColor: styles.darkBlueSuperLight,
                  marginRight: styles.spacing16,
                }}
                size="large"
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            {!value.required && (
              <Tooltip title={"Löschen"} arrow>
                <IconButton
                  aria-label="delete"
                  onClick={() => onDeleteClicked(key)}
                  style={{
                    backgroundColor: styles.darkBlueSuperLight,
                    marginRight: styles.spacing16,
                  }}
                  size="large"
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </TableCell>
        )}
      </TableRow>
    );
    index++;
  }

  return rowUiElements;
}

function showEditModal(
  measureLayerConfig,
  editModalOpen,
  closeModal,
  onUpdateMeasureLayerConfigItem
) {
  return (
    <MeasureLayerConfigDialog
      measureLayerConfig={measureLayerConfig}
      measureLayerConfigs={{}} // this is not correct. Correct implementation needed!
      edit={true}
      modalOpen={editModalOpen}
      onClose={() => closeModal()}
      onClick={() => closeModal()}
      onUpdateMeasureLayerConfig={onUpdateMeasureLayerConfigItem}
    />
  );
}

function showDeleteModal(
  measureLayerConfig,
  closeModal,
  deleteModalOpen,
  onClickMeasureLayerConfigDelete
) {
  return (
    <Confirmation
      highlightedText={measureLayerConfig.layer_name}
      headline="Layer löschen"
      leftSideText={`Möchten Sie den Layer`}
      modalOpen={deleteModalOpen}
      onClose={() => closeModal()}
      onClick={() => onClickMeasureLayerConfigDelete(measureLayerConfig)}
      rightSideText={`erstellt am ${moment(
        measureLayerConfig.inserted_at
      ).format("DD.MM.YYYY")} wirklich löschen?`}
      textButton="Löschen"
    />
  );
}

function dispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createMeasureLayerConfigItem,
      updateMeasureLayerConfigItem,
      deleteMeasureLayerConfigItem,
    },
    dispatch
  );
}

function stateToProps(state, ownProps) {
  const catalogCategories = state.getIn([
    "componentCatalogs",
    "items",
    "catalogCategories",
  ]);

  return {
    catalogCategories:
      (catalogCategories.size && catalogCategories.size === 0) === 0
        ? []
        : catalogCategories,
  };
}

MeasureLayerConfigItemsTable.propTypes = propTypes;
export default connect(
  stateToProps,
  dispatchToProps
)(MeasureLayerConfigItemsTable);
