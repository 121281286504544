export const german_boundary_low = {
    "type": "FeatureCollection",
    "features": [
        {
            "type": "Feature",
            "id": 0,
            "properties": {},
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [
                    [
                        [
                            [
                                8.4072979,
                                55.0439523
                            ],
                            [
                                8.4425001,
                                55.0159721
                            ],
                            [
                                8.3536091,
                                54.9673615
                            ],
                            [
                                8.3669443,
                                54.9023628
                            ],
                            [
                                8.4297209,
                                54.8776398
                            ],
                            [
                                8.8120394,
                                54.9167366
                            ],
                            [
                                9.2247791,
                                54.8559532
                            ],
                            [
                                9.2825918,
                                54.8022346
                            ],
                            [
                                9.40868,
                                54.8411713
                            ],
                            [
                                9.4352779,
                                54.7884712
                            ],
                            [
                                9.6136112,
                                54.8759727
                            ],
                            [
                                9.603056,
                                54.8315277
                            ],
                            [
                                9.8430557,
                                54.7562485
                            ],
                            [
                                9.9552794,
                                54.7801399
                            ],
                            [
                                10.0347223,
                                54.6723595
                            ],
                            [
                                9.9830542,
                                54.7012482
                            ],
                            [
                                9.9638917,
                                54.6730363
                            ],
                            [
                                10.0341663,
                                54.6698608
                            ],
                            [
                                10.0275002,
                                54.550415
                            ],
                            [
                                9.8402777,
                                54.4673615
                            ],
                            [
                                10.1991673,
                                54.4559708
                            ],
                            [
                                10.1319456,
                                54.3112488
                            ],
                            [
                                10.2280569,
                                54.4134712
                            ],
                            [
                                10.3186121,
                                54.4356956
                            ],
                            [
                                10.7047224,
                                54.304863
                            ],
                            [
                                10.9286108,
                                54.3818054
                            ],
                            [
                                11.1280556,
                                54.3906937
                            ],
                            [
                                11.05861,
                                54.3543053
                            ],
                            [
                                11.0936108,
                                54.1979179
                            ],
                            [
                                10.7541656,
                                54.054863
                            ],
                            [
                                10.8908319,
                                53.9556961
                            ],
                            [
                                11.1791677,
                                54.0156937
                            ],
                            [
                                11.2586107,
                                53.9848595
                            ],
                            [
                                11.2586107,
                                53.9340286
                            ],
                            [
                                11.3352766,
                                53.9584732
                            ],
                            [
                                11.4547224,
                                53.9004173
                            ],
                            [
                                11.4836102,
                                53.9684715
                            ],
                            [
                                11.3780556,
                                53.9973602
                            ],
                            [
                                11.4925013,
                                54.0229149
                            ],
                            [
                                11.4902792,
                                53.9681931
                            ],
                            [
                                11.6258326,
                                54.0895844
                            ],
                            [
                                11.5252771,
                                54.071804
                            ],
                            [
                                11.6824989,
                                54.1531944
                            ],
                            [
                                12.0874987,
                                54.1831932
                            ],
                            [
                                12.1247215,
                                54.1501389
                            ],
                            [
                                12.0952778,
                                54.1809731
                            ],
                            [
                                12.3391657,
                                54.2979164
                            ],
                            [
                                12.519721,
                                54.4843063
                            ],
                            [
                                12.9624996,
                                54.4376373
                            ],
                            [
                                12.6835146,
                                54.4064435
                            ],
                            [
                                12.7152777,
                                54.4043045
                            ],
                            [
                                12.6780548,
                                54.3701401
                            ],
                            [
                                12.7869453,
                                54.3962517
                            ],
                            [
                                12.8102789,
                                54.3451385
                            ],
                            [
                                13.019722,
                                54.4390259
                            ],
                            [
                                13.0936098,
                                54.366806
                            ],
                            [
                                13.1058331,
                                54.2818069
                            ],
                            [
                                13.2863884,
                                54.2351379
                            ],
                            [
                                13.3463888,
                                54.1804161
                            ],
                            [
                                13.3180542,
                                54.1598625
                            ],
                            [
                                13.4158344,
                                54.1751404
                            ],
                            [
                                13.3824987,
                                54.1423607
                            ],
                            [
                                13.4569454,
                                54.0906944
                            ],
                            [
                                13.6963892,
                                54.1718063
                            ],
                            [
                                13.8069448,
                                54.1031952
                            ],
                            [
                                13.7441664,
                                54.0293045
                            ],
                            [
                                13.9141674,
                                53.9223595
                            ],
                            [
                                13.8247223,
                                53.8662491
                            ],
                            [
                                13.937501,
                                53.908474
                            ],
                            [
                                13.9058342,
                                53.9898605
                            ],
                            [
                                13.9652786,
                                53.990139
                            ],
                            [
                                13.9597216,
                                53.9340286
                            ],
                            [
                                14.0424995,
                                53.9420815
                            ],
                            [
                                14.0469446,
                                53.9965286
                            ],
                            [
                                14.0034786,
                                54.0366769
                            ],
                            [
                                14.2213898,
                                53.9301376
                            ],
                            [
                                14.1863298,
                                53.9155807
                            ],
                            [
                                14.2173367,
                                53.8654175
                            ],
                            [
                                13.8063889,
                                53.8581963
                            ],
                            [
                                14.0386124,
                                53.7551384
                            ],
                            [
                                14.2830563,
                                53.7387505
                            ],
                            [
                                14.2152767,
                                53.7026405
                            ],
                            [
                                14.2731628,
                                53.6993065
                            ],
                            [
                                14.3249083,
                                53.6186485
                            ],
                            [
                                14.3027086,
                                53.5426102
                            ],
                            [
                                14.4489298,
                                53.2616386
                            ],
                            [
                                14.3789186,
                                53.2041588
                            ],
                            [
                                14.3457031,
                                53.0529175
                            ],
                            [
                                14.1424522,
                                52.961113
                            ],
                            [
                                14.1212702,
                                52.840271
                            ],
                            [
                                14.6390619,
                                52.5800323
                            ],
                            [
                                14.6006041,
                                52.5330238
                            ],
                            [
                                14.6312494,
                                52.4991302
                            ],
                            [
                                14.5289087,
                                52.3964119
                            ],
                            [
                                14.5709,
                                52.2895622
                            ],
                            [
                                14.6995707,
                                52.2410889
                            ],
                            [
                                14.6693487,
                                52.1215515
                            ],
                            [
                                14.7412786,
                                52.073391
                            ],
                            [
                                14.7047777,
                                51.9426613
                            ],
                            [
                                14.5867014,
                                51.8236046
                            ],
                            [
                                14.7387285,
                                51.6668701
                            ],
                            [
                                14.6981392,
                                51.5585098
                            ],
                            [
                                14.9338589,
                                51.4822693
                            ],
                            [
                                14.9678183,
                                51.3544159
                            ],
                            [
                                15.0284796,
                                51.3097992
                            ],
                            [
                                14.9301119,
                                50.9914055
                            ],
                            [
                                14.8050795,
                                50.8289185
                            ],
                            [
                                14.7108707,
                                50.8267593
                            ],
                            [
                                14.6119289,
                                50.8547821
                            ],
                            [
                                14.6516724,
                                50.9326401
                            ],
                            [
                                14.560113,
                                50.9234848
                            ],
                            [
                                14.5950556,
                                50.9885101
                            ],
                            [
                                14.5016804,
                                51.051506
                            ],
                            [
                                14.3974104,
                                51.0082817
                            ],
                            [
                                14.2940168,
                                51.0541649
                            ],
                            [
                                14.2468681,
                                50.9732018
                            ],
                            [
                                14.4009466,
                                50.9423485
                            ],
                            [
                                14.3722687,
                                50.8885803
                            ],
                            [
                                13.9546099,
                                50.8037109
                            ],
                            [
                                13.8508091,
                                50.7182007
                            ],
                            [
                                13.5489759,
                                50.7132149
                            ],
                            [
                                13.4651909,
                                50.596489
                            ],
                            [
                                13.3749104,
                                50.6436615
                            ],
                            [
                                13.3267879,
                                50.5818138
                            ],
                            [
                                13.2486181,
                                50.5922699
                            ],
                            [
                                13.1959906,
                                50.5005913
                            ],
                            [
                                13.033267,
                                50.5085487
                            ],
                            [
                                12.977046,
                                50.4142723
                            ],
                            [
                                12.8287697,
                                50.458622
                            ],
                            [
                                12.7051287,
                                50.3977585
                            ],
                            [
                                12.5161133,
                                50.4000854
                            ],
                            [
                                12.3640881,
                                50.2764244
                            ],
                            [
                                12.32759,
                                50.1797295
                            ],
                            [
                                12.2827158,
                                50.1826782
                            ],
                            [
                                12.1940918,
                                50.322876
                            ],
                            [
                                12.0858603,
                                50.255352
                            ],
                            [
                                12.1989193,
                                50.1956215
                            ],
                            [
                                12.1990604,
                                50.1118202
                            ],
                            [
                                12.2560301,
                                50.0622787
                            ],
                            [
                                12.5477362,
                                49.9271431
                            ],
                            [
                                12.4720726,
                                49.7902718
                            ],
                            [
                                12.4028902,
                                49.7551613
                            ],
                            [
                                12.5278597,
                                49.6877594
                            ],
                            [
                                12.5880518,
                                49.5439987
                            ],
                            [
                                12.6458302,
                                49.5310593
                            ],
                            [
                                12.6610746,
                                49.4321671
                            ],
                            [
                                12.7841053,
                                49.3519058
                            ],
                            [
                                13.0335894,
                                49.3086395
                            ],
                            [
                                13.1802101,
                                49.1444397
                            ],
                            [
                                13.4037294,
                                49.0517807
                            ],
                            [
                                13.4016209,
                                48.9839134
                            ],
                            [
                                13.6312599,
                                48.9505806
                            ],
                            [
                                13.8359575,
                                48.7750511
                            ],
                            [
                                13.7870588,
                                48.7215118
                            ],
                            [
                                13.8099442,
                                48.5909042
                            ],
                            [
                                13.7210922,
                                48.5167923
                            ],
                            [
                                13.5031586,
                                48.5965118
                            ],
                            [
                                13.4357491,
                                48.564682
                            ],
                            [
                                13.4106188,
                                48.377739
                            ],
                            [
                                13.2857199,
                                48.305172
                            ],
                            [
                                12.8682146,
                                48.2036667
                            ],
                            [
                                12.7530289,
                                48.1172905
                            ],
                            [
                                13.0011473,
                                47.8522301
                            ],
                            [
                                12.9112673,
                                47.7312431
                            ],
                            [
                                13.043539,
                                47.7209892
                            ],
                            [
                                13.1055889,
                                47.6392021
                            ],
                            [
                                13.013464,
                                47.4657669
                            ],
                            [
                                12.799818,
                                47.5614624
                            ],
                            [
                                12.8266773,
                                47.6162605
                            ],
                            [
                                12.7827721,
                                47.6759224
                            ],
                            [
                                12.6053343,
                                47.6792488
                            ],
                            [
                                12.5060644,
                                47.6288528
                            ],
                            [
                                12.4359627,
                                47.7007332
                            ],
                            [
                                12.2587795,
                                47.6762199
                            ],
                            [
                                12.2542791,
                                47.7399902
                            ],
                            [
                                12.1743517,
                                47.6988754
                            ],
                            [
                                12.20928,
                                47.6012001
                            ],
                            [
                                11.636344,
                                47.5982704
                            ],
                            [
                                11.5810204,
                                47.5118217
                            ],
                            [
                                11.4373798,
                                47.5132599
                            ],
                            [
                                11.388032,
                                47.4719238
                            ],
                            [
                                11.4240808,
                                47.4456215
                            ],
                            [
                                11.3416061,
                                47.4518242
                            ],
                            [
                                11.2739,
                                47.3910103
                            ],
                            [
                                11.2241392,
                                47.3912697
                            ],
                            [
                                11.2460585,
                                47.4347801
                            ],
                            [
                                10.9775209,
                                47.3961105
                            ],
                            [
                                10.9265089,
                                47.4780807
                            ],
                            [
                                10.862999,
                                47.4780312
                            ],
                            [
                                10.9185944,
                                47.5160942
                            ],
                            [
                                10.8831329,
                                47.538105
                            ],
                            [
                                10.7720251,
                                47.5161438
                            ],
                            [
                                10.6000605,
                                47.5736504
                            ],
                            [
                                10.5613813,
                                47.5359306
                            ],
                            [
                                10.4322453,
                                47.5855598
                            ],
                            [
                                10.4715691,
                                47.4330635
                            ],
                            [
                                10.4335804,
                                47.3787193
                            ],
                            [
                                10.1701689,
                                47.2699013
                            ],
                            [
                                10.2268696,
                                47.3929176
                            ],
                            [
                                10.0959606,
                                47.3548698
                            ],
                            [
                                10.0907555,
                                47.4565926
                            ],
                            [
                                9.9973383,
                                47.4862251
                            ],
                            [
                                9.9711866,
                                47.5504837
                            ],
                            [
                                9.8736696,
                                47.5307198
                            ],
                            [
                                9.7742186,
                                47.5968018
                            ],
                            [
                                9.6887321,
                                47.5439835
                            ],
                            [
                                9.0440149,
                                47.8236885
                            ],
                            [
                                9.2211399,
                                47.6681519
                            ],
                            [
                                9.1640949,
                                47.6535835
                            ],
                            [
                                8.9916401,
                                47.7479858
                            ],
                            [
                                8.9413652,
                                47.731823
                            ],
                            [
                                9.0063696,
                                47.6950912
                            ],
                            [
                                8.8918343,
                                47.6552238
                            ],
                            [
                                8.8082161,
                                47.7416801
                            ],
                            [
                                8.7711582,
                                47.7197685
                            ],
                            [
                                8.7981482,
                                47.6799088
                            ],
                            [
                                8.72789,
                                47.6968422
                            ],
                            [
                                8.7304459,
                                47.7661095
                            ],
                            [
                                8.5679913,
                                47.8143768
                            ],
                            [
                                8.404398,
                                47.6800499
                            ],
                            [
                                8.4736786,
                                47.6433563
                            ],
                            [
                                8.6071491,
                                47.6759949
                            ],
                            [
                                8.5846863,
                                47.6003113
                            ],
                            [
                                8.5209141,
                                47.6380959
                            ],
                            [
                                8.4585485,
                                47.605957
                            ],
                            [
                                8.4885035,
                                47.5813942
                            ],
                            [
                                8.3795404,
                                47.5702515
                            ],
                            [
                                8.2028732,
                                47.6261559
                            ],
                            [
                                8.0878344,
                                47.5628853
                            ],
                            [
                                7.9440718,
                                47.5497017
                            ],
                            [
                                7.8207722,
                                47.5946999
                            ],
                            [
                                7.6694932,
                                47.537117
                            ],
                            [
                                7.6323838,
                                47.5624237
                            ],
                            [
                                7.6705608,
                                47.5932617
                            ],
                            [
                                7.6077709,
                                47.5809593
                            ],
                            [
                                7.5121269,
                                47.6960907
                            ],
                            [
                                7.6221571,
                                47.9736595
                            ],
                            [
                                7.5685902,
                                48.0363388
                            ],
                            [
                                7.5778594,
                                48.1213913
                            ],
                            [
                                7.7452316,
                                48.3298264
                            ],
                            [
                                7.7335467,
                                48.3986855
                            ],
                            [
                                7.8359227,
                                48.6336746
                            ],
                            [
                                8.0870152,
                                48.8020134
                            ],
                            [
                                8.228879,
                                48.9706306
                            ],
                            [
                                7.9370403,
                                49.0562325
                            ],
                            [
                                7.6352863,
                                49.0541687
                            ],
                            [
                                7.4455862,
                                49.1840248
                            ],
                            [
                                7.2934003,
                                49.1151581
                            ],
                            [
                                7.0981507,
                                49.1543312
                            ],
                            [
                                7.0580244,
                                49.112587
                            ],
                            [
                                7.0337062,
                                49.1882629
                            ],
                            [
                                6.9242954,
                                49.2230759
                            ],
                            [
                                6.8404441,
                                49.2142334
                            ],
                            [
                                6.8344626,
                                49.1513786
                            ],
                            [
                                6.7379875,
                                49.1645699
                            ],
                            [
                                6.535419,
                                49.4341621
                            ],
                            [
                                6.3548212,
                                49.4649849
                            ],
                            [
                                6.3636479,
                                49.5740471
                            ],
                            [
                                6.5164852,
                                49.7241783
                            ],
                            [
                                6.5282521,
                                49.8085709
                            ],
                            [
                                6.3122811,
                                49.8354988
                            ],
                            [
                                6.0983701,
                                50.0599098
                            ],
                            [
                                6.1896381,
                                50.1894646
                            ],
                            [
                                6.170382,
                                50.2362556
                            ],
                            [
                                6.40834,
                                50.3330688
                            ],
                            [
                                6.3397579,
                                50.3798943
                            ],
                            [
                                6.3746719,
                                50.4459496
                            ],
                            [
                                6.3300281,
                                50.4936447
                            ],
                            [
                                6.172194,
                                50.5505142
                            ],
                            [
                                6.278379,
                                50.6163979
                            ],
                            [
                                6.1730871,
                                50.6214333
                            ],
                            [
                                6.118732,
                                50.7087364
                            ],
                            [
                                5.9631991,
                                50.7950516
                            ],
                            [
                                6.0738401,
                                50.846859
                            ],
                            [
                                6.0829401,
                                50.9217987
                            ],
                            [
                                6.0151701,
                                50.9331589
                            ],
                            [
                                6.0300012,
                                50.9833641
                            ],
                            [
                                5.9036908,
                                50.9782715
                            ],
                            [
                                5.8720589,
                                51.0434113
                            ],
                            [
                                5.9695439,
                                51.0344696
                            ],
                            [
                                6.1717992,
                                51.1529312
                            ],
                            [
                                6.1447802,
                                51.1737175
                            ],
                            [
                                6.1931391,
                                51.1916618
                            ],
                            [
                                6.0918341,
                                51.175293
                            ],
                            [
                                6.078186,
                                51.2447128
                            ],
                            [
                                6.2319689,
                                51.3659821
                            ],
                            [
                                6.220356,
                                51.5091705
                            ],
                            [
                                6.0909581,
                                51.6052208
                            ],
                            [
                                6.1187692,
                                51.6604576
                            ],
                            [
                                5.9640079,
                                51.7416115
                            ],
                            [
                                6.0047779,
                                51.7681694
                            ],
                            [
                                5.9646492,
                                51.8244095
                            ],
                            [
                                6.168982,
                                51.8450317
                            ],
                            [
                                6.1071491,
                                51.8889885
                            ],
                            [
                                6.1588898,
                                51.9053841
                            ],
                            [
                                6.4174671,
                                51.825634
                            ],
                            [
                                6.4022999,
                                51.8748016
                            ],
                            [
                                6.7427092,
                                51.8990517
                            ],
                            [
                                6.835361,
                                51.9955292
                            ],
                            [
                                6.6981788,
                                52.0401192
                            ],
                            [
                                6.7006888,
                                52.0737915
                            ],
                            [
                                7.0693092,
                                52.239254
                            ],
                            [
                                7.0297189,
                                52.2943192
                            ],
                            [
                                7.0791159,
                                52.3827248
                            ],
                            [
                                7.0062799,
                                52.4695015
                            ],
                            [
                                6.9505391,
                                52.4369698
                            ],
                            [
                                6.7648621,
                                52.4649315
                            ],
                            [
                                6.6837912,
                                52.5560646
                            ],
                            [
                                6.768261,
                                52.5651665
                            ],
                            [
                                6.724298,
                                52.5906105
                            ],
                            [
                                6.7438102,
                                52.6470909
                            ],
                            [
                                7.0518599,
                                52.635849
                            ],
                            [
                                7.0942769,
                                52.8464508
                            ],
                            [
                                7.261488,
                                52.9975395
                            ],
                            [
                                7.2269688,
                                53.1244621
                            ],
                            [
                                7.2845602,
                                53.1995697
                            ],
                            [
                                7.2052779,
                                53.2388077
                            ],
                            [
                                7.249167,
                                53.3298607
                            ],
                            [
                                6.998611,
                                53.3612518
                            ],
                            [
                                7.0341668,
                                53.5331955
                            ],
                            [
                                7.1336112,
                                53.5323601
                            ],
                            [
                                7.0902772,
                                53.5765266
                            ],
                            [
                                7.1580539,
                                53.6279182
                            ],
                            [
                                7.3169441,
                                53.6834717
                            ],
                            [
                                8.0158319,
                                53.7106934
                            ],
                            [
                                8.1724997,
                                53.5545845
                            ],
                            [
                                8.1552763,
                                53.5137482
                            ],
                            [
                                8.0647221,
                                53.5059738
                            ],
                            [
                                8.0736113,
                                53.4648628
                            ],
                            [
                                8.2524996,
                                53.3990288
                            ],
                            [
                                8.3163891,
                                53.4662514
                            ],
                            [
                                8.3163891,
                                53.5220833
                            ],
                            [
                                8.2308321,
                                53.5204163
                            ],
                            [
                                8.2719431,
                                53.6098595
                            ],
                            [
                                8.5163898,
                                53.5562515
                            ],
                            [
                                8.5569448,
                                53.5256958
                            ],
                            [
                                8.519722,
                                53.5009727
                            ],
                            [
                                8.5708342,
                                53.5181961
                            ],
                            [
                                8.4836111,
                                53.6943054
                            ],
                            [
                                8.6080561,
                                53.8787498
                            ],
                            [
                                8.8836107,
                                53.8279152
                            ],
                            [
                                9.0997229,
                                53.862915
                            ],
                            [
                                8.9636106,
                                53.8945847
                            ],
                            [
                                8.8197222,
                                54.0215263
                            ],
                            [
                                8.9825001,
                                54.0465279
                            ],
                            [
                                8.9274998,
                                54.1318054
                            ],
                            [
                                8.8591671,
                                54.1226387
                            ],
                            [
                                8.8074989,
                                54.1731949
                            ],
                            [
                                8.8358326,
                                54.2518044
                            ],
                            [
                                8.9519444,
                                54.3128929
                            ],
                            [
                                8.8463879,
                                54.2629166
                            ],
                            [
                                8.5802784,
                                54.3040276
                            ],
                            [
                                8.604166,
                                54.3579178
                            ],
                            [
                                8.685832,
                                54.3570824
                            ],
                            [
                                8.6086102,
                                54.3862495
                            ],
                            [
                                8.8936119,
                                54.4120827
                            ],
                            [
                                9.0236111,
                                54.4726372
                            ],
                            [
                                8.9891672,
                                54.5193062
                            ],
                            [
                                8.9036102,
                                54.4606934
                            ],
                            [
                                8.8063889,
                                54.470417
                            ],
                            [
                                8.8902769,
                                54.5926399
                            ],
                            [
                                8.813056,
                                54.5973625
                            ],
                            [
                                8.8225002,
                                54.6459732
                            ],
                            [
                                8.6874981,
                                54.7298622
                            ],
                            [
                                8.5902767,
                                54.8851395
                            ],
                            [
                                8.4163876,
                                54.847084
                            ],
                            [
                                8.3102779,
                                54.8743057
                            ],
                            [
                                8.2797222,
                                54.7518044
                            ],
                            [
                                8.2980547,
                                54.9093056
                            ],
                            [
                                8.4072979,
                                55.0439523
                            ]
                        ],
                        [
                            [
                                12.6459905,
                                54.4022479
                            ],
                            [
                                12.435833,
                                54.3787498
                            ],
                            [
                                12.3636122,
                                54.2659721
                            ],
                            [
                                12.4602766,
                                54.2484741
                            ],
                            [
                                12.4091673,
                                54.2798615
                            ],
                            [
                                12.6459905,
                                54.4022479
                            ]
                        ],
                        [
                            [
                                9.9412968,
                                54.639771
                            ],
                            [
                                9.9330568,
                                54.6276398
                            ],
                            [
                                9.8590766,
                                54.5913955
                            ],
                            [
                                9.938055,
                                54.6234741
                            ],
                            [
                                9.9412968,
                                54.639771
                            ]
                        ],
                        [
                            [
                                9.7405052,
                                54.5333051
                            ],
                            [
                                9.7124996,
                                54.5195847
                            ],
                            [
                                9.5452776,
                                54.509304
                            ],
                            [
                                9.5741663,
                                54.4751396
                            ],
                            [
                                9.6241655,
                                54.511528
                            ],
                            [
                                9.7147226,
                                54.4912491
                            ],
                            [
                                9.7405052,
                                54.5333051
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                13.9476278,
                                54.063983
                            ],
                            [
                                13.9108324,
                                54.0643044
                            ],
                            [
                                13.8625011,
                                53.9993057
                            ],
                            [
                                13.8580551,
                                54.0484734
                            ],
                            [
                                13.769166,
                                54.0190277
                            ],
                            [
                                13.812499,
                                54.0990295
                            ],
                            [
                                13.7491674,
                                54.1590271
                            ],
                            [
                                13.8036108,
                                54.1784706
                            ],
                            [
                                13.8708344,
                                54.1015282
                            ],
                            [
                                13.9476278,
                                54.063983
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                8.4117852,
                                55.0494831
                            ],
                            [
                                8.4174995,
                                55.0565262
                            ],
                            [
                                8.4630556,
                                55.0456963
                            ],
                            [
                                8.4117852,
                                55.0494831
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                13.406171,
                                54.5965605
                            ],
                            [
                                13.3702784,
                                54.6145821
                            ],
                            [
                                13.2430553,
                                54.5587502
                            ],
                            [
                                13.2830563,
                                54.6462517
                            ],
                            [
                                13.1608334,
                                54.5590286
                            ],
                            [
                                13.2497244,
                                54.6598625
                            ],
                            [
                                13.4291668,
                                54.6845818
                            ],
                            [
                                13.3758326,
                                54.6351395
                            ],
                            [
                                13.406171,
                                54.5965605
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                13.4462989,
                                54.5764124
                            ],
                            [
                                13.6797209,
                                54.5626373
                            ],
                            [
                                13.5697212,
                                54.4618073
                            ],
                            [
                                13.7669439,
                                54.341526
                            ],
                            [
                                13.7252789,
                                54.2734718
                            ],
                            [
                                13.646389,
                                54.2965279
                            ],
                            [
                                13.7041674,
                                54.3262482
                            ],
                            [
                                13.6108322,
                                54.3162498
                            ],
                            [
                                13.6830559,
                                54.3493042
                            ],
                            [
                                13.5808344,
                                54.3529167
                            ],
                            [
                                13.352499,
                                54.2695847
                            ],
                            [
                                13.4180555,
                                54.2548599
                            ],
                            [
                                13.39361,
                                54.220974
                            ],
                            [
                                13.2902794,
                                54.2512512
                            ],
                            [
                                13.3352776,
                                54.2781944
                            ],
                            [
                                13.1391668,
                                54.2823601
                            ],
                            [
                                13.1847229,
                                54.300972
                            ],
                            [
                                13.1147213,
                                54.3318062
                            ],
                            [
                                13.1274996,
                                54.3712502
                            ],
                            [
                                13.2619438,
                                54.3829155
                            ],
                            [
                                13.1497221,
                                54.4290276
                            ],
                            [
                                13.2680569,
                                54.4793053
                            ],
                            [
                                13.1586113,
                                54.5040283
                            ],
                            [
                                13.1436119,
                                54.5468063
                            ],
                            [
                                13.3052778,
                                54.5140266
                            ],
                            [
                                13.2974987,
                                54.5523605
                            ],
                            [
                                13.3686123,
                                54.5793037
                            ],
                            [
                                13.3380556,
                                54.5487518
                            ],
                            [
                                13.3774986,
                                54.5590286
                            ],
                            [
                                13.4130564,
                                54.4937515
                            ],
                            [
                                13.5063877,
                                54.4809723
                            ],
                            [
                                13.5019436,
                                54.5484734
                            ],
                            [
                                13.4462989,
                                54.5764124
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                13.1841669,
                                54.4943047
                            ],
                            [
                                13.226944,
                                54.46875
                            ],
                            [
                                13.1208344,
                                54.4423599
                            ],
                            [
                                13.1841669,
                                54.4943047
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                13.1251689,
                                54.5824013
                            ],
                            [
                                13.1363888,
                                54.6051407
                            ],
                            [
                                13.1580544,
                                54.5793037
                            ],
                            [
                                13.1251689,
                                54.5824013
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                11.0697212,
                                54.5347023
                            ],
                            [
                                11.2341681,
                                54.5068054
                            ],
                            [
                                11.3136091,
                                54.4020844
                            ],
                            [
                                11.0091667,
                                54.4412498
                            ],
                            [
                                11.0697212,
                                54.5347023
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                8.6919451,
                                54.5570831
                            ],
                            [
                                8.6719446,
                                54.4945831
                            ],
                            [
                                8.5891666,
                                54.5118065
                            ],
                            [
                                8.6919451,
                                54.5570831
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                8.5397234,
                                54.7556953
                            ],
                            [
                                8.5952768,
                                54.7195816
                            ],
                            [
                                8.5669432,
                                54.679863
                            ],
                            [
                                8.396945,
                                54.7056961
                            ],
                            [
                                8.5397234,
                                54.7556953
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                8.5513878,
                                54.5795822
                            ],
                            [
                                8.5730562,
                                54.5587502
                            ],
                            [
                                8.5091667,
                                54.574028
                            ],
                            [
                                8.5513878,
                                54.5795822
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                8.476388,
                                54.4765282
                            ],
                            [
                                8.5258322,
                                54.4334717
                            ],
                            [
                                8.4702778,
                                54.4218063
                            ],
                            [
                                8.476388,
                                54.4765282
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                8.4830551,
                                54.5845833
                            ],
                            [
                                8.5013876,
                                54.5581932
                            ],
                            [
                                8.4552774,
                                54.5587502
                            ],
                            [
                                8.4830551,
                                54.5845833
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                8.3569441,
                                54.7115288
                            ],
                            [
                                8.3952789,
                                54.6120834
                            ],
                            [
                                8.2924986,
                                54.6670837
                            ],
                            [
                                8.3569441,
                                54.7115288
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                7.895833,
                                53.7940292
                            ],
                            [
                                7.9680548,
                                53.7748604
                            ],
                            [
                                7.8463879,
                                53.7868042
                            ],
                            [
                                7.895833,
                                53.7940292
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                7.706389,
                                53.779583
                            ],
                            [
                                7.8058329,
                                53.7745819
                            ],
                            [
                                7.666944,
                                53.7587509
                            ],
                            [
                                7.706389,
                                53.779583
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                7.5736108,
                                53.7573624
                            ],
                            [
                                7.6274991,
                                53.7484741
                            ],
                            [
                                7.4675021,
                                53.7270851
                            ],
                            [
                                7.5736108,
                                53.7573624
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                7.394166,
                                53.7345848
                            ],
                            [
                                7.4297228,
                                53.7251396
                            ],
                            [
                                7.3602781,
                                53.7268066
                            ],
                            [
                                7.394166,
                                53.7345848
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                7.0586109,
                                53.6845818
                            ],
                            [
                                7.0958338,
                                53.6806946
                            ],
                            [
                                6.854722,
                                53.6612511
                            ],
                            [
                                7.0586109,
                                53.6845818
                            ]
                        ]
                    ],
                    [
                        [
                            [
                                6.7619448,
                                53.6187515
                            ],
                            [
                                6.811389,
                                53.6026382
                            ],
                            [
                                6.7213879,
                                53.5837517
                            ],
                            [
                                6.749722,
                                53.5568047
                            ],
                            [
                                6.6308331,
                                53.5979156
                            ],
                            [
                                6.7619448,
                                53.6187515
                            ]
                        ]
                    ]
                ]
            }
        }
    ]
}