/* eslint-disable import/namespace */
/* eslint-disable no-unused-vars */
import { createAction } from "redux-actions";
import {
  SET_VOLUMES,
  SET_VOLUME,
  EDIT_VOLUME,
  REMOVE_VOLUME,
  REMOVE_VOLUMES,
} from "../constants/actions/actions";
import {
  VOLUMES_CREATE_EVENT,
  VOLUME_UPDATE_EVENT,
  VOLUME_DELETED_EVENT,
} from "../constants/channelevents";
import {
  deleteVolumeFail,
  deleteVolumeSuccess,
  updateVolumeFail,
  updateVolumeSuccess,
} from "../constants/snackbars";

import API from "../lib/api";
import HTTP from "../lib/http";
import { renderSnackbar } from "./snackbars";

export const setVolumes = createAction(SET_VOLUMES);
export const setVolume = createAction(SET_VOLUME);
export const editVolume = createAction(EDIT_VOLUME);
export const removeVolume = createAction(REMOVE_VOLUME);
export const removeVolumes = createAction(REMOVE_VOLUMES);

export const CableActions = (message, connection, store) => {
  switch (message.event) {
    case VOLUMES_CREATE_EVENT:
      var volumes = message.payload.volumes;
      var isArray = volumes && volumes.constructor === Array;

      if (isArray) {
        volumes.forEach((volume) => {
          store.dispatch(
            setVolume({
              id: volume.id,
              item: volume,
            })
          );
        });
      }
      break;
    case VOLUME_UPDATE_EVENT:
      store.dispatch(
        editVolume({
          id: message.payload.volume.id,
          item: message.payload.volume,
        })
      );
      break;
    case VOLUME_DELETED_EVENT:
      store.dispatch(
        removeVolume({
          id: message.payload.volume.id,
          item: message.payload.volume,
        })
      );
      break;
    default:
      break;
  }
};

export function deleteVolume(id) {
  return async (dispatch, getState) => {
    await HTTP.delete(API.volumes.deleteVolume(id))
      .then(function (response) {
        dispatch(renderSnackbar(deleteVolumeSuccess));
      })
      .catch(function (error) {
        dispatch(renderSnackbar(deleteVolumeFail));
        console.log("DELETE VOLUME ERROR", error);
      });
  };
}

export function updateVolume(volume) {
  return async (dispatch, getState) => {
    await HTTP.put(API.volumes.updateVolume(volume.id), { volume })
      .then(function (response) {
        dispatch(renderSnackbar(updateVolumeSuccess));
      })
      .catch(function (error) {
        dispatch(renderSnackbar(updateVolumeFail));
        console.log("UPDATE VOLUME ERROR", error);
      });
  };
}
