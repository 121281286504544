import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Errors from "./../screens/error";
import PrivateWrapper from "./../screens/private/PrivateWrapper";
import Public from "./../screens/public";
import { userIsAuthenticatedRedir } from "./auth";

const AuthPrivateWrapper = userIsAuthenticatedRedir(PrivateWrapper);

// wrap router to access store
// check for token
// if token -> redirect
// if not -> validate and show spinner

export default function AppRoutes() {
  return (
    <Switch>
      {/*Public Routes*/}
      <Route exact path="/login" component={Public.Login} />

      <Route exact path="/register" component={Public.Registration} />

      <Route exact path="/register-mobile" component={Public.Registration} />

      <Route
        exact
        path="/forgot-password"
        component={Public.ForgotPasswordDialog}
      />

      <Route
        exact
        path="/reset-password"
        component={Public.ResetPasswordDialog}
      />

      <Route exact path="/confirmation" component={Public.ConfirmUser} />

      <Route
        exact
        render={(props) => <Public.Registration {...props} admin={false} />}
        path="/invitation"
      />

      <Route exact path="/404" component={Errors.NotFoundPage} />

      {/*Private Routes */}
      <Route path="/" component={AuthPrivateWrapper} />

      {/*Redirect*/}
      <Route
        path="*"
        render={() => (
          <Redirect
            to={{
              pathname: "/404",
            }}
          />
        )}
      />
    </Switch>
  );
}
