/* eslint-disable no-unused-vars */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import classNames from "classnames";
import ListItem from "@mui/material/ListItem";
import withStyles from '@mui/styles/withStyles';
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import PropTypes from "prop-types";

import {
  deleteInvitation,
  resendInvitation,
} from "../../../actions/userAdministration";
import InvitedUsersActions from "../../../components/users/InvitedUsersActions";
import SecondaryActions from "../../../components/users/SecondaryActions";
import AvatarName from "../../../components/AvatarName";

import { styling } from "../styles";
import "./styles.css";

const propTypes = {
  classes: PropTypes.object,
  deleteInvitation: PropTypes.func,
  users: PropTypes.object,
  resendInvitation: PropTypes.func,
};

const overrideStyles = (theme) => ({
  root: {
    ...styling.root,
  },
  listItem: {
    ...styling.listItem,
  },
});

const InvitedUsers = ({
  classes,
  deleteInvitation,
  resendInvitation,
  users,
}) => {
  const dropdownMenu = [
    {
      text: "Einladung zurückziehen",
      key: "delete",
      func: deleteInvitation,
    },
  ];

  const compare = (a, b) => {
    if (a.email < b.email) return -1;
    if (a.email > b.email) return 1;
    return 0;
  };

  return (
    <>
      {users.toJS &&
        users
          .toJS()
          .sort(compare)
          .map((user, index) => {
            return (
              <ListItem
                key={index}
                classes={{ root: classes.root }}
                className={classNames(classes.listItem)}
              >
                <AvatarName registredUser={false} email={user.email} />
                <div className="invited-users-text-wrapper">
                  <InvitedUsersActions
                    user={user}
                    resendInvitation={resendInvitation}
                  />
                </div>
                <ListItemSecondaryAction style={{ flex: 2 }}>
                  <SecondaryActions user={user} dropdownMenu={dropdownMenu} />
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
    </>
  );
};

function dispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteInvitation,
      resendInvitation,
    },
    dispatch
  );
}

function stateToProps(state) {
  if (!state.getIn(["organizations", "items"]).first()) {
    return { users: [] };
  }
  const invitedUsers = state
    .getIn(["organizations", "items"])
    .first()
    .get("organization_invitations");
  const searchTerm = state.getIn(["userAdministration", "searchTerm"]);
  return {
    users:
      searchTerm.length > 0
        ? invitedUsers.filter((invitedUser) => {
            return searchTerm.split(" ").some((word) => {
              return (
                invitedUser
                  .get("email")
                  .toLowerCase()
                  .indexOf(word.toLowerCase()) > -1
              );
            });
          })
        : invitedUsers,
  };
}

InvitedUsers.propTypes = propTypes;
const styledInvitedUsers = withStyles(overrideStyles, { withTheme: true })(
  InvitedUsers
);
export default connect(stateToProps, dispatchToProps)(styledInvitedUsers);
