/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import React, { useState } from "react";

import withStyles from "@mui/styles/withStyles";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";

import ButtonGroup from "../../../components/ButtonGroup";
import CustomDialog from "../../../components/CustomDialog";
import HintView from "../../../components/HintView";
import { HINT_UPDATE_BEHAVIOUR } from "../../../constants/hinttypesmapping";
import { validateTextFields } from "../../../sharedFunctions/validation";

import * as styles from "../../../styles/styles";

const propTypes = {
  classes: PropTypes.object,
  topographicPointLayer: PropTypes.object,
  modalOpen: PropTypes.bool,
  onPositiveButtonClicked: PropTypes.func,
  onNegativeButtonClicked: PropTypes.func,
};

const overrideStyles = (theme) => ({
  alignText: {
    margin: "auto 0 0 0",
  },
});

const EditTopographicPointLayerDialog = ({
  classes,
  topographicPointLayer,
  modalOpen,
  onPositiveButtonClicked,
  onNegativeButtonClicked,
}) => {
  const [stateLayerNameInputValue, setStateLayerNameInputValue] = useState(
    topographicPointLayer.layer_name
  );
  const [stateDisablePositiveButton, setDisablePositiveButton] = useState(true);

  const validationValues = React.useMemo(() => {
    return {
      layerName: {
        value: topographicPointLayer.layer_name,
        required: true,
      },
    };
  }, [topographicPointLayer.layer_name]);

  const onChangeLayerName = (event) => {
    const layerName = event.target.value;
    const newValues = { layerName: layerName };
    setStateLayerNameInputValue(layerName);
    setDisablePositiveButton(validateTextFields(validationValues, newValues));
  };

  const handleOnPositiveButtonClicked = () => {
    const updateTopographicPointLayer = {
      ...topographicPointLayer,
      layer_name: stateLayerNameInputValue,
    };
    onPositiveButtonClicked(updateTopographicPointLayer);
  };

  return (
    <>
      <CustomDialog
        open={modalOpen}
        onClick={handleOnPositiveButtonClicked}
        onClose={onNegativeButtonClicked}
        showHint={true}
        renderTitle={() => {
          return (
            <DialogTitle id="alert-dialog-title">
              {"Layer editieren"}
            </DialogTitle>
          );
        }}
        renderHint={() => {
          return <HintView hintMessageType={HINT_UPDATE_BEHAVIOUR} />;
        }}
        renderContent={() => {
          return (
            <DialogContent>
              <TextField
                fullWidth={true}
                InputProps={{ classes: { input: classes.alignText } }}
                margin="none"
                label="Layername"
                multiline={false}
                onChange={onChangeLayerName}
                placeholder="Layername"
                required={true}
                style={{
                  margin: `${styles.spacing24} 0 ${styles.spacing24} 0`,
                }}
                variant="outlined"
                value={stateLayerNameInputValue}
              />
            </DialogContent>
          );
        }}
        renderButtonGroup={(
          handleOnPositiveButtonClicked,
          onNegativeButtonClicked
        ) => {
          return (
            <DialogActions style={{ padding: "2.4rem 2.4rem 2rem" }}>
              <ButtonGroup
                align="right"
                leftButtonText="Abbrechen"
                leftButtonClick={onNegativeButtonClicked}
                rightButtonText={"Ändern"}
                rightButtonClick={handleOnPositiveButtonClicked}
                rightButtonDisabled={stateDisablePositiveButton}
                spacingTop={false}
              />
            </DialogActions>
          );
        }}
      />
    </>
  );
};

EditTopographicPointLayerDialog.propTypes = propTypes;
export default withStyles(overrideStyles, { withTheme: true })(
  EditTopographicPointLayerDialog
);
