/* eslint-disable import/namespace */
/* eslint-disable no-unused-vars */
import { createAction } from "redux-actions";

import {
  SET_MEASURE_LAYER_CONFIG,
  SET_MEASURE_LAYER_CONFIGS,
  EDIT_MEASURE_LAYER_CONFIG,
  REMOVE_MEASURE_LAYER_CONFIG,
  CREATE_MEASURE_LAYER_CONFIG,
} from "../constants/actions/actions";

import {
  MEASURE_LAYER_CONFIGS_CREATE_EVENT,
  MEASURE_LAYER_CONFIG_UPDATE_EVENT,
  MEASURE_LAYER_CONFIG_DELETE_EVENT,
} from "../constants/channelevents";

import {
  updateMeasureLayerConfigFail,
  updateMeasureLayerConfigSuccess,
  deleteMeasureLayerConfigFail,
  deleteMeasureLayerConfigSuccess,
  createMeasureLayerConfigSuccess,
  createMeasureLayerConfigFail,
} from "../constants/snackbars";

import API from "../lib/api";
import HTTP from "../lib/http";
import { renderSnackbar } from "./snackbars";

export const setMeasureLayerConfigs = createAction(SET_MEASURE_LAYER_CONFIGS);
export const setMeasureLayerConfig = createAction(SET_MEASURE_LAYER_CONFIG);
export const editMeasureLayerConfig = createAction(EDIT_MEASURE_LAYER_CONFIG);
export const removeMeasureLayerConfig = createAction(
  REMOVE_MEASURE_LAYER_CONFIG
);
export const createMeasureLayerConfig = createAction(
  CREATE_MEASURE_LAYER_CONFIG
);

export const CableActions = (message, connection, store) => {
  switch (message.event) {
    case MEASURE_LAYER_CONFIGS_CREATE_EVENT:
      store.dispatch(
        setMeasureLayerConfigs(message.payload.measure_layer_configs)
      );
      break;
    case MEASURE_LAYER_CONFIG_UPDATE_EVENT:
      store.dispatch(
        editMeasureLayerConfig({
          id: message.payload.measure_layer_config.id,
          item: message.payload.measure_layer_config,
        })
      );
      break;
    case MEASURE_LAYER_CONFIG_DELETE_EVENT:
      store.dispatch(
        removeMeasureLayerConfig({
          id: message.payload.measure_layer_config.id,
          item: message.payload.measure_layer_config,
        })
      );
      break;
    default:
      break;
  }
};

export function createMeasureLayerConfigs(project, measure_layer_configs) {
  return async (dispatch, getState) => {
    await HTTP.post(API.projects.createMeasureLayerConfigs(project.id), {
      measure_layer_configs,
    })
      .then(function (response) {
        dispatch(renderSnackbar(createMeasureLayerConfigSuccess));
      })
      .catch(function (error) {
        dispatch(renderSnackbar(createMeasureLayerConfigFail));
      });
  };
}

export function updateMeasureLayerConfig(measure_layer_config) {
  return async (dispatch, getState) => {
    await HTTP.put(
      API.measureLayerConfigs.updateMeasureLayerConfig(measure_layer_config.id),
      { measure_layer_config }
    )
      .then(function (response) {
        dispatch(renderSnackbar(updateMeasureLayerConfigSuccess));
      })
      .catch(function (error) {
        dispatch(renderSnackbar(updateMeasureLayerConfigFail));
      });
  };
}

export function deleteMeasureLayerConfig(measureLayerConfig) {
  return async (dispatch, getState) => {
    await HTTP.delete(
      API.measureLayerConfigs.deleteMeasureLayerConfig(measureLayerConfig.id)
    )
      .then(function (response) {
        dispatch(renderSnackbar(deleteMeasureLayerConfigSuccess));
      })
      .catch(function (error) {
        dispatch(renderSnackbar(deleteMeasureLayerConfigFail));
      });
  };
}
