/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import PropTypes from "prop-types";

import { withStyles } from "@mui/styles";
import { Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import ProjectForm from "../../../containers/dialogs/ProjectForm";

import * as styles from "../../../styles/styles";

const CustomButton = withStyles({
  root: {
    "&.Mui-disabled": {
      pointerEvents: "auto",
    },
  },
})(Button);

const propTypes = {
  disabled: PropTypes.bool,
};

const defaultProps = {
  disabled: false,
};

const Buttons = ({ disabled }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const renderButton = () => {
    return (
      <Button
        disabled={disabled}
        style={{ margin: `0 ${styles.spacing16} 0 0` }}
        color="primary"
        variant="contained"
        size="medium"
        onClick={() => setModalOpen(true)}
      >
        <AddCircleIcon style={{ margin: "0 1.2rem 0 0" }} />
        Neues Projekt
      </Button>
    );
  };

  const renderDisabledButton = () => {
    return (
      <Tooltip title="Sie haben keine freien Projekte mehr. Bitte löschen Sie erst ein Projekt oder führen Sie ein Upgrade Ihres Accounts durch!">
        <span>{renderButton()}</span>
      </Tooltip>
    );
  };

  return (
    <>
      {disabled ? renderDisabledButton() : renderButton()}
      {modalOpen && (
        <ProjectForm
          edit={false}
          modalOpen={modalOpen}
          onClick={() => setModalOpen(false)}
          onClose={() => setModalOpen(false)}
        />
      )}
    </>
  );
};

Buttons.propTypes = propTypes;
Buttons.defaultProps = defaultProps;
export default Buttons;
