const validateAddressObject = (addressObject) => {
  if (
    addressObject !== null &&
    Object.prototype.toString.call(addressObject) === "[object Object]" &&
    Object.keys(addressObject).length > 0
  )
    return addressObject;

  return "";
};

export const parseAddress = (addressObject) => {
  const validatedAddressObject = validateAddressObject(addressObject);
  if (validatedAddressObject === "") return "";

  const streetName = parseStreetNameFromAddress(validatedAddressObject);
  const zipCode = parseZipCodeFromAddress(validatedAddressObject);
  const city = parseCityFromAddress(validatedAddressObject);

  return `${streetName}, ${zipCode} ${city}`;
};

export const parseAddressForReverseGeocoding = (addressObject) => {
  const validatedAddressObject = validateAddressObject(addressObject);
  if (validatedAddressObject === "") return "";

  const streetName = parseStreetNameFromAddress(validatedAddressObject);
  const zipCode = parseZipCodeFromAddress(validatedAddressObject);
  const city = parseCityFromAddress(validatedAddressObject);

  return encodeURI(`${streetName} ${zipCode} ${city}`);
};

export const parseStreetNameFromAddress = (addressObject) => {
  const validatedAddressObject = validateAddressObject(addressObject);
  if (validatedAddressObject === "") return "";

  var streetName = "";

  if ("streetname" in validatedAddressObject)
    streetName = validatedAddressObject.streetname;

  return streetName;
};

export const parseZipCodeFromAddress = (addressObject) => {
  const validatedAddressObject = validateAddressObject(addressObject);
  if (validatedAddressObject === "") return "";

  var zipCode = "";

  if ("zip_code" in validatedAddressObject)
    zipCode = validatedAddressObject.zip_code;

  return zipCode;
};

export const parseCityFromAddress = (addressObject) => {
  const validatedAddressObject = validateAddressObject(addressObject);
  if (validatedAddressObject === "") return "";

  var city = "";

  if ("city" in validatedAddressObject) city = validatedAddressObject.city;

  return city;
};
