import Projects from "./Projects";
import Project from "./Project";
import UserProfile from "./UserProfile";
import SubscriptionExpired from "./SubscriptionExpired";
import ComponentCatalogs from "./ComponentCatalogs";
import ComponentCatalog from "./ComponentCatalog";
import MeasureLayerConfigTemplates from "./MeasureLayerConfigTemplates";
import MeasureLayerConfigTemplate from "./MeasureLayerConfigTemplate";
import Configurator from "./Configurator";
import ConfirmAccountDeletion from "./ConfirmAccountDeletion";
import MapLayers from "./MapLayers";

export default {
  Projects,
  Project,
  UserProfile,
  SubscriptionExpired,
  ComponentCatalogs,
  ComponentCatalog,
  MeasureLayerConfigTemplates,
  MeasureLayerConfigTemplate,
  Configurator,
  ConfirmAccountDeletion,
  MapLayers
};
