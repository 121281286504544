/* eslint-disable no-unused-vars */
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import List from "@mui/material/List";
import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import ButtonGroup from "../../../../components/ButtonGroup";

import { nameMap } from "../../../../constants/download";

import * as styles from "../../../../styles/styles";
import SingleActionDownloadItems from "../SingleAction/SingleActionDownloadItems";
import BulkActionDownloadItem from "./BulkActionDownloadItem";
import { arrayToStateObject } from "../sharedFunctions";
import { subheadline } from "../styles";
import "./styles.css";

const propTypes = {
  classes: PropTypes.object,
  handleChange: PropTypes.func,
  firstCategoryFiles: PropTypes.array,
  secondCategoryFiles: PropTypes.array,
  thirdCategoryFiles: PropTypes.array,
  onClose: PropTypes.func,
  projects: PropTypes.array,
  selectedProjects: PropTypes.object,
  setNextPage: PropTypes.func,
};

const overrideStyles = (theme) => ({
  subheadline,
});

const BulkAction = ({
  classes,
  setNextPage,
  firstCategoryFiles,
  secondCategoryFiles,
  thirdCategoryFiles,
  onClose,
  projects,
  selectedProjects,
}) => {
  const [stateFirstCategory, setStateFirstCategory] = useState({
    ...arrayToStateObject(firstCategoryFiles),
  });
  const [stateSecondCategory, setStateSecondCategory] = useState({
    ...arrayToStateObject(secondCategoryFiles),
  });
  const [stateThirdCategory, setStateThirdCategory] = useState({
    ...arrayToStateObject(thirdCategoryFiles),
  });
  const [statePageNumber, setStatePageNumber] = useState({ page: 1 });

  const handleChangeFirstCategory = React.useCallback(
    (file, checked) => {
      setStateFirstCategory({
        ...stateFirstCategory,
        [file]: checked,
      });
    },
    [stateFirstCategory]
  );

  const handleChangeSecondCategory = React.useCallback(
    (file, checked) => {
      setStateSecondCategory({
        ...stateSecondCategory,
        [file]: checked,
      });
    },
    [stateSecondCategory]
  );

  const handleChangeThirdCategory = React.useCallback(
    (file, checked) => {
      setStateThirdCategory({
        ...stateThirdCategory,
        [file]: checked,
      });
    },
    [stateThirdCategory]
  );

  const nextPage = () => {
    setNextPage();
    setStatePageNumber({ ...statePageNumber, page: 2 });
  };

  const renderList = (filesList, handleChange, state) =>
    filesList.map((file, index) => {
      return (
        <BulkActionDownloadItem
          key={index}
          primaryText={nameMap[file]}
          listItemIndex={index}
          listItemDownloadFile={file}
          checkboxChecked={state[file]}
          onCheckBoxChanged={handleChange}
        />
      );
    });

  const selectedFiles = () => {
    let mergedCategories = {
      ...stateFirstCategory,
      ...stateSecondCategory,
      ...stateThirdCategory,
    };
    let result = Object.keys(mergedCategories).filter(
      (key) => mergedCategories[key]
    );
    return result;
  };

  const renderPageTwo = () => {
    return selectedProjects.valueSeq().map((project, key) => (
      <div key={key} className={"page-two-container"}>
        <Typography
          classes={{ root: classes.subheadline }}
          color="primary"
          variant="h6"
        >
          {project.get("title")}
        </Typography>
        <SingleActionDownloadItems
          project={project.toJS()}
          files={selectedFiles()}
        />
      </div>
    ));
  };

  const renderPageOne = () => (
    <>
      <List>
        <Typography color="primary" variant="body2">
          Dokumentation
        </Typography>
        {renderList(
          firstCategoryFiles,
          handleChangeFirstCategory,
          stateFirstCategory
        )}
      </List>
      <List>
        <Typography
          classes={{ root: classes.subheadline }}
          color="primary"
          variant="body2"
        >
          Geoformate
        </Typography>
        {renderList(
          secondCategoryFiles,
          handleChangeSecondCategory,
          stateSecondCategory
        )}
      </List>
      <List>
        <Typography
          classes={{ root: classes.subheadline }}
          color="primary"
          variant="body2"
        >
          Lokale Koordinaten
        </Typography>
        {renderList(
          thirdCategoryFiles,
          handleChangeThirdCategory,
          stateThirdCategory
        )}
      </List>
      <DialogActions
        style={{ padding: `${styles.spacing24} ${styles.spacing24} 2rem` }}
      >
        <ButtonGroup
          align="right"
          leftButtonText="Abbrechen"
          leftButtonClick={onClose}
          rightButtonText="Download"
          rightButtonClick={nextPage}
          rightButtonDisabled={!selectedFiles().length > 0}
          spacingTop={true}
        />
      </DialogActions>
    </>
  );

  return (
    <DialogContent>
      {statePageNumber.page === 1 ? renderPageOne() : renderPageTwo()}
    </DialogContent>
  );
};

function stateToProps(state, props) {
  return {
    selectedProjects: state
      .getIn(["projects", "items"])
      .filter((project) => props.projects.includes(project.get("id"))),
  };
}

BulkAction.propTypes = propTypes;
const styledBulkAction = withStyles(overrideStyles, { withTheme: true })(
  BulkAction
);
export default connect(stateToProps, null)(styledBulkAction);
