import { LAST_PATH } from "../constants/localStorageConstants";

/**
 * A React Hook that provides utility functions for handling values in the localStorage.
 * This hook includes two functions: getLastPath and clearLastPath.
 *
 * * getLastPath: Retrieves the value stored in the localStorage using the key LAST_PATH.
 * This function is typically used to obtain the last visited path or route in a web application.
 * The value associated with the LAST_PATH key can be an empty string or a valid path.
 * The LAST_PATH key is commonly set in the useExitDetector.js Hook to keep track of the last
 * visited path when the page is reloaded.
 *
 *  * clearLastPath: Clears the value associated with the LAST_PATH key in the localStorage.
 * This function is typically used to reset or remove the last visited path stored in the localStorage.
 * It sets the value of the LAST_PATH key to an empty string, effectively clearing the stored path.
 *
 * @returns {[getLastPath: Function, clearLastPath: Function]} An array containing the `getLastPath`
 *                                                            and `clearLastPath` functions.
 */
export default function localStorageHandler() {
  /**
   * Retrieves the value stored in the localStorage using the key LAST_PATH.
   * This function is typically used to obtain the last visited path or route
   * in a web application. The value associated with the LAST_PATH key can be
   * an empty string or a valid path. The LAST_PATH key is commonly set in the
   * useExitDetector.js Hook to keep track of the last visited path when the
   * page is reloaded.
   *
   * @returns {string | null} The value associated with the LAST_PATH key in the
   */
  const getLastPath = () => {
    return localStorage.getItem(LAST_PATH);
  };

  /**
   * Clears the value associated with the LAST_PATH key in the localStorage.
   * This function is typically used to reset or remove the last visited path
   * stored in the localStorage. It sets the value of the LAST_PATH key to an
   * empty string, effectively clearing the stored path.
   * @returns {void} This function does not return any value.
   */
  const clearLastPath = () => {
    localStorage.setItem(LAST_PATH, "");
  };

  return [getLastPath, clearLastPath];
}
