import React from 'react';
import { Link } from 'react-router-dom';

import "./styles.css";


const NotFoundPage = () => {
  return <div className="background-contain-wrapper">
    <img alt="404 Page not found"
      src={"http://www.404notfound.fr/assets/images/pages/img/androiddev101.jpg"}
    />
    <Link
      to='/login'>
      <button>Login</button>
    </Link>
  </div>
}

export default NotFoundPage;
