/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import mapboxgl from "mapbox-gl";
import withStyles from "@mui/styles/withStyles";
import UndoIcon from "@mui/icons-material/Undo";
import { createRoot } from "react-dom/client";
import * as turf from "@turf/turf";

import CustomModal from "../../../../../components/CustomModal";
import CustomImageView from "../../../../../components/CustomImageView";
import MeasurementList from "../MeasurementList";
import MapPopUp from "../MapPopUp";

import { GERMANY, UNDEFINED, checkCountry } from "./ecs/utils/country_check";
import { loadCustomImageList } from "./ecs/utils/image_loader";

import "./styles.css";
import "mapbox-gl/dist/mapbox-gl.css";

import {
  SYSTEM_CLICK,
  SYSTEM_HOVER,
  SYSTEM_RENDER,
} from "../../../../../constants/ecs";
import { generateMapEntities } from "./ecs/mapEntitiesGenerator";
import { loadGridFile, createGeoJsonPoints } from "./ecs/utils/geojsonutils";
import SystemClick from "./ecs/systems/systemClick";
import SystemHover from "./ecs/systems/systemHover";
import SystemRender from "./ecs/systems/systemRender";
import { BASEMAP_DE_VECTOR_STYLE, DEFAULT_STYLE } from "./ecs/utils/base_styles";



const propTypes = {
  classes: PropTypes.object,
  project: PropTypes.object,
  measurements: PropTypes.object,
  referencePoints: PropTypes.object,
  referencePointsPerLayer: PropTypes.array,
  measurementsPerLayer: PropTypes.array,
  volumes: PropTypes.object,
  mediaFiles: PropTypes.object,
  topographicPoints: PropTypes.object,
  topographicPointsPerLayer: PropTypes.array,
  componentCatalogItems: PropTypes.object,
  gnssPointsPerLayer: PropTypes.array,
  gnssRefPointsPerLayer: PropTypes.array,
  gnssLinesPerLayer: PropTypes.array,
  gnssPolygonPerLayer: PropTypes.array,
  gnssPoints: PropTypes.array,
  gnssImages: PropTypes.array
};

const overrideStyles = (theme) => ({
  zoomOutMapIcon: {
    position: "absolute",
    width: "3.4rem",
    height: "3.4rem",
    borderRadius: "0.6rem",
    border: "2px solid rgba(0,0,0,0.2)",
    top: "22rem",
    right: "0.8rem",
    padding: "0.4rem",
    backgroundColor: "white",
    zIndex: 800,
    cursor: "auto",
    "&:hover": {
      backgroundColor: "#F4F4F1",
      cursor: "pointer",
    },
  },
});

class ProjectMap extends React.Component {
  constructor(props) {
    super(props);

    const { 
      measurements, 
      topographicPoints, 
      volumes, 
      gnssPoints,
      gnssImages } = this.props;
      
      //loadGridFile(
      //  "BETA2007.gsb", 
      //  "https://raw.githubusercontent.com/OSGeo/proj-datumgrid/b6593c9e7047e5ec26ffd8ed386c5f3ac4fc7b6d/BETA2007.gsb")

    const rawPointData = Object.values(measurements)
      .concat(
        Object.values(topographicPoints), 
        Object.values(gnssPoints),
        Object.values(gnssImages))
    const points = createGeoJsonPoints(rawPointData, {});

    var country = UNDEFINED
    if (points.features.length > 0) {
      const pt = turf.point(points.features[0].geometry.coordinates)
      country = checkCountry(pt)
    } 

    this.state = {
      currentHighlight: null,
      image: null,
      points,
      bbox: turf.bbox(points),
      measurementListHover: null,
      modalOpen: false,
      measurementLineAvailabe:
        Object.getOwnPropertyNames(measurements).length > 0,
      gnssDataAvailable: Object.getOwnPropertyNames(gnssPoints).length > 0,
      volumeAvailable: Object.getOwnPropertyNames(volumes).length > 0,
      systems: [],
      country: country,
      cameraImg: null
    };

    this.popUp = null;

    this.addPopup = this.addPopup.bind(this);
    this.onShowPopUp = this.onShowPopUp.bind(this);
    this.onHighlightUpdatedListener =
      this.onHighlightUpdatedListener.bind(this);
    this.setFeatureStateOnListClicked =
      this.setFeatureStateOnListClicked.bind(this);
    this.onListClick = this.onListClick.bind(this);
    this.fitBounds = this.fitBounds.bind(this);
    this.onHoverListEnter = this.onHoverListEnter.bind(this);
    this.onHoverListLeave = this.onHoverListLeave.bind(this);
  }

  componentDidMount() {
    const { 
      bbox, 
      measurementLineAvailabe, 
      gnssDataAvailable, 
      country } = this.state;

    const style = country === GERMANY ? BASEMAP_DE_VECTOR_STYLE : DEFAULT_STYLE

    if (measurementLineAvailabe || gnssDataAvailable) {
      this.map = new mapboxgl.Map({
        bounds: bbox,
        fitBoundsOptions: { padding: 30 },
        container: this.mapContainer,
        style: style,
        accessToken:
          "pk.eyJ1IjoiZ3JvYmVydCIsImEiOiJja3d2ejAwN2MweGdxMnFtaThpbGRxbzNmIn0.p83TeA_nVV8WiMEpNFYnoQ",
      });

      loadCustomImageList(this.map)
      
      this.map.on("load", async () => {
        this.map.addControl(
          new mapboxgl.NavigationControl({ visualizePitch: true }),
          "top-right"
        );
        this.map.addControl(new mapboxgl.ScaleControl());
        this.map.addControl(
          new mapboxgl.FullscreenControl({
            container: document.getElementById("project-map-container"),
          })
        );
        this.map.getCanvas().style.cursor = "auto";

        this.mapEntity = generateMapEntities(
          country,
          this.props.referencePointsPerLayer,
          this.props.measurementsPerLayer,
          this.props.topographicPointsPerLayer,
          this.props.gnssPointsPerLayer,
          this.props.gnssRefPointsPerLayer,
          this.props.gnssLinesPerLayer,
          this.props.gnssPolygonPerLayer,
          this.props.gnssImages
        );

        const systemClick = new SystemClick({
          map: this.map,
          onShowPopUp: this.onShowPopUp,
          onHighlightUpdatedListener: this.onHighlightUpdatedListener,
        });
        const systemHover = new SystemHover({
          map: this.map,
        });
        const systemRender = new SystemRender({ map: this.map });

        const systems = [systemClick, systemHover, systemRender];

        this.setState({ systems });

        systems.forEach((system) => {
          system.run(this.mapEntity);
        });
      });
    } else {
      this.map = new mapboxgl.Map({
        container: this.mapContainer,
        style: "mapbox://styles/mapbox/streets-v9",
        accessToken:
          "pk.eyJ1IjoiZ3JvYmVydCIsImEiOiJja3d2ejAwN2MweGdxMnFtaThpbGRxbzNmIn0.p83TeA_nVV8WiMEpNFYnoQ",
      });

      this.map.on("load", async () => {
        this.map.addControl(
          new mapboxgl.NavigationControl({ visualizePitch: true }),
          "top-right"
        );
        this.map.addControl(new mapboxgl.ScaleControl());
        this.map.addControl(
          new mapboxgl.FullscreenControl({
            container: document.getElementById("project-map-container"),
          })
        );
        this.map.getCanvas().style.cursor = "auto";

        if (this.props.project.place.latLng) {
          this.map.jumpTo({
            center: this.props.project.place.latLng,
            zoom: 19,
          });
        }
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { 
      measurements, 
      topographicPoints, 
      gnssPoints, 
      gnssImages, 
      project } = this.props;

    if (prevProps.project.id !== project.id) {
      this.state.systems.forEach((system) => {
        if (system.systemType === SYSTEM_RENDER)
          system.clearComponents(this.mapEntity);
      });

      this.mapEntity = generateMapEntities(
        this.props.referencePointsPerLayer,
        this.props.measurementsPerLayer,
        this.props.topographicPointsPerLayer,
        this.props.gnssPointsPerLayer,
        this.props.gnssRefPointsPerLayer,
        this.props.gnssLinesPerLayer,
        this.props.gnssPolygonPerLayer,
        this.props.gnssImages
      );

      this.state.systems.forEach((system) => {
        system.run(this.mapEntity);
      });

      const rawPointData = Object.values(measurements)
        .concat(
          Object.values(topographicPoints), 
          Object.values(gnssPoints),
          Object.values(gnssImages))

      const points = createGeoJsonPoints(rawPointData, {});
      this.map.fitBounds(turf.bbox(points), { padding: 30 });
      this.popUp && this.popUp.remove();
      this.popUp = null;
      this.setState({
        points,
        bbox: turf.bbox(points),
        measurementListHover: null,
        currentHighlight: null,
        prevHighlight: null,
      });
    }
  }

  addPopup(viewComponent, component, coordinates) {
    const placeholder = document.createElement("div");
    const root = createRoot(placeholder);
    root.render(viewComponent);
    this.popUp = new mapboxgl.Popup({
      className: "custom-mapboxgl-popup-content",
      closeButton: true,
      closeOnClick: true,
    })
      .setDOMContent(placeholder)
      .setLngLat(coordinates)
      .addTo(this.map);
  }

  onShowPopUp(event, component, id, coordinates) {
    this.addPopup(
      <MapPopUp
        onClick={(image) => this.setState({ modalOpen: true, image })}
        id={id}
        feature={event.features[0]}
      />,
      component,
      coordinates
    );
  }

  onHighlightUpdatedListener(id, component) {
    this.setState({
      currentHighlight: id,
    });
  }

  setFeatureStateOnListClicked(id, componentType) {
    this.state.systems.forEach((system) => {
      if (system.systemType === SYSTEM_CLICK)
        system.onGlobalHighlightEvent(this.mapEntity, id, componentType);
    });
  }

  onListClick(dataItem) {
    const { points } = this.state;

    var id = "";
    if ("id" in dataItem) {
      id = dataItem.id
    } else if ("uuid" in dataItem) {
      id = dataItem.uuid
    }

    this.setFeatureStateOnListClicked(id, dataItem.type);
    this.map.flyTo({
      center: points.features.filter((feature) => feature.id === id)[0]
        .geometry.coordinates,
      zoom: 24,
    });

    this.popUp !== null && this.popUp.remove();
    if (this.state.currentHighlight === id) {
      this.setState({ currentHighlight: null });
    } else {
      this.setState({ currentHighlight: id });
    }
  }

  fitBounds(event) {
    event.stopPropagation();
    this.map.fitBounds(this.state.bbox, { padding: 30 });
  }

  onHoverListEnter(id, componentType) {
    if (this.map.loaded()) {
      this.state.systems.forEach((system) => {
        if (system.systemType === SYSTEM_HOVER) {
          system.onGlobalHoverListEnterEvent(this.mapEntity, id, componentType);
        }
      });
    }
  }

  onHoverListLeave(id, componentType) {
    if (this.map.loaded()) {
      this.state.systems.forEach((system) => {
        if (system.systemType === SYSTEM_HOVER)
          system.onGlobalHoverListLeaveEvent(this.mapEntity, id, componentType);
      });
    }
  }

  render() {
    const {
      referencePoints,
      referencePointsPerLayer,
      measurementsPerLayer,
      volumes,
      mediaFiles,
      topographicPointsPerLayer,
      componentCatalogItems,
      gnssPointsPerLayer,
      gnssRefPointsPerLayer,
      gnssLinesPerLayer,
      gnssPolygonPerLayer,
      gnssImages,
      classes,
    } = this.props;
    const { modalOpen, image } = this.state;
    return (
      <>
        {modalOpen && (
          <CustomModal
            showCloseButton={true}
            open={modalOpen}
            image={image}
            onClose={() => this.setState({ modalOpen: false, image: null })}
            render={() => (
              <CustomImageView>
                <img src={image} className="image-modal-image" alt={`Bild`} />
              </CustomImageView>
            )}
          />
        )}
        <div
          style={{
            backgroundColor: "white",
            height: "45vh",
            width: "100%",
          }}
          id="project-map-container"
          ref={(el) => (this.mapContainer = el)}
        />
        <UndoIcon
          onClick={this.fitBounds}
          classes={{ root: classes.zoomOutMapIcon }}
        />
        <MeasurementList
          measurmentListClick={this.state.currentHighlight}
          onHoverListEnter={this.onHoverListEnter}
          onHoverListLeave={this.onHoverListLeave}
          onListClick={(measurement) => this.onListClick(measurement)}
          referencePoints={referencePoints}
          referencePointsPerLayer={referencePointsPerLayer}
          measurementsPerLayer={measurementsPerLayer}
          topographicPointsPerLayer={topographicPointsPerLayer}
          volumes={volumes}
          mediaFiles={mediaFiles}
          componentCatalogItems={componentCatalogItems}
          gnssPointsPerLayer={gnssPointsPerLayer}
          gnssRefPointsPerLayer={gnssRefPointsPerLayer}
          gnssLinesPerLayer={gnssLinesPerLayer}
          gnssPolygonPerLayer={gnssPolygonPerLayer}
          gnssImages={gnssImages}
        />
      </>
    );
  }
}

ProjectMap.propTypes = propTypes;
export default withStyles(overrideStyles, { withTheme: true })(ProjectMap);
