/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import withStyles from "@mui/styles/withStyles";
import classNames from "classnames";
import { Typography } from "@mui/material";
import * as styles from "../../styles/styles";

import "./styles.css";
import "../../screens/private/Projects/ProjectList/ProjectListItem/styles.css";

const propTypes = {
  classes: PropTypes.object,
};

const overrideStyles = (theme) => ({
  root: {
    paddingTop: "4rem",
    paddingBottom: "4rem",
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
    backgroundColor: styles.white,
    boxShadow: styles.boxShadowListElement,
    "&:hover": {
      boxShadow: styles.boxShadowListElementHover,
    },
  },
  listItem: {
    cursor: "pointer",
    height: "10rem",
    minHeight: "10rem",
  },
});

const divider = <div className="project-list-item-vertical-divider" />;

const ProjectPreview = ({ classes }) => {
  return (
    <ListItem
      classes={{ root: classes.root, divider: classes.divider }}
      className={classNames(classes.listItem)}
      style={{ cursor: "auto" }}
    >
      <div className="project-preview-shine"></div>
      <div className="project-list-item-jobnumber-state-wrapper">
        <ListItemText
          className="project-list-item-truncate-text"
          primary={
            <Typography className="project-preview-job-number"> </Typography>
          }
          secondary={
            <Typography className="project-preview-state-indicator project-preview-spacing">
              {" "}
            </Typography>
          }
        />
      </div>
      {divider}
      <ListItemText
        className="project-list-item-title-description-wrapper project-list-item-truncate-text"
        primary={
          <Typography className="project-preview-headline"> </Typography>
        }
        secondary={
          <Typography className="project-preview-description project-preview-spacing"></Typography>
        }
      />
      {divider}
      <ListItemText
        className="project-list-item-date-sections"
        primary={
          <Typography className="project-preview-created-at"> </Typography>
        }
        secondary={
          <Typography className="project-preview-created-at-date project-preview-spacing">
            {" "}
          </Typography>
        }
      />
      {divider}
      <ListItemText
        className="project-list-item-date-sections"
        primary={
          <Typography className="project-preview-edited-at"> </Typography>
        }
        secondary={
          <Typography className="project-preview-edited-at-date project-preview-spacing">
            {" "}
          </Typography>
        }
      />
    </ListItem>
  );
};

ProjectPreview.propTypes = propTypes;
export default withStyles(overrideStyles, { withTheme: true })(ProjectPreview);
