import { fromJS } from 'immutable';
import { SET_INIT_SYNCING, 
  SET_LAST_SYNC,
  SET_STANDARD_PROJECT_SYNC_STATUS,
  SET_ARCHIVED_PROJECT_SYNC_STATUS } from "../constants/actions/actions";

export const initialState = fromJS({
  lastOpenAt: false,
  syncing: false,
  standardProjectSyncStatus: null,
  archivedProjectSyncStatus: null
});

export function app(state = initialState, action) {
  switch (action.type) {
    case SET_LAST_SYNC:
      return state.setIn(['lastSyncAt'], action.payload);
    case SET_INIT_SYNCING:
      return state.setIn(['syncing'], action.payload);
    case SET_STANDARD_PROJECT_SYNC_STATUS:
      return state.setIn(['standardProjectSyncStatus'], action.payload);
    case SET_ARCHIVED_PROJECT_SYNC_STATUS:
      return state.setIn(['archivedProjectSyncStatus'], action.payload);
    default:
      return state;
  }
}
