import { fromJS, Map } from "immutable";
import {
  EDIT_MEDIA_FILE,
  REMOVE_MEDIA_FILE,
  REMOVE_MEDIA_FILES,
  ARCHIVED_REMOVE_MEDIA_FILES,
  SET_MEDIA_FILE,
  SET_MEDIA_FILES,
} from "../constants/actions/actions";

const initialState = fromJS({
  loading: false,
  new: 0,
  items: fromJS({}),
});

export const prepareItems = (array) => {
  return Map(array.map((item) => [item.id.toString(), fromJS(item)]));
};

export function mediaFiles(state = initialState, action) {
  switch (action.type) {
    case SET_MEDIA_FILES:
      return state.mergeIn(["items"], prepareItems(action.payload));

    case SET_MEDIA_FILE:
      return state.setIn(
        ["items", action.payload.id.toString()],
        fromJS(action.payload.item)
      );

    case EDIT_MEDIA_FILE: {
      let { id, item } = action.payload;
      return state.getIn(["items", id.toString()])
        ? state.mergeIn(["items", id.toString()], fromJS(item))
        : state;
    }

    case REMOVE_MEDIA_FILE:
      return state.deleteIn(["items", action.payload.id.toString()]);

    case REMOVE_MEDIA_FILES: {
      const projectIdInt = parseInt(action.payload.id);
      return state.set(
        "items",
        state
          .get("items")
          .filter((item) => item.get("project_id") !== projectIdInt)
      );
    }

    case ARCHIVED_REMOVE_MEDIA_FILES:
      return state.set(
        "items",
        state
          .get("items")
          .filter(
            (item) =>
              item.get("project_id") !== parseInt(action.payload.id) &&
              item.get("type") !== "image"
          )
      );

    default:
      return state;
  }
}
