import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';

const userIsAuthenticatedDefaults = {
  authenticatedSelector: state => state.getIn(['user', 'login']),
  authenticatingSelector: state => state.getIn(['user', 'loading'])
};

export const userIsAuthenticatedRedir = connectedRouterRedirect({
  ...userIsAuthenticatedDefaults,
  redirectPath: '/login'
});

// redux-auth-wrappern isn't maintened anymore
// replace with a in house solution like shown here -> https://blog.logrocket.com/authentication-react-router-v6/