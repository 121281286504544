/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import queryString from "qs";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

import {
  registerUser,
  registerAdmin,
  resetRegisterFlags,
  registerFreeAccount,
} from "../../../actions/register";

import { newsletterRegistration } from "../../../actions/newsletter";
import LandingPage from "../../../components/LandingPage";
import PageOne from "../../../components/registration/RegistrationDialog/PageOne";
import PageTwo from "../../../components/registration/RegistrationDialog/PageTwo";
import PageThree from "../../../components/registration/RegistrationDialog/PageThree";

import { hashCode } from "../../../sharedFunctions/hashCodeUtils";

import { SECTORS } from "../../../constants/organizationSectorConstants";

import { REGISTRATION_FROM_BROWSER_PATHNAME } from "../../../constants/registrationConstants";

import * as styles from "../../../styles/styles";
import "./styles.css";
import { success } from "../../../constants/status";

const propTypes = {
  admin: PropTypes.bool,
  history: PropTypes.object,
  registerUserStatus: PropTypes.string,
  registerAdmin: PropTypes.func,
  registerFreeAccount: PropTypes.func,
  token: PropTypes.string,
  location: PropTypes.object,
  resetRegisterFlags: PropTypes.any,
  registerUser: PropTypes.func,
  newsletterRegistration: PropTypes.func,
};

const defaultProps = {
  admin: true,
  email: "",
  token: "",
};

const Registration = ({
  admin,
  history,
  location,
  resetRegisterFlags,
  registerUserStatus,
  registerUser,
  registerAdmin,
  registerFreeAccount,
  newsletterRegistration,
}) => {
  let urlParams = queryString.parse(location && location.search, {
    decoder: (c) => c,
  });
  const email = urlParams["email"] || "";
  const token = urlParams["?access_key"];

  const [pathNameState, setPathNameState] = useState(location.pathname);

  const [state, setState] = useState({
    page: 1,
    age: 0,
    firstName: "",
    lastName: "",
    company: "",
    email: email,
    phoneNumber: "",
    sector: SECTORS[0],
    sectorFreeInput: "",
    password: "",
    agbAccepted: false,
    obligationOfSecrecyAccepted: false,
    subscribeToNewsletter: false,
  });

  useEffect(() => {
    resetRegisterFlags();
  }, [resetRegisterFlags]);

  const onClick = () => {
    const user = {
      age: state.age,
      email: state.email.trim(),
      password: state.password,
      password_confirmation: state.password,
      first_name: state.firstName.trim(),
      last_name: state.lastName.trim(),
      access_key: token,
    };

    if (admin) {
      const sector =
        state.sector.toLowerCase() === "sonstige" &&
        state.sectorFreeInput.length > 0
          ? state.sectorFreeInput
          : state.sector;

      let companyName = "";
      if (state.company.length === 0) {
        const firstFourDigits = (Math.floor(Math.random() * 10000) + 10000)
          .toString()
          .substring(1);
        companyName = `${state.firstName.trim()} ${state.lastName.trim()}#${firstFourDigits}`;
      } else {
        companyName = state.company;
      }

      const organization = {
        name: companyName.trim(),
        sector: sector.trim(),
        max_user_count: 1,
      };
      registerFreeAccount(user, organization);

      if (state.subscribeToNewsletter) {
        newsletterRegistration(state.email.trim());
      }
    } else {
      registerUser(user);
    }

    setState({ ...state, page: 3 });
  };

  const renderPage = () => {
    switch (state.page) {
      case 1:
        return (
          <PageOne
            age={state.age}
            firstName={state.firstName}
            lastName={state.lastName}
            email={state.email}
            phoneNumber={state.phoneNumber}
            admin={admin}
            sector={state.sector}
            sectorFreeInput={state.sectorFreeInput}
            company={state.company}
            onChangeAge={(age) => setState({ ...state, age })}
            onChangeSector={(sector, sectorFreeInput) =>
              setState({ ...state, sector, sectorFreeInput })
            }
            onNextPage={(page) => setState({ ...state, page })}
            onChangeEmail={(email) => setState({ ...state, email })}
            onChangeFirstName={(firstName) => setState({ ...state, firstName })}
            onChangeLastName={(lastName) => setState({ ...state, lastName })}
            onChangeCompany={(company) => setState({ ...state, company })}
            onChangePhoneNumber={(phoneNumber) =>
              setState({ ...state, phoneNumber })
            }
          />
        );
      case 2:
        return (
          <PageTwo
            registerUser={onClick}
            password={state.password}
            agbAccepted={state.agbAccepted}
            obligationOfSecrecyAccepted={state.obligationOfSecrecyAccepted}
            subscribeToNewsletter={state.subscribeToNewsletter}
            onNextPage={(page) => setState({ ...state, page })}
            onChangeAgb={(agbAccepted) => setState({ ...state, agbAccepted })}
            onChangePassword={(password) => setState({ ...state, password })}
            onChangeObligationOfSecrecy={(obligationOfSecrecyAccepted) =>
              setState({ ...state, obligationOfSecrecyAccepted })
            }
            onChangeSubscribeToNewsletter={(subscribeToNewsletter) =>
              setState({ ...state, subscribeToNewsletter })
            }
          />
        );
      case 3:
        return (
          <PageThree
            registerUserStatus={registerUserStatus}
            pathNameState={pathNameState}
          />
        );
      default:
        break;
    }
  };

  const renderLoginComponent = () => {
    return (
      <div>
        <Typography
          variant="body2"
          style={{
            marginTop: styles.spacing24,
            color: styles.darkBlueMediumDark,
          }}
        >
          Haben Sie bereits einen Account?
        </Typography>
        <Typography
          className="registration-log-in-link"
          color="primary"
          onClick={() => history.push("/login")}
          variant="body2"
          sx={{ textAlign: "center" }}
        >
          Anmelden
        </Typography>
      </div>
    );
  };

  return (
    <LandingPage headline="Registrieren">
      {renderPage()}
      {pathNameState === REGISTRATION_FROM_BROWSER_PATHNAME &&
        renderLoginComponent()}
    </LandingPage>
  );
};

function dispatchToProps(dispatch) {
  return bindActionCreators(
    {
      registerUser,
      registerAdmin,
      registerFreeAccount,
      resetRegisterFlags,
      newsletterRegistration,
    },
    dispatch
  );
}

function stateToProps(state) {
  return {
    registerUserStatus: state.getIn(["register", "registerUserStatus"]),
  };
}

Registration.propTypes = propTypes;
Registration.defaultProps = defaultProps;
export default connect(stateToProps, dispatchToProps)(Registration);
