
import cameraSVG from "../../../../../../../assets/svg/photo_camera_black_24dp.svg"
import triangleSVG from "../../../../../../../assets/svg/triangle_black_24dp.svg"

export const loadCustomImageList = (map) => {
    let cameraImg = new Image(24, 24)
    cameraImg.onload = () => map.addImage('camera', cameraImg)
    cameraImg.src = cameraSVG

    let cameraHoverImg = new Image(28, 28)
    cameraHoverImg.onload = () => map.addImage('camera-hover', cameraHoverImg)
    cameraHoverImg.src = cameraSVG

    let triangleImg = new Image(24, 24)
    triangleImg.onload = () => map.addImage("triangle", triangleImg, {sdf: true})
    triangleImg.src = triangleSVG

    let triangleHoverImg = new Image(28, 28)
    triangleHoverImg.onload = () => map.addImage("triangle-hover", triangleHoverImg, {sdf: true})
    triangleHoverImg.src = triangleSVG
}