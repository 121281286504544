export const AR_STROKE_SEGMENT = "ar.stroke.segment";
export const FEATURE_VOLUME_MEASUREMENT = "feature.volume.measurement";
export const FEATURE_LINE_MEASUREMENT = "feature.line.measurement";
export const FEATURE_GNSS_MEASUREMENT = "feature.gnss.measurement";
export const FEATURE_MULTILINE_MEASUREMENT = "feature.multiline.measurement";
export const FEATURE_TOPOGRAPHIC_POINT_MEASUREMENT =
  "feature.topographic.point.measurement";
export const FEATURE_MOBILE_APP = "feature.mobile_app";
export const FEATURE_DATAPLATFORM = "feature.dataplatform";
export const FEATURE_ADMIN_PANEL = "feature.admin_panel";
export const PROJECT_RESTRICTED_SINGLE = "project.restricted.single";
