import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { appSettingsGroupingTitle } from "../../../../constants/userSettings";

import * as styles from "../../../../styles/styles";

const propTypes = {
  property: PropTypes.string,
};

const GroupTitleUiElement = ({ property }) => {
  return (
    <Typography
      key={`group_category-title-${property}`}
      variant="body2"
      style={{ margin: `${styles.spacing32} 0 0 0` }}
    >
      {appSettingsGroupingTitle[property]}
    </Typography>
  );
};

GroupTitleUiElement.propTypes = propTypes;
export default GroupTitleUiElement;
