import React from "react";
import PropTypes from "prop-types";
import netpipeWordmark from "../../assets/images/Netpipe_wordmark_color.png";

const propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

const Wordmark = ({ width, height }) => {
  return (
    <img
      src={netpipeWordmark}
      style={{
        width: `${width}em`,
        height: `${height}em`,
      }}
    />
  );
};

Wordmark.propTypes = propTypes;
export default Wordmark;
