/* eslint-disable no-unused-vars */

const ITEMS = "items";
const MEASURE_LAYER_CONFIG_ORGANIZATION_TEMPLATES =
  "measureLayerConfigOrganizationTemplates";
const MEASUER_LAYER_CONFIG_TEMPLATES = "measureLayerConfigTemplates";
const MEASURE_LAYER_CONFIG_ITEMS = "measureLayerConfigItems";

/**
 * Result of the sync request
 *
 * ```
 * "measure_layer_config_organization_templates": [
 *   {
 *     "measure_layer_config_template": {
 *       "description": "Template mit den typischen Layern für Wasser",
 *       "measure_layer_config_items": [
 *         {
 *           "measure_layer_config_item": {
 *             "color": "indigo",
 *             "component_catalogs": null,
 *             "inserted_at": "2023-05-02T12:12:44Z",
 *             "layer_name": "Referenzpunkte",
 *             "layer_type": 2,
 *             "protected": false,
 *             "required": true,
 *             "updated_at": "2023-05-02T12:12:44Z",
 *             "uuid": "c911ab83-f03a-473d-a7a2-5c5135416320"
 *           },
 *           "measure_layer_config_template_uuid": "832857b0-9c26-4b90-b459-b4e836847499",
 *           "updated_at": "2023-05-02T12:12:44Z",
 *           "uuid": "135156dd-a7bd-4260-8213-47ef8418a91d"
 *         },
 *         {
 *           "measure_layer_config_item": {
 *             "color": "purple",
 *             "component_catalogs": null,
 *             "inserted_at": "2023-05-02T12:12:44Z",
 *             "layer_name": "Linie",
 *             "layer_type": 1,
 *             "protected": false,
 *             "required": true,
 *             "updated_at": "2023-05-02T12:12:44Z",
 *             "uuid": "737db5b6-c6dd-4351-b347-3c651abc43ba"
 *           },
 *           "measure_layer_config_template_uuid": "832857b0-9c26-4b90-b459-b4e836847499",
 *           "updated_at": "2023-05-02T12:12:44Z",
 *           "uuid": "8d703b0c-28d3-4d7c-95c2-56b406ff55d4"
 *         },
 *       ],
 *       "name": "Wasser Layer Template",
 *       "updated_at": "2023-05-02T12:12:44Z",
 *       "uuid": "832857b0-9c26-4b90-b459-b4e836847499"
 *     },
 *     "measure_layer_config_template_md5_hash": "168343A34BE5E1940DC94BFB8565CA17",
 *     "organization_id": 1,
 *     "updated_at": "2023-05-02T12:12:44Z",
 *     "uuid": "276e2025-93ea-4dbf-8ffe-4d622018add8"
 *   }
 *]
 * ```
 *
 * Process the result of the sync request. The returned structure looks
 * like this in the end.
 *
 * ```
 * {
 *   "items": {
 *     "measureLayerConfigOrganizationTemplates": [
 *       {
 *         item: {
 *           measure_layer_config_template_md5_hash: "",
 *           organization_id: 1,
 *           updated_at: "",
 *           uuid: ""
 *         }
 *       }
 *     ],
 *     "measureLayerConfigTemplates": [
 *       {
 *         item: {
 *           name: "",
 *           description: "",
 *           updated_at: "",
 *           uuid: "",
 *           rootUuid: "measureLayerConfigOrganizationTemplates uuid"
 *         },
 *         rootUuid: "measureLayerConfigOrganizationTemplates uuid"
 *       }
 *     ],
 *     "measureLayerConfigItems": [
 *       {
 *         items: [
 *           {
 *             ...,
 *             rootUuid: "measureLayerConfigTemplates uuid"
 *           }
 *         ],
 *         rootUuid: "measureLayerConfigTemplates uuid"
 *       }
 *     ]
 *   }
 * }
 * ```
 *
 * @param {object} state That is the MeasureLayerConfigTemplates State.
 * @param {object} action The action object contains the sync result.
 * @returns The updated Immutable State is returned.
 */
export function setMeasureLayerConfigTemplates(state, action) {
  if (action.payload && Object.keys(action.payload).length > 0) {
    const newMeasureLayerConfigItems = [];
    const newMeasureLayerConfigTemplates = [];
    const newMeasureLayerConfigOrganizationTemplates = [];

    const measure_layer_config_organization_templates =
      action.payload.measure_layer_config_organization_templates;

    measure_layer_config_organization_templates.forEach((element) => {
      const rootUuid = element.uuid;

      newMeasureLayerConfigOrganizationTemplates.push({
        item: parseMeasureLayerConfigOrganizationTemplate(element),
      });

      const measureLayerConfigTemplate = element.measure_layer_config_template;
      const measureLayerConfigtemplateUuid = measureLayerConfigTemplate.uuid;
      newMeasureLayerConfigTemplates.push({
        rootUuid: rootUuid,
        item: parseMeaureLayerConfigTemplate(
          rootUuid,
          measureLayerConfigTemplate
        ),
      });

      newMeasureLayerConfigItems.push({
        rootUuid: measureLayerConfigtemplateUuid,
        items: parseMeasureLayerConfigItems(
          measureLayerConfigtemplateUuid,
          measureLayerConfigTemplate
        ),
      });
    });

    const newItems = {
      measureLayerConfigOrganizationTemplates:
        newMeasureLayerConfigOrganizationTemplates,
      measureLayerConfigTemplates: newMeasureLayerConfigTemplates,
      measureLayerConfigItems: newMeasureLayerConfigItems,
    };

    return state.mergeIn([ITEMS], newItems);
  } else {
    return state;
  }
}

function parseMeasureLayerConfigOrganizationTemplate(element) {
  return {
    measure_layer_config_template_md5_hash:
      element.measure_layer_config_template_md5_hash,
    organization_id: element.organization_id,
    uuid: element.uuid,
    updated_at: element.updated_at,
  };
}

function parseMeaureLayerConfigTemplate(rootUuid, element) {
  return {
    name: element.name,
    description: element.description,
    uuid: element.uuid,
    rootUuid: rootUuid,
    updated_at: element.updated_at,
  };
}

function parseMeasureLayerConfigItems(rootUuid, measureLayerConfigTemplate) {
  const parsedList = [];

  const elements = measureLayerConfigTemplate.measure_layer_config_items;

  elements.forEach((element) => {
    parsedList.push(parseMeasureLayerConfigItem(rootUuid, element));
  });

  return parsedList;
}

function parseMeasureLayerConfigItem(rootUuid, element) {
  return {
    rootUuid: rootUuid,
    uuid: element.measure_layer_config_item.uuid,
    color: element.measure_layer_config_item.color,
    layer_name: element.measure_layer_config_item.layer_name,
    layer_type: element.measure_layer_config_item.layer_type,
    component_catalogs: element.measure_layer_config_item.component_catalogs,
    protected: element.measure_layer_config_item.protecetd,
    required: element.measure_layer_config_item.required,
    inserted_at: element.measure_layer_config_item.inserted_at,
    updated_at: element.measure_layer_config_item.updated_at,
  };
}

/**
 * Add a new MeasureLayerConfigTemplate, MeasureLayerConfigOrganizationTemplate and MeasureLayerConfigItems
 * entries into the MeasureLayerConfigTemplates State. A new MeasureLayerConfigTemplate contains
 * always two MeasureLayerConfigItems.
 *
 * @param {object} state That is the MeasureLayerConfigTemplates State.
 * @param {object} action The action object contains the newly created MeasureLayerConfigTemplate.
 * @returns The updated Immutable State is returned.
 */
export function createMeasureLayerConfigTemplate(state, action) {
  return state
    .updateIn([ITEMS, MEASURE_LAYER_CONFIG_ORGANIZATION_TEMPLATES], (items) => {
      return items.concat([
        {
          item: parseMeasureLayerConfigOrganizationTemplate(action.payload),
        },
      ]);
    })
    .updateIn([ITEMS, MEASUER_LAYER_CONFIG_TEMPLATES], (items) => {
      return items.concat([
        {
          rootUuid: action.payload.uuid,
          item: parseMeaureLayerConfigTemplate(
            action.payload.uuid,
            action.payload.measure_layer_config_template
          ),
        },
      ]);
    })
    .updateIn([ITEMS, MEASURE_LAYER_CONFIG_ITEMS], (items) => {
      return items.concat([
        {
          rootUuid: action.payload.measure_layer_config_template.uuid,
          items: parseMeasureLayerConfigItems(
            action.payload.measure_layer_config_template.uuid,
            action.payload.measure_layer_config_template
          ),
        },
      ]);
    });
}

/**
 * Update an existing MeasureLayerConfigTemplate entry.
 *
 * @param {object} state That is the MeasureLayerConfigTemplates State.
 * @param {object} action The action object contains the updated MeasureLayerConfigTemplate.
 * @returns The updated Immutable State is returned.
 */
export function updateMeasureLayerConfigTemplate(state, action) {
  const oldItemIndex = state
    .getIn([ITEMS, MEASUER_LAYER_CONFIG_TEMPLATES])
    .findIndex((x) => x.item.uuid === action.payload.uuid);

  return state.updateIn(
    [ITEMS, MEASUER_LAYER_CONFIG_TEMPLATES, oldItemIndex],
    function (item) {
      return {
        rootUuid: item.rootUuid,
        item: {
          rootUuid: item.rootUuid,
          ...action.payload,
        },
      };
    }
  );
}

/**
 * Delete a MeasureLayerConfigTemplate entry from the MeasureLayerConfigTemplates State.
 *
 * @param {object} state That is the MeasureLayerConfigTemplates State.
 * @param {object} action The action object contains the deleted MeasureLayerConfigTemplate.
 * @returns The updated Immutable State is returned.
 */
export function deleteMeasureLayerConfigTemplate(state, action) {
  const oldItemIndex = state
    .getIn([ITEMS, MEASUER_LAYER_CONFIG_TEMPLATES])
    .findIndex((x) => x.item.uuid === action.payload.uuid);

  const measureLayerConfigTemplate = state.getIn([
    ITEMS,
    MEASUER_LAYER_CONFIG_TEMPLATES,
    oldItemIndex,
  ]);

  const measureLayerConfigOrganizationTemplateIndex = state
    .getIn([ITEMS, MEASURE_LAYER_CONFIG_ORGANIZATION_TEMPLATES])
    .findIndex((x) => x.item.uuid === measureLayerConfigTemplate.rootUuid);

  const measureLayerConfigItemsIndex = state
    .getIn([ITEMS, MEASURE_LAYER_CONFIG_ITEMS])
    .findIndex((x) => x.rootUuid === action.payload.uuid);

  return state
    .deleteIn([ITEMS, MEASUER_LAYER_CONFIG_TEMPLATES, oldItemIndex])
    .deleteIn([ITEMS, MEASURE_LAYER_CONFIG_ITEMS, measureLayerConfigItemsIndex])
    .deleteIn([
      ITEMS,
      MEASURE_LAYER_CONFIG_ORGANIZATION_TEMPLATES,
      measureLayerConfigOrganizationTemplateIndex,
    ]);
}

/**
 * Add a new MeasureLayerConfigItem entry to the MeasureLayerConfigTemplates State.
 *
 * @param {object} state That is the MeasureLayerConfigTemplates State.
 * @param {object} action The action object contains the newly created MeasureLayerConfigItem.
 * @returns The updated Immutable State is returned.
 */
export function createMeasureLayerConfigItem(state, action) {
  const itemIndex = state
    .getIn([ITEMS, MEASURE_LAYER_CONFIG_ITEMS])
    .findIndex(
      (item) =>
        item.rootUuid === action.payload.measure_layer_config_template_uuid
    );

  return state.updateIn(
    [ITEMS, MEASURE_LAYER_CONFIG_ITEMS, itemIndex],
    function (item) {
      return {
        rootUuid: item.rootUuid,
        items: item.items.concat([
          parseMeasureLayerConfigItem(item.rootUuid, action.payload),
        ]),
      };
    }
  );
}

/**
 * Update an existing MeasureLayerConfigItem entry.
 *
 * @param {object} state That is the MeasureLayerConfigTemplates State.
 * @param {object} action The action object contains the updated MeasureLayerConfigItem.
 * @returns The updated Immutable State is returned.
 */
export function updateMeasureLayerConfigItem(state, action) {
  const activeMeasureLayerConfigTemplateUuid = state.get(
    "activeMeasureLayerConfigTemplateUuid"
  );

  const measureLayerConfigItemsIndex = state
    .getIn([ITEMS, MEASURE_LAYER_CONFIG_ITEMS])
    .findIndex(
      (measureLayerConfigItem) =>
        measureLayerConfigItem.rootUuid === activeMeasureLayerConfigTemplateUuid
    );

  const itemIndex = state
    .getIn([
      ITEMS,
      MEASURE_LAYER_CONFIG_ITEMS,
      measureLayerConfigItemsIndex,
      ITEMS,
    ])
    .findIndex((item) => item.uuid === action.payload.uuid);

  return state.updateIn(
    [
      ITEMS,
      MEASURE_LAYER_CONFIG_ITEMS,
      measureLayerConfigItemsIndex,
      ITEMS,
      itemIndex,
    ],
    function (item) {
      return {
        root_uuid: item.root_uuid,
        ...action.payload,
      };
    }
  );
}

/**
 * Delete a MeasureLayerConfigItem entry from the MeasureLayerConfigTemplates State.
 *
 * @param {object} state That is the MeasureLayerConfigTemplates State.
 * @param {object} action The action object contains the deleted MeasureLayerConfigItem.
 * @returns The updated Immutable State is returned.
 */
export function deleteMeasureLayerConfigItem(state, action) {
  const activeMeasureLayerConfigTemplateUuid = state.get(
    "activeMeasureLayerConfigTemplateUuid"
  );

  const measureLayerConfigItemsIndex = state
    .getIn([ITEMS, MEASURE_LAYER_CONFIG_ITEMS])
    .findIndex(
      (measureLayerConfigItem) =>
        measureLayerConfigItem.rootUuid === activeMeasureLayerConfigTemplateUuid
    );

  const itemIndex = state
    .getIn([
      ITEMS,
      MEASURE_LAYER_CONFIG_ITEMS,
      measureLayerConfigItemsIndex,
      ITEMS,
    ])
    .findIndex((item) => item.uuid === action.payload.uuid);

  return state.deleteIn([
    ITEMS,
    MEASURE_LAYER_CONFIG_ITEMS,
    measureLayerConfigItemsIndex,
    ITEMS,
    itemIndex,
  ]);
}
