import React from "react";
import PropTypes from "prop-types";

import Tooltip from "@mui/material/Tooltip";

import "./styles.css";

const propTypes = {
  color: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.string,
};

const defaultProps = {
  color: "#000000",
  name: "",
  size: "xs",
};

const ColoredCircle = ({ color, name, size }) => {
  return (
    <div>
      {name === "" ? (
        <div
          className={`circle circle-${size}`}
          style={{ backgroundColor: color }}
        ></div>
      ) : (
        <Tooltip title={name} arrow>
          <div
            className={`circle circle-${size}`}
            style={{ backgroundColor: color }}
          ></div>
        </Tooltip>
      )}
    </div>
  );
};

ColoredCircle.propTypes = propTypes;
ColoredCircle.defaultProps = defaultProps;
export default ColoredCircle;
