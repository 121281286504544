/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Spinner from "../../../atoms/Spinner";

import MeasureLayerConfigTemplateHeader from "./MeasureLayerConfigTemplateHeader";
import MeasureLayerConfigDialog from "../../../containers/dialogs/MeasureLayerConfigDialog";
import MeasureLayerConfigItemsTable from "./MeasureLayerConfigItemsTable";

import {
  createMeasureLayerConfigItem,
  syncMeasureLayerConfigTemplates,
} from "../../../actions/measureLayerConfigTemplates";
import { syncComponentCatalogs } from "../../../actions/componentCatalogs";
import { idle, success } from "../../../constants/status";
import {
  LAYER_TYPE_REFERENCE_POINT_IDX,
  LAYER_TYPE_POINT_IDX,
  LAYER_TYPE_LINE_IDX,
  LAYER_TYPE_GNSS_POINT_IDX,
  LAYER_TYPE_GNSS_LINE_IDX,
  LAYER_TYPE_GNSS_CONTINUOUS_LINE_IDX,
  LAYER_TYPE_GNSS_POLYGON_IDX,
  LAYER_TYPE_GNSS_REF_POINT_IDX
} from "../../../constants/measureLayerConfig"
import { isAdmin } from "../../../sharedFunctions/user";

import "./styles.css";

const propTypes = {
  syncStatus: PropTypes.string,
  syncStatusComponentCatalogs: PropTypes.string,
  measureLayerConfigTemplateUuid: PropTypes.string,
  measureLayerConfigTemplate: PropTypes.any,
  measureLayerConfigItems: PropTypes.array,
  isAdmin: PropTypes.bool,
  createMeasureLayerConfigItem: PropTypes.func,
  syncMeasureLayerConfigTemplates: PropTypes.func,
  syncComponentCatalogs: PropTypes.func,
};

const MeasureLayerConfigTemplate = ({
  syncStatus,
  syncStatusComponentCatalogs,
  measureLayerConfigTemplateUuid,
  measureLayerConfigTemplate,
  measureLayerConfigItems,
  isAdmin,
  createMeasureLayerConfigItem,
  syncMeasureLayerConfigTemplates,
  syncComponentCatalogs,
}) => {
  const sortedMap = sortMeasureLayerConfigArray(measureLayerConfigItems);
  const [createModalOpen, setCreateModalOpen] = React.useState(false);

  React.useEffect(() => {
    if (syncStatus === idle) {
      syncMeasureLayerConfigTemplates();
    }
    if (syncStatusComponentCatalogs === idle) {
      syncComponentCatalogs();
    }
  }, [syncStatus, syncStatusComponentCatalogs]);

  const closeModal = () => {
    setCreateModalOpen(false);
  };

  const onCreateMeasureLayerConfigs = (payload) => {
    const data = {
      params: {
        ...payload.measureLayerConfigs[0],
      },
    };

    createMeasureLayerConfigItem(measureLayerConfigTemplateUuid, data);
  };

  return (
    <div className="project-bottom-container">
      {createModalOpen &&
        showCreateMeasureLayerConfigDialog(
          measureLayerConfigItems,
          createModalOpen,
          closeModal,
          onCreateMeasureLayerConfigs
        )}
      {syncStatus != success ? (
        <div className="spinner-container">
          <Spinner />
        </div>
      ) : (
        <div>
          <MeasureLayerConfigTemplateHeader
            measureLayerConfigTemplate={measureLayerConfigTemplate}
            setCreateModalOpen={setCreateModalOpen}
            isAdmin={isAdmin}
          />
          <MeasureLayerConfigItemsTable
            isAdmin={isAdmin}
            measureLayerConfigTemplateUuid={measureLayerConfigTemplateUuid}
            measureLayerConfigItemMap={sortedMap}
          />
        </div>
      )}
    </div>
  );
};

function showCreateMeasureLayerConfigDialog(
  measureLayerConfigItems,
  createModalOpen,
  closeModal,
  onCreateMeasureLayerConfigs
) {
  return (
    <MeasureLayerConfigDialog
      measureLayerConfigs={measureLayerConfigItems}
      edit={false}
      modalOpen={createModalOpen}
      onClose={() => closeModal()}
      onClick={() => closeModal()}
      onCreateMeasureLayerConfigs={onCreateMeasureLayerConfigs}
    />
  );
}

function sortMeasureLayerConfigArray(unsortArray) {
  const sortedMap = new Map();

  unsortArray.forEach((element) => {
    let priority = 0;
    if (element.required) {
      if (element.layer_type === LAYER_TYPE_REFERENCE_POINT_IDX) priority = 1;
      else if (element.layer_type === LAYER_TYPE_LINE_IDX) priority = 2;
      else if (element.layer_type === LAYER_TYPE_POINT_IDX) priority = 3;
    } else {
      if (element.layer_type === LAYER_TYPE_LINE_IDX) priority = 4;
      else if (element.layer_type === LAYER_TYPE_POINT_IDX) priority = 5;
      else if (element.layer_type === LAYER_TYPE_GNSS_POINT_IDX) priority = 6;
      else if (element.layer_type === LAYER_TYPE_GNSS_LINE_IDX) priority = 7;
      else if (element.layer_type === LAYER_TYPE_GNSS_POLYGON_IDX) priority = 8;
      else if (element.layer_type === LAYER_TYPE_GNSS_CONTINUOUS_LINE_IDX) priority = 9;
      else if (element.layer_type === LAYER_TYPE_GNSS_REF_POINT_IDX) priority = 10;
    }

    sortedMap.set(element.uuid || element.id, { priority, element });
  })

  // Create a new sorted Map by the priority of every entry of the sortedMap Map.
  const sortedMapByPriority = new Map([...sortedMap.entries()].sort((a, b) => a[1].priority - b[1].priority));
  
  // Create the final Map with the uuid or id of an element as the key
  // and the element as the value.
  const finalSortedMap = new Map();
  sortedMapByPriority.forEach((value, key) => {
    finalSortedMap.set(key, value.element);
  });

  return finalSortedMap;
}

function dispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createMeasureLayerConfigItem,
      syncMeasureLayerConfigTemplates,
      syncComponentCatalogs,
    },
    dispatch
  );
}

function stateToProps(state, ownProps) {
  const measureLayerConfigTemplateUuid = ownProps.match.params.id;

  const syncStatusComponentCatalogs = state.getIn([
    "componentCatalogs",
    "componentCatalogSyncStatus",
  ]);

  const syncStatus = state.getIn([
    "measureLayerConfigTemplates",
    "measureLayerConfigTemplateSyncStatus",
  ]);

  const data = state.getIn(["measureLayerConfigTemplates", "items"]).toJS();

  const measureLayerConfigItemsHolder = data.measureLayerConfigItems.filter(
    (item) => {
      return item.rootUuid === measureLayerConfigTemplateUuid;
    }
  );

  const measureLayerConfigItems = measureLayerConfigItemsHolder.length
    ? measureLayerConfigItemsHolder[0].items
    : [];

  const measureLayerConfigTemplate = data.measureLayerConfigTemplates.filter(
    (item) => {
      return item.item.uuid === measureLayerConfigTemplateUuid;
    }
  );

  return {
    syncStatusComponentCatalogs,
    syncStatus: syncStatus,
    isAdmin: isAdmin(state.getIn(["user", "organization_role_groups"]), true),
    measureLayerConfigTemplateUuid,
    measureLayerConfigTemplate,
    measureLayerConfigItems: measureLayerConfigItems,
  };
}

MeasureLayerConfigTemplate.propTypes = propTypes;
export default connect(
  stateToProps,
  dispatchToProps
)(MeasureLayerConfigTemplate);
