/* eslint-disable import/namespace */
/* eslint-disable no-unused-vars */
import { createAction } from "redux-actions";
import {
  SET_TOPOGRAPHIC_POINT,
  SET_TOPOGRAPHIC_POINTS,
  EDIT_TOPOGRAPHIC_POINT,
  REMOVE_TOPOGRAPHIC_POINT,
  REMOVE_TOPOGRAPHIC_POINTS,
} from "../constants/actions/actions";
import {
  TOPOGRAPHIC_POINTS_CREATE_EVENT,
  TOPOGRAPHIC_POINT_UPDATE_EVENT,
} from "../constants/channelevents";
import {
  updateTopographicPointSuccess,
  updateTopographicPointFail,
} from "../constants/snackbars";

import API from "../lib/api";
import HTTP from "../lib/http";
import { renderSnackbar } from "./snackbars";

export const setTopographicPoints = createAction(SET_TOPOGRAPHIC_POINTS);
export const setTopographicPoint = createAction(SET_TOPOGRAPHIC_POINT);
export const editTopographicPoint = createAction(EDIT_TOPOGRAPHIC_POINT);
export const removeTopographicPoint = createAction(REMOVE_TOPOGRAPHIC_POINT);
export const removeTopographicPoints = createAction(REMOVE_TOPOGRAPHIC_POINTS);

export const CableActions = (message, connection, store) => {
  switch (message.event) {
    case TOPOGRAPHIC_POINTS_CREATE_EVENT:
      store.dispatch(setTopographicPoints(message.payload.topographic_points));
      break;

    case TOPOGRAPHIC_POINT_UPDATE_EVENT:
      store.dispatch(
        editTopographicPoint({
          id: message.payload.topographic_point.id,
          item: message.payload.topographic_point,
        })
      );
      break;
    default:
      break;
  }
};

export function updateTopographicPoint(topographic_point) {
  return async (dispatch, getState) => {
    await HTTP.put(
      API.topographicPoints.updateTopographicPoint(topographic_point.id),
      { topographic_point }
    )
      .then(function (response) {
        dispatch(renderSnackbar(updateTopographicPointSuccess));
      })
      .catch(function (error) {
        dispatch(renderSnackbar(updateTopographicPointFail));
      });
  };
}
