/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import React from "react";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { Tooltip } from "@mui/material";

import * as styles from "../../../../styles/styles";

const propTypes = {
  data: PropTypes.object,
  defaultComponentCategories: PropTypes.array,
  organizationComponentCategories: PropTypes.array,
  changeComponentCatalog: PropTypes.func,
  stateComponentCatalogs: PropTypes.array,
};

const ComponentCatalogSelector = ({
  data,
  organizationComponentCategories,
  defaultComponentCategories,
  changeComponentCatalog,
  stateComponentCatalogs,
}) => {
  const generateTooltipText = (element) => {
    const components = data
      .getIn(["catalogComponentsCategories"])
      .filter((item) => {
        return item.rootUuid === element.item.uuid;
      });
    const materials = data
      .getIn(["catalogMaterialsCategories"])
      .filter((item) => {
        return item.rootUuid === element.item.uuid;
      });
    const dimensions = data
      .getIn(["catalogDimensionsCategories"])
      .filter((item) => {
        return item.rootUuid === element.item.uuid;
      });

    const componentCountText =
      components[0].items.length === 1 ? "Komponente" : "Komponenten";
    const materialCountText =
      materials[0].items.length === 1 ? "Material" : "Materialien";
    const dimensionCountText =
      dimensions[0].items.length === 1 ? "Dimension" : "Dimensionen";

    return `${components[0].items.length} ${componentCountText}, 
      ${materials[0].items.length} ${materialCountText}, 
      ${dimensions[0].items.length} ${dimensionCountText}`;
  };
  // for now accept only one entry, multiple are possible according to the DB Schema
  const value =
    stateComponentCatalogs.length > 0 ? stateComponentCatalogs[0] : "";

  const menuItems = [];
  menuItems.push(
    <MenuItem key={"empty"} value={""}>
      Ohne
    </MenuItem>
  );

  if (organizationComponentCategories.length > 0) {
    menuItems.push(
      <ListSubheader
        sx={{
          backgroundColor: `${styles.primary}`,
          color: `${styles.white}`,
        }}
        key={`list-subheader-1`}
      >
        Bauteillisten
      </ListSubheader>
    );

    organizationComponentCategories.forEach((element) => {
      menuItems.push(
        <MenuItem
          key={`${element.rootUuid}_${element.item.uuid}`}
          value={element.item.uuid}
        >
          <Tooltip enterDelay={1000} title={generateTooltipText(element)}>
            <div style={{ marginLeft: "1.5rem" }}>{element.item.name}</div>
          </Tooltip>
        </MenuItem>
      );
    });
  }

  if (defaultComponentCategories.length > 0) {
    menuItems.push(
      <ListSubheader
        sx={{
          backgroundColor: `${styles.primary}`,
          color: `${styles.white}`,
        }}
        key={`list-subheader-2`}
      >
        Standard Bauteillisten
      </ListSubheader>
    );

    defaultComponentCategories.forEach((element) => {
      menuItems.push(
        <MenuItem
          key={`${element.rootUuid}_${element.item.uuid}`}
          value={element.item.uuid}
        >
          <Tooltip enterDelay={1000} title={generateTooltipText(element)}>
            <div style={{ marginLeft: "1.5rem" }}>{element.item.name}</div>
          </Tooltip>
        </MenuItem>
      );
    });
  }

  return (
    <div style={{ margin: `0 0 ${styles.spacing32} 0` }}>
      <FormControl fullWidth={true} size="medium">
        <InputLabel id="select-component-catalog-label">
          Bauteilliste (optional)
        </InputLabel>
        <Select
          label="Bauteilkatalog (optional)"
          labelId="select-component-catalog-label"
          id="select-component-catalog"
          value={value}
          onChange={changeComponentCatalog}
          defaultValue={""}
        >
          {menuItems}
        </Select>
      </FormControl>
    </div>
  );
};

ComponentCatalogSelector.propTypes = propTypes;
export default ComponentCatalogSelector;
