// PROJECT
export const deleteProjectSuccess = "deleteProjectSuccess";
export const deleteProjectFail = "deleteProjectFail";
export const deleteProjectsFail = "deleteProjectsFail";
export const downloadProjectsFail = "downloadProjectsFail";
export const updateProjectFail = "updateProjectFail";
export const updateProjectSuccess = "updateProjectSuccess";
export const assignProjectSuccess = "assignProjectSuccess";
export const assignProjectFail = "assignProjectFail";
export const createProjectFail = "createProjectFail";
export const createProjectSuccess = "createProjectSuccess";
export const createProjectFailOnlyOneProjectAllowed =
  "createProjectFailOnlyOneProjectAllowed";
export const createProjectFailJobOrderAlreadyUsed =
  "createProjectFailJobOrderAlreadyUsed";
export const resetProjectFail = "resetProjectFail";
export const resetProjectSuccess = "resetProjectSuccess";

// USER
export const inviteUserFail = "inviteUserFail";
export const inviteUserSuccess = "inviteUserSuccess";
export const resendInvitationFail = "resendInvitationFail";
export const resendInvitationSuccess = "resendInvitationSuccess";
export const deleteInvitationFail = "deleteInvitationFail";
export const deleteInvitationSuccess = "deleteInvitationSuccess";
export const removeUserFail = "removeUserFail";
export const removeUserSuccess = "removeUserSuccess";
export const deleteAvatarSuccess = "deleteAvatarSuccess";
export const deleteAvatarFail = "deleteAvatarFail";
export const editUserDetailsSuccess = "editUserDetailsSuccess";
export const editUserDetailsFail = "editUserDetailsFail";
export const uploadAvatarSuccess = "uploadAvatarSuccess";
export const uploadAvatarFail = "uploadAvatarFail";
export const requestAccountDeletionSuccess = "requestAccountDeletionSuccess";
export const requestAccountDeletionFail = "requestAccountDeletionFail";
export const confirmAccountDeletionSuccess = "confirmAccountDeletionSuccess";
export const confirmAccountDeletionFail = "confirmAccountDeletionFail";

// MEASUREMENT
export const updateMeasurementSuccess = "updateMeasurementSuccess";
export const updateMeasurementFail = "updateMeasurementFail";

// MEASUREMENT_LINE
export const updateMeasurementLineSuccess = "updateMeasurementLineSuccess";
export const updateMeasurementLineFail = "updateMeasurementLineFail";

// MEASUREMENT_SEGMENT
export const updateMeasurementSegmentSuccess =
  "updateMeasurementSegmentSuccess";
export const updateMeasurementSegmentFail = "updateMeasurementSegmentFail";

// VOLUME
export const updateVolumeSuccess = "updateVolumeSuccess";
export const updateVolumeFail = "updateVolumeFail";
export const deleteVolumeSuccess = "deleteVolumeSuccess";
export const deleteVolumeFail = "deleteVolumeFail";

// TOPOGRAPHIC POINT
export const updateTopographicPointSuccess = "updateTopographicPointSuccess";
export const updateTopographicPointFail = "updateTopographicPointFail";

// MEASURE_LAYER_CONFIG
export const updateMeasureLayerConfigSuccess =
  "updateMeasureLayerConfigSuccess";
export const updateMeasureLayerConfigFail = "updateMeasureLayerConfigFail";
export const deleteMeasureLayerConfigSuccess =
  "deleteMeasureLayerConfigSuccess";
export const deleteMeasureLayerConfigFail = "deleteMeasureLayerConfigFail";
export const createMeasureLayerConfigSuccess =
  "createMeasureLayerConfigSuccess";
export const createMeasureLayerConfigFail = "createMeasureLayerConfigFail";

// MEDIA_FILE
export const deleteMediaFileSuccess = "deleteMediaFileSuccess";
export const deleteMediaFileFail = "deleteMediaFileFail";

// PROJECT_FILE
export const createProjectFileSuccess = "createProjectFileSuccess";
export const createProjectFileFail = "createProjectFileFail";
export const deleteProjectFileSuccess = "deleteProjectFileSuccess";
export const deleteProjectFileFail = "deleteProjectFileFail";
export const projectFileIsToLargeFail = "projectFileIsToLargeFail";
export const projectFileExtensionNotSupportedFail =
  "projectFileExtensionNotSupportedFail";
export const createProjectFileAlreadyUploadedFail =
  "createProjectFileAlreadyUploadedFail";

// MAP_LAYER
export const createMapLayerSuccess = "createMapLayerSuccess"
export const createMapLayerFail = "createMapLayerFail"
export const updateMapLayerSuccess = "updateMapLayerSuccess"
export const updateMapLayerFail = "updateMapLayerFail"
export const deleteMapLayerSuccess = "deleteMapLayerSuccess"
export const deleteMapLayerFail = "deleteMapLayerFail"

export const snackbarSeverityLookupTable = {
  error: "Fehler",
  info: "Information",
  success: "Erfolgreich",
  warning: "Warnung",
};
