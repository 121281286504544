/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import queryString from "qs";

import { confirmAccountDeletion } from "../../../actions/user";

import { confirmAccountDeletionSuccess } from "../../../constants/snackbars";

import ConfirmAccountDeletionHeader from "./ConfirmAccountDeletionHeader";

import "./styles.css";

const propTypes = {
  history: PropTypes.object,
  organization: PropTypes.object,
  location: PropTypes.object,
  confirmAccountDeletion: PropTypes.func,
};

const ConfirmAccountDeletion = ({
  location,
  history,
  organization,
  confirmAccountDeletion,
}) => {
  const [state, setState] = useState({
    optionalText: "",
  });

  let urlParams = queryString.parse(location && location.search);
  const token = urlParams["?account_deletion_token"];

  const changeOptionalText = (event) => {
    const description = event.target.value;
    setState({ ...state, optionalText: description });
  };

  const executeConfirmAccountDeletion = () => {
    (async () => {
      const executionState = await confirmAccountDeletion(
        organization.get("id"),
        token,
        state.optionalText
      );

      if (executionState == confirmAccountDeletionSuccess) {
        history.replace(`/settings`);
      }
    })();
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <ConfirmAccountDeletionHeader />
      <div className="confirm-account-deletion-box">
        <div style={{ maxWidth: "500px" }}>
          <Typography
            variant="body1"
            component="div"
            sx={{ marginBottom: "2rem", fontSize: "1.5rem" }}
          >
            Wenn Sie diesen Vorgang bestätigen, leiten wir alles Notwendige in
            die Wege, um Ihren Account und alle dazugehörigen Daten zu löschen.
            Falls auf unserer Seite noch Fragen auftauchen sollten, werden wir
            uns bei Ihnen melden. Wenn Sie möchten, können Sie uns optional
            mitteilen, aus welchen Gründen Sie Ihren Account löschen möchten.
          </Typography>
          <TextField
            fullWidth={true}
            margin="none"
            label="Optionaler Text"
            multiline={true}
            onChange={changeOptionalText}
            placeholder="Gründe (Optional)"
            required={true}
            sx={{ marginBottom: "2.4rem" }}
            minRows="5"
            variant="outlined"
            value={state.optionalText}
          />
          <Button
            style={{ width: "500px" }}
            size="medium"
            variant="contained"
            color="error"
            onClick={() => executeConfirmAccountDeletion()}
          >
            Bestätigung der Kontolöschung
          </Button>
        </div>
      </div>
    </div>
  );
};

function dispatchToProps(dispatch) {
  return bindActionCreators(
    {
      confirmAccountDeletion,
    },
    dispatch
  );
}

function stateToProps(state) {
  const organization = state.getIn(["organizations", "items"]).first();
  return {
    organization,
  };
}

ConfirmAccountDeletion.propTypes = propTypes;
export default connect(stateToProps, dispatchToProps)(ConfirmAccountDeletion);
