// used as used in the app
// needed as kes to access media files
export const WKT = "wkt";
export const DXF = "dxf";
export const DXF_LOCAL = "dxf_local";
export const DXF_8_DIGIT = "dxf_8_digit";
export const SHAPE = "shape";
export const LOCAL = "local";
export const PDF = "pdf";
export const IMAGES = "images";
export const TRANSFORMATION_RESULT = "transformationResult";
export const TXT = "txt";
export const IMAGE = "image";
export const DATA_MONITOR_RESULT = "dataMonitoringResult";

export const nameMap = {
  [WKT]: "WKT",
  [DXF]: "DXF",
  [DXF_LOCAL]: "DXF lokale Koordinaten",
  [DXF_8_DIGIT]: "DXF 8-stellig",
  [SHAPE]: "Shape Datei",
  [LOCAL]: "Lokales Koordinatensystem",
  [PDF]: "PDF",
  [IMAGES]: "Bilder",
  [TRANSFORMATION_RESULT]: "Ergebnis der Koordinatentransformation",
  [TXT]: "Textdatei",
  [DATA_MONITOR_RESULT]: "Ergebnis des Datenmonitoring",
};
