/* eslint-disable no-unused-vars */
const ITEMS = "items";
const MAP_LAYERS = "mapLayers";
const MAP_LAYERS_TO_PROJECT_ASSIGNMENT = "mapLayersToProjectAssignment";

/**
 * Result of a MapLayer Sync-Request
 * 
 * {
 *   "map_layers": [
 *       {
 *           "file": {
 *               "original": ""
 *           },
 *           "description": "DiesDas",
 *           "uuid": "e18a71e2-8ff1-4f54-8e2c-0f6be8c4f1b0",
 *           "updated_at": "2024-04-05T07:46:58Z",
 *           "inserted_at": "2024-04-05T07:46:58Z",
 *           "organization_id": 2,
 *           "original_filename": "Spree_Palais_8.tif",
 *           "map_file_type": "tif",
 *           "map_layer_type": "overlay",
 *           "map_layer_reason_type": "map_layer",
 *           "map_layer_assignment_type": "global"
 *       },
 *   ]
 * }
 */
export function setMapLayersToState(state, action) {
    if (action.payload && Object.keys(action.payload).length > 0) {

      const newItems = {
        ...state.getIn([ITEMS, "mapLayersToProjectAssignment"]),
        mapLayers: action.payload.map_layers
      }

      return state.mergeIn([ITEMS], newItems);

    } else {
        return state;
    }
}

/**
 * {
 *   "map_layers_project_mapping": [
 *     {
 *       "uuid": "8b4eaf5e-6c20-4bec-b25b-dd8973b6706b",
 *       "map_layer_uuid": "df7c36f9-3e35-4323-9c07-88f6cd637665",
 *       "project_id": 1,
 *       "inserted_at": "2024-04-11T15:14:22Z",
 *       "updated_at": "2024-04-11T15:14:22Z"
 *     }
 *   ]
 * }
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */
export function setMapLayerToProjectAssignmentsToState(state, action) {
  if (action.payload && Object.keys(action.payload).length > 0) {
    const newItems = {
      ...state.getIn([ITEMS, "mapLayers"]),
      mapLayersToProjectAssignment: action.payload.map_layers_project_mapping
    }
    
    return state.mergeIn([ITEMS], newItems)
  
  } else {
    return state;
  }
}

/**
 * Create a new Entry in the local state for MapLayers
 * 
 * {
 *   "file": {
 *       "original": ""
 *   },
 *   "description": "gdfrcdxc",
 *   "uuid": "c66051ee-1c3d-41d1-afb0-c35bf3ca2a99",
 *   "updated_at": "2024-04-10T06:02:32Z",
 *   "inserted_at": "2024-04-10T06:02:32Z",
 *   "organization_id": 2,
 *   "original_filename": "Berlin_Anlagenbäume_Spandau.geojson",
 *   "map_file_type": "tif",
 *   "map_layer_type": "basemap",
 *   "map_layer_reason_type": "map_layer",
 *   "map_layer_assignment_type": "project"
 * }
 * 
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */
export function createMapLayerToState(state, action) {
    if (action.payload) {
      return state.updateIn([ITEMS, MAP_LAYERS], (items) => {
        return items.concat([
          action.payload
        ])
      })

    } else {
      return state;
    }
}

/**
 * Create a new Entry in the local state for MapLayerToProjectAssignments
 * 
 * {
 *    "uuid": "8b4eaf5e-6c20-4bec-b25b-dd8973b6706b",
 *    "map_layer_uuid": "df7c36f9-3e35-4323-9c07-88f6cd637665",
 *    "project_id": 1,
 *    "inserted_at": "2024-04-11T15:14:22Z",
 *    "updated_at": "2024-04-11T15:14:22Z"
 * }
 * 
 * @param {*} state 
 * @param {*} action 
 */
export function createMapLayerToProjectAssignmentsToState(state, action) {
  if (action.payload) {

    if(!("map_layer_uuid" in action.payload)) {
      action.payload["map_layer_uuid"] = action.payload.map_layer.uuid
    }

    return state.updateIn([ITEMS, MAP_LAYERS_TO_PROJECT_ASSIGNMENT], (items) => {
      return items.concat([
        action.payload
      ])
    })

  } else {
    return state;
  }
}

/**
 * 
 * @param {*} state 
 * @param {*} action 
 * @returns
 */
export function updateMapLayerToState(state, action) {
  const oldItemIndex = state
    .getIn([ITEMS, MAP_LAYERS])
    .findIndex((x) => x.uuid === action.payload.uuid);

  return state.updateIn(
    [ITEMS, MAP_LAYERS, oldItemIndex],
    function (item) {
      return {
        ...action.payload
      }
    }
  )
}


/**
 * 
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */
export function deleteMapLayerToState(state, action) {
  const itemIndex = state
    .getIn([ITEMS, MAP_LAYERS])
    .findIndex((x) => x.uuid === action.payload.uuid);

  return state.deleteIn(
    [ITEMS, MAP_LAYERS, itemIndex]
  )
}

/**
 * 
 * @param {*} state 
 * @param {*} action 
 */
export function deleteMapLayerToProjectAssignmentsToState(state, action) {
  const itemIndex = state
    .getIn([ITEMS, MAP_LAYERS_TO_PROJECT_ASSIGNMENT])
    .findIndex((x) => x.uuid === action.payload.uuid);

  return state.deleteIn(
    [ITEMS, MAP_LAYERS_TO_PROJECT_ASSIGNMENT, itemIndex]
  )
}