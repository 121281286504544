/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import InputBase from "@mui/material/InputBase";
import classNames from "classnames";
import SearchIcon from "@mui/icons-material/Search";
import { searchInList } from "../../sharedFunctions/search";
import { SEARCH_KEY_OBJECT } from "../../constants/project";
import * as styles from "../../styles/styles";
import "./styles.css";

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  classes: PropTypes.object,
  showResults: PropTypes.bool,
  projects: PropTypes.object,
  users: PropTypes.array,
  searchKeyList: PropTypes.object,
  results: PropTypes.func,
  search: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  placeholderText: PropTypes.string,
};

const defaultProps = {
  results: () => {},
  showResults: false,
  projects: {},
  searchKeyList: SEARCH_KEY_OBJECT,
  placeholderText: "Suche...",
};

const overrideStyles = (theme) => ({
  search: {
    marginLeft: "6rem",
    width: "auto",
    height: "3.8rem",
  },
  inputRoot: {
    color: styles.darkBlueBase,
    height: "3.7rem",
    lineHeight: "3.7rem",
  },
  inputInput: {
    margin: "0 0 0 2.5rem",
    padding: "1rem",
    width: "100%",
  },
  inputFocused: {
    color: styles.darkBlueDarker,
  },
});

const SearchButton = ({
  children,
  classes,
  showResults,
  projects,
  users,
  searchKeyList,
  results,
  searchTerm,
  search,
  placeholderText,
}) => {
  const [searchTermState, setSearchTermState] = React.useState(searchTerm);
  const [timeoutState, setTimeoutState] = React.useState({});

  const onChange = (event) => {
    setSearchTermState(event.target.value);
    const searchTerm = event.target.value;

    clearTimeout(timeoutState);

    // Simple debounce effect to not search after every keystroke for a
    // matching project
    setTimeoutState(
      setTimeout(() => {
        // call a action. The component who is listing this action will perform
        // a search by it's own
        search(searchTerm);
        // if showResults is set to true, perform a search
        // directly in this component and send the results to
        // a callback function called results.
        showResults &&
          results(
            searchInList(
              {
                projects: projects,
                users: users,
              },
              searchKeyList,
              searchTerm,
              "projects"
            )
          );
      }, 500)
    );
  };

  return (
    <div className={classNames(classes.search, "search-button")}>
      <div className="search-button-icon">
        <SearchIcon style={{ color: styles.darkBlueDark }} />
      </div>
      <InputBase
        placeholder={placeholderText}
        value={searchTermState}
        onChange={onChange}
        type="search"
        classes={{
          focused: classes.inputFocused,
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ "aria-label": "Search" }}
      />
      {children}
    </div>
  );
};

SearchButton.propTypes = propTypes;
SearchButton.defaultProps = defaultProps;
const styledSearchButton = withStyles(overrideStyles, { withTheme: true })(
  SearchButton
);
export default styledSearchButton;
