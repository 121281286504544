/* eslint-disable no-unused-vars */
import { COMPONENT_TYPE_HOVER } from "../../../../../../../constants/ecs";

export default class ComponentHover {
  constructor(params) {
    this.componentType = COMPONENT_TYPE_HOVER;
  }

  getComponentType() {
    return this.componentType;
  }
}
