import PropTypes from "prop-types";
import React from "react";

import { Typography } from "@mui/material";

import ColoredAndClickableCircle from "../../../../components/ColoredAndClickableCircle";

import { COLOR_MAP } from "../../../../constants/colormapping";

import * as styles from "../../../../styles/styles";

import "./styles.css";

const propTypes = {
  stateColor: PropTypes.string,
  onSelect: PropTypes.func,
};

const ColorSelection = ({ stateColor, onSelect }) => {
  const renderColorSelector = () => {
    return Object.keys(COLOR_MAP).map((key, index) => {
      return (
        <ColoredAndClickableCircle
          key={index}
          colorKey={key}
          color={COLOR_MAP[key]}
          size={"xm"}
          selected={stateColor === key}
          onSelect={onSelect}
        />
      );
    });
  };

  return (
    <div>
      <Typography
        style={{ margin: `0 0 ${styles.spacing16} 0` }}
        variant="subtitle2"
      >
        {"Farbe auswählen *"}
      </Typography>
      <div className="coloredCircleHolder">{renderColorSelector()}</div>
    </div>
  );
};

ColorSelection.propTypes = propTypes;
export default ColorSelection;
