export const SYSTEM_CLICK = "SystemClick";
export const SYSTEM_HOVER = "SystemHover";
export const SYSTEM_RENDER = "SystemRender";

export const COMPONENT_TYPE_CLICK = "ComponentClick";
export const COMPONENT_TYPE_HOVER = "ComponentHover";
export const COMPONENT_TYPE_RENDER = "ComponentRender";

export const COMPONENT_TYPE_POINT = "point";
export const COMPONENT_TYPE_LINE = "line";
export const COMPONENT_TYPE_LABEL = "label";
export const COMPONENT_TYPE_GNSS_POINT = "gnss_point";
export const COMPONENT_TYPE_GNSS_LINE = "gnss_line";
export const COMPONENT_TYPE_GNSS_POLYGON = "gnss_polygon";
export const COMPONENT_TYPE_GNSS_IMAGE = "gnss_image";
export const COMPONENT_TYPE_GNSS_REF_POINT = "gnss_ref_point";

export const MOUSE_EVENT_CLICK = "click";
export const MOUSE_EVENT_ENTER = "mouseenter";
export const MOUSE_EVENT_LEAVE = "mouseleave";
