import React from "react";
import PropTypes from "prop-types";
import "./styles.css";
import Tooltip from "@mui/material/Tooltip";

import { COLOR_MAP_NAMES } from "../../constants/colormapping";

const propTypes = {
  color: PropTypes.string,
  colorKey: PropTypes.string,
  size: PropTypes.string,
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
};

const defaultProps = {
  color: "#000000",
  size: "xs",
  selected: false,
};

const ColoredAndClickableCircle = React.memo(
  ({ color, colorKey, size, selected, onSelect }) => {
    return (
      <div className={`clickable-circle-holder-${size}`}>
        <Tooltip title={COLOR_MAP_NAMES[colorKey]} arrow>
          {selected ? (
            <div
              className={`clickable-circle-selected clickable-circle-${size}`}
              style={{ borderColor: color, boxShadow: `0 0 10px 2px ${color}` }}
              onClick={() => onSelect(colorKey)}
            />
          ) : (
            <div
              className={`clickable-circle clickable-circle-${size}`}
              style={{ backgroundColor: color }}
              onClick={() => onSelect(colorKey)}
            />
          )}
        </Tooltip>
      </div>
    );
  }
);

ColoredAndClickableCircle.propTypes = propTypes;
ColoredAndClickableCircle.defaultProps = defaultProps;
ColoredAndClickableCircle.displayName = "ColoredAndClickableCircle";
export default ColoredAndClickableCircle;
