//import * as styles from "../../../../../../../styles/styles";
import { GNSS_REF_POINT } from "../../../../../../../constants/pointLookupTable";
import { COMPONENT_TYPE_GNSS_REF_POINT } from "../../../../../../../constants/ecs";

export default class ComponentGnssRefPoint {
  constructor(params) {
    this.componentType = COMPONENT_TYPE_GNSS_REF_POINT;
    this.componentName = GNSS_REF_POINT;
    this.currentHighlight = -1;
    this.hoveredStateId = -1;

    this.circleRadiusNormal = 5;
    this.circleRadiusHover = 10;

    this.name = params.name;

    this.idList = params.data.features.map((feature) => feature.id);

    this.source = {
      type: "geojson",
      data: params.data,
      promoteId: "uuid",
    };

    this.layer = {
        id: this.name,
        type: "symbol",
        source: this.name,
        layout: {
          "icon-image": "triangle",
          "icon-anchor": "center",
          "icon-offset": [4,3],
          "symbol-placement": "point",
          'icon-allow-overlap': true,
        },
        paint: {
           "icon-opacity": [
            "case",
            ["boolean", ["feature-state", "hover"], false],
            1,
            0.6,
          ],
        }
      };
  }

  updateColor(color) {
    this.layer.paint["icon-color"] = color;
  }

  getComponentType() {
    return this.componentType;
  }

  getComponentName() {
    return this.componentName;
  }
}
