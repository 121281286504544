/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import React from "react";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

import {
  LAYER_TYPE_NAME_MAP,
  LAYER_TYPE_LINE_IDX,
  LAYER_TYPE_POINT_IDX,
  LAYER_TYPE_GNSS_POINT_IDX,
  LAYER_TYPE_GNSS_LINE_IDX,
  LAYER_TYPE_GNSS_POLYGON_IDX,
  LAYER_TYPE_GNSS_CONTINUOUS_LINE_IDX,
  LAYER_TYPE_GNSS_REF_POINT_IDX
} from "../../../../constants/measureLayerConfig";

import * as styles from "../../../../styles/styles";

const propTypes = {
  stateLayerType: PropTypes.number,
  changeLayerType: PropTypes.func,
  featureGnssMeasurementAllowed: PropTypes.bool,
  measureLayerConfigs: PropTypes.object,
  edit: PropTypes.bool
};

const LayerTypeSelector = ({ 
  stateLayerType, 
  changeLayerType,
  featureGnssMeasurementAllowed,
  measureLayerConfigs,
  edit
}) => {
  const alreadyCreatedLayerTypes = getLayerTypes(measureLayerConfigs)
  const menuItemEntryList = buildMenuItemEntries(
    edit, 
    alreadyCreatedLayerTypes, 
    featureGnssMeasurementAllowed)

  return (
    <div style={{ margin: `0 0 ${styles.spacing32} 0` }}>
      <FormControl fullWidth={true} size="medium">
        <InputLabel id="select-layer-type-label">Typ *</InputLabel>
        <Select
          label="Typ"
          labelId="select-layer-type-label"
          id="select-layer-type"
          value={stateLayerType}
          onChange={changeLayerType}
          defaultValue={""}
        >
          {menuItemEntryList}

        </Select>
      </FormControl>
    </div>
  );
};

const buildMenuItemEntries = (
  edit, 
  alreadyCreatedLayerTypes, 
  featureGnssMeasurementAllowed
) => {
  const entryList = []

  entryList.push(
    <MenuItem 
      key={`layer_type_${LAYER_TYPE_LINE_IDX}`}
      value={LAYER_TYPE_LINE_IDX}
    >
      {LAYER_TYPE_NAME_MAP[LAYER_TYPE_LINE_IDX]}
    </MenuItem>
  )

  entryList.push(
    <MenuItem 
      key={`layer_type_${LAYER_TYPE_POINT_IDX}`}
      value={LAYER_TYPE_POINT_IDX}
    >
      {LAYER_TYPE_NAME_MAP[LAYER_TYPE_POINT_IDX]}
    </MenuItem>
  )

  if (featureGnssMeasurementAllowed) {
    entryList.push(
      <MenuItem 
        key={`layer_type_${LAYER_TYPE_GNSS_POINT_IDX}`}
        value={LAYER_TYPE_GNSS_POINT_IDX}
      >
        {LAYER_TYPE_NAME_MAP[LAYER_TYPE_GNSS_POINT_IDX]}
      </MenuItem>
    )

    entryList.push(
      <MenuItem 
        key={`layer_type_${LAYER_TYPE_GNSS_LINE_IDX}`}
        value={LAYER_TYPE_GNSS_LINE_IDX}
      >
        {LAYER_TYPE_NAME_MAP[LAYER_TYPE_GNSS_LINE_IDX]}
      </MenuItem>
    )

    entryList.push(
      <MenuItem 
        key={`layer_type_${LAYER_TYPE_GNSS_POLYGON_IDX}`}
        value={LAYER_TYPE_GNSS_POLYGON_IDX}
      >
        {LAYER_TYPE_NAME_MAP[LAYER_TYPE_GNSS_POLYGON_IDX]}
      </MenuItem>
    )

    entryList.push(
      <MenuItem 
        key={`layer_type_${LAYER_TYPE_GNSS_CONTINUOUS_LINE_IDX}`}
        value={LAYER_TYPE_GNSS_CONTINUOUS_LINE_IDX}
      >
        {LAYER_TYPE_NAME_MAP[LAYER_TYPE_GNSS_CONTINUOUS_LINE_IDX]}
      </MenuItem>
    )

    if (!edit && !alreadyCreatedLayerTypes.includes(LAYER_TYPE_GNSS_REF_POINT_IDX)) {
      entryList.push(
        <MenuItem 
          key={`layer_type_${LAYER_TYPE_GNSS_REF_POINT_IDX}`}
          value={LAYER_TYPE_GNSS_REF_POINT_IDX}
        >
          {LAYER_TYPE_NAME_MAP[LAYER_TYPE_GNSS_REF_POINT_IDX]}
        </MenuItem>
      )
    }
  }

  return entryList
}

function getLayerTypes(obj) {
  return Object.keys(obj).reduce((acc, key) => {
      acc.push(obj[key]['layer_type']);
      return acc;
  }, []);
}

LayerTypeSelector.propTypes = propTypes;
export default LayerTypeSelector;
