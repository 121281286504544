import { MATERIAL, DIMENSION } from "../../constants/catalogCategoryMapping";

/**
 * The schema for Component, Material and Dimension is
 * identical. Because of that the the function can be used
 * to add a new Entry into the componentCatalogs State.
 *
 * @param {object} state That ist the componentCatalogs State.
 * @param {object} action The action contains the new Entry from the Backend. It can be a Component, Material or Dimension.
 * @param {string} stateComponentName This one is used to get the correct place to store the new Entry. Possible values are
 * catalogComponentsCategories, catalogDimensionsCategories or catalogMaterialsCategories.
 * @param {string} payloadObjectName The root key of the payload from the Backend depends on the Typ (Component, Material or Dimension).
 * Possible values are PAYLOAD_CATALOG_COMPONENT, PAYLOAD_CATALOG_MATERIAL or PAYLOAD_CATALOG_DIMENSION.
 * See `../../constants/catalogCategoryMapping` for the values of the mentioned constants.
 * @returns The updated Immutable State is returned.
 */
export function createComponentData(
  state,
  action,
  stateComponentName,
  payloadObjectName
) {
  const newEntry = {
    name: action.payload[payloadObjectName].name,
    description: action.payload[payloadObjectName].description,
    rootUuid: action.payload.uuid,
    uuid: action.payload[payloadObjectName].uuid,
    updated_at: action.payload[payloadObjectName].updated_at,
  };

  const itemIndex = state
    .getIn(["items", stateComponentName])
    .findIndex(
      (item) => item.rootUuid === action.payload.catalog_category_uuid
    );

  return state.updateIn(
    ["items", stateComponentName, itemIndex],
    function (item) {
      return {
        rootUuid: item.rootUuid,
        items: item.items.concat([newEntry]),
      };
    }
  );
}

/**
 * The schema for Component, Material and Dimension is
 * identical. Because of that the the function can be used
 * to update a Entry in the componentCatalogs State.
 *
 * @param {object} state That ist the componentCatalogs State.
 * @param {object} action The action contains the new Entry from the Backend. It can be a Component, Material or Dimension.
 * @param {string} stateComponentName This one is used to get the correct place to store the updated Entry. Possible values are
 * catalogComponentsCategories, catalogDimensionsCategories or catalogMaterialsCategories.
 * @returns The updated Immutable State is returned.
 */
export function updateComponentData(state, action, stateComponentName) {
  const activeComponentCatalogUuid = state.get("activeComponentCatalogUuid");

  const componentIndex = state
    .getIn(["items", stateComponentName])
    .findIndex(
      (componentItem) => componentItem.rootUuid === activeComponentCatalogUuid
    );

  const itemIndex = state
    .getIn(["items", stateComponentName, componentIndex, "items"])
    .findIndex((item) => item.uuid === action.payload.uuid);

  return state.updateIn(
    ["items", stateComponentName, componentIndex, "items", itemIndex],
    function (item) {
      return {
        root_uuid: item.root_uuid,
        ...action.payload,
      };
    }
  );
}

/**
 * Use this function to delete a Component, Material or Dimension from the
 * state.
 *
 * @param {object} state That ist the componentCatalogs State.
 * @param {object} action The action contains the new Entry from the Backend. It can be a Component, Material or Dimension.
 * @param {string} stateComponentName This one is used to get the correct place to delete the Entry. Possible values are
 * catalogComponentsCategories, catalogDimensionsCategories or catalogMaterialsCategories.
 * @returns The updated Immutable State is returned.
 */
export function deleteComponentData(state, action, stateComponentName) {
  const activeComponentCatalogUuid = state.get("activeComponentCatalogUuid");

  const componentIndex = state
    .getIn(["items", stateComponentName])
    .findIndex(
      (componentItem) => componentItem.rootUuid === activeComponentCatalogUuid
    );

  const itemIndex = state
    .getIn(["items", stateComponentName, componentIndex, "items"])
    .findIndex((item) => item.uuid === action.payload.uuid);

  return state.deleteIn([
    "items",
    stateComponentName,
    componentIndex,
    "items",
    itemIndex,
  ]);
}

/**
 * After the sync of all available Catalogs call this function to
 * populate the date in the state.
 *
 * @param {object} state That ist the componentCatalogs State.
 * @param {object} action The action holds data for all Categories, Materials, Dimensions, Components
 * and Pipe Definitions.
 * @returns he updated Immutable State is returned.
 */
export function setCatalogComponents(state, action) {
  const newStateCatalogOrganizationCategories = [];
  const newStateCatalogCategories = [];
  const newStateCatalogComponentsCategories = [];
  const newStateCatalogDimenionsCategories = [];
  const newStateCatalogMaterialsCategories = [];
  const newStateCatalogPipeDefinitionsCategories = [];

  const catalog_organization_categories =
    action.payload.catalog_organization_categories;

  catalog_organization_categories.forEach((element) => {
    const rootUuid = element.uuid;

    // Catalog Organization Category
    newStateCatalogOrganizationCategories.push({
      item: parseCatalogOrganizationCategory(element),
    });

    // Catalog Category
    const catalog_category = element.catalog_category;
    const catalogCategoryUuid = catalog_category.uuid;
    newStateCatalogCategories.push({
      rootUuid: rootUuid,
      item: parseCatalogCategory(rootUuid, catalog_category),
    });

    // Catalog Component Category
    newStateCatalogComponentsCategories.push({
      rootUuid: catalogCategoryUuid,
      items: parseCatalogComponentCategories(
        catalogCategoryUuid,
        catalog_category
      ),
    });

    // Catalog Dimension Category
    newStateCatalogDimenionsCategories.push({
      rootUuid: catalogCategoryUuid,
      items: parseCatalogDimensionCategories(
        catalogCategoryUuid,
        catalog_category
      ),
    });

    // Catalog Material Category
    newStateCatalogMaterialsCategories.push({
      rootUuid: catalogCategoryUuid,
      items: parseCatalogMaterialCategories(
        catalogCategoryUuid,
        catalog_category
      ),
    });

    // Catalog Pipe Definition Category
    newStateCatalogPipeDefinitionsCategories.push({
      rootUuid: catalogCategoryUuid,
      items: parseCatalogPipeDefinitionCategories(
        catalogCategoryUuid,
        catalog_category
      ),
    });
  });

  const newItems = {
    catalogOrganizationCategories: newStateCatalogOrganizationCategories,
    catalogCategories: newStateCatalogCategories,
    catalogComponentsCategories: newStateCatalogComponentsCategories,
    catalogDimensionsCategories: newStateCatalogDimenionsCategories,
    catalogMaterialsCategories: newStateCatalogMaterialsCategories,
    catalogPipeDefinitionsCategories: newStateCatalogPipeDefinitionsCategories,
  };

  // Set the State here

  return state.mergeIn(["items"], newItems);
}

function parseCatalogOrganizationCategory(element) {
  return {
    catalog_category_md5_hash: element.catalog_category_md5_hash,
    organization_id: element.organization_id,
    updated_at: element.updated_at,
    uuid: element.uuid,
  };
}

function parseCatalogCategory(rootUuid, catalogCategory) {
  return {
    description: catalogCategory.description,
    name: catalogCategory.name,
    updated_at: catalogCategory.updated_at,
    uuid: catalogCategory.uuid,
    root_uuid: rootUuid,
  };
}

function parseCatalogComponentCategories(rootUuid, catalogCategory) {
  const parsedList = [];

  const elements = catalogCategory.catalog_components_categories;

  elements.forEach((element) => {
    parsedList.push(parseCatalogComponentCategory(rootUuid, element));
  });

  return parsedList;
}

function parseCatalogComponentCategory(rootUuid, element) {
  return {
    description: element.catalog_component.description,
    name: element.catalog_component.name,
    updated_at: element.catalog_component.updated_at,
    uuid: element.catalog_component.uuid,
    root_uuid: rootUuid,
  };
}

function parseCatalogDimensionCategories(rootUuid, catalogCategory) {
  const parsedList = [];

  const elements = catalogCategory.catalog_dimensions_categories;

  elements.forEach((element) => {
    parsedList.push(
      parseCatalogDimensionCategory(rootUuid, element.catalog_dimension)
    );
  });

  return parsedList;
}

function parseCatalogDimensionCategory(rootUuid, element) {
  return {
    description: element.description,
    name: element.name,
    updated_at: element.updated_at,
    uuid: element.uuid,
    root_uuid: rootUuid,
  };
}

function parseCatalogMaterialCategories(rootUuid, catalogCategory) {
  const parsedList = [];

  const elements = catalogCategory.catalog_materials_categories;

  elements.forEach((element) => {
    parsedList.push(
      parseCatalogMaterialCategory(rootUuid, element.catalog_material)
    );
  });

  return parsedList;
}

function parseCatalogMaterialCategory(rootUuid, element) {
  return {
    description: element.description,
    name: element.name,
    updated_at: element.updated_at,
    uuid: element.uuid,
    root_uuid: rootUuid,
  };
}

function parseCatalogPipeDefinitionCategories(rootUuid, catalogCategory) {
  const parsedList = [];

  const elements = catalogCategory.catalog_pipe_definitions_categories;

  elements.forEach((element) => {
    const pipeDefinition = element.catalog_pipe_definition;

    parsedList.push({
      pipeDefinition: {
        rootUuid: rootUuid,
        uuid: pipeDefinition.uuid,
        updated_at: pipeDefinition.updated_at,
      },
      material: parseCatalogMaterialCategory(
        rootUuid,
        pipeDefinition.catalog_material
      ),
      dimension: parseCatalogDimensionCategory(
        rootUuid,
        pipeDefinition.catalog_dimension
      ),
    });
  });

  return parsedList;
}

/**
 * If a new Category was created call this function to insert the information
 * in the componentCatalogs State. For the catalogComponentsCategories,
 * catalogDimensionsCategories, catalogMaterialsCategories and
 * catalogPipeDefinitionsCategories Lists new entries are inserted to add later on
 * data about Components, Materials, Dimensions and Pipe Definitions for this
 * Category.
 *
 * @param {object} state That ist the componentCatalogs State.
 * @param {object} action
 * @returns The updated Immutable State is returned.
 */
export function createCatalogOrganizationCategory(state, action) {
  return state
    .updateIn(["items", "catalogOrganizationCategories"], (items) => {
      return items.concat([
        {
          item: {
            catalog_category_md5_hash: action.payload.catalog_category_md5_hash,
            organization_id: action.payload.organization_id,
            updated_at: action.payload.updated_at,
            uuid: action.payload.uuid,
          },
        },
      ]);
    })
    .updateIn(["items", "catalogCategories"], (items) => {
      return items.concat([
        {
          rootUuid: action.payload.uuid,
          item: {
            name: action.payload.catalog_category.name,
            description: action.payload.catalog_category.description,
            rootUuid: action.payload.uuid,
            uuid: action.payload.catalog_category.uuid,
            updated_at: action.payload.catalog_category.updated_at,
          },
        },
      ]);
    })
    .updateIn(["items", "catalogComponentsCategories"], (items) => {
      return items.concat([
        {
          rootUuid: action.payload.catalog_category.uuid,
          items: [],
        },
      ]);
    })
    .updateIn(["items", "catalogDimensionsCategories"], (items) => {
      return items.concat([
        {
          rootUuid: action.payload.catalog_category.uuid,
          items: [],
        },
      ]);
    })
    .updateIn(["items", "catalogMaterialsCategories"], (items) => {
      return items.concat([
        {
          rootUuid: action.payload.catalog_category.uuid,
          items: [],
        },
      ]);
    })
    .updateIn(["items", "catalogPipeDefinitionsCategories"], (items) => {
      return items.concat([
        {
          rootUuid: action.payload.catalog_category.uuid,
          items: [],
        },
      ]);
    });
}

/**
 * Add a new CatalogCategory to the componentCatalogs State.
 *
 * @param {object} state That ist the componentCatalogs State.
 * @param {object} action
 * @returns The updated Immutable State is returned.
 */
export function createCatalogCategory(state, action) {
  return state.updateIn(["items", "catalogCategories"], (items) => {
    return items.concat([
      {
        rootUuid: action.payload.uuid,
        item: {
          name: action.payload.catalog_category.name,
          description: action.payload.catalog_category.description,
          rootUuid: action.payload.uuid,
          uuid: action.payload.catalog_category.uuid,
          updated_at: action.payload.catalog_category.updated_at,
        },
      },
    ]);
  });
}

/**
 * Update a CatalogCategory Entry in the componentCatalogs State.
 *
 * @param {object} state That ist the componentCatalogs State.
 * @param {object} action
 * @returns The updated Immutable State is returned.
 */
export function updateCatalogCategory(state, action) {
  const oldItemIndex = state
    .getIn(["items", "catalogCategories"])
    .findIndex((x) => x.item.uuid === action.payload.uuid);

  return state.updateIn(
    ["items", "catalogCategories", oldItemIndex],
    function (item) {
      return {
        rootUuid: item.rootUuid,
        item: {
          ...action.payload,
        },
      };
    }
  );
}

/**
 * Delete a CatalogCategory Entry from the componentCatalogs State.
 *
 * @param {object} state That ist the componentCatalogs State.
 * @param {object} action
 * @returns The updated Immutable State is returned.
 */
export function deleteCatalogCategory(state, action) {
  const oldItemIndex = state
    .getIn(["items", "catalogCategories"])
    .findIndex((x) => x.item.uuid === action.payload.uuid);

  return state.deleteIn(["items", "catalogCategories", oldItemIndex]);
}

/**
 * If a Material or Dimension was updated, the changes have to be applied
 * to all available Pipe Definitions.
 *
 * @param {object} state That ist the componentCatalogs State.
 * @param {object} action The action contains the new Material or Dimension.
 * @param {string} dataType This String determines which part of the Pipe Definiton should
 * be updated. Possible values are MATERIAL and DIMENSION, see `../../constants/catalogCategoryMapping`.
 * @returns The updated Immutable State is returned.
 */
export function updateDataInPipeDefinitions(state, action, dataType) {
  const activeComponentCatalogUuid = state.get("activeComponentCatalogUuid");

  const itemIndex = state
    .getIn(["items", "catalogPipeDefinitionsCategories"])
    .findIndex((x) => x.rootUuid === activeComponentCatalogUuid);

  return state.setIn(
    ["items", "catalogPipeDefinitionsCategories", itemIndex, "items"],
    state
      .getIn(["items", "catalogPipeDefinitionsCategories", itemIndex, "items"])
      .filter((x) => {
        return x[dataType].uuid === action.payload.uuid;
      })
      .map((x) => {
        let updatedData = {};
        if (dataType === DIMENSION) {
          updatedData = {
            dimension: {
              ...action.payload,
              rootUuid: x.dimension.rootUuid,
            },
            material: {
              ...x.material,
            },
          };
        } else if (dataType === MATERIAL) {
          updatedData = {
            dimension: {
              ...x.dimension,
            },
            material: {
              ...action.payload,
              rootUuid: x.material.rootUuid,
            },
          };
        }
        return {
          ...updatedData,
          pipeDefinition: {
            ...x.pipeDefinition,
          },
        };
      })
  );
}

/**
 *
 * @param {object} state That ist the componentCatalogs State.
 * @param {object} action The action contains the deleted Material or Dimension.
 * @param {string} dataType This String determines by what the Pipe Definitions should be filtered and then deleted.
 * Possible values are MATERIAL and DIMENSION, see `../../constants/catalogCategoryMapping`.
 * @returns The updated Immutable State is returned.
 */
export function deleteDataInPipeDefinitions(state, action, dataType) {
  const activeComponentCatalogUuid = state.getIn([
    "activeComponentCatalogUuid",
  ]);

  const itemIndex = state
    .getIn(["items", "catalogPipeDefinitionsCategories"])
    .findIndex((x) => x.rootUuid === activeComponentCatalogUuid);

  return state.setIn(
    ["items", "catalogPipeDefinitionsCategories", itemIndex, "items"],
    state
      .getIn(["items", "catalogPipeDefinitionsCategories", itemIndex, "items"])
      .filter((x) => {
        return x[dataType].uuid !== action.payload.uuid;
      })
  );
}

/**
 * Add a new Pipe Definition Entry to the componentCatalogs State.
 *
 * @param {object} state That ist the componentCatalogs State.
 * @param {object} action The action contains the new Pipe Definition.
 * @returns The updated Immutable State is returned.
 */
export function createCatalogPipeDefinition(state, action) {
  const data = {
    pipeDefinition: {
      rootUuid: action.payload.catalog_category.uuid,
      uuid: action.payload.catalog_pipe_definition.uuid,
      updated_at: action.payload.catalog_pipe_definition.updated_at,
    },
    material: parseCatalogMaterialCategory(
      action.payload.catalog_category.uuid,
      action.payload.catalog_pipe_definition.catalog_material
    ),
    dimension: parseCatalogDimensionCategory(
      action.payload.catalog_category.uuid,
      action.payload.catalog_pipe_definition.catalog_dimension
    ),
  };

  const componentIndex = state
    .getIn(["items", "catalogPipeDefinitionsCategories"])
    .findIndex((x) => x.rootUuid === action.payload.catalog_category.uuid);

  return state.updateIn(
    ["items", "catalogPipeDefinitionsCategories", componentIndex],
    function (item) {
      return {
        rootUuid: item.rootUuid,
        items: item.items.concat([data]),
      };
    }
  );
}

/**
 * Delete a Pipe Definition Entry from the componentCatalogs State.
 *
 * @param {object} state That ist the componentCatalogs State.
 * @param {object} action The action contains the deleted Pipe Definition.
 * @returns The updated Immutable State is returned.
 */
export function deleteCatalogPipeDefinition(state, action) {
  const activeComponentCatalogUuid = state.get("activeComponentCatalogUuid");
  const componentIndex = state
    .getIn(["items", "catalogPipeDefinitionsCategories"])
    .findIndex((x) => x.rootUuid === activeComponentCatalogUuid);

  const itemIndex = state
    .getIn([
      "items",
      "catalogPipeDefinitionsCategories",
      componentIndex,
      "items",
    ])
    .findIndex(
      (x) =>
        x.pipeDefinition.uuid === action.payload.catalog_pipe_definition_uuid
    );

  return state.deleteIn([
    "items",
    "catalogPipeDefinitionsCategories",
    componentIndex,
    "items",
    itemIndex,
  ]);
}
