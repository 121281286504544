import {
  SYSTEM_RENDER,
  COMPONENT_TYPE_POINT,
  COMPONENT_TYPE_LINE,
  COMPONENT_TYPE_LABEL,
  COMPONENT_TYPE_GNSS_POINT,
  COMPONENT_TYPE_GNSS_LINE,
  COMPONENT_TYPE_GNSS_POLYGON,
  COMPONENT_TYPE_GNSS_IMAGE,
  COMPONENT_TYPE_GNSS_REF_POINT
} from "../../../../../../../constants/ecs";

export default class SystemRender {
  constructor(params) {
    this.systemType = SYSTEM_RENDER;
    this.map = params.map;
  }

  clearComponents(entities) {
    for (var entity in entities) {
      this.clearComponent(entities, entity, COMPONENT_TYPE_POINT)
      this.clearComponent(entities, entity, COMPONENT_TYPE_LINE)
      this.clearComponent(entities, entity, COMPONENT_TYPE_LABEL)
      this.clearComponent(entities, entity, COMPONENT_TYPE_GNSS_POINT)
      this.clearComponent(entities, entity, COMPONENT_TYPE_GNSS_LINE)
      this.clearComponent(entities, entity, COMPONENT_TYPE_GNSS_POLYGON)
      this.clearComponent(entities, entity, COMPONENT_TYPE_GNSS_IMAGE)
      this.clearComponent(entities, entity, COMPONENT_TYPE_GNSS_REF_POINT)
    }
  }

  run(entities) {
    for (var entity in entities) {
      this.runComponent(entities, entity, COMPONENT_TYPE_POINT)
      this.runComponent(entities, entity, COMPONENT_TYPE_LINE)
      this.runComponent(entities, entity, COMPONENT_TYPE_GNSS_POINT)
      this.runComponent(entities, entity, COMPONENT_TYPE_GNSS_LINE)
      this.runComponent(entities, entity, COMPONENT_TYPE_GNSS_POLYGON)
      this.runComponent(entities, entity, COMPONENT_TYPE_GNSS_IMAGE)
      this.runComponent(entities, entity, COMPONENT_TYPE_LABEL)
      this.runComponent(entities, entity, COMPONENT_TYPE_GNSS_REF_POINT)
    }
  }

  runComponent(entities, entity, componentName) {
    if (entities[entity].getComponents()[componentName]) {
      this.map.addSource(
        entities[entity].getComponents()[componentName].name,
        entities[entity].getComponents()[componentName].source
      );
      this.map.addLayer(
        entities[entity].getComponents()[componentName].layer
      );
    }
  }

  clearComponent(entities, entity, componentName) {
    if (entities[entity].getComponents()[componentName]) {
      this.map.removeLayer(
        entities[entity].getComponents()[componentName].name
      );
      this.map.removeSource(
        entities[entity].getComponents()[componentName].name
      );
    }
  }
}
