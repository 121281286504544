import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  deleteAvatar,
  uploadAvatar,
  updateUserDetails,
  requestAccountDeletion,
} from "../../../actions/user";
import EditUserSettingsDialog from "../../../containers/dialogs/EditUserSettingsDialog";
import RequestAccountDeletionDialog from "../../../containers/dialogs/RequestAccountDeletionDialog";
import Container from "../../../components/layout/Container";
import Header from "../../../components/Header";
import DisplayUserProfile from "../../../components/userProfile/DisplayUserProfile";
import UserProfileSupport from "../../../components/userProfile/UserProfileSupport";
import UserProfileDocuments from "../../../components/userProfile/UserProfileDocuments";
import UserProfileAppSettings from "../../../components/userProfile/UserProfileAppSettings";
import UserProfileAccountDeletion from "../../../components/userProfile/UserProfileAccountDeletion";
import Divider from "../../../atoms/Divider";

import * as styles from "../../../styles/styles";
import "./styles.css";

const propTypes = {
  user: PropTypes.object,
  organization: PropTypes.object,
  deleteAvatar: PropTypes.func,
  uploadAvatar: PropTypes.func,
  updateUserDetails: PropTypes.func,
  requestAccountDeletion: PropTypes.func,
};

class UserProfile extends React.Component {
  constructor(props) {
    super(props);

    const { user } = this.props;

    const settings = user.toJS().settings.android;

    this.state = {
      avatar: user.getIn(["avatar", "original"]),
      firstName: user.get("first_name"),
      lastName: user.get("last_name"),
      settingsAndroid: settings,
      settingsChanged: false,
      editUser: false,
      firstNameValid: true,
      lastNameValid: true,
      avatarTooLarge: false,
      appSettingsModalOpened: false,
      requestAccountDeletionModalOpened: false,
    };

    this.cancel = this.cancel.bind(this);
    this.updateEditUserFlag = this.updateEditUserFlag.bind(this);
    this.editUserDetails = this.editUserDetails.bind(this);
    this.createAvatarUrl = this.createAvatarUrl.bind(this);
    this.onChangeAvatar = this.onChangeAvatar.bind(this);
    this.onAvatarSizeToLarge = this.onAvatarSizeToLarge.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onAppSettingsModelOpen = this.onAppSettingsModelOpen.bind(this);
    this.onRequestAccountDeletionModalOpen =
      this.onRequestAccountDeletionModalOpen.bind(this);
    this.onRequestAccountDeletionModalClose =
      this.onRequestAccountDeletionModalClose.bind(this);
    this.executeRequestAccountDeletion =
      this.executeRequestAccountDeletion.bind(this);
  }

  // If the cancel button was presse, reset the component state to
  // the last state of the available props.
  cancel() {
    const { user } = this.props;
    const androidSettings = user.toJS().settings.android;

    this.setState({
      editUser: false,
      avatarTooLarge: false,
      firstName: user.get("first_name"),
      lastName: user.get("last_name"),
      avatar: user.getIn(["avatar", "original"]),
      settingsAndroid: androidSettings,
      settingsChanged: false,
    });
  }

  updateEditUserFlag(flag) {
    this.setState({ editUser: flag });
  }

  editUserDetails() {
    const {
      avatar,
      firstNameValid,
      lastNameValid,
      firstName,
      lastName,
      settingsAndroid,
      settingsChanged,
    } = this.state;
    const { deleteAvatar, uploadAvatar, updateUserDetails, user } = this.props;

    // Update firstName, lastName and Android-Settings here
    if (firstNameValid && lastNameValid) {
      const userDetails = {
        first_name: firstName.trim(),
        last_name: lastName.trim(),
        settings: {
          android: settingsAndroid,
        },
      };
      if (
        userDetails.first_name !== user.get("first_name") ||
        userDetails.last_name !== user.get("last_name") ||
        settingsChanged
      )
        updateUserDetails(userDetails);
    }

    // Update the Avatar here
    if (avatar !== null && typeof avatar === "object") {
      const newAvatar = new FormData();
      newAvatar.append(
        "avatar",
        avatar,
        avatar ? avatar.name : { user: { avatar: "" } }
      );
      uploadAvatar(newAvatar);
    } else if (avatar === null && user.getIn(["avatar", "original"])) {
      deleteAvatar();
    }

    // Reset the component state
    this.setState({
      editUser: false,
      avatarTooLarge: false,
      settingsChanged: false,
    });
  }

  createAvatarUrl(avatar) {
    if (avatar)
      return typeof avatar === "string" ? avatar : URL.createObjectURL(avatar);
    return avatar;
  }

  onChangeAvatar(avatar) {
    this.setState({ avatar });
  }

  onAvatarSizeToLarge(avatarTooLarge) {
    this.setState({ avatarTooLarge });
  }

  onChangeName(field, event) {
    const name = event.target.value;
    const validKey = `${field}Valid`;
    this.setState({
      [field]: name,
      [validKey]: name.length > 0,
    });
  }

  onAppSettingsModelOpen() {
    this.setState({ appSettingsModalOpened: true });
  }
  appSettingsModalClose() {
    this.setState({ appSettingsModalOpened: false });
  }

  onRequestAccountDeletionModalOpen() {
    this.setState({ requestAccountDeletionModalOpened: true });
  }
  onRequestAccountDeletionModalClose() {
    this.setState({ requestAccountDeletionModalOpened: false });
  }

  editUserAppSettings(settingsAndroid) {
    const { firstName, lastName, firstNameValid, lastNameValid } = this.state;
    const { updateUserDetails } = this.props;

    if (firstNameValid && lastNameValid) {
      const userDetails = {
        first_name: firstName.trim(),
        last_name: lastName.trim(),
        settings: {
          android: settingsAndroid,
        },
      };
      updateUserDetails(userDetails);
    }

    this.setState({
      editUser: false,
      avatarTooLarge: false,
      settingsChanged: false,
    });
  }

  executeRequestAccountDeletion() {
    const { requestAccountDeletion, organization } = this.props;
    requestAccountDeletion(organization.get("id"));
  }

  renderEditUserSettingDialog() {
    const { appSettingsModalOpened } = this.state;
    const androidSettings = this.props.user.toJS().settings.android;

    return (
      <EditUserSettingsDialog
        key={"EditUserSettingsDialog"}
        settings={androidSettings}
        modalOpen={appSettingsModalOpened}
        onNegativeButtonClicked={() => this.appSettingsModalClose()}
        onPositiveButtonClicked={(stateChanged, updatedSettings) => {
          this.setState({
            settingsAndroid: updatedSettings,
            settingsChanged: stateChanged,
          });
          this.appSettingsModalClose();
          this.editUserAppSettings(updatedSettings);
        }}
      />
    );
  }

  renderRequestAccountDeletionDialog() {
    const { requestAccountDeletionModalOpened } = this.state;

    return (
      <RequestAccountDeletionDialog
        key={"RequestAccountDeletionDialog"}
        modalOpen={requestAccountDeletionModalOpened}
        onNegativeButtonClicked={() =>
          this.onRequestAccountDeletionModalClose()
        }
        onPositiveButtonClicked={() => {
          this.onRequestAccountDeletionModalClose();
          this.executeRequestAccountDeletion();
        }}
      />
    );
  }

  render() {
    const {
      avatarTooLarge,
      avatar,
      editUser,
      firstName,
      lastName,
      appSettingsModalOpened,
      requestAccountDeletionModalOpened,
      firstNameValid,
      lastNameValid,
    } = this.state;

    return (
      <>
        {appSettingsModalOpened && this.renderEditUserSettingDialog()}
        {requestAccountDeletionModalOpened &&
          this.renderRequestAccountDeletionDialog()}

        <Header showUserSettings={false}>
          <div></div>
        </Header>
        <Container fullAppHeight={true} divider={true}>
          <Typography
            color="primary"
            variant="h2"
            style={{ width: "100%", margin: `0 0 ${styles.spacing24} 0` }}
          >
            Profil
          </Typography>

          <DisplayUserProfile
            avatar={avatar}
            avatarTooLarge={avatarTooLarge}
            firstName={firstName}
            firstNameValid={firstNameValid}
            lastName={lastName}
            lastNameValid={lastNameValid}
            editUser={editUser}
            email={this.props.user.get("email")}
            cancel={this.cancel}
            editUserDetails={this.editUserDetails}
            updateEditUserFlag={this.updateEditUserFlag}
            onChangeName={this.onChangeName}
            createAvatarUrl={this.createAvatarUrl}
            onAvatarSizeToLarge={this.onAvatarSizeToLarge}
            onChangeAvatar={this.onChangeAvatar}
          />

          <Divider />

          <UserProfileAppSettings modalOpen={this.onAppSettingsModelOpen} />

          <Divider />

          <UserProfileDocuments />

          <Divider />

          <UserProfileSupport />

          <Divider />

          <UserProfileAccountDeletion
            user={this.props.user}
            modalOpen={this.onRequestAccountDeletionModalOpen}
          />
        </Container>
      </>
    );
  }
}

function dispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteAvatar,
      uploadAvatar,
      updateUserDetails,
      requestAccountDeletion,
    },
    dispatch
  );
}

function stateToProps(state) {
  const organization = state.getIn(["organizations", "items"]).first();
  const user = state.get("user");

  return {
    user,
    organization,
  };
}

UserProfile.propTypes = propTypes;
export default connect(stateToProps, dispatchToProps)(UserProfile);
