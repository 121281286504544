import { store } from "./globals";
import { setInitSyncing } from "../actions/app";
import { default as initChannels } from "./channels";
import { syncRequestStandard } from "../actions/user";

export const initApp = () => {
  return async (dispatch) => {
    dispatch(setInitSyncing(true));
    dispatch(syncRequestStandard());
  };
};

export const initSockets = () => {
  initChannels(store.instance);
}