import React from 'react';
import { Typography } from '@mui/material';

import Header from '../../../components/Header'

const SubscriptionExpired = () => {
  return <>
    <Header
      showUserSettings={false}>
      <div />
    </Header>
    <Typography
      variant='h6'>
      Das Abo Ihrer Firma ist ausgelaufen. Bitte wenden Sie sich an den Administrator.
  </Typography>
  </>
}

export default SubscriptionExpired;