/* eslint-disable no-unused-vars */
import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import withStyles from '@mui/styles/withStyles';
import classNames from "classnames";

import * as styles from "../../styles/styles";
import "./styles.css";
const overrideStyles = (theme) => ({
  root: {
    paddingTop: "4rem",
    paddingBottom: "4rem",
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
    backgroundColor: styles.white,
    boxShadow: styles.boxShadowListElement,
    "&:hover": {
      boxShadow: styles.boxShadowListElementHover,
    },
  },
  tableRow: {
    cursor: "pointer",
    height: "10rem",
    minHeight: "10rem",
  },
});

const ProjectFilePreview = (classes) => {
  return (
    <TableRow
      key={1}
      classes={{ root: classes.root, divider: classes.divider }}
      className={classNames(classes.tableRow)}
      style={{ cursor: "auto" }}
    >
      <TableCell className="project-list-item-truncate-text shimmer">
        <p className="project-preview-thumbnail"> </p>
      </TableCell>
      <TableCell className="project-list-item-truncate-text shimmer">
        <p className="project-file-preview-file-name"> </p>
      </TableCell>
      <TableCell className="project-list-item-truncate-text shimmer">
        <p className="project-file-preview-inserted-at"> </p>
      </TableCell>
      <TableCell
        align="right"
        className="project-list-item-truncate-text shimmer"
      >
        <div className="project-file-preview-buttons"></div>
      </TableCell>
    </TableRow>
  );
};

export default withStyles(overrideStyles, { withTheme: true })(
  ProjectFilePreview
);
