/* eslint-disable no-unused-vars */
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import withStyles from "@mui/styles/withStyles";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { SECTORS } from "../../../../constants/organizationSectorConstants";
import * as styles from "../../../../styles/styles";

const propTypes = {
  classes: PropTypes.object,
  company: PropTypes.string,
  admin: PropTypes.bool,
  age: PropTypes.number,
  onChangeAge: PropTypes.func,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  onChangeEmail: PropTypes.func,
  onChangeFirstName: PropTypes.func,
  onChangeLastName: PropTypes.func,
  onChangeSector: PropTypes.func,
  onChangeCompany: PropTypes.func,
  onChangePhoneNumber: PropTypes.func,
  onNextPage: PropTypes.func,
  sector: PropTypes.string,
  sectorFreeInput: PropTypes.string,
};

const defaultProps = {
  company: "",
};

const overrideStyles = (theme) => ({
  label: {
    ...styles.body1,
  },
});

const PageOne = ({
  admin,
  company,
  classes,
  age,
  onChangeAge,
  email,
  firstName,
  lastName,
  phoneNumber,
  onNextPage,
  onChangeCompany,
  onChangeFirstName,
  onChangeLastName,
  onChangeEmail,
  onChangeSector,
  onChangePhoneNumber,
  sector,
  sectorFreeInput,
}) => {
  const [state, setState] = useState({
    validEmail: !!email,
    validFirstName: !!firstName,
    validLastName: !!lastName,
    validSector: !!sector,
    sectorFreeInput: sectorFreeInput,
  });

  const validateInput = (value, field) => {
    setState({ ...state, [field]: value.length > 0 });
  };

  const changePhoneNumber = (event) => {
    const value = event.target.value;
    onChangePhoneNumber(value);
  };

  const changeCompany = (event) => {
    const value = event.target.value;
    onChangeCompany(value);
  };

  /* H O N E Y P O T */
  const changeAge = (event) => {
    const value = event.target.value;
    onChangeAge(value);
  };

  const changeFirstName = (event) => {
    const value = event.target.value;
    onChangeFirstName(value);
    validateInput(value, "validFirstName");
  };

  const changeLastName = (event) => {
    const value = event.target.value;
    onChangeLastName(value);
    validateInput(value, "validLastName");
  };

  const changeEmail = (event) => {
    const value = event.target.value.trim();
    onChangeEmail(value);
    const pattern = /\S+@\S+\.\S+/;
    setState({ ...state, validEmail: new RegExp(pattern).test(value) });
  };

  const changeSector = (event) => {
    const sector = event.target.value;
    onChangeSector(sector, state.sectorFreeInput);
    if (sector !== "sonstige") {
      onChangeSector(sector, "");
    }
    setState({
      ...state,
      sector: event.target.value,
      sectorFreeInput: "",
      validSector: true,
    });
  };

  const changeSectorFreeInput = (event) => {
    const sectorFreeInput = event.target.value;
    onChangeSector(state.sector, sectorFreeInput);
    setState({ ...state, sectorFreeInput: sectorFreeInput, validSector: true });
  };

  const showPhoneNumber = false;

  return (
    <>
      {/*H O N E Y P O T - F I E L D - A G E */}
      <TextField
        variant="standard"
        autoFocus={false}
        fullWidth={true}
        required={true}
        value={age}
        placeholder="Alter..."
        name="age"
        type="number"
        label="Alter"
        sx={{
          opacity: 0,
          position: "absolute",
          top: 0,
          left: 0,
          height: 0,
          width: 0,
          zIndex: -1,
        }}
        style={{ margin: `${styles.spacing24} 0` }}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          inputProps: { tabIndex: -1 },
        }}
        onChange={changeAge}
      />

      <TextField
        variant="standard"
        autoFocus={true}
        fullWidth={true}
        required={true}
        value={firstName}
        placeholder="Vorname..."
        name="firstName"
        type="name"
        label="Vorname"
        style={{ margin: `${styles.spacing24} 0` }}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={changeFirstName}
      />

      <TextField
        variant="standard"
        autoFocus={false}
        fullWidth={true}
        required={true}
        value={lastName}
        placeholder="Nachname..."
        name="lastName"
        type="name"
        label="Nachname"
        InputLabelProps={{
          shrink: true,
        }}
        style={{ margin: `${styles.spacing24} 0` }}
        onChange={changeLastName}
      />

      <TextField
        autoFocus={false}
        fullWidth={true}
        required={true}
        value={email}
        disabled={!admin}
        variant="standard"
        placeholder="E-Mail Adresse..."
        name="email"
        type="email"
        label="E-Mail"
        InputLabelProps={{
          shrink: true,
        }}
        style={{ margin: `${styles.spacing24} 0` }}
        onChange={changeEmail}
      />

      {showPhoneNumber && (
        <TextField
          autoFocus={false}
          fullWidth={true}
          required={false}
          value={phoneNumber}
          variant="standard"
          placeholder="Telefon (Optional)"
          name="phoneNumber"
          type="number"
          inputProps={{ min: 0, pattern: "[0-9]*" }}
          label="Telefon (Optional)"
          InputLabelProps={{
            shrink: true,
          }}
          style={{ margin: `${styles.spacing24} 0` }}
          onChange={changePhoneNumber}
          sx={{
            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
              {
                display: "none",
              },
          }}
        />
      )}

      {admin && (
        <>
          <TextField
            variant="standard"
            autoFocus={false}
            fullWidth={true}
            required={false}
            value={company}
            inputProps={{
              maxLength: 255,
            }}
            helperText={`${company.length}/255`}
            placeholder="Unternehmen (Optional)"
            name="company"
            type="name"
            label="Unternehmen (Optional)"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ margin: `${styles.spacing24} 0` }}
            onChange={changeCompany}
          />
          <FormControl fullWidth={true} component="fieldset">
            <FormLabel
              required={true}
              component="legend"
              style={{
                fontSize: "1.2rem",
                padding: `${styles.spacing24} 0 0 0`,
              }}
            >
              Bereich
            </FormLabel>
            <RadioGroup
              style={{ alignItems: "center", justifyContent: "space-between" }}
              row={true}
              aria-label="Bereich"
              name="sector"
              value={sector}
              onChange={changeSector}
            >
              {SECTORS.map((sector, index) => {
                return (
                  <FormControlLabel
                    style={{ width: "49%" }}
                    classes={{ label: classes.label }}
                    key={index}
                    value={sector}
                    control={<Radio />}
                    label={sector}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
          {sector.toLowerCase() === "sonstige" && (
            <TextField
              variant="standard"
              autoFocus={false}
              fullWidth={true}
              required={false}
              value={sectorFreeInput}
              inputProps={{
                maxLength: 255,
              }}
              helperText={`${sectorFreeInput.length}/255`}
              placeholder="Bereich (Optional)"
              name="sector"
              type="name"
              label="Bereich (Optional)"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ margin: `${styles.spacing24} 0` }}
              onChange={changeSectorFreeInput}
            />
          )}
        </>
      )}
      <div className="page-one-button-wrapper">
        <Button
          style={{ margin: `${styles.spacing24} 0 0 0` }}
          size="medium"
          onClick={() => onNextPage(2)}
          disabled={
            !(
              state.validFirstName &&
              state.validLastName &&
              state.validEmail &&
              (admin ? state.validSector : true)
            )
          }
          color="primary"
          variant="contained"
        >
          Weiter
        </Button>
      </div>
    </>
  );
};

PageOne.propTypes = propTypes;
PageOne.defaultProps = defaultProps;
export default withStyles(overrideStyles, { withTheme: true })(PageOne);
