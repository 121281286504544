/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import { CardActionArea, IconButton, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import classNames from "classnames";

import * as styles from "../../../../styles/styles";
import "./styles.css";

const propTypes = {
  history: PropTypes.object,
  item: PropTypes.object,
  catalogOrganizationCategory: PropTypes.array,
  catalogComponents: PropTypes.array,
  catalogMaterials: PropTypes.array,
  catalogDimensions: PropTypes.array,
  onEditCategoryClicked: PropTypes.func,
  onDeleteCategoryClicked: PropTypes.func,
  setActiveComponentCatalogUuid: PropTypes.func,
  isAdmin: PropTypes.bool,
  classes: PropTypes.object,
};

const overrideStyles = (theme) => ({
  root: {
    borderRadius: "10px",
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
    backgroundColor: styles.white,
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: styles.darkBlueMedium,
    //boxShadow: styles.boxShadowListElement,
    boxShadow: "none",
    "&:hover": {
      boxShadow: styles.boxShadowListElementHover,
    },
  },
});

const ComponentCatalogCard = ({
  history,
  item,
  catalogOrganizationCategory,
  catalogComponents,
  catalogMaterials,
  catalogDimensions,
  onEditCategoryClicked,
  onDeleteCategoryClicked,
  setActiveComponentCatalogUuid,
  isAdmin,
  classes,
}) => {
  const renderCardContent = () => {
    return (
      <div>
        <Typography
          variant="h5"
          component="div"
          sx={{ marginBottom: "1.0rem", fontSize: "2.5rem" }}
        >
          {item.name}
        </Typography>
        <Typography
          variant="body2"
          component="div"
          sx={{ marginBottom: "2rem", fontSize: "1.5rem" }}
        >
          {item.description
            ? `${item.description}`
            : "Es liegt keine Beschreibung vor"}
        </Typography>
        <Grid container direction="row">
          <Grid
            container
            direction="row"
            item
            xs={12}
            sx={{ marginBottom: "0.35rem" }}
          >
            <Grid item xs={1}>
              <Typography
                variant="body2"
                component="div"
                sx={{ fontSize: "1.5rem", fontWeight: "600" }}
              >
                {`${catalogComponents[0].items.length}`}
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Typography
                variant="body2"
                component="div"
                sx={{ fontSize: "1.5rem" }}
              >
                {catalogComponents[0].items.length === 1
                  ? "Komponente"
                  : "Komponenten"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} sx={{ marginBottom: "0.35rem" }}>
            <Grid item xs={1}>
              <Typography
                variant="body2"
                component="div"
                sx={{ fontSize: "1.5rem", fontWeight: "600" }}
              >
                {`${catalogMaterials[0].items.length}`}
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Typography
                variant="body2"
                component="div"
                sx={{ fontSize: "1.5rem" }}
              >
                {catalogMaterials[0].items.length === 1
                  ? "Material"
                  : "Materialien"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={1}>
              <Typography
                variant="body2"
                component="div"
                sx={{ fontSize: "1.5rem", fontWeight: "600" }}
              >
                {`${catalogDimensions[0].items.length}`}
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Typography
                variant="body2"
                component="div"
                sx={{ fontSize: "1.5rem" }}
              >
                {catalogDimensions[0].items.length === 1
                  ? "Dimension"
                  : "Dimensionen"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  };

  const renderCardActions = (
    onDeleteCategoryClicked,
    onEditCategoryClicked
  ) => {
    return (
      <div className="card-actions-container">
        <Tooltip arrow placement="bottom" title="Editieren">
          <IconButton
            aria-label="edit"
            onClick={() =>
              onEditCategoryClicked(catalogOrganizationCategory, item)
            }
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip arrow placement="bottom" title="Löschen">
          <IconButton
            aria-label="delete"
            onClick={() =>
              onDeleteCategoryClicked(catalogOrganizationCategory, item)
            }
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  return (
    <Grid item xs={2} sm={2} md={3}>
      <Card className={classNames(classes.root)}>
        <CardActionArea
          onClick={() => {
            setActiveComponentCatalogUuid(item.uuid);
            history.push(`/configurator/component-catalogs/${item.uuid}`);
          }}
        >
          <CardContent>{renderCardContent()}</CardContent>
        </CardActionArea>
        {catalogOrganizationCategory[0].item.organization_id && isAdmin && (
          <CardActions disableSpacing>
            {renderCardActions(onDeleteCategoryClicked, onEditCategoryClicked)}
          </CardActions>
        )}
      </Card>
    </Grid>
  );
};

ComponentCatalogCard.propTypes = propTypes;
export default withStyles(overrideStyles, { withTheme: true })(
  ComponentCatalogCard
);
