/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";

import { useLocation } from "react-router-dom";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { purple } from '@mui/material/colors';

import Header from "../../../../components/Header";

import history from "../../../../lib/history";

import useExitDetector from "../../../../util/useExitDetector";
import localStorageHandler from "../../../../util/localStorageHandler";

import * as styles from "../../../../styles/styles";

const propTypes = {
  setCreateModalOpen: PropTypes.func,
  isAdmin: PropTypes.bool,
  uploading: PropTypes.bool
};

const MapLayersHeader = ({
  setCreateModalOpen,
  isAdmin,
  uploading
}) => {
  const [getLastPath, clearLastPath] = localStorageHandler();
  const pathName = useLocation().pathname;
  useExitDetector(pathName);

  return (
    <Header>
      <IconButton
        style={{
          textDecoration: "none",
        }}
        onClick={() => {
          if (getLastPath() === pathName) {
            clearLastPath();
            history.go(-2);
          } else {
            history.goBack();
          }
        }}
        size="large"
      >
        <ArrowBackIcon />
      </IconButton>

      <div className="header-right-side">
        
        {isAdmin && (
          <Box sx={{ m: 1, position: 'relative' }}>
            <Button
              disabled={uploading}
              style={{ margin: `0 ${styles.spacing16} 0 0` }}
              color="primary"
              variant="contained"
              size="medium"
              onClick={() => setCreateModalOpen(true)}
            >
              <AddCircleIcon style={{ margin: "0 1.2rem 0 0" }} />
              Neu anlegen
            </Button>
            {uploading && (
              <CircularProgress
                size={24}
                sx={{
                  color: purple[500],
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Box>
        )}
      </div>
    </Header>
  );
};

MapLayersHeader.propTypes = propTypes;
export default MapLayersHeader;
