
import { GNSS_IMAGE } from "../../../../../../../constants/pointLookupTable";
import { COMPONENT_TYPE_GNSS_IMAGE } from "../../../../../../../constants/ecs";

export default class ComponentGnssImage {
  constructor(params) {
    this.componentType = COMPONENT_TYPE_GNSS_IMAGE;
    this.componentName = GNSS_IMAGE;
    this.currentHighlight = -1;
    this.hoveredStateId = -1;

    this.circleRadiusNormal = 1;
    this.circleRadiusHover = 0;

    this.name = params.name;

    this.idList = params.data.features.map((feature) => feature.id);

    this.source = {
      type: "geojson",
      data: params.data,
      promoteId: "uuid",
    };

    this.layer = {
      id: this.name,
      type: "symbol",
      source: this.name,
      layout: {
        "icon-image": "camera",
        'icon-allow-overlap': true,
       
      },
      paint: {
         "icon-opacity": [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          1,
          0.7,
        ],
      }
    };
  }


  getComponentType() {
    return this.componentType;
  }

  getComponentName() {
    return this.componentName;
  }
}
