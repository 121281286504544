import axios from "axios";

let instance = axios.create({
  headers: {
    common: {
      // can be common or any other method
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  },
});

export default instance;
