/* eslint-disable no-unused-vars */
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import withStyles from "@mui/styles/withStyles";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { assignUser } from "../../../actions/projects";
import ButtonGroup from "../../../components/ButtonGroup";
import CustomDialog from "../../../components/CustomDialog";
import "./styles.css";

const propTypes = {
  users: PropTypes.array,
  modalOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  assignUser: PropTypes.func,
  project: PropTypes.object,
};

const overrideStyles = (theme) => ({
  alignText: {
    margin: "auto 0 0 0",
  },
});

class AssignUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selectedUser: null };
    this.assignUser = this.assignUser.bind(this);
  }

  assignUser() {
    const { onClick, assignUser, project } = this.props;
    const { selectedUser } = this.state;
    assignUser(project, selectedUser);
    onClick();
  }

  buildPattern(str) {
    const arr = [...str];
    arr.splice(0, 0, ".*");
    arr.splice(arr.length, 0, ".*");
    return new RegExp(arr.join(""), "i");
  }

  fullName(obj) {
    return obj.first_name + " " + obj.last_name + " (" + obj.email + ")";
  }

  render() {
    const { modalOpen, onClose, users, project } = this.props;
    const { selectedUser } = this.state;
    const assignedUser = users.find((u) => u.id === project.assigned_user_id);
    return (
      <CustomDialog
        open={modalOpen}
        onClick={this.inviteUser}
        onClose={onClose}
        renderTitle={() => {
          return (
            <DialogTitle id="alert-dialog-title">Projekt zuweisen</DialogTitle>
          );
        }}
        renderContent={() => {
          return (
            <DialogContent>
              <Autocomplete
                options={users}
                value={assignedUser}
                noOptionsText={"Keine Nutzer gefunden"}
                filterSelectedOptions={true}
                filterOptions={(options, state) => {
                  const pattern = this.buildPattern(state.inputValue);
                  return options.filter((option) =>
                    pattern.test(this.fullName(option))
                  );
                }}
                renderInput={(params) => <TextField {...params} />}
                getOptionLabel={this.fullName}
                onChange={(e, value) => this.setState({ selectedUser: value })}
              />
            </DialogContent>
          );
        }}
        renderButtonGroup={(onClick, onClose) => {
          return (
            <DialogActions style={{ padding: "2.4rem 2.4rem 2rem" }}>
              <ButtonGroup
                align="right"
                leftButtonText="Abbrechen"
                leftButtonClick={onClose}
                rightButtonText="Zuweisen"
                rightButtonDisabled={selectedUser === null}
                rightButtonClick={this.assignUser}
                spacingTop={true}
              />
            </DialogActions>
          );
        }}
      />
    );
  }
}

function dispatchToProps(dispatch) {
  return bindActionCreators(
    {
      assignUser,
    },
    dispatch
  );
}

function stateToProps(state) {
  const organization = state.getIn(["organizations", "items"]).first();
  const users = organization ? organization.get("users").toJS() : [];
  return {
    users,
  };
}

AssignUser.propTypes = propTypes;
const styledAssignUser = withStyles(overrideStyles, { withTheme: true })(
  AssignUser
);
export default connect(stateToProps, dispatchToProps)(styledAssignUser);
