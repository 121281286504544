import React from 'react';

import './styles.css';

const Divider = () => {
  return <div
    className='divider-container'>
    <div
      className='divider-line'></div>
  </div>
}

export default Divider