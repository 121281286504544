import React from "react";
import PropTypes from "prop-types";
import mapboxgl from "mapbox-gl";

const propTypes = {
  project: PropTypes.object,
};

class EmptyProjectMap extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.map = new mapboxgl.Map({
      container: this.mapContainer,
      style: "mapbox://styles/mapbox/streets-v9",
      accessToken:
        "pk.eyJ1IjoiZ3JvYmVydCIsImEiOiJja3d2ejAwN2MweGdxMnFtaThpbGRxbzNmIn0.p83TeA_nVV8WiMEpNFYnoQ",
    });

    this.map.on("load", async () => {
      this.map.addControl(
        new mapboxgl.NavigationControl({ visualizePitch: true }),
        "top-right"
      );
      this.map.addControl(new mapboxgl.ScaleControl());
      this.map.addControl(
        new mapboxgl.FullscreenControl({
          container: document.getElementById("project-map-container"),
        })
      );
      this.map.getCanvas().style.cursor = "auto";

      if (this.props.project.place.latLng) {
        this.map.jumpTo({
          center: this.props.project.place.latLng,
          zoom: 19,
        });
      }
    });
  }

  componentWillUnmount() {
    this.map.remove();
  }

  render() {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          width: "100%",
        }}
        ref={(el) => (this.mapContainer = el)}
      />
    );
  }
}

EmptyProjectMap.propTypes = propTypes;
export default EmptyProjectMap;
