import { fromJS, Map } from "immutable";
import {
  EDIT_PROJECT,
  REMOVE_PROJECT,
  SET_PROJECTS,
  SET_PROJECT,
  SET_PROJECT_STATUS,
  RESET_PROJECT,
  GEOCODE_ADDRESS,
  SET_PROJECT_JOB_ORDER_VALID,
} from "../constants/actions/actions";

const initialState = fromJS({
  loading: false,
  new: 0,
  items: fromJS({}),
  projectStatus: null,
  isProjectJobOrderValid: false,
});

export const prepareItems = (array) => {
  return Map(array.map((item) => [item.id.toString(), fromJS(item)]));
};

export function projects(state = initialState, action) {
  switch (action.type) {
    case SET_PROJECT_JOB_ORDER_VALID:
      return state.set("isProjectJobOrderValid", action.payload);

    case SET_PROJECTS:
      return state.mergeIn(["items"], prepareItems(action.payload));

    case SET_PROJECT:
      return state.setIn(
        ["items", action.payload.id.toString()],
        fromJS(action.payload.item)
      );

    case EDIT_PROJECT: {
      let { id, item } = action.payload;
      return state.getIn([`items`, id.toString()])
        ? state.mergeIn([`items`, id.toString()], fromJS(item))
        : state;
    }

    case REMOVE_PROJECT:
      return state.deleteIn([`items`, action.payload.id.toString()]);

    case SET_PROJECT_STATUS:
      return state.set("projectStatus", action.payload);

    case RESET_PROJECT:
      return state.getIn([`items`, action.payload.id.toString()])
        ? state.mergeIn(
            [`items`, action.payload.id.toString()],
            fromJS(action.payload)
          )
        : state;

    case GEOCODE_ADDRESS: {
      let { projectId, response } = action.payload;

      const latLng = response.data.features[0].center;

      return state.getIn([`items`, projectId.toString()])
        ? state.mergeIn(
            [`items`, projectId.toString(), `place`],
            fromJS({ latLng: latLng })
          )
        : state;
    }
    default:
      return state;
  }
}
