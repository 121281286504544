/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const propTypes = {
  classes: PropTypes.object,
  dropdownSelection: PropTypes.object.isRequired,
  sortProjectsReverse: PropTypes.func.isRequired,
  descendingSorted: PropTypes.bool.isRequired,
  sortProjects: PropTypes.func.isRequired,
};

const overrideStyles = (theme) => ({
  iconSize: {
    fontSize: "1.5rem",
  },
  height: {
    padding: "1rem 4rem 1rem 1.5rem",
  },
  reverseButton: {
    margin: "0 0 0 0.4rem",
    padding: "1.15rem",
    minWidth: "1rem",
  },
});

class Dropdowns extends React.Component {
  constructor(props) {
    super(props);

    const { dropdownSelection } = this.props;

    this.state = {
      values: {
        order: dropdownSelection.value,
      },
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const { sortProjects } = this.props;
    const value = event.target.value;
    const name = "order";
    sortProjects({ text: name, value: value });
    this.setState({
      values: { ...this.state.values, [name]: value },
    });
  }

  render() {
    const { descendingSorted, sortProjectsReverse, classes } = this.props;
    return (
      <>
        <FormControl variant="outlined" size="small">
          <Select
            value={this.state.values.order}
            onChange={this.handleChange}
            autoWidth={true}
            displayEmpty
          >
            <MenuItem value={"inserted_at"}>Erstellungsdatum</MenuItem>
            <MenuItem value={"updated_at"}>Zuletzt geändert</MenuItem>
            <MenuItem value={"due_date"}>Fälligkeitsdatum</MenuItem>
            <MenuItem value={"state"}>Projektstatus</MenuItem>
            <MenuItem value={"user_id"}>Mitarbeiter</MenuItem>
            <MenuItem value={"assigned_user_id"}>
              Zugewiesener Mitarbeiter
            </MenuItem>
          </Select>
        </FormControl>
        <Button
          classes={{ outlined: classes.reverseButton }}
          size="small"
          variant="outlined"
          onClick={sortProjectsReverse}
        >
          {descendingSorted ? (
            <ArrowUpwardIcon
              classes={{ fontSizeSmall: classes.iconSize }}
              fontSize="small"
            />
          ) : (
            <ArrowDownwardIcon
              classes={{ fontSizeSmall: classes.iconSize }}
              fontSize="small"
            />
          )}
        </Button>
      </>
    );
  }
}

Dropdowns.propTypes = propTypes;
export default withStyles(overrideStyles, { withTheme: true })(Dropdowns);
