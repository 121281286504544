import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const propTypes = {
  text: PropTypes.string,
};

const TooltipUiElement = ({ text }) => {
  return (
    <Tooltip
      TransitionComponent={Zoom}
      title={<Typography varaint="body1">{text}</Typography>}
    >
      <ErrorOutlineIcon fontSize="small" />
    </Tooltip>
  );
};

TooltipUiElement.propTypes = propTypes;
export default TooltipUiElement;
