/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { AiOutlineCheckCircle, AiOutlineCloudUpload } from "react-icons/ai";
import { MdClear } from "react-icons/md";

import { renderSnackbar } from "../../actions/snackbars";
import { projectFileIsToLargeFail, projectFileExtensionNotSupportedFail } from "../../constants/snackbars";
import { extractFileInformationFromFile } from "../../sharedFunctions/ioUtils";

import "./styles.css";

const propTypes = {
    onFilesSelected: PropTypes.func,
    width: PropTypes.number,
    height: PropTypes.number,
    maxSize: PropTypes.number,
    acceptFileTypes: PropTypes.string,
    acceptedFileExtensions: PropTypes.array,
    titleText: PropTypes.string,
    bodyText: PropTypes.string,
    uploadLimit: PropTypes.number,
    renderSnackbar: PropTypes.func,
};

const defaultProps = {
    uploadLimit: 1
};

const FileUploadComponent = ({
  onFilesSelected,
  width,
  height,
  maxSize,
  acceptFileTypes,
  acceptedFileExtensions,
  titleText,
  bodyText,
  uploadLimit,
  renderSnackbar
}) => {
  const [stateFiles, setFiles] = useState([]);
    
  const validateFile = (files) => {
    if (files.length > 0) {
      var fileToLargeFlag = false;
      var fileWrongFileExtensionFlag = false;

      files.forEach(file => {
        const fileInformation = extractFileInformationFromFile(file);

        if (fileInformation[3] > maxSize) {
          fileToLargeFlag = true;
        }

        if (!acceptedFileExtensions.includes(fileInformation[2].toLowerCase())) {
          fileWrongFileExtensionFlag = true;
        }
      })

      if (fileToLargeFlag) {
        renderSnackbar(projectFileIsToLargeFail);
      }

      if (fileWrongFileExtensionFlag) {
        renderSnackbar(projectFileExtensionNotSupportedFail);
      }

      if (fileToLargeFlag || fileWrongFileExtensionFlag) {
        return false;
      }
      
      return true;
    }

    return false;
  }

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;

    if (selectedFiles && 
      selectedFiles.length > 0 && 
      validateFile([...selectedFiles])
    ) {

      if (selectedFiles.length === uploadLimit) {
        setFiles([])
      }

      const newFiles = Array.from(selectedFiles);
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;

    if (droppedFiles.length > 0 && 
      validateFile([...droppedFiles])
    ) {

      if (droppedFiles.length === uploadLimit) {
        setFiles([])
      }

      const newFiles = Array.from(droppedFiles);
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  useEffect(() => {
    onFilesSelected(stateFiles);
  }, [stateFiles, onFilesSelected]);

  var fileSelectionText = ""

  if (stateFiles.length > 0) {
    if (stateFiles.length == 1) {
      fileSelectionText = "1 Datei ausgewählt"
    } else {
      fileSelectionText = `${stateFiles.length} Dateien ausgewählt`
    }
  }

  return (
    <section className="drag-drop" style={{ width: width, height: height }}>
      <div
        className={`document-uploader ${
          stateFiles.length > 0 ? "upload-box active" : "upload-box"
        }`}
        onDrop={handleDrop}
        onDragOver={(event) => event.preventDefault()}
      >
        <>
          <div className="upload-info">
            <AiOutlineCloudUpload />
            <div>
              <p>{titleText}</p>
              <p>
                {bodyText}
              </p>
            </div>
          </div>
          <input
            type="file"
            hidden
            id="browse"
            onChange={handleFileChange}
            accept={acceptFileTypes}
            multiple
          />
          {/*multiple. Put this in the input tag to allow the upload of multiple files*/}
          <label htmlFor="browse" className="browse-btn">
            Browse files
          </label>
        </>

        {stateFiles.length > 0 && (
          <div className="file-list">
            <div className="file-list__container">
              {stateFiles.map((file, index) => (
                <div className="file-item" key={index}>
                  <div className="file-info">
                    <p>{file.name}</p>
                    {/* <p>{file.type}</p> */}
                  </div>
                  <div className="file-actions">
                    <MdClear onClick={() => handleRemoveFile(index)} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {stateFiles.length > 0 && (
          <div className="success-file">
            <AiOutlineCheckCircle
              style={{ color: "#6DC24B", marginRight: 1 }}
            />
            <p>{fileSelectionText}</p>
          </div>
        )}
      </div>
    </section>
  );
};


function dispatchToProps(dispatch) {
  return bindActionCreators(
    {
      renderSnackbar
    },
    dispatch
  );
}



FileUploadComponent.propTypes = propTypes;
FileUploadComponent.defaultProps = defaultProps;
export default connect(null, dispatchToProps)(FileUploadComponent);
