/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

import Confirmation from "../../../../../components/dialogs/Confirmation";
import ProjectFilePreview from "../../../../../components/ProjectFilePreview";

import { saveAs } from "file-saver";

import { deleteProjectFile } from "../../../../../actions/projectFiles";

import * as styles from "../../../../../styles/styles";
import "./styles.css";

const propTypes = {
  project: PropTypes.object,
  projectFiles: PropTypes.object,
  deleteProjectFile: PropTypes.func,
  creatingProjectFile: PropTypes.bool,
};

const ProjectFileTable = ({
  project,
  projectFiles,
  deleteProjectFile,
  creatingProjectFile,
}) => {
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [toModifyItemKey, setToModifyItemKey] = React.useState("");

  const closeModal = () => {
    setDeleteModalOpen(false);
    setToModifyItemKey("");
  };

  const onDeleteClicked = (key) => {
    setDeleteModalOpen(true);
    setToModifyItemKey(key);
  };

  const onClickProjectFileDelete = (projectFile) => {
    deleteProjectFile(projectFile.id);
    closeModal();
  };

  const onProjectFileItemClicked = (projectFile) => {
    fetch(projectFile.file.original)
      .then((res) => res.blob()) // Gets the response and returns it as a blob
      .then((blob) => {
        saveAs(blob, projectFile.original_filename);
      });
  };

  return (
    <>
      {deleteModalOpen &&
        showDeleteModal(
          projectFiles[toModifyItemKey],
          closeModal,
          deleteModalOpen,
          onClickProjectFileDelete
        )}
      <div>
        <TableContainer
          component={Paper}
          style={{ maxHeight: "calc(100vh - 7.2rem)" }}
        >
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Hochgeladen am</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projectFiles &&
                createRows(
                  projectFiles,
                  onDeleteClicked,
                  onProjectFileItemClicked
                )}
              {creatingProjectFile && <ProjectFilePreview />}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

function createRows(projectFiles, onDeleteClicked, onProjectFileItemClicked) {
  const rows = projectFiles;

  return Object.keys(rows).map((key, index) => {
    return (
      <TableRow key={index}>
        <TableCell align="center">
          {rows[key].original_filename.split(".")[1] === "pdf" ? (
            <img
              onClick={() => onProjectFileItemClicked(rows[key])}
              src={process.env.PUBLIC_URL + "/PDF_file_icon.svg.png"}
              className="project-file-list-item-list-item "
              alt={rows[key].original_filename}
            />
          ) : (
            <img
              onClick={() => onProjectFileItemClicked(rows[key])}
              src={rows[key].file.thumb}
              className="project-file-list-item-list-item "
              alt={rows[key].original_filename}
            />
          )}
        </TableCell>
        <TableCell>
          <Typography variant="body2">{rows[key].original_filename}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">
            {`${moment(rows[key].inserted_at).format(
              "dd. DD.MM.YYYY, HH:mm"
            )} Uhr`}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <IconButton
            aria-label="delete"
            onClick={() => onDeleteClicked(key)}
            style={{
              backgroundColor: styles.darkBlueSuperLight,
              marginRight: styles.spacing16,
            }}
            size="large"
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  });
}

function showDeleteModal(
  projectFile,
  closeModal,
  deleteModalOpen,
  onClickProjectFileDelete
) {
  return (
    <Confirmation
      highlightedText={projectFile.original_filename}
      headline="Layer löschen"
      leftSideText={`Möchten Sie die Datei`}
      modalOpen={deleteModalOpen}
      onClose={() => closeModal()}
      onClick={() => onClickProjectFileDelete(projectFile)}
      rightSideText={`erstellt am ${moment(projectFile.inserted_at).format(
        "DD.MM.YYYY"
      )} wirklich löschen?`}
      textButton="Löschen"
    />
  );
}

function dispatchToProps(dispatch) {
  return bindActionCreators({ deleteProjectFile }, dispatch);
}

function stateToProps(state) {
  const projectFiles = state.get("projectFiles");
  return {
    creatingProjectFile: projectFiles.getIn(["ui", "creatingProjectFile"]),
  };
}

ProjectFileTable.propTypes = propTypes;
export default connect(stateToProps, dispatchToProps)(ProjectFileTable);
