/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Link } from "react-router-dom";
import { Typography, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Confirmation from "../../../../components/dialogs/Confirmation";
import EditUserSettingsDialog from "../../../../containers/dialogs/EditUserSettingsDialog";
import UserProfileAppSettings from "../../../../components/userProfile/UserProfileAppSettings";
import UserProfileDeleteUser from "../../../../components/userProfile/UserProfileDeleteUser";
import DisplayUserProfile from "../../../../components/userProfile/DisplayUserProfile";
import Divider from "../../../../atoms/Divider";
import Header from "../../../../components/Header";
import Container from "../../../../components/layout/Container";

import {
  updateUser,
  removeUser,
  uploadAvatar,
  deleteAvatar,
} from "../../../../actions/userAdministration";

import history from "../../../../lib/history";

import * as styles from "../../../../styles/styles";

const propTypes = {
  userId: PropTypes.string,
  user: PropTypes.object,
  settingsAndroid: PropTypes.array,
  updateUser: PropTypes.func,
  removeUser: PropTypes.func,
  uploadAvatar: PropTypes.func,
  deleteAvatar: PropTypes.func,
};

const AdminUserProfil = ({
  userId,
  user,
  settingsAndroid,
  updateUser,
  removeUser,
  uploadAvatar,
  deleteAvatar,
}) => {
  const [state, setState] = React.useState({
    editUser: false,
    avatarTooLarge: false,
    firstNameValid: true,
    lastNameValid: true,
    avatar: user.getIn(["avatar", "original"]),
    settingsAndroid: settingsAndroid,
    settingsChanged: false,
    appSettingsModalOpened: false,
  });

  const [stateAvatar, setStateAvatar] = React.useState(
    user.getIn(["avatar", "original"])
  );
  const [stateFirstName, setStateFirstName] = React.useState(
    user.get("first_name")
  );
  const [stateLastName, setStateLastName] = React.useState(
    user.get("last_name")
  );

  const cancel = () => {
    const androidSettings = user.toJS().settings.android;

    setState({
      ...state,
      editUser: false,
      avatarTooLarge: false,
      avatar: user.getIn(["avatar", "original"]),
      settingsAndroid: androidSettings,
      settingsChanged: false,
    });

    setStateFirstName(user.get("first_name"));
    setStateLastName(user.get("last_name"));
  };

  const updateEditUserFlag = (flag) => {
    setState({ ...state, editUser: flag });
  };

  const editUserDetails = () => {
    const { firstNameValid, lastNameValid, settingsAndroid, settingsChanged } =
      state;

    const firstName = stateFirstName;
    const lastName = stateLastName;

    // Update firstName, lastName and Android-Settings here
    if (firstNameValid && lastNameValid) {
      const userDetails = {
        params: {
          id: userId,
          first_name: firstName.trim(),
          last_name: lastName.trim(),
          settings: {
            android: settingsAndroid,
          },
        },
      };
      if (
        userDetails.first_name !== user.get("first_name") ||
        userDetails.last_name !== user.get("last_name") ||
        settingsChanged
      )
        updateUser(userDetails);
    }

    // Update the Avatar here
    if (stateAvatar !== null && typeof stateAvatar === "object") {
      const newAvatar = new FormData();
      newAvatar.append(
        "avatar",
        stateAvatar,
        stateAvatar ? stateAvatar.name : { user: { avatar: "" } }
      );
      uploadAvatar(userId, newAvatar);
    } else if (stateAvatar === null && user.getIn(["avatar", "original"])) {
      deleteAvatar(userId);
    }

    // Reset the component state
    setState({
      ...state,
      editUser: false,
      avatarTooLarge: false,
      settingsChanged: false,
    });
  };

  const createAvatarUrl = (avatar) => {
    if (avatar)
      return typeof avatar === "string" ? avatar : URL.createObjectURL(avatar);
    return avatar;
  };

  const onChangeAvatar = (avatar) => {
    setStateAvatar(avatar);
  };

  const onAvatarSizeToLarge = (avatarTooLarge) => {
    setState({ ...state, avatarTooLarge: avatarTooLarge });
  };

  const onChangeName = (field, event) => {
    const name = event.target.value;
    const validKey = `${field}Valid`;

    if (field === "firstName") {
      setStateFirstName(name);
    }
    if (field === "lastName") {
      setStateLastName(name);
    }

    setState({
      ...state,
      [validKey]: name.length > 0,
    });
  };

  const onAppSettingsModelClose = () => {
    setState({ ...state, appSettingsModalOpened: false });
  };
  const onAppSettingsModelOpen = () => {
    setState({ ...state, appSettingsModalOpened: true });
  };

  const onDeleteUserModalClose = () => {
    setState({ ...state, deleteUserModalOpened: false });
  };

  const onDeleteUserModalOpen = () => {
    setState({ ...state, deleteUserModalOpened: true });
  };

  const editUserAppSettings = (settingsAndroid) => {
    const { firstNameValid, lastNameValid } = state;
    const firstName = stateFirstName;
    const lastName = stateLastName;

    if (firstNameValid && lastNameValid) {
      const userDetails = {
        params: {
          id: userId,
          first_name: firstName.trim(),
          last_name: lastName.trim(),
          settings: {
            android: settingsAndroid,
          },
        },
      };

      updateUser(userDetails);
    }

    setState({
      editUser: false,
      avatarTooLarge: false,
      settingsChanged: false,
    });
  };

  const removeSelectedUser = (user) => {
    onDeleteUserModalClose(false);
    history.goBack();
    removeUser(user.get("id"));
  };

  const renderEditUserSettingDialog = (appSettingsModalOpened) => {
    return (
      <EditUserSettingsDialog
        key={"EditUserSettingsDialog"}
        settings={user.getIn(["settings", "android"]).toJS()}
        modalOpen={appSettingsModalOpened}
        onNegativeButtonClicked={() => onAppSettingsModelClose()}
        onPositiveButtonClicked={(stateChanged, updatedSettings) => {
          setState({
            settingsAndroid: updatedSettings,
            settingsChanged: stateChanged,
          });
          onAppSettingsModelClose();
          editUserAppSettings(updatedSettings);
        }}
      />
    );
  };

  const renderDeleteUserDialog = (deleteUserModalOpened) => {
    return (
      <Confirmation
        highlightedText={`${user.get("first_name")} ${user.get("last_name")}`}
        headline="Mitarbeiter entfernen"
        leftSideText="Möchten Sie den Mitarbeiter "
        modalOpen={deleteUserModalOpened}
        onClose={() => onDeleteUserModalClose()}
        onClick={() => removeSelectedUser(user)}
        rightSideText={`wirklich entfernen?`}
        textButton="Entfernen"
      />
    );
  };

  // Header
  // Section to edit first_name and last_name
  // Section to remove the User from the Organization
  return (
    <div>
      {state.appSettingsModalOpened &&
        renderEditUserSettingDialog(state.appSettingsModalOpened)}
      {state.deleteUserModalOpened &&
        renderDeleteUserDialog(state.deleteUserModalOpened)}
      <Header showUserSettings={false}>
        <Link
          to={"/organization"}
          style={{
            position: "absolute",
            left: "1rem",
          }}
        >
          <IconButton
            style={{
              textDecoration: "none",
            }}
            size="large"
          >
            <ArrowBackIcon />
          </IconButton>
        </Link>
      </Header>
      <Container fullAppHeight={true} divider={true}>
        <Typography
          color="primary"
          variant="h2"
          style={{ width: "100%", margin: `0 0 ${styles.spacing24} 0` }}
        >
          Profil
        </Typography>

        <DisplayUserProfile
          avatar={stateAvatar}
          avatarTooLarge={state.avatarTooLarge}
          firstName={stateFirstName}
          firstNameValid={state.firstNameValid}
          lastName={stateLastName}
          lastNameValid={state.lastNameValid}
          editUser={state.editUser}
          email={user.get("email")}
          cancel={cancel}
          editUserDetails={editUserDetails}
          updateEditUserFlag={updateEditUserFlag}
          onChangeName={onChangeName}
          createAvatarUrl={createAvatarUrl}
          onAvatarSizeToLarge={onAvatarSizeToLarge}
          onChangeAvatar={onChangeAvatar}
        />
        <Divider />
        <UserProfileAppSettings modalOpen={onAppSettingsModelOpen} />
        <Divider />

        <UserProfileDeleteUser modalOpen={onDeleteUserModalOpen} />
      </Container>
    </div>
  );
};

function dispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateUser,
      removeUser,
      uploadAvatar,
      deleteAvatar,
    },
    dispatch
  );
}

function stateToProps(state, ownProps) {
  const userId = ownProps.match.params.id;

  const user = state
    .getIn(["organizations", "items"])
    .first()
    .get("users")
    .filter((user) => {
      return String(user.get("id")) === String(userId);
    })
    .first();

  return {
    userId,
    user,
    settingsAndroid: user.getIn(["settings", "android"]).toJS(),
  };
}

AdminUserProfil.propTypes = propTypes;
export default connect(stateToProps, dispatchToProps)(AdminUserProfil);
