/* eslint-disable no-prototype-builtins */
// SEARCH WITH PLAIN OBJECTS

const findValueInDataObjectByKey = (dataObject, searchKey) => {
  const result = Object.keys(dataObject).map((key) => {
    if (Object.prototype.toString.call(dataObject[key]) === "[object Object]") {
      return findValueInDataObjectByKey(dataObject[key], searchKey);
    } else if (dataObject.hasOwnProperty(key) && key === searchKey) {
      return dataObject[key];
    } else {
      return "";
    }
  });

  const filtered = result.filter((element) => element !== "");

  return !filtered.length ? "" : filtered[0];
};

const childrenSearch = (dataObject, searchableKey, word) => {
  const keyList = Object.keys(searchableKey.children);
  for (var index = 0; index < keyList.length; ++index) {
    const searchableChildKey = searchableKey.children[keyList[index]];
    const directSearchResult = directSearch(
      dataObject,
      searchableChildKey,
      word
    );
    if (directSearchResult) return true;
  }
  return false;
};

const referenceSearch = (dataObjectMap, dataObject, searchableKey, word) => {
  if (
    dataObject === null &&
    Object.prototype.toString.call(dataObject) !== "[object Object]"
  )
    return false;
  const ref = searchableKey["ref"];
  const searchKey = searchableKey.key;

  const searchableValue = findValueInDataObjectByKey(dataObject, searchKey);
  const refList = dataObjectMap[ref.main];

  const refItem = refList.find(
    (refItem) => refItem[ref.key] === searchableValue
  );
  if (refItem === null) return false;

  for (var index = 0; index < ref.search_by.length; ++index) {
    const searchBy = refItem[ref.search_by[index]];
    if (searchBy === null) continue;

    const result =
      String(searchBy)
        .toLowerCase()
        .indexOf(word.toLowerCase()) > -1;
    if (result) return true;
  }
  return false;
};

const directSearch = (dataObject, searchableKey, word) => {
  const searchKey = searchableKey.key;

  if (
    dataObject === null &&
    Object.prototype.toString.call(dataObject) !== "[object Object]"
  )
    return false;

  const searchableValue = findValueInDataObjectByKey(dataObject, searchKey);
  const result =
    String(searchableValue)
      .toLowerCase()
      .indexOf(word.toLowerCase()) > -1;
  if (result) return true;

  return false;
};

export const searchInList = (
  dataObjectMap,
  searchableKeys,
  searchTerm,
  searchIn
) => {
  const foundObjects = Object.keys(dataObjectMap[searchIn]).map((key) => {
    const dataObject = dataObjectMap[searchIn][key];

    return searchTerm.split(" ").some((word) => {
      const keyList = Object.keys(searchableKeys);

      for (var index = 0; index < keyList.length; ++index) {
        const searchableKey = searchableKeys[keyList[index]];

        if (searchableKey["children"]) {
          const childrenSearchResult = childrenSearch(
            dataObject,
            searchableKey,
            word
          );
          if (childrenSearchResult) return true;
        }
        if (searchableKey["ref"]) {
          const referenceSearchResult = referenceSearch(
            dataObjectMap,
            dataObject,
            searchableKey,
            word
          );
          if (referenceSearchResult) return true;
        }
        const directSearchResult = directSearch(
          dataObject,
          searchableKey,
          word
        );
        if (directSearchResult) return true;
      }
      return false;
    });
  });

  const resultObjectList = {};
  Object.keys(dataObjectMap[searchIn])
    .filter((_, index) => foundObjects[index])
    .forEach((index) => {
      resultObjectList[index] = dataObjectMap[searchIn][index];
    });

  return resultObjectList;
};

export const searchInImmutableList = (
  immutableDataObjectMap,
  searchableKeys,
  searchTerm,
  searchIn
) => {
  return immutableDataObjectMap[searchIn].filter((immutableDataObject) => {
    return searchTerm.split(" ").some((word) => {
      const keyList = Object.keys(searchableKeys);
      for (var index = 0; index < keyList.length; ++index) {
        const searchableKey = searchableKeys[keyList[index]];

        if (searchableKey["children"]) {
          const childrenSearchResult = childrenImmutableSearch(
            immutableDataObject,
            searchableKey,
            word
          );
          if (childrenSearchResult) return true;
        }

        if (searchableKey["ref"]) {
          const referenceSearchResult = referenceImmutableSearch(
            immutableDataObjectMap,
            immutableDataObject,
            searchableKey,
            word
          );
          if (referenceSearchResult) return true;
        }

        const directSearchResult = directImmutableSearch(
          immutableDataObject,
          searchableKey,
          word
        );
        if (directSearchResult) return true;
      }
      return false;
    });
  });
};

const directImmutableSearch = (immutableDataObject, searchableKey, word) => {
  const searchKey = searchableKey.key;
  const nestedSearchKey = searchKey.split(",");

  if (nestedSearchKey.length === 1) {
    if (!immutableDataObject.has(searchKey)) return false;
    const searchableValue = immutableDataObject.get(searchKey);
    if (searchableValue === null && searchableValue === "ifNotSet")
      return false;
    const result =
      String(searchableValue)
        .toLowerCase()
        .indexOf(word.toLowerCase()) > -1;
    if (result) return true;
  } else {
    if (!immutableDataObject.hasIn(nestedSearchKey)) return false;
    const searchableValue = immutableDataObject.getIn(nestedSearchKey);
    if (searchableValue === null && searchableValue === "ifNotSet")
      return false;
    const result =
      String(searchableValue)
        .toLowerCase()
        .indexOf(word.toLowerCase()) > -1;
    if (result) return true;
  }
  return false;
};

const referenceImmutableSearch = (
  immutableDataObjectMap,
  immutableDataObject,
  searchableKey,
  word
) => {
  const ref = searchableKey["ref"];
  const searchKey = searchableKey.key;

  if (!immutableDataObject.has(searchKey)) return false;
  const searchableValue = immutableDataObject.get(searchKey);
  if (searchableValue === null && searchableValue === "ifNotSet") return false;

  const users = immutableDataObjectMap[ref.main];

  const userIndex = users.findIndex((user) => {
    if (user.has(ref.key)) {
      return user.get(ref.key) === searchableValue;
    }
    return user[ref.key] === searchableValue;
  });
  const user = users.get(userIndex);

  for (var index = 0; index < ref.search_by.length; ++index) {
    if (!user.has(ref.search_by[index])) continue;
    const searchBy = user.get(ref.search_by[index]);
    if (searchBy === null && searchBy === "ifNotSet") continue;

    const result =
      String(searchBy)
        .toLowerCase()
        .indexOf(word.toLowerCase()) > -1;
    if (result) return true;
  }

  return false;
};

const childrenImmutableSearch = (immutableDataObject, searchableKey, word) => {
  const keyList = Object.keys(searchableKey.children);
  for (var index = 0; index < keyList.length; ++index) {
    const searchableChildKey = searchableKey.children[keyList[index]];

    const directSearchResult = directImmutableSearch(
      immutableDataObject,
      searchableChildKey,
      word
    );
    if (directSearchResult) return true;
  }
  return false;
};

export const filterImmutable = (
  immutableDataObjectMap,
  filterByKey,
  filterValue
) => {
  const filterValues = new Set(filterValue.split(","));

  return immutableDataObjectMap.filter((immutableDataObject) => {
    if (!immutableDataObject.has(filterByKey)) return false;

    const filterableValue = immutableDataObject.get(filterByKey);

    if (filterableValue === null && filterableValue === "ifNotSet")
      return false;

    const result = filterValues.has(String(filterableValue).toLowerCase());

    if (result) return true;
  });
};
