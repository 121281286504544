import React from "react";
import PropTypes from "prop-types";
import { 
  pointNameLookupTable,
  MEASURE_POINT,
  REFERENCE_POINT,
  TOPOGRAPHIC_POINT,
  GNSS_POINT,
  GNSS_IMAGE,
  GNSS_REF_POINT
} from "../../../../../constants/pointLookupTable";

import "./styles.css";

const propTypes = {
  onClick: PropTypes.func,
  id: PropTypes.number,
  feature: PropTypes.object,
};

const MapPopUp = ({ id, feature, onClick }) => {
  var popUpView;

  if (feature.layer.id.includes(MEASURE_POINT) || 
    feature.layer.id.includes(REFERENCE_POINT) || 
    feature.layer.id.includes(TOPOGRAPHIC_POINT)
  ) {
    popUpView = renderMeasurementPointPopUp(
      id, feature, onClick
    )
  } else if (feature.layer.id.includes(GNSS_POINT) || 
    feature.layer.id.includes(GNSS_IMAGE) || 
    feature.layer.id.includes(GNSS_REF_POINT)
  ) {
    popUpView = renderGnssPointPopUp(
      id, feature, onClick
    )
  }

  return popUpView
};

const renderMeasurementPointPopUp = (
  id,
  feature,
  onClick
) => {

  const image = JSON.parse(feature.properties.image).original;
  return (
    <div className="map-pop-up-custom-mapgoxgl-pop-up">
      <div className="map-pop-up-image-container">
        <img
          onClick={() => onClick(image)}
          className="map-pop-up-image"
          src={image}
          alt={`Bild-${id}`}
        />
      </div>

      <div className="map-pop-up-title">Punkt ID:</div>
      <div className="map-pop-up-text">
        {feature.properties.id_user}
      </div>
      <div className="map-pop-up-title">Punkt Typ:</div>
      <div className="map-pop-up-text">
        {`${
          feature.properties.used_for_transformation
            ? "Transformationspunkt"
            : pointNameLookupTable[feature.properties.type]
        }`}
      </div>
      <div className="map-pop-up-title">Bauteil:</div>
      <div className="map-pop-up-text">
        {`${
          feature.properties.component !== "null"
            ? feature.properties.component
            : "Keine Angabe"
        }`}
      </div>
      
    </div>
  );
}

const renderGnssPointPopUp = (
  id,
  feature,
  onClick
) => {
  const image = JSON.parse(feature.properties.image).original;
  console.log(image)
  return (
    <div className="map-pop-up-custom-mapgoxgl-pop-up">
      <div className="map-pop-up-image-container">
        {image ? 
        <img
          onClick={() => onClick(image)}
          className="map-pop-up-image"
          src={image}
          alt={`Bild-${id}`}
        />
      : <div className="map-pop-up-empty-image"/>}
      </div>

      <div className="map-pop-up-title">Punkt ID:</div>
      <div className="map-pop-up-text">
        {feature.properties.user_id}
      </div>
      <div className="map-pop-up-title">Bauteil:</div>
      <div className="map-pop-up-text">
        {`${
          feature.properties.component !== "null"
            ? feature.properties.component_name
            : "Keine Angabe"
        }`}
      </div>
      
    </div>
  );
}

MapPopUp.propTypes = propTypes;
export default MapPopUp;
