import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { sortProjects, sortProjectsReverse } from "../../actions/dashboard";
import { searchProjects } from "../../actions/data";
import SearchButton from "../../components/SearchButton";
import Buttons from "../../components/singleActionsMenu/Buttons";
import Dropdowns from "../../components/singleActionsMenu/Dropdowns";

import * as styles from "../../styles/styles";
import "./styles.css";

const propTypes = {
  disableCreateButton: PropTypes.bool,
  projects: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  dropdownSelection: PropTypes.object.isRequired,
  descendingSorted: PropTypes.bool,
  sortProjects: PropTypes.func.isRequired,
  sortProjectsReverse: PropTypes.func.isRequired,
  searchProjects: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
};

const overrideStyles = (theme) => ({
  search: {
    "&:hover": {
      backgroundColor: styles.darkBlueMedium,
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: "6rem",
      width: "auto",
    },
  },
  inputRoot: {
    color: "inherit",
    height: "3.7rem",
    lineHeight: "3.7rem",
  },
  inputInput: {
    margin: "0 0 0 2.5rem",
    padding: "1rem",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "10rem",
      "&:focus": {
        width: "20rem",
      },
    },
  },
});

const ProjectHeaderMenu = ({
  disableCreateButton,
  projects,
  users,
  dropdownSelection,
  descendingSorted,
  sortProjectsReverse,
  sortProjects,
  searchProjects,
  searchTerm,
}) => {
  const handleChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    sortProjects({ text: name, value });
  };

  return (
    <>
      <SearchButton
        placeholderText="Nach Projekt suchen..."
        projects={projects.toJS()}
        users={users}
        searchTerm={searchTerm}
        search={searchProjects}
        showResults={false}
      />
      <div className="header-right-side">
        <Buttons disabled={disableCreateButton} />

        <Dropdowns
          sortProjects={sortProjects}
          sortProjectsReverse={sortProjectsReverse}
          handleChange={handleChange}
          descendingSorted={descendingSorted}
          dropdownSelection={dropdownSelection.toJS()}
        />
      </div>
    </>
  );
};

function dispatchToProps(dispatch) {
  return bindActionCreators(
    {
      sortProjects,
      sortProjectsReverse,
      searchProjects,
    },
    dispatch
  );
}

function stateToProps(state) {
  const dashboard = state.get("dashboard");
  const organization = state.getIn(["organizations", "items"]).first();
  const users = organization ? organization.get("users").toJS() : [];
  return {
    descendingSorted: dashboard.getIn(["header", "descendingSorted"]),
    dropdownSelection: dashboard.getIn(["header", "currentDropdownSelection"]),
    projects: state.getIn(["projects", "items"]),
    users: users,
    searchTerm: state.getIn(["data", "searchTerm"]),
  };
}

ProjectHeaderMenu.propTypes = propTypes;
const styledProjectHeaderMenu = withStyles(overrideStyles, { withTheme: true })(
  ProjectHeaderMenu
);
export default connect(stateToProps, dispatchToProps)(styledProjectHeaderMenu);
