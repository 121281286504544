/* eslint-disable no-unused-vars */
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import React, { useState } from "react";
import CustomDialog from "../../../components/CustomDialog";

import {
  DXF,
  DXF_LOCAL,
  DXF_8_DIGIT,
  IMAGES,
  LOCAL,
  PDF,
  SHAPE,
  TRANSFORMATION_RESULT,
  TXT,
  WKT,
  DATA_MONITOR_RESULT,
} from "../../../constants/download";
import BulkAction from "./BulkAction";
import SingleAction from "./SingleAction";

const propTypes = {
  bulkAction: PropTypes.bool,
  classes: PropTypes.object,
  downloadDataFail: PropTypes.bool,
  modalOpen: PropTypes.bool,
  projects: PropTypes.array,
  project: PropTypes.object,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
};

const defaultProps = {
  bulkAction: false,
};

const DownloadData = ({
  bulkAction,
  modalOpen,
  projects,
  project,
  onClick,
  onClose,
}) => {
  const [state, setState] = useState({
    closeButton: false,
    headlineBulk: "Bitte wählen Sie aus, was Sie herunterladen möchten",
    headlineSingle: "Datendownload",
  });

  const firstCategoryFiles = [PDF, IMAGES];
  const secondCategoryFiles = [
    WKT,
    DXF,
    DXF_8_DIGIT,
    SHAPE,
    TRANSFORMATION_RESULT,
    DATA_MONITOR_RESULT,
  ];
  const thirdCategoryFiles = [TXT, LOCAL, DXF_LOCAL];

  return (
    <CustomDialog
      open={modalOpen}
      onClick={onClick}
      onClose={onClose}
      showCloseButton={state.closeButton ? true : !bulkAction}
      renderTitle={() => {
        return (
          <DialogTitle id="alert-dialog-title">
            {bulkAction ? state.headlineBulk : state.headlineSingle}
          </DialogTitle>
        );
      }}
      renderContent={() => {
        return (
          <>
            {bulkAction ? (
              <BulkAction
                firstCategoryFiles={firstCategoryFiles}
                secondCategoryFiles={secondCategoryFiles}
                thirdCategoryFiles={thirdCategoryFiles}
                setNextPage={() => {
                  setState({
                    ...state,
                    closeButton: true,
                    headlineBulk: "Datendownload",
                  });
                }}
                onClick={onClick}
                onClose={onClose}
                projects={projects}
              />
            ) : (
              <SingleAction
                firstCategoryFiles={firstCategoryFiles}
                secondCategoryFiles={secondCategoryFiles}
                thirdCategoryFiles={thirdCategoryFiles}
                project={project}
              />
            )}
          </>
        );
      }}
      renderButtonGroup={(onClick, onClose) => {}}
    />
  );
};

DownloadData.propTypes = propTypes;
DownloadData.defaultProps = defaultProps;
export default DownloadData;
