/* eslint-disable import/namespace */
/* eslint-disable no-unused-vars */
import { createAction } from "redux-actions";
import {
  SET_PROJECT_FILE,
  SET_PROJECT_FILES,
  REMOVE_PROJECT_FILE,
  REMOVE_PROJECT_FILES,
  SHOW_PROJECT_FILE_PREVIEW,
  HIDE_PROJECT_FILE_PREVIEW,
} from "../constants/actions/actions";
import {
  PROJECT_FILE_CREATE_EVENT,
  PROJECT_FILE_DELETED_EVENT,
} from "../constants/channelevents";
import {
  createProjectFileFail,
  createProjectFileSuccess,
  deleteProjectFileFail,
  deleteProjectFileSuccess,
  createProjectFileAlreadyUploadedFail,
} from "../constants/snackbars";

import API from "../lib/api";
import HTTP from "../lib/http";
import { renderSnackbar } from "./snackbars";

export const setProjectFile = createAction(SET_PROJECT_FILE);
export const setProjectFiles = createAction(SET_PROJECT_FILES);
export const removeProjectFile = createAction(REMOVE_PROJECT_FILE);
export const removeProjectFiles = createAction(REMOVE_PROJECT_FILES);
export const showProjectFilePreview = createAction(SHOW_PROJECT_FILE_PREVIEW);
export const hideProjectFilePreview = createAction(HIDE_PROJECT_FILE_PREVIEW);

export const CableActions = (message, connection, store) => {
  switch (message.event) {
    case PROJECT_FILE_CREATE_EVENT:
      store.dispatch(
        setProjectFile({
          id: message.payload.project_file.id,
          item: message.payload.project_file,
        })
      );
      break;

    case PROJECT_FILE_DELETED_EVENT:
      store.dispatch(
        removeProjectFile({
          id: message.payload.project_file.id,
          item: message.payload.project_file,
        })
      );
      break;
    default:
      break;
  }
};

export function createProjectFile(project_file, projectId) {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  return async (dispatch, getState) => {
    dispatch(showProjectFilePreview());

    await HTTP.post(
      API.projectFiles.createProjectFile(projectId),
      project_file,
      config
    )
      .then(function (response) {
        if (response.data === "already inserted") {
          dispatch(renderSnackbar(createProjectFileAlreadyUploadedFail));
        } else {
          dispatch(renderSnackbar(createProjectFileSuccess));
        }
      })
      .catch(function (error) {
        dispatch(renderSnackbar(createProjectFileFail));
      })
      .finally(function () {
        dispatch(hideProjectFilePreview());
      });
  };
}

export function deleteProjectFile(id) {
  return async (dispatch, getState) => {
    await HTTP.delete(API.projectFiles.deleteProjectFile(id))
      .then(function (response) {
        if (response.data === "deleted") {
          dispatch(renderSnackbar(deleteProjectFileSuccess));
        }
      })
      .catch(function (error) {
        dispatch(renderSnackbar(deleteProjectFileFail));
        console.log("DELETE MEDIA FILE ERROR", error);
      });
  };
}

export function getProjectFiles(id) {
  return async (dispatch, getState) => {
    await HTTP.get(API.projectFiles.getProjectFiles(id)).then(function (
      response
    ) {
      dispatch(setProjectFiles(response.data.project_files));
    });
  };
}
