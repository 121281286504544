/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { ListSubheader } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import * as styles from "../../../../../../styles/styles";
import ColoredCircle from "../../../../../../components/ColoredCircle";
import {
  COLOR_MAP,
  COLOR_MAP_NAMES,
} from "../../../../../../constants/colormapping";

const overrideStyles = (theme) => ({
  root: {
    backgroundColor: styles.darkBlueSuperLight,
    lineHeight: "60px",
    fontSize: "2rem",
  },
});

const propTypes = {
  color: PropTypes.string,
  classes: PropTypes.object,
  subHeaderName: PropTypes.string,
};

const CustomizedListSubHeader = ({ color, subHeaderName, classes }) => {
  return (
    <>
      <ListSubheader classes={{ root: classes.root }}>
        <div className="container">
          {COLOR_MAP[color] && (
            <ColoredCircle
              color={COLOR_MAP[color]}
              name={COLOR_MAP_NAMES[color]}
              size={"xm"}
            />
          )}
          <span className="sub-header-text">{subHeaderName}</span>
        </div>
      </ListSubheader>
    </>
  );
};

CustomizedListSubHeader.propTypes = propTypes;
export default withStyles(overrideStyles, { withTheme: true })(
  CustomizedListSubHeader
);
