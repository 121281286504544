import { fromJS, Map } from "immutable";
import {
  EDIT_MEASUREMENT_LINE,
  REMOVE_MEASUREMENT_LINE,
  REMOVE_MEASUREMENT_LINES,
  SET_MEASUREMENT_LINE,
  SET_MEASUREMENT_LINES,
} from "../constants/actions/actions";

const initialState = fromJS({
  loading: false,
  new: 0,
  items: fromJS({}),
});

export const prepareItems = (array) => {
  return Map(array.map((item) => [item.id.toString(), fromJS(item)]));
};

export function measurementLines(state = initialState, action) {
  switch (action.type) {
    case SET_MEASUREMENT_LINES:
      return state.mergeIn(["items"], prepareItems(action.payload));

    case SET_MEASUREMENT_LINE:
      return state.setIn(
        ["items", action.payload.id.toString()],
        fromJS(action.payload.item)
      );

    case EDIT_MEASUREMENT_LINE: {
      let { id, item } = action.payload;
      return state.getIn([`items`, id.toString()])
        ? state.mergeIn([`items`, id.toString()], fromJS(item))
        : state;
    }

    case REMOVE_MEASUREMENT_LINE:
      return state.deleteIn([`items`, action.payload.id.toString()]);

    case REMOVE_MEASUREMENT_LINES: {
      // I have no f*cking clue how to remove certain items. This
      // implementation feels bad!
      const projectIdInt = parseInt(action.payload.id);

      return state.set(
        "items",
        state
          .get("items")
          .filter((item) => item.get("project_id") !== projectIdInt)
      );
    }
    default:
      return state;
  }
}
