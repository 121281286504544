import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { resetSnackbar } from "../../actions/snackbars";
import CustomSnackbar from "../../components/CustomSnackbar";

import {
  // PROJECT
  assignProjectSuccess,
  assignProjectFail,
  createProjectFail,
  createProjectSuccess,
  createProjectFailOnlyOneProjectAllowed,
  createProjectFailJobOrderAlreadyUsed,
  deleteProjectsFail,
  resetProjectFail,
  resetProjectSuccess,
  downloadProjectsFail,
  updateProjectFail,
  updateProjectSuccess,
  deleteProjectSuccess,
  deleteProjectFail,

  // USER
  deleteAvatarFail,
  deleteAvatarSuccess,
  deleteInvitationFail,
  deleteInvitationSuccess,
  editUserDetailsFail,
  editUserDetailsSuccess,
  inviteUserFail,
  inviteUserSuccess,
  removeUserFail,
  removeUserSuccess,
  resendInvitationFail,
  resendInvitationSuccess,
  uploadAvatarFail,
  uploadAvatarSuccess,
  requestAccountDeletionSuccess,
  requestAccountDeletionFail,
  confirmAccountDeletionSuccess,
  confirmAccountDeletionFail,

  // MEASUREMENT
  updateMeasurementFail,
  updateMeasurementSuccess,

  // MEASUREMENT_LINE
  updateMeasurementLineFail,
  updateMeasurementLineSuccess,

  // MEASUREMENT_SEGMENT

  // VOLUME
  deleteVolumeFail,
  deleteVolumeSuccess,
  updateVolumeFail,
  updateVolumeSuccess,

  // TOPOGRAPHIC POINT
  updateTopographicPointSuccess,
  updateTopographicPointFail,

  // MEASURE_LAYER_CONFIG
  createMeasureLayerConfigSuccess,
  createMeasureLayerConfigFail,
  updateMeasureLayerConfigFail,
  updateMeasureLayerConfigSuccess,
  deleteMeasureLayerConfigFail,
  deleteMeasureLayerConfigSuccess,

  // MEDIA_FILE
  deleteMediaFileFail,
  deleteMediaFileSuccess,

  // PROJECT_FILE
  createProjectFileSuccess,
  createProjectFileFail,
  deleteProjectFileSuccess,
  deleteProjectFileFail,
  projectFileIsToLargeFail,
  projectFileExtensionNotSupportedFail,
  createProjectFileAlreadyUploadedFail,

  // MAP_LAYER
  createMapLayerSuccess,
  createMapLayerFail,
  updateMapLayerSuccess,
  updateMapLayerFail,
  deleteMapLayerSuccess,
  deleteMapLayerFail
} from "../../constants/snackbars";

const propTypes = {
  resetSnackbar: PropTypes.func,
  snackbarFlags: PropTypes.object,
};

const ShowSnackbar = ({ resetSnackbar, snackbarFlags }) => {
  const allTrueSnackbarFlags = Object.keys(snackbarFlags).filter(
    (key) => snackbarFlags[key]
  );
  const snackbarTexts = {
    // PROJECT
    [updateProjectFail]: "Projekt konnte nicht bearbeitet werden.",
    [updateProjectSuccess]: "Das Projekt wurde erfolgreich aktualisiert.",
    [assignProjectSuccess]:
      "Das Projekt wurde erfolg einem neuen Mitarbeiter zugewiesen.",
    [assignProjectFail]: "Projekt konnte nicht zugewiesen werden.",
    [deleteProjectsFail]: "Projekte konnten nicht gelöscht werden.",
    [downloadProjectsFail]: "Projekte konnten nicht heruntergeladen werden.",
    [createProjectFail]: "Projekt konnte nicht erstellt werden.",
    [createProjectSuccess]: "Projekt erfolgreich erstellt.",
    [createProjectFailOnlyOneProjectAllowed]:
      "Mit Ihrem kostenlosen Account dürfen Sie nur ein Projekt in der Liste haben.",
    [createProjectFailJobOrderAlreadyUsed]:
      "Die von Ihnen angegebene Auftragsnummer ist bereits vergeben.",
    [resetProjectFail]: "Das Projekt konnte nicht zurückgesetzt werden.",
    [resetProjectSuccess]: "Das Projekt wurde erfolgreich zurückgesetzt.",
    [deleteProjectSuccess]: "Das Projekt wurde erfolgreich gelöscht.",
    [deleteProjectFail]: "Das Projekt konnte nicht gelöscht werden.",

    // USER
    [inviteUserFail]: "Einladung konnte nicht verschickt werden.",
    [inviteUserSuccess]: "Die Einladung wurde verschickt.",
    [deleteInvitationFail]: "Einladung konnte nicht zurückgezogen werden.",
    [deleteInvitationSuccess]: "Die Einladung wurde erfolgreich zurückgezogen.",
    [resendInvitationFail]: "Einladung konnte nicht erneut gesendet werden.",
    [resendInvitationSuccess]: "Einladung erneut gesendet.",
    [removeUserFail]: "Nutzer konnte nicht gelöscht werden.",
    [removeUserSuccess]: "Der Nutzer wurde erfolgreich gelöscht.",
    [deleteAvatarFail]: "Ihr Anzeigebild konnte nicht gelöscht werden.",
    [deleteAvatarSuccess]: "Ihr Anzeigebild wurde gelöscht.",
    [uploadAvatarSuccess]: "Ihr neues Anzeigebild wurde hochgeladen.",
    [uploadAvatarFail]:
      "Ihr neues Anzeigebild konnte nicht hochgeladen werden.",
    [editUserDetailsSuccess]: "Ihre Daten wurden aktualisiert.",
    [editUserDetailsFail]: "Ihre Daten konnten nicht geändert werden.",
    [requestAccountDeletionSuccess]: "Anfrage ist bei uns eingegangen.",
    [requestAccountDeletionFail]: "Fehler",
    [confirmAccountDeletionSuccess]: "Bestätigung ist bei uns eingegangen.",
    [confirmAccountDeletionFail]: "Fehler",

    // MEASUREMENT
    [updateMeasurementFail]: "Der Messpunkt konnte nicht aktualisiert werden.",
    [updateMeasurementSuccess]: "Der Messpunkt wurde erfolgreich aktualisiert.",

    // MEASUREMENT_LINE
    [updateMeasurementLineFail]:
      "Die Messungslinie konnte nicht aktualisiert werden.",
    [updateMeasurementLineSuccess]:
      "Die Messungslinie wurde erfolgreich aktualisiert.",

    // MEASUREMENT_SEGMENT

    // VOLUME
    [deleteVolumeFail]: "Das Volumen konnte nicht gelöscht werden.",
    [deleteVolumeSuccess]: "Das Volumen wurde erfolgreich gelöscht.",
    [updateVolumeFail]: "Das Volumen konnte nicht aktualisiert werden.",
    [updateVolumeSuccess]: "Das Volumen wurde erfolgreich aktualisiert.",

    // TOPOGRAPHIC POINT
    [updateTopographicPointSuccess]:
      "Topographischer Punkt wurde aktualisiert.",
    [updateTopographicPointFail]:
      "Topographischer Punkt konnte nicht aktualisiert werden.",

    // MEASURE_LAYER_CONFIG
    [createMeasureLayerConfigSuccess]:
      "Der neue Layer wurde erfolgreich angelegt.",
    [createMeasureLayerConfigFail]: "Layer konnte nicht angelegt werden.",
    [updateMeasureLayerConfigFail]:
      "Der Layer konnte nicht aktualisiert werden.",
    [updateMeasureLayerConfigSuccess]:
      "Der Layer wurde erfolgreich aktualisiert.",
    [deleteMeasureLayerConfigFail]: "Der Layer konnte nicht gelöscht werden.",
    [deleteMeasureLayerConfigSuccess]: "Der Layer wurde erfolgreich gelöscht.",

    // MEDIA_FILE
    [deleteMediaFileFail]: "Die Mediendatei konnte nicht gelöscht werden.",
    [deleteMediaFileSuccess]: "Die Mediendatei wurde erfolgreich gelöscht.",

    // PROJECT_FILE
    [createProjectFileSuccess]: "Ihre Datei wurde erfolgreich hochgeladen",
    [createProjectFileFail]: "Ihre Datei konnte nicht hochgeladen werden",
    [deleteProjectFileSuccess]: "Ihre Datei wurde erfolgreich gelöscht",
    [deleteProjectFileFail]: "Ihre Datei konnte nicht gelöscht werden",
    [projectFileIsToLargeFail]: "Ihre ausgewählte Datei ist größer als 10 MB",
    [projectFileExtensionNotSupportedFail]:
      "Ihre ausgewählte Datei wird nicht unterstützt",
    [createProjectFileAlreadyUploadedFail]:
      "Sie haben diese Datei bereits hochgeladen",

    // MAP_LAYER
    [createMapLayerSuccess]: "Ihre Kartendatei wurde erfolgreich hochgeladen",
    [createMapLayerFail]: "Ihre Kartendatei konnte nicht hochgeladen werden",
    [updateMapLayerSuccess]: "Informationen erfolgreich aktualisiert",
    [updateMapLayerFail]: "Informationen konnten nicht aktualisiert werden",
    [deleteMapLayerSuccess]: "Ihre Kartendatei wurde erfolgreich gelöscht",
    [deleteMapLayerFail]: "Ihre Kartendatei konnte nicht gelöscht werden"
  };

  return allTrueSnackbarFlags.map((snackbarFlag, index) => {
    return (
      <CustomSnackbar
        key={index}
        variant={snackbarFlag.includes("Fail") ? "warning" : "success"}
        message={snackbarTexts[snackbarFlag]}
        open={snackbarFlags[snackbarFlag]}
        onClose={() => resetSnackbar(snackbarFlag)}
      />
    );
  });
};

function dispatchToProps(dispatch) {
  return bindActionCreators(
    {
      resetSnackbar,
    },
    dispatch
  );
}

function stateToProps(state) {
  const snackbars = state.get("snackbars");
  return {
    snackbarFlags: {
      // PROJECT
      [createProjectFail]: snackbars.get(createProjectFail),
      [createProjectSuccess]: snackbars.get(createProjectSuccess),
      [createProjectFailOnlyOneProjectAllowed]: snackbars.get(
        createProjectFailOnlyOneProjectAllowed
      ),
      [createProjectFailJobOrderAlreadyUsed]: snackbars.get(
        createProjectFailJobOrderAlreadyUsed
      ),
      [deleteProjectsFail]: snackbars.get(deleteProjectsFail),
      [downloadProjectsFail]: snackbars.get(downloadProjectsFail),
      [updateProjectFail]: snackbars.get(updateProjectFail),
      [updateProjectSuccess]: snackbars.get(updateProjectSuccess),
      [assignProjectSuccess]: snackbars.get(assignProjectSuccess),
      [assignProjectFail]: snackbars.get(assignProjectFail),
      [resetProjectFail]: snackbars.get(resetProjectFail),
      [resetProjectSuccess]: snackbars.get(resetProjectSuccess),
      [deleteProjectSuccess]: snackbars.get(deleteProjectSuccess),
      [deleteProjectFail]: snackbars.get(deleteProjectFail),

      // USER
      [inviteUserFail]: snackbars.get(inviteUserFail),
      [inviteUserSuccess]: snackbars.get(inviteUserSuccess),
      [deleteInvitationFail]: snackbars.get(deleteInvitationFail),
      [deleteInvitationSuccess]: snackbars.get(deleteInvitationSuccess),
      [resendInvitationFail]: snackbars.get(resendInvitationFail),
      [resendInvitationSuccess]: snackbars.get(resendInvitationSuccess),
      [removeUserFail]: snackbars.get(removeUserFail),
      [removeUserSuccess]: snackbars.get(removeUserSuccess),
      [deleteAvatarSuccess]: snackbars.get(deleteAvatarSuccess),
      [deleteAvatarFail]: snackbars.get(deleteAvatarFail),
      [uploadAvatarFail]: snackbars.get(uploadAvatarFail),
      [uploadAvatarSuccess]: snackbars.get(uploadAvatarSuccess),
      [editUserDetailsFail]: snackbars.get(editUserDetailsFail),
      [editUserDetailsSuccess]: snackbars.get(editUserDetailsSuccess),
      [requestAccountDeletionSuccess]: snackbars.get(
        requestAccountDeletionSuccess
      ),
      [requestAccountDeletionFail]: snackbars.get(requestAccountDeletionFail),
      [confirmAccountDeletionSuccess]: snackbars.get(
        confirmAccountDeletionSuccess
      ),
      [confirmAccountDeletionFail]: snackbars.get(confirmAccountDeletionFail),

      // MEASUREMENT
      [updateMeasurementFail]: snackbars.get(updateMeasurementFail),
      [updateMeasurementSuccess]: snackbars.get(updateMeasurementSuccess),

      // MEASUREMENT_LINE
      [updateMeasurementLineFail]: snackbars.get(updateMeasurementLineFail),
      [updateMeasurementLineSuccess]: snackbars.get(
        updateMeasurementLineSuccess
      ),

      // MEASUREMENT_SEGMENT

      // VOLUME
      [deleteVolumeFail]: snackbars.get(deleteVolumeFail),
      [deleteVolumeSuccess]: snackbars.get(deleteVolumeSuccess),
      [updateVolumeFail]: snackbars.get(updateVolumeFail),
      [updateVolumeSuccess]: snackbars.get(updateVolumeSuccess),

      // TOPOGRAPHIC POINT
      [updateTopographicPointSuccess]: snackbars.get(
        updateTopographicPointSuccess
      ),
      [updateTopographicPointFail]: snackbars.get(updateTopographicPointFail),

      // MEASURE_LAYER_CONFIG
      [createMeasureLayerConfigSuccess]: snackbars.get(
        createMeasureLayerConfigSuccess
      ),
      [createMeasureLayerConfigFail]: snackbars.get(
        createMeasureLayerConfigFail
      ),
      [updateMeasureLayerConfigFail]: snackbars.get(
        updateMeasureLayerConfigFail
      ),
      [updateMeasureLayerConfigSuccess]: snackbars.get(
        updateMeasureLayerConfigSuccess
      ),
      [deleteMeasureLayerConfigFail]: snackbars.get(
        deleteMeasureLayerConfigFail
      ),
      [deleteMeasureLayerConfigSuccess]: snackbars.get(
        deleteMeasureLayerConfigSuccess
      ),

      // MEDIA_FILE
      [deleteMediaFileFail]: snackbars.get(deleteMediaFileFail),
      [deleteMediaFileSuccess]: snackbars.get(deleteMediaFileSuccess),

      // PROJECT_FILE
      [createProjectFileSuccess]: snackbars.get(createProjectFileSuccess),
      [createProjectFileFail]: snackbars.get(createProjectFileFail),
      [deleteProjectFileSuccess]: snackbars.get(deleteProjectFileSuccess),
      [deleteProjectFileFail]: snackbars.get(deleteProjectFileFail),
      [projectFileIsToLargeFail]: snackbars.get(projectFileIsToLargeFail),
      [projectFileExtensionNotSupportedFail]: snackbars.get(
        projectFileExtensionNotSupportedFail
      ),
      [createProjectFileAlreadyUploadedFail]: snackbars.get(
        createProjectFileAlreadyUploadedFail
      ),

      // MAP_LAYER
      [createMapLayerSuccess]: snackbars.get(createMapLayerSuccess),
      [createMapLayerFail]: snackbars.get(createMapLayerFail),
      [updateMapLayerSuccess]: snackbars.get(updateMapLayerSuccess),
      [updateMapLayerFail]: snackbars.get(updateMapLayerFail),
      [deleteMapLayerSuccess]: snackbars.get(deleteMapLayerSuccess),
      [deleteMapLayerFail]: snackbars.get(deleteMapLayerFail)
    },
  };
}

ShowSnackbar.propTypes = propTypes;
export default connect(stateToProps, dispatchToProps)(ShowSnackbar);
