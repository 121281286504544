import React from "react";

import Wordmark from "../../../atoms/Wordmark";
import Spinner from "../../../atoms/Spinner";

import "./styles.css";

const netpipeWordmarkWidth = 22.7;
const netpipeWordmarkHeight = 6.1;

const Validate = () => {
  return (
    <div className="validate-container">
      <Wordmark width={netpipeWordmarkWidth} height={netpipeWordmarkHeight} />
      <Spinner />
    </div>
  );
};

export default Validate;
