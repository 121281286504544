import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import queryString from "qs";

import LandingPage from "../../../../components/LandingPage";
import EnterPassword from "./EnterPassword";
import InformationResetPassword from "./InformationResetPassword";

import { resetPassword, resetUserFlags } from "../../../../actions/user";

const propTypes = {
  resetPassword: PropTypes.func,
  resetUserFlags: PropTypes.func,
  user: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
};

const ResetPasswordDialog = ({ location, resetPassword, user, history }) => {
  const [state, setState] = useState({
    page: 1,
    password: "",
  });

  let urlParams = queryString.parse(location && location.search);
  const token = urlParams["?reset_token"];

  const resetUserPassword = () => {
    resetPassword(state.password, token);
    setState({ ...state, page: 2 });
  };

  const renderPage = () => {
    switch (state.page) {
      case 1:
        return (
          <EnterPassword
            history={history}
            password={state.password}
            onChangePassword={(password) => setState({ ...state, password })}
            resetPassword={resetUserPassword}
          />
        );
      case 2:
        return (
          <InformationResetPassword
            resetUserFlags={resetUserFlags}
            user={user.toJS()}
          />
        );
      default:
        break;
    }
  };

  return (
    <LandingPage headline="Passwort zurücksetzen">{renderPage()}</LandingPage>
  );
};

function dispatchToProps(dispatch) {
  return bindActionCreators(
    {
      resetPassword,
      resetUserFlags,
    },
    dispatch
  );
}

function stateToProps(state) {
  const user = state.get("user");
  return {
    user,
  };
}

ResetPasswordDialog.propTypes = propTypes;
export default connect(stateToProps, dispatchToProps)(ResetPasswordDialog);
