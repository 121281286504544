/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { isAdmin } from "../../../sharedFunctions/user";

import {
  setMeasureLayerConfigTemplateSearchTerm,
  setActiveMeasureLayerConfigTemplateUuid,
  syncMeasureLayerConfigTemplates,
  createMeasureLayerConfigTemplate,
  updateMeasureLayerConfigTemplate,
  deleteMeasureLayerConfigTemplate,
} from "../../../actions/measureLayerConfigTemplates";

import { syncComponentCatalogs } from "../../../actions/componentCatalogs";

import { idle } from "../../../constants/status";
import {
  EDIT_KEY,
  DELETE_KEY,
  CREATE_KEY,
} from "../../../constants/contextMenuEntries";

import MeasureLayerConfigTemplatesHeader from "./MeasureLayerConfigTemplatesHeader";
import MeasureLayerConfigTemplateCard from "./MeasureLayerConfigTemplateCard";
import Spinner from "../../../atoms/Spinner";
import MeasureLayerConfigTemplateDialog from "../../../containers/dialogs/MeasureLayerConfigTemplateDialog";
import Confirmation from "../../../components/dialogs/Confirmation";

const propTypes = {
  data: PropTypes.object,
  searchTerm: PropTypes.string,
  filteredBySearchTerm: PropTypes.array,
  measureLayerConfigOrganizationTemplateUuids: PropTypes.any,
  isAdmin: PropTypes.bool,
  history: PropTypes.object,
  syncStatus: PropTypes.string,
  syncStatusComponentCatalogs: PropTypes.string,
  setMeasureLayerConfigTemplateSearchTerm: PropTypes.func,
  setActiveMeasureLayerConfigTemplateUuid: PropTypes.func,
  syncMeasureLayerConfigTemplates: PropTypes.func,
  createMeasureLayerConfigTemplate: PropTypes.func,
  updateMeasureLayerConfigTemplate: PropTypes.func,
  deleteMeasureLayerConfigTemplate: PropTypes.func,
  syncComponentCatalogs: PropTypes.func,
};

const MeasureLayerConfigTemplates = ({
  data,
  searchTerm,
  filteredBySearchTerm,
  measureLayerConfigOrganizationTemplateUuids,
  isAdmin,
  history,
  syncStatus,
  syncStatusComponentCatalogs,
  setMeasureLayerConfigTemplateSearchTerm,
  setActiveMeasureLayerConfigTemplateUuid,
  syncMeasureLayerConfigTemplates,
  createMeasureLayerConfigTemplate,
  updateMeasureLayerConfigTemplate,
  deleteMeasureLayerConfigTemplate,
  syncComponentCatalogs,
}) => {
  const defaultStateShowModal = React.useMemo(() => {
    return {
      mode: "",
      modalOpen: false,
      data: {},
    };
  });

  const [stateShowModal, setStateShowModal] = React.useState(
    defaultStateShowModal
  );

  React.useEffect(() => {
    if (syncStatus === idle) {
      syncMeasureLayerConfigTemplates();
    }
    if (syncStatusComponentCatalogs === idle) {
      syncComponentCatalogs();
    }
  }, [syncStatus, syncStatusComponentCatalogs]);

  /**
   * The content of the payload depends on the mode.
   * See onCreateMeasureLayerConfigTemplateClicked, onEditMeasureLayerConfigTemplateClicked and
   * onDeleteMeasureLayerConfigTemplateClicked.
   *
   * @param {object} payload
   */
  const openModal = (payload) => {
    setStateShowModal(payload);
  };

  const closeModal = () => {
    setStateShowModal({ mode: "", modalOpen: false, data: {} });
  };

  const onCreateMeasureLayerConfigTemplateClicked = () => {
    openModal({ mode: CREATE_KEY, modalOpen: true, data: {} });
  };

  const onEditMeasureLayerConfigTemplateClicked = (
    measureLayerConfigOrganizationTemplates,
    item
  ) => {
    openModal({
      mode: EDIT_KEY,
      modalOpen: true,
      data: {
        measureLayerConfigOrganizationTemplates:
          measureLayerConfigOrganizationTemplates,
        item: item,
      },
    });
  };

  const onDeleteMeasureLayerConfigTemplateClicked = (
    measureLayerConfigOrganizationTemplates,
    item
  ) => {
    openModal({
      mode: DELETE_KEY,
      modalOpen: true,
      data: {
        measureLayerConfigOrganizationTemplates:
          measureLayerConfigOrganizationTemplates,
        item: item,
      },
    });
  };

  /**
   * For information about the payload see the
   * MeasureLayerConfigTemplateDialog Component
   *
   * @param {object} payload
   */
  const onModalCreateMeasureLayerConfigTemplateOkClicked = (payload) => {
    openModal(defaultStateShowModal);
    createMeasureLayerConfigTemplate(payload.data);
  };

  /**
   * For information about the payload see the
   * MeasureLayerConfigTemplateDialog Component
   *
   * @param {object} payload
   */
  const onModalUpdateMeasureLayerConfigTemplateOkClicked = (payload) => {
    openModal(defaultStateShowModal);
    updateMeasureLayerConfigTemplate(payload.uuid, payload.data);
  };

  /**
   * For information about the payload see the
   * MeasureLayerConfigTemplateDialog Component
   *
   * @param {object} payload
   */
  const onModalDeleteMeasureLayerConfigTemplateOkClicked = (payload) => {
    openModal(defaultStateShowModal);
    deleteMeasureLayerConfigTemplate(payload.data.item.uuid);
  };

  const renderMeasureLayeConfigTemplateCards = (dataItems) => {
    const catalogCategoryGridElements = [];

    dataItems.forEach((dataItem) => {
      const measureLayerConfigTemplate = dataItem.item;

      const measureLayerConfigItems = data.measureLayerConfigItems.filter(
        (measureLayerConfigItem) => {
          return (
            measureLayerConfigItem.rootUuid === measureLayerConfigTemplate.uuid
          );
        }
      );
      const measureLayerConfigOrganizationTemplates =
        data.measureLayerConfigOrganizationTemplates.filter(
          (measureLayerConfigIrganizationTemplate) => {
            return (
              measureLayerConfigIrganizationTemplate.item.uuid ===
              measureLayerConfigTemplate.rootUuid
            );
          }
        );

      catalogCategoryGridElements.push(
        <MeasureLayerConfigTemplateCard
          key={measureLayerConfigTemplate.uuid}
          history={history}
          item={measureLayerConfigTemplate}
          measureLayerConfigOrganizationTemplates={
            measureLayerConfigOrganizationTemplates
          }
          measureLayerConfigItems={measureLayerConfigItems}
          onEditMeasureLayerConfigTemplateClicked={
            onEditMeasureLayerConfigTemplateClicked
          }
          onDeleteMeasureLayerConfigTemplateClicked={
            onDeleteMeasureLayerConfigTemplateClicked
          }
          setActiveMeasureLayerConfigTemplateUuid={
            setActiveMeasureLayerConfigTemplateUuid
          }
          isAdmin={isAdmin}
        />
      );
    });

    return catalogCategoryGridElements;
  };

  const renderMeasureLayerConfigTemplates = () => {
    const filteredMeasureLayerConfigTemplates =
      data.measureLayerConfigTemplates.filter((item) => {
        return (
          measureLayerConfigOrganizationTemplateUuids.includes(item.rootUuid) &&
          filteredBySearchTerm.includes(item.item.uuid)
        );
      });

    return (
      <div>
        <Typography
          variant="h1"
          sx={{ marginTop: "5rem", marginBottom: "2.5rem" }}
        >
          Projekt-Vorlagen
        </Typography>
        <Grid container columns={{ xs: 2, sm: 3, md: 8, lg: 12 }} spacing={4}>
          {renderMeasureLayeConfigTemplateCards(
            filteredMeasureLayerConfigTemplates
          )}
        </Grid>
      </div>
    );
  };

  return (
    <div className="project-bottom-container">
      {stateShowModal.mode !== "" &&
        renderModalContent(
          stateShowModal,
          closeModal,
          onModalCreateMeasureLayerConfigTemplateOkClicked,
          onModalUpdateMeasureLayerConfigTemplateOkClicked,
          onModalDeleteMeasureLayerConfigTemplateOkClicked
        )}
      <MeasureLayerConfigTemplatesHeader
        searchTerm={searchTerm}
        setMeasureLayerConfigTemplateSearchTerm={
          setMeasureLayerConfigTemplateSearchTerm
        }
        onCreateMeasureLayerConfigTemplateClicked={
          onCreateMeasureLayerConfigTemplateClicked
        }
        isAdmin={isAdmin}
      />
      <div className="component-category-cards-container">
        {syncStatus != "success" ? (
          <div className="spinner-container">
            <Spinner />
          </div>
        ) : (
          <div>{renderMeasureLayerConfigTemplates()}</div>
        )}
      </div>
    </div>
  );
};

function renderModalContent(
  payload,
  closeModal,
  onModalCreateMeasureLayerConfigTemplateOkClicked,
  onModalUpdateMeasureLayerConfigTemplateOkClicked,
  onModalDeleteMeasureLayerConfigTemplateOkClicked
) {
  switch (payload.mode) {
    case CREATE_KEY:
      return (
        <MeasureLayerConfigTemplateDialog
          modalOpen={payload.modalOpen}
          onClose={() => closeModal()}
          onClick={onModalCreateMeasureLayerConfigTemplateOkClicked}
        />
      );
    case EDIT_KEY:
      return (
        <MeasureLayerConfigTemplateDialog
          edit
          data={payload.data.item}
          modalOpen={payload.modalOpen}
          onClose={() => closeModal()}
          onClick={onModalUpdateMeasureLayerConfigTemplateOkClicked}
        />
      );
    case DELETE_KEY:
      return (
        <Confirmation
          highlightedText={payload.data.item.name}
          headline="Template löschen"
          leftSideText="Möchten Sie das ausgewählte Template"
          modalOpen={payload.modalOpen}
          onClose={() => closeModal()}
          onClick={() =>
            onModalDeleteMeasureLayerConfigTemplateOkClicked(payload)
          }
          rightSideText={`wirklich löschen?`}
          textButton="Löschen"
        />
      );
  }
}

function dispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setMeasureLayerConfigTemplateSearchTerm,
      setActiveMeasureLayerConfigTemplateUuid,
      syncMeasureLayerConfigTemplates,
      createMeasureLayerConfigTemplate,
      updateMeasureLayerConfigTemplate,
      deleteMeasureLayerConfigTemplate,
      syncComponentCatalogs,
    },
    dispatch
  );
}

function stateToProps(state) {
  const syncStatusComponentCatalogs = state.getIn([
    "componentCatalogs",
    "componentCatalogSyncStatus",
  ]);

  const syncStatus = state.getIn([
    "measureLayerConfigTemplates",
    "measureLayerConfigTemplateSyncStatus",
  ]);

  const searchTerm = state.getIn(["measureLayerConfigTemplates", "searchTerm"]);

  const data = state.getIn(["measureLayerConfigTemplates", "items"]).toJS();

  const filteredBySearchTerm = data.measureLayerConfigTemplates
    .filter((item) => {
      const cond1 = String(item.item.name)
        .toLowerCase()
        .includes(String(searchTerm).toLowerCase());
      const cond2 = String(item.item.description)
        .toLowerCase()
        .includes(String(searchTerm).toLowerCase());
      return cond1 || cond2;
    })
    .map((item) => item.item.uuid);

  const measureLayerConfigOrganizationTemplateUuids =
    data.measureLayerConfigOrganizationTemplates.map((item) => item.item.uuid);

  return {
    data: data,
    measureLayerConfigOrganizationTemplateUuids,
    searchTerm: searchTerm,
    filteredBySearchTerm:
      filteredBySearchTerm.length === undefined ||
      filteredBySearchTerm.length === 0
        ? []
        : filteredBySearchTerm,
    syncStatusComponentCatalogs,
    syncStatus: syncStatus,
    isAdmin: isAdmin(state.getIn(["user", "organization_role_groups"]), true),
  };
}

MeasureLayerConfigTemplates.propTypes = propTypes;
export default connect(
  stateToProps,
  dispatchToProps
)(MeasureLayerConfigTemplates);
