import React from "react";

import Typography from "@mui/material/Typography";

import Column from "../../layout/Column";

import * as styles from "../../../styles/styles";

const UserProfileDocuments = () => {
  return (
    <>
      <Column side="left">
        <Typography
          color="primary"
          style={{ margin: `0 0 ${styles.spacing24} 0` }}
          variant="h2"
        >
          Dokumente
        </Typography>
      </Column>
      <Column side="right">
        <div style={{ margin: `6rem 0 0 0` }}>
          <a
            href="https://www.netpipe.io/rechtliches/agb"
            target="_BLANK"
            rel="noopener noreferrer"
          >
            <Typography color="textPrimary" variant="body1">
              Allgemeine Geschäftsbedingungen
            </Typography>
          </a>
          <br />
          <a
            href="https://www.netpipe.io/rechtliches/datenschutzerklarung"
            target="_BLANK"
            rel="noopener noreferrer"
          >
            <Typography color="textPrimary" variant="body1">
              Datenschutzbestimmungen
            </Typography>
          </a>
          <br />
          <a
            href={process.env.PUBLIC_URL + "/Auftragsverarbeitung.pdf"}
            download
            target="_BLANK"
            rel="noopener noreferrer"
          >
            <Typography color="textPrimary" variant="body1">
              Auftragsdatenverarbeitung
            </Typography>
          </a>
        </div>
      </Column>
    </>
  );
};

export default UserProfileDocuments;
