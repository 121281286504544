import { createAction } from "redux-actions";

import {
  HIDE_DUMMY_PROJECT,
  SHOW_DUMMY_PROJECT,
  SORT_PROJECTS,
  SORT_PROJECTS_REVERSE,
} from "../constants/actions/actions";

export const sortProjects = createAction(SORT_PROJECTS);
export const sortProjectsReverse = createAction(SORT_PROJECTS_REVERSE);
export const showDummyProject = createAction(SHOW_DUMMY_PROJECT);
export const hideDummyProject = createAction(HIDE_DUMMY_PROJECT);
