export default class Entity  {
    constructor() {
        this.components = {}
    }

    addComponent(component) {
        this.components[component.getComponentType()] = component;
    }

    removeComponent(componentType) {
        delete this.components[componentType];
    }

    removeComponents() {
        this.components = {};
    }
    
    getComponents() {
        return this.components;
    }
}
