import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { appSettingsOrderingCategoryTitle } from "../../../../constants/userSettings";

import * as styles from "../../../../styles/styles";

const propTypes = {
  property: PropTypes.string,
};

const TitleUiElement = ({ property }) => {
  return (
    <Typography
      key={`category-title-${property}`}
      variant="h6"
      style={{ margin: `${styles.spacing32} 0 0 0` }}
    >
      {appSettingsOrderingCategoryTitle[property]}
    </Typography>
  );
};

TitleUiElement.propTypes = propTypes;
export default TitleUiElement;
