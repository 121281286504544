/* eslint-disable import/namespace */
/* eslint-disable no-unused-vars */
import { createAction } from "redux-actions";

import {
  SET_MEASUREMENT_SEGMENT,
  SET_MEASUREMENT_SEGMENTS,
  EDIT_MEASUREMENT_SEGMENT,
  REMOVE_MEASUREMENT_SEGMENT,
  REMOVE_MEASUREMENT_SEGMENTS,
} from "../constants/actions/actions";

import {
  MEASUREMENT_SEGMENTS_CREATE_EVENT,
  MEASUREMENT_SEGMENT_UPDATE_EVENT,
} from "../constants/channelevents";

import { updateMeasurementSegmentFail } from "../constants/snackbars";

import API from "../lib/api";
import HTTP from "../lib/http";
import { renderSnackbar } from "./snackbars";

export const setMeasurementSegments = createAction(SET_MEASUREMENT_SEGMENTS);
export const setMeasurementSegment = createAction(SET_MEASUREMENT_SEGMENT);
export const editMeasurementSegment = createAction(EDIT_MEASUREMENT_SEGMENT);
export const removeMeasurementSegment = createAction(
  REMOVE_MEASUREMENT_SEGMENT
);
export const removeMeasurementSegments = createAction(
  REMOVE_MEASUREMENT_SEGMENTS
);

export const CableActions = (message, connection, store) => {
  switch (message.event) {
    case MEASUREMENT_SEGMENTS_CREATE_EVENT:
      store.dispatch(
        setMeasurementSegments(message.payload.measurement_segments)
      );
      break;
    case MEASUREMENT_SEGMENT_UPDATE_EVENT:
      store.dispatch(
        editMeasurementSegment({
          id: message.payload.measurement_segment.id,
          item: message.payload.measurement_segment,
        })
      );
      break;
    default:
      break;
  }
};

export function updateMeasurementSegment(measurement_segment) {
  return async (dispatch, getState) => {
    try {
      await HTTP.put(
        API.measurementSegments.updateMeasurementSegment(
          measurement_segment.id
        ),
        { measurement_segment }
      );
    } catch (error) {
      dispatch(renderSnackbar(updateMeasurementSegmentFail));
      console.log("UPDATE MEASUREMENT SEGMENT ERROR", error);
    }
  };
}
