export const TYPE_BOOLEAN = "Boolean";
export const TYPE_INTEGER = "Integer";
export const TYPE_DOUBLE = "Double";
export const TYPE_STRING = "String";
export const TYPE_STRING_SET = "StringSet";

export const ORDERING_TYPE_INTRO_TOUR = "IntroTour";
export const ORDERING_TYPE_HINT_DIALOGS = "HintDialogs";
export const ORDERING_TYPE_EXPORT = "Export";
export const ORDERING_TYPE_ADVANCED_SETTINGS = "AdvancedSettings";
export const ORDERING_TYPE_GNSS = "GNSS";
export const ORDERING_TYPE_TRANSFORMATION = "Transformation";
export const ORDERUNG_SYNC = "Sync";

export const INTRO_TOUR_PROJECT_LIST = "ProjectList";
export const INTRO_TOUR_PROJECT = "Project";
export const INTRO_TOUR_PROJECT_DETAILS = "ProjectDetails";
export const INTRO_TOUR_CAMERA_AR_MEASUREMENT = "CameraArMeasurement";
export const INTRO_TOUR_VOLUME = "Volume";
export const INTRO_TOUR_MAP = "Map";
export const INTRO_TOUR_DRAWING = "Drawing";
export const INTRO_TOUR_CONFLICT = "Conflict";
export const INTRO_TOUR_GNSS = "GNSS";

export const HINT_DIALOGS_MAP_AND_DRAWING = "HintMapAndDrawing";
export const HINT_DIALOGS_CAMERA_AR_MEASUREMENT = "HintCameraArMeasurement";
export const HINT_DIALOGS_PROJECT_LIST = "HintProjectList";
export const HINT_DIALOGS_PROJECT_DETAILS = "HintProjectDetails";

export const ADVANCED_SETTINGS_CONFLICT = "AdvancedSettingsConflict";
export const ADVANCED_SETTINGS_CAMERA_AR_MEASUREMENT_LOGGING = "AdvancedSettingsCameraArMeasuementLogging";
export const ADVANCED_SETTINGS_CAMERA_AR_MEASUREMENT = "AdvancedSettingsCameraArMeasurement";

export const SYNC_SYNC = "SyncSync";

export const appSettingsOrdering = {
  intro_tour_project_list_activity: ORDERING_TYPE_INTRO_TOUR,
  intro_tour_ar_activity: ORDERING_TYPE_INTRO_TOUR,
  intro_tour_ar_point_activity_one: ORDERING_TYPE_INTRO_TOUR,
  intro_tour_drawing_fragment: ORDERING_TYPE_INTRO_TOUR,
  intro_tour_maps_fragment: ORDERING_TYPE_INTRO_TOUR,
  intro_tour_project_comparison_fragment: ORDERING_TYPE_INTRO_TOUR,
  intro_tour_result_fragment_finished_project: ORDERING_TYPE_INTRO_TOUR,
  intro_tour_result_fragment_open_project: ORDERING_TYPE_INTRO_TOUR,
  intro_tour_create_update_project_two: ORDERING_TYPE_INTRO_TOUR,
  intro_tour_gnss_map_view: ORDERING_TYPE_INTRO_TOUR,

  vis_explain_icon_dialog: ORDERING_TYPE_HINT_DIALOGS,
  map_hint: ORDERING_TYPE_HINT_DIALOGS,
  result_hint: ORDERING_TYPE_HINT_DIALOGS,
  depth_api_not_supported_hint: ORDERING_TYPE_HINT_DIALOGS,
  connectivity_hint: ORDERING_TYPE_HINT_DIALOGS,
  start_line_measurement_without_custom_maplayer_hint:
    ORDERING_TYPE_HINT_DIALOGS,
  missing_custom_map_folder_hint: ORDERING_TYPE_HINT_DIALOGS,

  project_conflict_handling: ORDERING_TYPE_ADVANCED_SETTINGS,
  measure_position_monitoring: ORDERING_TYPE_ADVANCED_SETTINGS,
  measure_position_data_logging: ORDERING_TYPE_ADVANCED_SETTINGS,
  measure_position_monitoring_and_data_logging_frequency:
    ORDERING_TYPE_ADVANCED_SETTINGS,
  measure_position_monitoring_and_data_logging_segment_distance_threshold:
    ORDERING_TYPE_ADVANCED_SETTINGS,
  measure_position_monitoring_and_data_logging_point_position_distance_threshold:
    ORDERING_TYPE_ADVANCED_SETTINGS,
  ar_camera_update_rate: ORDERING_TYPE_ADVANCED_SETTINGS,

  //gnss_interval_timer: "GNSS",
  //pref_gnss_device_name: "GNSS",
  //gnss_min_quality: "GNSS",
  //ntrip_caster: "GNSS",
  //ntrip_port: "GNSS",
  //ntrip_username: "GNSS",
  //ntrip_mount_point: "GNSS",
  //gnss_antenna_height: "GNSS",

  pref_gnss_use_height_component: ORDERING_TYPE_TRANSFORMATION,
  geo_export_crs: ORDERING_TYPE_TRANSFORMATION,

  pref_export_files: ORDERING_TYPE_EXPORT,
  pref_export_pdf_pages: ORDERING_TYPE_EXPORT,

  
};


export const introTourGrouping = {
  intro_tour_project_list_activity: INTRO_TOUR_PROJECT_LIST,
  intro_tour_create_update_project_two: INTRO_TOUR_PROJECT,
  intro_tour_project_comparison_fragment: INTRO_TOUR_CONFLICT,
  intro_tour_result_fragment_open_project: INTRO_TOUR_PROJECT_DETAILS,
  intro_tour_result_fragment_finished_project: INTRO_TOUR_PROJECT_DETAILS,
  intro_tour_ar_activity: INTRO_TOUR_CAMERA_AR_MEASUREMENT,
  intro_tour_ar_point_activity_one: INTRO_TOUR_VOLUME,
  intro_tour_gnss_map_view: INTRO_TOUR_GNSS,
  intro_tour_drawing_fragment: INTRO_TOUR_DRAWING,
  intro_tour_maps_fragment: INTRO_TOUR_MAP,
}

export const hintDialogsGrouping = {
  vis_explain_icon_dialog: HINT_DIALOGS_MAP_AND_DRAWING,
  missing_custom_map_folder_hint: HINT_DIALOGS_MAP_AND_DRAWING,
  map_hint: HINT_DIALOGS_CAMERA_AR_MEASUREMENT,
  result_hint: HINT_DIALOGS_CAMERA_AR_MEASUREMENT,
  depth_api_not_supported_hint: HINT_DIALOGS_CAMERA_AR_MEASUREMENT,
  connectivity_hint: HINT_DIALOGS_PROJECT_LIST,
  start_line_measurement_without_custom_maplayer_hint:
  HINT_DIALOGS_PROJECT_DETAILS,
}

export const advancedSettingsGrouping = {
  project_conflict_handling: ADVANCED_SETTINGS_CONFLICT,
  measure_position_monitoring: ADVANCED_SETTINGS_CAMERA_AR_MEASUREMENT_LOGGING,
  measure_position_data_logging: ADVANCED_SETTINGS_CAMERA_AR_MEASUREMENT_LOGGING,
  measure_position_monitoring_and_data_logging_frequency:
    ADVANCED_SETTINGS_CAMERA_AR_MEASUREMENT_LOGGING,
  measure_position_monitoring_and_data_logging_segment_distance_threshold:
    ADVANCED_SETTINGS_CAMERA_AR_MEASUREMENT_LOGGING,
  measure_position_monitoring_and_data_logging_point_position_distance_threshold:
    ADVANCED_SETTINGS_CAMERA_AR_MEASUREMENT_LOGGING,
  ar_camera_update_rate: ADVANCED_SETTINGS_CAMERA_AR_MEASUREMENT,
}



export const appSettingsGroupingTitle = {
  [INTRO_TOUR_PROJECT_LIST]: "Projektliste",
  [INTRO_TOUR_PROJECT]: "Projekt erstellen/editieren",
  [INTRO_TOUR_CONFLICT]: "Konflikthandling",
  [INTRO_TOUR_PROJECT_DETAILS]: "Projekt Detailansicht",
  [INTRO_TOUR_CAMERA_AR_MEASUREMENT]: "Kamera (AR) Aufnahmeansicht",
  [INTRO_TOUR_VOLUME]: "Volumenansicht",
  [INTRO_TOUR_GNSS]: "Multi GNSS-Ansicht",
  [INTRO_TOUR_DRAWING]: "Zeichenansicht",
  [INTRO_TOUR_MAP]: "Kartenansicht",
  [HINT_DIALOGS_MAP_AND_DRAWING]: "Karten- und Zeichenansicht",
  [HINT_DIALOGS_CAMERA_AR_MEASUREMENT]: "Kamera (AR) Aufnahmeansicht",
  [HINT_DIALOGS_PROJECT_LIST]: "Projektliste",
  [HINT_DIALOGS_PROJECT_DETAILS]: "Projekt Detailansicht",
  [ADVANCED_SETTINGS_CONFLICT]: "Projekt Konflikthandling",
  [ADVANCED_SETTINGS_CAMERA_AR_MEASUREMENT_LOGGING]: "Kamera (AR) Aufnahmeansicht - Datenlogging und Monitoring",
  [ADVANCED_SETTINGS_CAMERA_AR_MEASUREMENT]: "Kamera (AR) Aufnahmeansicht - Allgemein"
}

export const appSettingsGrouping = {
  [ORDERING_TYPE_INTRO_TOUR]: introTourGrouping,
  [ORDERING_TYPE_HINT_DIALOGS]: hintDialogsGrouping,
  [ORDERING_TYPE_ADVANCED_SETTINGS]: advancedSettingsGrouping
}

export const appSettingsOrderingTitle = {
  intro_tour_project_list_activity: "IntroTour durch die Projektlistenansicht",
  intro_tour_ar_activity: "Tour durch die Aufnahmeansicht",
  intro_tour_ar_point_activity_one: `Tour durch den Aufnahmeprozess der Volumenberechnung`,
  intro_tour_drawing_fragment: "Tour durch die Zeichenansicht",
  intro_tour_maps_fragment: "Tour durch die Kartenansicht",
  intro_tour_project_comparison_fragment: `Tour durch die Ansicht für das Konflikthandling von Projekten`,
  intro_tour_result_fragment_finished_project: `Tour durch die Detailansicht eines abgeschlossenen Projekts`,
  intro_tour_result_fragment_open_project: `Tour durch die Detailansicht eines offenen Projekts`,
  intro_tour_create_update_project_two: `Tour durch die zweite Seite der Erstellungs-/Änderungsansicht für ein Projekt`,
  intro_tour_gnss_map_view: "Tour durch die Multi-GNSS-View",

  vis_explain_icon_dialog: "Tooltip für Screenshotfunktion",
  map_hint: "Hinweisfenster für Aufruf der Kartenansicht",
  result_hint: "Hinweisfenster für Aufruf der Ergebnisansicht",
  depth_api_not_supported_hint: "Hinweisfenster für Depth-API Support",
  connectivity_hint: "Hinweisfenster bei mobiler Internetverbindung",
  start_line_measurement_without_custom_maplayer_hint:
    "Hinweisfenster für Leitungsaufnahme ohne eigenem Kartenmaterial",
  missing_custom_map_folder_hint: "Hinweis zum eigenen Kartenordner",

  project_conflict_handling: "Konflikthandling Projekte",
  measure_position_monitoring: "Monitoring",
  measure_position_data_logging: "Datenlogging",
  measure_position_monitoring_and_data_logging_frequency:
    "Überprüfungsintervall in Millisekunden",
  measure_position_monitoring_and_data_logging_segment_distance_threshold:
    "Grenzwert für Distanzabweichung bei Segmenten in Metern",
  measure_position_monitoring_and_data_logging_point_position_distance_threshold:
    "Grenzwert für Distanzabweichung von Messpunkten in Metern",
  ar_camera_update_rate: "Kamera Updaterate",

  //gnss_interval_timer: "Intervall für GNSS-Punktmessung",
  //pref_gnss_device_name: "GNSS-Empfänger",
  //gnss_min_quality: "Welche GNSS Quality soll verwendet werden",
  //ntrip_caster: "NTRIP-Caster",
  //ntrip_port: "NTRIP-Caster Port",
  //ntrip_username: "Benutzername",
  //ntrip_mount_point: "NTRIP-Caster Mountpoint",
  //gnss_antenna_height: "Gnss Antennenhöhe",

  pref_gnss_use_height_component: "Höhe verwenden",
  geo_export_crs: "Transformation",

  pref_export_files: "Export-Formate",
  pref_export_pdf_pages: "PDF Report",
};

export const appSettingsOrderingDescription = {
  intro_tour_project_list_activity: "",
  intro_tour_ar_activity: "",
  intro_tour_ar_point_activity_one: "",
  intro_tour_drawing_fragment: "",
  intro_tour_maps_fragment: "",
  intro_tour_project_comparison_fragment: "",
  intro_tour_result_fragment_finished_project: "",
  intro_tour_result_fragment_open_project: "",
  intro_tour_create_update_project_two: "",
  intro_tour_gnss_map_view: "",
  vis_explain_icon_dialog: "",

  map_hint: `Auf diesem Dialog werden einige
  Verhaltensregeln erläutert, die zu beachten sind, wenn man die Kartenansicht während einer
  Aufnahme geöffnet hat.`,
  result_hint: `Auf diesem Dialog werden einige
  Verhaltensregeln erläutert, die zu beachten sind, wenn man die Ergebnisansicht während einer
  Aufnahme geöffnet hat.`,
  depth_api_not_supported_hint: `Dies ist ein Informationsfenster das
  angezeigt wird wenn Ihr Smartphone die Depth-API nicht unterstützt.`,
  connectivity_hint: `Wenn eine mobile Internetverbindung
  besteht, wird vor jeder Synchronisierung eine Abfrage durchgeführt. Diese kann mit OK bestätigt werden.
  Wird der Dialog nicht bestätigt, wird auch keine Synchronisierung durchgeführt.`,
  start_line_measurement_without_custom_maplayer_hint: `Eine Leitungsaufnahme ohne Zuweisung von eigenem Kartenmaterial erlaubt keine genaue Georeferenzierung.
  Auf diesen Umstand weist dieser Dialog hin.`,
  missing_custom_map_folder_hint: `Dieser Dialog wird eingeblendet,
  wenn Netpipe keine Zugriffsberechtigungen zu einem selbst festgelegten Kartenordner erhalten hat.
  Die Nutzung eines eigenen Kartenordners ist nicht zwingend notwendig, wird aber empfohlen.
  Hilfe zu diesem Thema finden Sie auf der Projektlistenansicht unter Kontextmenü -> Hilfe -> Kartenintegration.`,

  project_conflict_handling: `Änderungen die über die
  Datenplattform in die MobilApp eingespielt werden, führen zu keiner automatischen Aktualisierung.
  Der Anwender kann die Änderungen akzeptieren oder den aktuellen Stand in der MobilApp auf die
  Datenplattform übertragen.`,
  measure_position_monitoring: `Wenn beim Monitoring Auffälligkeiten
  festgestellt werden, wird ein Warnfenster eingeblendet.`,
  measure_position_data_logging: `Während einer Aufnahme werden
  in einem regelmäßigen Zeitabstand von allen gesetzten Messpunkten die aktuellen Positionsdaten
  abgerufen und mit der vorherigen Position abgeglichen. Weiterhin werden die Distanzen der Segmente
  überwacht. Am Ende einer Messung wird im Export-Ordner des Projekts eine Protokolldatei abgelegt`,
  measure_position_monitoring_and_data_logging_frequency:
    "Legen Sie hier fest, in welchem Intervall das Datenmonitoring die Überprüfung durchführen soll.",
  measure_position_monitoring_and_data_logging_segment_distance_threshold: `Die Abweichung bezieht sich auf eine Distanzabweichung von der aktuellen zur vorherigen Überprüfung.`,
  measure_position_monitoring_and_data_logging_point_position_distance_threshold: `Die Abweichung bezieht sich auf eine Distanzabweichung von der aktuellen zur vorherigen Überprüfung.`,
  ar_camera_update_rate:
    "Mögliche Werte sind 30_fps und 60_fps. Schlussendlich liegt es an dem verwendeten Smartphone, welche Updaterate tatsächlich während einer Aufnahme verwendet wird.",

  //gnss_interval_timer: "", // delete
  //pref_gnss_device_name: `Wenn Sie sich bereits in der Vergangenheit
  //mit einem externen GNSS-Empfänger verbunden haben sehen Sie hier den Namen des
  //Empfängers.`,
  //gnss_min_quality: "", // delete
  //ntrip_caster: "", // delete
  //ntrip_port: "", // delete
  //ntrip_username: "", // delete
  //ntrip_mount_point: "", // delete
  //gnss_antenna_height: `Auf welcher Höhe befindet sich Ihre Antenne?
  //Die Antennenhöhe kann auch über die GNSS-View und dort über die Messeinstellungen gesetzt
  //werden.`,

  pref_gnss_use_height_component: `Diese Option zu aktivieren macht nur Sinn,
  wenn ein externer GNSS-Empfänger in Verbindung mit einem Korrekturdienst verwendet wird.
  Bei der Verwendung des integrierten GNSS-Empfängers ist mit einer unzureichenden Genauigkeit
  zu rechnen. Alternativ kann über die manuelle Zuordnung auf der Kartenansicht auf Basis
  eines GeoJson Punkt-Layers, die Höhenkomponente gesetzt werden.`,
  geo_export_crs: `Auswahl eines Koordinatensystems für den Geodaten
  Export. Mögliche Werte sind utm, gauss_krueger, ch1903plus und custom. Wenn Sie custom auswählen, müssen auf 
  dem Smartphone des Anwenders weitere Einstellungen vorgenommen werden.`,

  pref_export_files: `Hier können Sie auswählen welche
  Export-Formate bei einem Datenupload erzeugt und hochgeladen werden.`,
  pref_export_pdf_pages: `Hier können Sie auswählen welche PDF-Seiten für den
  Report erzeugt werden sollen.`,
};

export const appSettingsOrderingCategoryTitle = {
  [ORDERING_TYPE_INTRO_TOUR]: "Intro Touren durch die App",
  [ORDERING_TYPE_HINT_DIALOGS]: "Hilfsdialoge",
  [ORDERING_TYPE_EXPORT]: "Export",
  [ORDERING_TYPE_ADVANCED_SETTINGS]: "Expertenmodus",
  [ORDERING_TYPE_GNSS]: "GNSS",
  [ORDERING_TYPE_TRANSFORMATION]: "Karteneinstellungen",
};

export const appSettingsOrderingCategoryDescription = {
  [ORDERING_TYPE_INTRO_TOUR]: `Interaktive Touren führen mit eingeblendeten Sprechblasen durch die wichtigsten Funktionen dieser Anwendung. Sie können bereits 
  angeschaute Touren jederzeit wieder aktivieren.`,
  [ORDERING_TYPE_HINT_DIALOGS]: `Aktivieren Sie die Anzeige von bestimmten Hinweisedialogen. Hinweisdialoge werden an wichtigen Stellen der Anwendung eingeblendet,
  die Anzeige kann aber unterdrückt werden.`,
  [ORDERING_TYPE_EXPORT]:
    "Sie können hier festlegen, welche Dateiformate bei einem Datenexport erzeugt werden sollen und wie umfangreich der PDF-Report ausfallen soll.",
  [ORDERING_TYPE_ADVANCED_SETTINGS]: `Netpipe bietet Ihnen die Möglichkeit, eine Feineinstellung diverse Aspekte wie beispielsweise während einer Aufnahme, vorzunehmen.
  Überprüfen Sie in jedem Fall jede Änderung vor einer richtigen Aufnahme.`,
  [ORDERING_TYPE_GNSS]: "GNSS",
  [ORDERING_TYPE_TRANSFORMATION]: `Treffen Sie hier Einstellungen, die für die Überführung Ihrer Aufnahmen in Gebrauchskoordinaten für Ihr Bestandssystem relevant sind.`,
};

const arCameraUpdateRateWordList = [
  { value: "30_fps", text: "30 FPS" },
  { value: "60_fps", text: "60 FPS" },
];
const geoExportCrsWordList = [
  { value: "utm", text: "UTM" },
  { value: "gauss_krueger", text: "Gauß Krüger" },
  { value: "ch1903plus", text: "CH1903+" },
  { value: "custom", text: "Custom" },
];

export const dropdownSettings = {
  ar_camera_update_rate: arCameraUpdateRateWordList,
  geo_export_crs: geoExportCrsWordList,
};

/*
{
		"key": "pref_export_files",
		"type": "StringSet",
		"value": ["11", "1", "2", "4", "8", "9", "10"]
	}, {
		"key": "pref_export_pdf_pages",
		"type": "StringSet",
		"value": ["11", "12", "14", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]
	}
*/

export const prefExportFiles = {
  1: "WKT",
  2: "DXF",
  3: "DXF 8-stellig",
  4: "SHAPE",
  5: "DXF Lokal",
  6: "CSV Lokal",
  7: "TXT Lokal",
  8: "Bilder",
  12: "Skizze als Bild",
  9: "PDF-Report",
  10: "Transformationsparameter",
  11: "Datenmonitoring CSV",
  13: "GNSS-GeoJSON"
};

export const prefExportPdfPages = {
  1: "Cover",
  14: "Übersichtskarte",
  2: "Übersichtsskizze (ab 2 Linien)",
  3: "Skizze",
  4: "Messpunktkarten",
  5: "Messpunkt bezogene Tabellen",
  6: "Topographische Punktkarten",
  7: "Topographisch bezogene Tabellen",
  8: "Referenzpunktkarten",
  9: "Transformationsparameter",
  10: "Transformation-Genauigkeit",
  11: "Screenshots",
  12: "Volumen",
};

export const stringSetMap = {
  pref_export_files: prefExportFiles,
  pref_export_pdf_pages: prefExportPdfPages,
};

export const editAppSettingsDialogTitle =
  "Netpipe Android-App Einstellungen editieren";
export const editAppSettingsDialogDescription =
  "Alle Einstellungen, die hier angezeigt werden, betreffen die Netpipe Android Mobil-App. Änderungen an den Einstellungen werden erst durch den Speichern-Button auf der Profilansicht wirksam.";
